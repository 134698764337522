import React from 'react'
import HelperDom from '../helper/helper-dom'
import HowToScorePopup from '../components/HowToScorePopup'
import RequiredLoginComponent from '../components/RequiredLogin'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import Seo from '../helper/seo'

@inject('loginStore')
@observer
export default class ToBeAuthorComponent extends React.Component<
ITobeAuthorProp,
IToBeAuthorState
> {
	constructor(props: ITobeAuthorProp) {
		super(props)

		this.btnTermsLogin = this.btnTermsLogin.bind(this)
	}

	btnTermsLogin(e: React.MouseEvent<HTMLElement>) {
		e.preventDefault()

		this.props.loginStore.openRegisterModal()
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>{Seo.getTitle('Yazar Ol')}</title>
				</Helmet>
				<RequiredLoginComponent title="Yazar olmak için üye olmanız gerekmektedir." />
				<div className="full-w">
					<div className="how-to-banner-area">
						<img
							src="Content\img\seturday-yazar-ol.jpg"
							className="how-to-img"
							alt="Yazar Ol"
						/>
					</div>
					<div className="writer-intro-wrapper">
						<div className="writer-intro-steps clearfix">
							<div className="intro-step">
								<div className="step-col">
									<img
										src="Content\yazigonder\img\step-1.png"
										className="step-img"
										alt="Yazar Ol"
									/>
								</div>
								<div className="step-col">
									<div className="step-text">
										<h4>Üye Ol!</h4>
										<p>
											Seturday’de yazılarını paylaşmak için ilk adımı şimdi at,
											hemen üye ol!
										</p>
										{window.sessionStorage.getItem('userInfo') !== null ? (
											<Link className="sd-btn" to={'/yazi-gonder'}>
												Yazı Gönder
											</Link>
										) : (
											<a
												href="#"
												onClick={this.btnTermsLogin}
												className="sd-btn"
												id="terms-login-button">
													Üye Ol
											</a>
										)}
									</div>
								</div>
							</div>
							<div className="intro-step">
								<div className="step-col">
									<div className="step-text">
										<h4>Yazını yükle!</h4>
										<p>
											Hadi sen de Seturday yazarı ol!
											<br />
											Seyahate dair sırlarını ve tavsiyelerini paylaştığın
											Seturday.com’da özgün seyahat içerikleri üret.
										</p>
									</div>
								</div>
								<div className="step-col">
									<img
										src="Content\yazigonder\img\step-2.png"
										className="step-img"
										alt="Yazar Ol"
									/>
								</div>
							</div>
							<div className="intro-step">
								<div className="step-col">
									<img
										src="Content\yazigonder\img\step-3.png"
										className="step-img"
										alt="Yazar Ol"
									/>
								</div>
								<div className="step-col">
									<div className="step-text">
										<h4>Onaylansın!</h4>
										<p>
											Seturday.com’a yazılı ve görsel içeriklerini gönder.
											İçeriğin editörlerimiz tarafından kontrol edilsin,
											onaylananlar sitemizde yayınlansın.
										</p>
									</div>
								</div>
							</div>
							<div className="intro-step">
								<div className="step-col">
									<div className="step-text">
										<h4>
											Onaylı yazılarınla
											<br />
											150 TL kazan!
										</h4>
										<p>
											Seturday.com’da yayınlanan her içeriğinle 150 TL kazan!
										</p>
									</div>
								</div>
								<div className="step-col">
									<img
										src="Content\yazigonder\img\step-4.png"
										className="step-img"
										alt="Yazar Ol"
									/>
								</div>
							</div>
							<div className="intro-step">
								<div className="step-col">
									<img
										src="/Content/yazigonder/img/step-5.png"
										className="step-img"
										alt="Yazar Ol"
									/>
								</div>
								<div className="step-col">
									<div className="step-text">
										<h4>
											Seturday Gezgini
											<br />
											olma fırsatını yakala!
										</h4>
										<p>
											Hem yayınlanan yazılarınla, hem de sitedeki hareketlerinle
											<br />
											{/* puan topla ve */} Seturday Gezgini olma şansını yakala.
										</p>
										{/*<a
											href="#"
											onClick={e => e.preventDefault()}
											className="sd-btn"
											id="HowToScoreLink">
											Nasıl Puan Kazanırım?
										</a>*/}
									</div>
								</div>
							</div>
						</div>
						<div className="terms-accordion-area">
							<h5 className="terms-title">Yazarlık Şartları</h5>
							<div className="terms-accordion">
								<div className="accordion-item">
									<div className="collapse">
										<p className="heading highlighted">Üye Olma Kuralları</p>
										<ul>
											<li>
												Seturday.com yazarı olabilmek için, öncelikle
												Seturday.com üyesi olunmalıdır.
											</li>
											<li>
												Seturday üyesi olmak için, Üye Ol butonuna tıklayarak
												üyelik işlemlerinizi tamamlayabilirsiniz.
											</li>
										</ul>
										<p className="heading highlighted">
											Görsel Ekleme Kuralları
										</p>
										<ul>
											<li>
												Yükleyeceğiniz görsel en fazla 2 MB büyüklüğünde ve JPEG
												formatında olmalıdır.
											</li>
											<li>En az 5 en çok 8 görsel kullanılmalıdır.</li>
											<li>
												Size ait olmayan görseller için telif haklarının ihlali
												çerçevesinde yasal işlem başlatılacaktır.
											</li>
											<li>
												İçerikte yer alan görsellerin açıklaması yapılarak, yazı
												içerisinde görselin ait olduğu lokasyon belirtilmelidir.
											</li>
										</ul>
										<p className="heading highlighted">
											Yazı Karakter Sınırlamaları
										</p>
										<ul>
											<li>
												Yazınızda kullanacağınız karakter sayısı 3500 - 5500
												sınırını aşmamalıdır.
											</li>
											<li>
												Yazınız en az 450 en çok 800 kelime içermelidir.
												Başlığınız 25-70 karakter sınırına uygun olmalıdır.
												Başlığınızda kullanacağınız kelime sayısı ise en az 3 en
												çok 8 kelime sınırına uygun olmalıdır.
											</li>
											<li>
												İçerik oluşturduktan sonra yazınızda, en az 1 en çok 5
												adet olacak şekilde etiket eklenmelidir.
											</li>
										</ul>
										<p className="heading highlighted">
											Özgün İçerik Oluşturma
										</p>
										<ul>
											<li>
												Yazı içeriğinin daha önce yayınlanmış yazıların
												içeriğiyle benzerlik taşıması durumunda Setur, yazıyı
												yayınlamama hakkına sahiptir.
											</li>
											<li>
												Yazı içeriğinde alkole özendirici, hakaret içeren
												herhangi bir görsel veya metnin yer alması halinde
												yazınız yayınlanmayacaktır.
											</li>
											<li>
												Yazının daha önce bir yerde yayınlanmış olması halinde
												yazınız yayınlanmayacaktır. Tüm görsel ve yazı
												içeriğinden kullanıcı sorumludur.
											</li>
										</ul>
										<p className="heading highlighted">Ödeme Koşulları</p>
										<ul>
											<li>
												Yayınlanan yazınla kazandığın 150 TL’nin sana ulaşması
												için; Nüfus Cüzdanı Ön/Arka Yüz Görüntüsü, İsim Soyisim,
												Adres, IBAN No, Banka ve Şube Adı bilgilerini
												seturdayyazarol@setur.com.tr adresine iletmeniz
												gerekmektedir.
											</li>
											<li>
												Yazınızın onaylanmasının ardından yayınını takip eden 15
												iş günü içerisinde ödemeniz, vermiş olduğunuz IBAN
												numarasına gerçekleştirilecektir.
											</li>
											<li>
												Bilgileriniz Setur A.Ş. Muhasebe birimi tarafından
												alınmakta olup, sizin adınıza gerçekleştirilecek
												ödemenin vergilerini ödeyerek, bilgilerinizi yasal
												düzenlemeye tabi şekilde muhafaza etmektedir.
											</li>
										</ul>
										<p className="heading highlighted">
											Yazı Yayınlanma Koşulları
										</p>
										<ul>
											<li>
												Sisteme yüklediğiniz tüm yazılar Seturday.com editörleri
												tarafından kısaltılabilir, düzeltilebilir veya
												değiştirilebilir.
											</li>
										</ul>
										<p className="heading highlighted">Yazı Onay Koşulları</p>
										<ul>
											<li>
												Seturday.com sitesine yüklemiş olduğunuz yazı, herhangi
												bir sebepten dolayı onaylanmayabilir. İçeriğin durumu
												Seturday.com editörleri tarafından belirlenir. Bu sitede
												yayınlanan yazı, görsel ve fotoğrafların tüm hakları
												Seturday.com’a aittir.
											</li>
										</ul>
										<p className="heading highlighted">Kategoriler</p>
										<p className="heading colored">Trendy</p>
										<p>
											Trendy kategorisi, seyahat dünyasının trendleri, en moda
											seyahat rotaları ve dünyada mutlaka görülmesi gereken
											noktaları içeren yazılardan oluşmaktadır.
										</p>
										<p className="heading colored">Gurme</p>
										<p>
											Gurme kategorisi, lokal lezzetler, dünyanın farklı
											noktalarındaki özgün lezzetler, sunumlar ve mutfak
											kültürlerini anlatan yazılardan oluşmaktadır.
										</p>
										<p className="heading colored">Bleisure</p>
										<p>
											Bleisure kategorisi, iş seyahatlerini keyifli yolculuklara
											dönüştürebilecek ipuçlarını konu edinene yazılardan
											oluşmaktadır.
										</p>
										<p className="heading colored">Art</p>
										<p>
											Art kategorisi, dünyanın dört bir yanında yer alan sanat
											eserleri ve sanatsal etkinlik programlarını içeren
											yazılardan oluşmaktadır.
										</p>
										<p className="heading colored">Adventurist</p>
										<p>
											Adventurist kategorisi, seyahatleri macera dolu
											yolculuklara dönüştürecek, dünyadaki ekstrem spor rotaları
											gibi başlıkları konu edinen yazılardan oluşmaktadır.
										</p>
										<p className="heading colored">Naturally</p>
										<p>
											Naturally kategorisi, dünyanın doğal güzelliklerini,
											farklı coğrafyaların kendine has iklimini ve canlı
											çeşitliliğini konu edinen içerikleri kapsamaktadır.
										</p>
										<p className="heading colored">Insider</p>
										<p>
											Insider kategorisi, keşif seyahatleri ve dünyanın farklı
											yerlerinde bilinmeyenlerle dolu yolculukları anlatan
											yazılardan oluşmaktadır.
										</p>
										<p className="heading colored">SetList</p>
										<p>
											SetList kategorisi, dünyanın hangi köşesi ne ile ünlü,
											seyahatin “en”leri neler ve hangi lokasyon ne için ziyaret
											etmeli’yi anlatan yazılardan oluşmaktadır.
										</p>
										<p className="heading colored">SetTalks</p>
										<p>
											SetTalks kategorisi, sanat dünyasının önemli isimlerinin
											dünyanın farklı ülkelerinde yaşarken gözlemledikleri
											kültürleri ve turistik deneyimleri konu edinen yazılardan
											oluşmaktadır.
										</p>
									</div>
								</div>
							</div>
						</div>
						{
							this.props.loginStore.isLogin ? <div className="next-writer-intro-area">
								{!this.props.loginStore.user.isAuthor ? (
									<Link className="sd-btn" to={'/yazi-gonder'}>
										İlk Yazını Yaz
									</Link>
								) : (
									<Link className="sd-btn" to={'/yazi-gonder'}>
											Yazı Gönder
									</Link>
								)}
							</div> :
								<div className="next-writer-intro-area">
									<a href="#" onClick={(e) => e.preventDefault()} id="WriterNotValid" className="sd-btn">
										İlk Yazını Yaz
									 </a>
								</div>
						}

					</div>
				</div>
				<HowToScorePopup />
			</div>
		)
	}

	componentDidMount() {
		HelperDom.loadScript('Content/yazigonder/main.js')
		HelperDom.loadStyle('Content/yazigonder/yazarol.css')

		$('#WriterNotValid').on('click', function () {
			$('.error-overlay').addClass('active')
		})
		$('#HowToScoreLink').on('click', function () {
			$('#HowToScorePopup').fadeIn()
			$('body').addClass('overflow-hidden')
		})
		$('#HowToScorePopup .popup-toggle-close').on('click', function () {
			$('#HowToScorePopup').fadeOut()
			$('body').removeClass('overflow-hidden')
		})
	}
}

interface ITobeAuthorProp {
	loginStore: ILoginStore
}

interface IToBeAuthorState { }
