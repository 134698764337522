import React, {Component} from 'react'
import RequiredLoginComponent from '../components/RequiredLogin'
import {observable, observe, reaction, intercept} from 'mobx'
import {inject, observer} from 'mobx-react'
import Const from '../const'
import Notification from '../helper/notification'
import ScriptCache from '../helper/script.cache'

//const key = process.env.NODE_ENV === 'development' ? 'AIzaSyA_-Ie_qvm8KMMAOV_yK72qV2ovPeQ5mj0' : 'AIzaSyAI2xJA2-PfS8v7JZXp40YPk0YLVc41lQo';
//console.log('NODE_ENV', process.env.NODE_ENV)
const key = 'AIzaSyA_-Ie_qvm8KMMAOV_yK72qV2ovPeQ5mj0'

@inject('loginStore', 'bucketStore', 'visitedPlaceStore')
@observer
export default class BucketListMapComponent extends Component<
	IBucketListMapProp,
	IBucketListMapState
> {
	refList: React.RefObject<HTMLDivElement> = React.createRef()
	map: React.RefObject<HTMLDivElement> = React.createRef()
	refBucketListMapOverlay: React.RefObject<HTMLDivElement> = React.createRef()
	refBucketListMapModal: React.RefObject<HTMLDivElement> = React.createRef()
	refPacInput: React.RefObject<HTMLInputElement> = React.createRef()
	showingModal: boolean = false

	markersBucket: any[] = []
	mapBucket: any
	current: any
	icon: any

	constructor(props: IBucketListMapProp) {
		super(props)

		this.closeModal = this.closeModal.bind(this)
		this.drawBucketListItem = this.drawBucketListItem.bind(this)
		this.drawVisitedPlaceItem = this.drawVisitedPlaceItem.bind(this)
	}

	async getVisitedPlaceList() {
		await this.props.visitedPlaceStore.getVisitedPlace()
		this.setState({})
	}

	async getBucketList() {
		await this.props.bucketStore.getBucketList()
		this.setState({})
	}

	componentWillReceiveProps(nextProps: IBucketListMapProp) {
		if (nextProps.loginStore.user.Id > 0 && this.showingModal) {
			this.showListPanel()
		}
	}

	showListPanel() {
		this.refList.current.classList.add('active')
		this.showingModal = true
	}

	hideListPanel() {
		this.refList.current.classList.remove('active')
		this.showingModal = false
	}

	checkLogin(userId: number) {
		if (userId > 0) {
			//$('#login-map').removeClass('active')
			if (this.props.isBucketList) {
				this.getBucketList()
			} else if (this.props.isVisitedPlaceList) {
				this.getVisitedPlaceList()
			}
			return true
		} else {
			$('#login-map').addClass('active')
			return false
		}
	}

	componentDidMount() {
		this.checkLogin(this.props.loginStore.user.Id || 0)
		
		const user = observable(this.props.loginStore.user)
		observe(user, change => {
			this.checkLogin(change.object.Id)
		})

		if(this.checkLogin(this.props.loginStore.user.Id || 0)){

			const scriptCache = ScriptCache({
				googleMaps:
					'https://maps.googleapis.com/maps/api/js?key=' + 
					key + 
					'&libraries=places&callback=initializeMap&language=tr'
			})

			scriptCache.googleMaps.onLoad(() => {
				try {
					this.initMap()
				} catch (error) {}
			})

			window.initializeMap = () => {}
		}

		var mut = new MutationObserver((mutations, mut) => {
			let mutation = mutations[0]
			if (
				mutation.type === 'attributes' &&
				mutation.attributeName === 'class'
			) {
				let divElement: HTMLDivElement = mutation.target as any
				if (
					divElement.classList.contains('active') &&
					this.props.loginStore.user.Id > 0
				) {
					setTimeout(() => {
						this.showListPanel()
					}, 1500)
				} else {
					this.hideListPanel()
				}
			}
		})
		
		mut.observe(this.refBucketListMapOverlay.current, {
			attributes: true
		})
	}

	async changeReadyToGo(bucket: IBucket) {
		const {user} = this.props.loginStore
		if (
			!bucket.Gitmekistiyorum &&
			!user.AvaibleForCall &&
			user.Emailgelsin === 0 &&
			!user.AvaibleForSms
		) {
			var mesaj =
				'Sizinle iletişime geçebilmemiz için hesabım sayfasından SMS, E-mail veya telefon seçeneklerinden en az bir tanesine izin vermeniz gerekmektedir.'
			var link =
				' İzin vermek için <a href="/hesabim/profilim" class="hesabim-link-white">tıklayın</a>'
			Notification.showToast(mesaj + link, 50000)
			bucket.Gitmekistiyorum = false
		} else {
			await this.props.bucketStore.changeReadyToGo(bucket)
		}
	}

	async remove(e: React.MouseEvent<HTMLAnchorElement>, bucket: IBucket) {
		e.preventDefault()
		const { CityCountryModel } = bucket
		const res = await this.props.bucketStore.remove(bucket)
		window.dataLayer.push({
			'event': 'removeFromCart',
			'remove': {
				'location': [{
					GooglePlaceId: CityCountryModel.GooglePlaceId,
					Id: CityCountryModel.Id,
					City: CityCountryModel.Sehir.trim(),
					Country: CityCountryModel.Ulke.trim(),
				}]
			}
		})
		if (res) {
			this.clearBucketMapMarkers()
			this.props.bucketStore.bucketList.forEach(bucket =>
				this.drawBucketMapMarkers(bucket)
			)
		}
	}

	async removeVisitedPlace(
		e: React.MouseEvent<HTMLAnchorElement>,
		visitedPlace: IVisitedPlace
	) {
		e.preventDefault()
		const res = await this.props.visitedPlaceStore.remove(visitedPlace)
		if (res) {
			this.clearBucketMapMarkers()
			this.props.visitedPlaceStore.visitedPlaces.forEach(place =>
				this.drawVisitedPlaceItem(place)
			)
		}
	}

	drawVisitedPlaceItem(place: IVisitedPlace) {
		let {GooglePlaceId, Resim, Sehir, Ulke} = place
		if (!place) return
		try {
			this.drawBucketMapMarkers(place)
			return (
				<li
					key={`place-list-item-${place.Id}`}
					className="active go-li"
					data-placeid={GooglePlaceId}>
					<div className="list-img">
						<img src={Const.seturdayCdnBuckerImageUrl(Resim)} />
					</div>
					<div className="list-area-left">
						<span>
							{Sehir}, {Ulke}
						</span>
					</div>
					<div className="list-area-right">
						<a onClick={e => this.removeVisitedPlace(e, place)}>
							<img src="/Content/img/delete-icon.png" />
						</a>
					</div>
				</li>
			)
		} catch (error) {}
	}

	drawBucketListItem(bucket: IBucket) {
		let {Resim, GooglePlaceId, Sehir, Ulke} = bucket.CityCountryModel
		if (!bucket && !bucket.CityCountryModel) return
		try {
			this.drawBucketMapMarkers(bucket.CityCountryModel)
			return (
				<li
					key={`bucket-list-item-${bucket.Id}`}
					className="active bucket-li"
					data-placeid={GooglePlaceId}>
					<div className="list-img">
						<img src={Const.seturdayCdnBuckerImageUrl(Resim)} />
					</div>
					<div className="list-area-left">
						<span className="bucket-span">
							<p className="bucket-span-p">
								{Sehir} {Ulke}
							</p>
						</span>
						<div className="ready-to-go-amount">
							<p>
								<span>0</span> kişinin listesinde.
							</p>
							<p>
								<span>0</span> kişi gitmeye hazır.
							</p>
						</div>
						<div className="remember-area">
							<div
								className="wrap"
								onClick={() => this.changeReadyToGo(bucket)}>
								<input
									type="checkbox"
									name="remember-bucket"
									id={GooglePlaceId}
									checked={bucket.Gitmekistiyorum}
									onChange={() => true}
									value="valuable"
									className={`remember ${
										bucket.Gitmekistiyorum ? 'checked' : ''
									}`}
								/>
								<label className="mark" htmlFor={GooglePlaceId}></label>
							</div>
							<label className="rememberText" htmlFor={GooglePlaceId}>
								Gitmeye Hazırım
							</label>
						</div>
					</div>
					<div className="list-area-right">
						<a href="#" onClick={e => this.remove(e, bucket)}>
							<img src="/Content/img/delete-icon.png" />
						</a>
					</div>
				</li>
			)
		} catch (error) {}
	}

	clearBucketMapMarkers() {
		try {
			for (let item of this.markersBucket) {
				item.setMap(null)
			}
			this.markersBucket = []
		} catch (error) {
		} finally {
			this.markersBucket = []
		}
	}

	drawBucketMapMarkers(place: IVisitedPlace) {
		if (
			this.markersBucket.filter(item => item.placeid === place.GooglePlaceId)
				.length > 0
		)
			return
		try {
			let {GooglePlaceId, Lat, Lng, Sehir, Ulke} = place
			this.markersBucket.push(
				new google.maps.Marker({
					placeid: GooglePlaceId,
					position: new google.maps.LatLng(parseInt(Lat), parseInt(Lng)),
					map: this.mapBucket,
					icon: this.icon,
					title: Sehir.trim() + ', ' + Ulke.trim()
				})
			)
		} catch (error) {}
	}

	async initMap() {
		this.mapBucket = new google.maps.Map(this.map.current, {
			center: {
				lat: 41,
				lng: 29
			},
			zoom: 3,
			maxZoom: 8,
			mapTypeId: 'roadmap',
			zoomControl: true,
			zoomControlOptions: {
				position: google.maps.ControlPosition.LEFT_CENTER
			},
			mapTypeControl: false,
			scaleControl: false,
			streetViewControl: false,
			rotateControl: false,
			fullscreenControl: false,
			styles: [
				{
					featureType: 'all',
					elementType: 'labels.text.fill',
					stylers: [
						{
							saturation: 36
						},
						{
							color: '#333333'
						},
						{
							lightness: 40
						}
					]
				},
				{
					featureType: 'all',
					elementType: 'labels.text.stroke',
					stylers: [
						{
							visibility: 'on'
						},
						{
							color: '#ffffff'
						},
						{
							lightness: 16
						}
					]
				},
				{
					featureType: 'all',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'administrative',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#fefefe'
						},
						{
							lightness: 20
						}
					]
				},
				{
					featureType: 'administrative',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#fefefe'
						},
						{
							lightness: 17
						},
						{
							weight: 1.2
						}
					]
				},
				{
					featureType: 'administrative.locality',
					elementType: 'all',
					stylers: [
						{
							visibility: 'on'
						}
					]
				},
				{
					featureType: 'landscape',
					elementType: 'geometry',
					stylers: [
						{
							color: '#ffffff'
						},
						{
							lightness: 20
						}
					]
				},
				{
					featureType: 'poi',
					elementType: 'geometry',
					stylers: [
						{
							color: '#f1f1f1'
						},
						{
							lightness: 21
						}
					]
				},
				{
					featureType: 'road.highway',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#dedede'
						},
						{
							lightness: 17
						}
					]
				},
				{
					featureType: 'road.highway',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#dedede'
						},
						{
							lightness: 29
						},
						{
							weight: 0.2
						}
					]
				},
				{
					featureType: 'road.arterial',
					elementType: 'geometry',
					stylers: [
						{
							color: '#dedede'
						},
						{
							lightness: 18
						}
					]
				},
				{
					featureType: 'road.local',
					elementType: 'geometry',
					stylers: [
						{
							color: '#ffffff'
						},
						{
							lightness: 16
						}
					]
				},
				{
					featureType: 'transit',
					elementType: 'geometry',
					stylers: [
						{
							color: '#f2f2f2'
						},
						{
							lightness: 19
						}
					]
				},
				{
					featureType: 'water',
					elementType: 'geometry',
					stylers: [
						{
							color: '#a0d6d1'
						},
						{
							lightness: 17
						}
					]
				}
			]
		})

		var options = {
			types: ['(cities)']
		}

		this.icon = {
			url: '/content/img/pin.png',
			size: new google.maps.Size(71, 71),
			origin: new google.maps.Point(0, 0),
			anchor: new google.maps.Point(8, 28),
			scaledSize: new google.maps.Size(15, 21)
		}

		try {
			// AUTO COMPLETE START //
			var autocomplete = new google.maps.places.Autocomplete(
				this.refPacInput.current,
				options
			)
			this.mapBucket.controls[google.maps.ControlPosition.TOP_CENTER].push(
				this.refPacInput.current
			)
			// Bias the SearchBox results towards current map's viewport.

			this.mapBucket.addListener('bounds_changed', () => {
				autocomplete.setBounds(this.mapBucket.getBounds())
			})

			let zoomTimeout: NodeJS.Timeout = null
			this.mapBucket.addListener('zoom_changed', () => {
				if (zoomTimeout) clearTimeout(zoomTimeout)
				zoomTimeout = setTimeout(() => {
					this.clearBucketMapMarkers()
					this.props.bucketStore.bucketList.forEach(bucket =>
						this.drawBucketMapMarkers(bucket.CityCountryModel)
					)
				}, 750)
			})

			// Listen for the event fired when the user selects a prediction and retrieve
			// more details for that place.
			autocomplete.addListener('place_changed', () => {
				var place = autocomplete.getPlace()

				$(this.refBucketListMapModal.current)
					.find('.map-in')
					.addClass('active')
				$(this.refBucketListMapModal.current)
					.find('.boxes-top')
					.addClass('active')

				$(this.refBucketListMapModal.current)
					.find('.list-area ul')
					.animate(
						{
							scrollTop: $(this.refBucketListMapModal.current)
								.find('.list-area ul')
								.prop('scrollHeight')
						},
						1000
					)

				// For each place, get the icon, name and location.
				var bounds = new google.maps.LatLngBounds()

				if (!place.geometry) {
					console.log('Returned place contains no geometry')
					return
				}

				this.current = place

				let getImg = 'no-image'
				if (place.hasOwnProperty('photos')) {
					getImg = place.photos[0].getUrl({
						maxHeight: 240
					})
				}

				if (this.props.isBucketList) {
					let currentPlace: IBucket = {
						CityCountryModel: {
							GooglePlaceId: place.place_id,
							Lat: place.geometry.location.lat(),
							Lng: place.geometry.location.lng(),
							Resim: getImg,
							Sehir: place.name,
							Ulke: place.address_components.filter(
								(x: any) => x.types[0] == 'country'
							)[0].long_name
						}
					}

					this.props.bucketStore.add(currentPlace).then(res => {
						if (res) {
							Notification.showToast('Listeye eklenmiştir.')
						} else {
							$('.map-same-value').addClass('active')
							setTimeout(() => {
								$('.map-same-value').removeClass('active')
							}, 2500)
						}
					})
				} else if (this.props.isVisitedPlaceList) {
					let currentPlace: IVisitedPlace = {
						GooglePlaceId: place.place_id,
						Resim: getImg,
						Lat: place.geometry.location.lat(),
						Lng: place.geometry.location.lng(),
						Sehir: place.name,
						Ulke: place.address_components.filter(
							(x: any) => x.types[0] == 'country'
						)[0].long_name
					}
					this.props.visitedPlaceStore.add(currentPlace).then(res => {
						if (res) {
							Notification.showToast('Listeye eklenmiştir.')
						} else {
							$('.map-same-value').addClass('active')
							setTimeout(() => {
								$('.map-same-value').removeClass('active')
							}, 2500)
						}
					})
				}

				if (place.geometry.viewport) {
					// Only geocodes have viewport.
					bounds.union(place.geometry.viewport)
				} else {
					bounds.extend(place.geometry.location)
				}
				this.mapBucket.fitBounds(bounds)
			})
		} catch (error) {}
	}

	closeModal() {
		$('.sedvice-circle').removeClass('active')
		$(this.refBucketListMapOverlay.current).removeClass('active')
		$(this.refBucketListMapModal.current).removeClass('active')
		$('body').removeClass('pfix-sb')
		$('first-overlay').removeClass('pfix-sb')
		setTimeout(function() {
			$('.main-wrap').removeClass('ov-pe')
		}, 2500)
	}

	saveAfterCloseModal = () => {
		if ($('#bucket-toaster').hasClass('active')) return false
		$('.sedvice-circle').removeClass('active')
		$(this.refBucketListMapOverlay.current).removeClass('active')
		$(this.refBucketListMapModal.current).removeClass('active')
		$('body').removeClass('pfix-sb')
		$('first-overlay').removeClass('pfix-sb')
		setTimeout(function() {
			$('.main-wrap').removeClass('ov-pe')
		}, 2500);
		Notification.showToast('Değişiklikler başarıyla kaydedildi.');
	}

	get getClassess() {
		return `${
			this.props.isBucketList
				? 'bucket-area'
				: this.props.isVisitedPlaceList
					? 'new-map new-map-area'
					: ''
		}`
	}

	get getId() {
		return this.props.isBucketList
			? 'bucket-modal'
			: this.props.isVisitedPlaceList
				? 'new-map-modal'
				: ''
	}

	gotoInterest(e: React.MouseEvent<HTMLElement>) {
		e.preventDefault()

		$('#new-map-modal').removeClass('active')
		$('#interest-modal').addClass('active')
	}

	render() {
		return (
			<div
				className={`${
					this.props.isBucketList
						? 'overlay bucket-overlay'
						: this.props.isVisitedPlaceList
							? 'overlay new-map-overlay'
							: ''
				}`}
				ref={this.refBucketListMapOverlay}>
				<div
					ref={this.refBucketListMapModal}
					className={this.getClassess}
					id={this.getId}>
					{this.props.isBucketList && (
						<RequiredLoginComponent
							id="login-map"
							onClose={this.closeModal}
							title="Bucket List oluşturabilmeniz için üye olmanız veya giriş yapmanız gerekmektedir."
						/>
					)}
					<div className="boxes-top">
						<div className="line"></div>
						<div className="line-bottom-icon">
							<img src="content\img\map-icon.png" />
						</div>
					</div>
					<div className="map-in">
						<div className="boxes-title">
							{this.props.isBucketList && (
								<>
									<h3> Bucket List Oluşturun </h3>
									<p>
										<b>
											{' '}
											Gitmediğiniz ama gitmeyi çok istediğiniz şehirler sizi
											bekliyor!
										</b>{' '}
										Tek yapmanız gereken bu şehirleri seçmek ve “gitmeye
										hazırım” demek. Biz de gitmeyi hayal ettiğiniz şehirlere ait
										turlarla ilgili size bilgi verelim.
									</p>
								</>
							)}
							{this.props.isVisitedPlaceList && <h3> Gittiğim Yerler </h3>}
						</div>
						<div className="map-div">
							<div className="map-same-value">
								<p>
									Listenizde bu şehir bulunuyor... <br />
									Tekrar ekleyemezsiniz!!
								</p>
							</div>
							<div className="map-input-area">
								{!this.props.loginStore.user.Id && (
									<div className="first-tooltip active">
										<p>
											Gittiğiniz yerleri seçerek diğer gezginlerle
											tecrübelerinizi paylaşabilirsiniz.
										</p>
									</div>
								)}
								<input
									ref={this.refPacInput}
									id="pac-input-bucket"
									onClick={() => $('pac-input-bucket').val('')}
									className="map-input controls"
									type="text"
									onFocus={() => $('.first-tooltip').removeClass('active')}
									placeholder="Bir Şehir Arayın"
								/>
							</div>
							<div
								id={
									this.props.isBucketList
										? 'map-bucket'
										: this.props.isVisitedPlaceList
											? 'map-new'
											: ''
								}
								ref={this.map}></div>
							<div className="list-area" ref={this.refList}>
								<ul>
									{this.props.isBucketList &&
										this.props.bucketStore.bucketList.map(
											this.drawBucketListItem
										)}
									{this.props.isVisitedPlaceList &&
										this.props.visitedPlaceStore.visitedPlaces.map(
											this.drawVisitedPlaceItem
										)}
								</ul>
								<div className={this.props.isBucketList ? 'bucket-save-area' : 'new-map-save-area'}>
									<input
										type="button"
										id="bucketsavebtn"
										name="bucketsave"
										value="Kaydet"
										onClick={this.saveAfterCloseModal}
									/>
								</div>
							</div>
						</div>
						{!this.props.loginStore.user.Ilgialanlari && (
							<div className="next-button">
								<div className="map-next-tooltip">
									<p>
										Hesabım sayfasından daha sonra gittiğiniz yerlere ekleme
										yapabilirsiniz! Keyifli keşifler!
									</p>
								</div>
								<a href="#" onClick={this.gotoInterest} className="map-next">
									İleri
								</a>
							</div>
						)}
					</div>
					<div className="bucket-close-area">
						<img src="content\img\bucket-close.png" onClick={this.closeModal} />
					</div>
				</div>
			</div>
		)
	}
}

interface IBucketListMapProp {
	isBucketList?: boolean
	isVisitedPlaceList?: boolean
	loginStore?: ILoginStore
	bucketStore?: IBucketStore
	visitedPlaceStore?: IVisitedPlaceStore
}

interface IBucketListMapState {}
