import React, { Component, Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import Const from '../const'

@inject('loginStore', 'bucketStore')
@observer
class Header extends Component<HeaderProps, HeaderState> {
	constructor(props: HeaderProps) {
		super(props)

		this.btnLogin = this.btnLogin.bind(this)
	}


	showMapModal = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		event.preventDefault();
		$('.bucket-overlay').addClass('active')
		$('#bucket-modal').addClass('active')
		$('.sedvice-circle').addClass('active')
		$('body').addClass('pfix-sb')
		$('first-overlay').addClass('pfix-sb')
		$('.main-wrap').addClass('ov-pe')
		if (!this.props.loginStore.user.Id) {
			$('.for-like-error').addClass('active')
		} else {
			$('.for-like-error').removeClass('active')
			this.props.bucketStore.getBucketList();
		}
	}

	render() {
		return (
			<div className='top-menu active-profile'>
				<div className='hamburger-menu'>
					<div id='nav-icon1'>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
				<div className='logo-area'>
					<Link to='/'>
						<img src='content\img\seturday-logo.png' />
					</Link>
				</div>

				<div>
				</div>
				<div className='right-menu'>
					<ul>
						<li className='last-link-desktop'>
							<Link to='/arama?q=dijitalrotalar'>#dijitalrotalar</Link>
						</li>
						<li className='last-link-desktop'>
							<Link to='/yazarlar'>Yazarlar</Link>
						</li>
						<li className='last-link-desktop'><Link to='/cok-okunanlar'>Çok Okunanlar</Link></li>
						<li><a href='#' onClick={(e) => e.preventDefault()} className='sedvice-button'>Bir Bilene Sor</a></li>
						<li><a href='#' onClick={this.showMapModal} className='bucket-button'>Bucket List</a></li>
						<li>
							<a href='#' onClick={(e) => e.preventDefault()} className='search-a'>
								<img src='content\img\search-icon-white.png' className='search-white active' />
								<img src='content\img\search-icon.png' className='search-black' />
							</a>
						</li>

						<li>
							<div className={`circle ${((this.props.loginStore.user.Id as number) > 0 ? 'profile' : '')}`}></div>
							{
								this.props.loginStore && (this.props.loginStore.user.Id as number) > 0
									? <Fragment>
										{
											this.props.loginStore.user.isAuthor
												? <Link to='/yazi-gonder' className='yazarol-button'>Yazı Gönder</Link>
												: <Link to='/yazarol' className='yazarol-button'>Yazar Ol</Link>
										}
										<div className='profile-circle active'>
											<span className={`message-notification-top ${(this.props.loginStore.unseenCount > 0 ? 'active' : '')}`} id='message-count'>{this.props.loginStore.unseenCount}</span>
											<a href='#' onClick={(e) => e.preventDefault()}>
												<p>{this.props.loginStore.user.Name}</p>
												<span className="img" style={{ backgroundImage: `url("${Const.seturdayCdnAuthorUrl}${(this.props.loginStore.user.Resim || 'anymous.png')}")`, backgroundSize: 'cover' }} />
											</a>
											<div className='profile-dropdown'>
												<ul>
													<li>
														<Link to='/hesabim/profilim'>HESABIM</Link>
													</li>
													<li>
														<Link to='/begendiklerim'>BEĞENDİKLERİM</Link>
													</li>
													<li>
														<Link to={'/hesabim/gittigim-yerler'}>GİTTİĞİM YERLER</Link>
													</li>
													<li>
														<Link to={'/hesabim/bucket'}>MY BUCKET LIST</Link>
													</li>
													<li>
														<Link to={'/hesabim/mesajlarim'}>MESAJLARIM</Link>
														<span className={`message-notification ${(this.props.loginStore.unseenCount > 0 ? 'active' : '')}`}>{this.props.loginStore.unseenCount}</span>
													</li>
													<li>
														<Link to={'/yazarol'}>NASIL YAZAR OLUNUR?</Link>
													</li>
													<li>
														<a href='#' onClick={(e) => this.signout(e)}>ÇIKIŞ YAP</a>
													</li>
												</ul>
											</div>
										</div>
									</Fragment>
									: <Fragment>
										<Link to='/yazarol' className='yazarol-button'>Yazar Ol</Link>
										<a className='giris-button' href='#' onClick={this.btnLogin}>Giriş Yap</a>
									</Fragment>
							}
							{ /* EndDonut */}
						</li>

					</ul>
				</div>

			</div>
		)
	}

	btnLogin(e: React.MouseEvent) {
		$('.circle').addClass('active')
		$('first-overlay').addClass('pfix')
		$('.login-overlay').addClass('active')
		$('#login-modal').addClass('active')
		$('.giris-button').addClass('active')
		$('#login-user').css('display', 'block')
		$('#new-user').css('display', 'none')
		$('#reset-password').css('display', 'none')
		$('html').animate({
			scrollTop: 0
		}, 'fast')
		e.preventDefault()
	}

	componentDidMount() {
		$('.sedvice-button').on('click', function (e) {
			e.preventDefault();
			let sedviceOverlay = $('.sedvice-overlay')
			let sedviceModal = $('#sedvice-modal')
			let sedviceCircle = $('.sedvice-circle')

			sedviceOverlay.addClass('active')
			sedviceModal.addClass('active')
			sedviceCircle.addClass('active')
			$('body').addClass('pfix-sb')
			$('first-overlay').addClass('pfix-sb')
			$('.main-wrap').addClass('ov-pe')
		})

		$('#nav-icon1').click(function () {
			$(this).toggleClass('open')
			$('.first-overlay').toggleClass('blur')
			$('.side-menu-area').toggleClass('active')
			const n = $('.main-wrap')
			n.hasClass('ov-pe') ? setTimeout(function () {
				n.removeClass('ov-pe')
			}, 600) : n.addClass('ov-pe')
		})
		window.fbAsyncInit = () => {
			FB.init({
				appId: '2161894480761423',
				cookie: true,
				xfbml: true,
				version: 'v3.0'
			})

			FB.AppEvents.logPageView()

			FB.getLoginStatus(function (response: any) {
				if (response.status === 'connected') {
					const status = document.getElementById('status')
					status && (status.innerHTML = 'We are connected.')
					const login = document.getElementById('login')
					login && (login.style.visibility = 'hidden')
				}
			})
		}

		(function (d: Document, id: string) {
			var js, fjs = d.getElementsByTagName('script')[0]
			if (d.getElementById(id)) { return }
			js = d.createElement('script')
			js.id = id
			js.src = 'https://connect.facebook.net/en_US/sdk.js'
			fjs && fjs.parentNode && fjs.parentNode.insertBefore(js, fjs)
		}(document, 'facebook-jssdk'))
	}

	login() {
		FB.login(function (response: any) {
			if (response.status === 'connected') {
				const status = document.getElementById('status')
				status && (status.innerHTML = 'We are connected.')
				const login = document.getElementById('login')
				login && (login.style.visibility = 'hidden')
			}
		}, { scope: 'email' })
	}

	logout() {
		FB.logout(() => {
			// Person is now logged out
		})
		if (this.props.loginStore) {
			this.props.loginStore.signOut()
		} else {
			sessionStorage.removeItem('userInfo');
			localStorage.removeItem('userToken');
			window.location.href = '/';
		}
	}

	signout(e: React.MouseEvent) {
		this.logout()
		e.preventDefault()
	}
}

export default withRouter(Header as any)

interface HeaderProps {
	history: any
	loginStore?: ILoginStore
	bucketStore?: IBucketStore
}

interface HeaderState {
}