// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react'

export default class Layout1SliderComponent extends Component<
	ILayout3Prop,
	ILayout3State
	> {
	constructor(props: ILayout3Prop) {
		super(props)
	}

	render() {
		return (
			<React.Fragment>
				{this.props.articleLayout.layout1 &&
					this.props.articleLayout.slider1 &&
					this.props.articleLayout.slider1.length > 0 && (
					<div className="full-w blur-slider">
						<div className="blur-slider-in">
							<div className="swiper-container blur-slider-swipe">
								<div className="swiper-wrapper">
									{this.props.articleLayout.slider1.map((item, i) => {
										if (this.props.articleLayout.slider1.length == i + 1) {
											setTimeout(() => {
												this.initializeSlider()
											}, 500)
										}

										return (
											<div
												key={`Layout1_Slider_${i}`}
												className="swiper-slide">
												{item.Resim20361032 && (
													<div className="blur-wrap">
														<img src={`https://cdn.seturday.com/content/uploads/makale_resimleri/laybir/sliderbir/${item.Resim20361032}`} />
													</div>)}
												<img src={`https://cdn.seturday.com/content/uploads/makale_resimleri/laybir/sliderbir/${item.Resim20361032}`} />
											</div>
										)
									}
									)}
								</div>
								<div className="swiper-pagination swiper-pagination-white"></div>
								<div className="swiper-button-next swiper-button-white"></div>
								<div className="swiper-button-prev swiper-button-white"></div>
							</div>
						</div>
					</div>
				)}
			</React.Fragment>
		)
	}

	initializeSlider() {
		var twoSlide = new Swiper('.blur-slider-swipe',{
			spaceBetween: 30,
			effect: 'fade',
			pagination: {
				el: '.swiper-pagination',
				clickable: !0
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			}
		})
	}
}

interface ILayout3Prop {
	articleLayout: IArticleLayout
}

interface ILayout3State { }
