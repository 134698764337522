import React, { Component } from 'react'
import Const from '../../const'

export default class Layout3SliderComponent extends Component<ILayout3Prop, ILayout3State> {
    constructor(props: ILayout3Prop) {
        super(props)
    }
    
    render() {
        return <React.Fragment>
            {
                this.props.articleLayout.layout3 &&
                this.props.articleLayout.slider3 &&
                this.props.articleLayout.slider3.length > 0 &&
                <div className="layout-third-slider">
                    <div className="swiper-container third-slider">
                        <div className="swiper-wrapper">
                            {
                                this.props.articleLayout.slider3 && this.props.articleLayout.slider3.map((item, i) => {
                                    if (this.props.articleLayout.slider3.length === (i + 1)) {
                                        setTimeout(() => {
                                            this.initializeThirdSlider()
                                        }, 500);
                                    }
                                    return <div className="swiper-slide" key={`layout_3_slider_${i}`}>
                                        <div className="third-image-area">
                                            {
                                                item.Resim940480 && <img src={`${Const.seturdayCdnArticleSlider3Url}${item.Resim940480}`} />
                                            }
                                            {
                                                item.Embeed && <div dangerouslySetInnerHTML={{ __html: item.Embeed }}>
                                                </div>
                                            }
                                        </div>
                                        <div className="third-text-area">
                                            <h3 className="third-slider-title">{item.SliderBaslik}</h3>
                                            <p className="third-slider-text" dangerouslySetInnerHTML={{
                                                __html: item.SliderAciklama
                                            }}></p>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className="third-slider-left-arrow"></div>
                        <div className="third-slider-right-arrow"></div>
                        <div className="swiper-pagination"></div>
                    </div>
                </div>
            }
        </React.Fragment>
    }

    initializeThirdSlider() {
        var f = $(window).width() as number
        var e = f <= 768 ? new Swiper(".third-slider", {
            slidesPerView: 1,
            spaceBetween: 100,
            centeredSlides: !0,
            loop: !0,
            pagination: {
                el: ".swiper-pagination",
                clickable: !0
            },
            navigation: {
                nextEl: ".third-slider-right-arrow",
                prevEl: ".third-slider-left-arrow"
            }
        }) : new Swiper(".third-slider", {
            slidesPerView: 2,
            spaceBetween: 100,
            centeredSlides: !0,
            loop: !0,
            pagination: {
                el: ".swiper-pagination",
                clickable: !0
            },
            navigation: {
                nextEl: ".third-slider-right-arrow",
                prevEl: ".third-slider-left-arrow"
            }
        });
        $(window).resize(function () {
            e.params.slidesPerView = f <= 768 ? 1 : 2
        });
    }
}

interface ILayout3Prop {
    articleLayout: IArticleLayout
}

interface ILayout3State {

}