import HttpClient from '../helper/http'

export default class ServiceBucket {
    static addToBucketAsList(...model: IBucket[]) {
        return HttpClient.post<IBucket[]>('/api/bucket/addToBucketAsList', model)
	}
	
	static bucketList() {
		return HttpClient.get<IBucket[]>('/api/bucket/bucketList')
	}

	static setReadyToGo(bucketId: number) {
		return HttpClient.get<IBucket[]>(`/api/bucket/setReadyToGo?bucketId=${bucketId}`)
	}

	static setNotReadyToGo(bucketId: number) {
		return HttpClient.get<IBucket[]>(`/api/bucket/setNotReadyToGo?bucketId=${bucketId}`)
	}

	static remove(bucket: IBucket) {
		return HttpClient.delete<IBucket>(`/api/bucket/remove?Id=${bucket.Id}`, bucket)
	}
}