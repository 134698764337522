import React from 'react'

export default class Loading extends React.Component<{ loaded?: boolean, title?: string }, any> {
	constructor(props: any) {
		super(props)
	}

	render() {
		return this.props.loaded ? null : <div className="error-overlay active">
			<div className="error-modal">
				<div className="bar-loader">
					<div className="bar bar1"></div>
					<div className="bar bar2"></div>
					<div className="bar bar3"></div>
				</div>
				<p><strong>{this.props.title || 'İçerik yükleniyor...'}</strong></p>
			</div>
		</div>
	}
}
