import React from 'react'

import ServiceUser from '../services/ServiceUser'

import Validation from '../helper/validation'

import {inject, observer} from 'mobx-react'

import passwordController from '../helper/passwordController'

import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3'

@inject('loginStore', 'visitedPlaceStore')

@observer

export default class NewUserComponent extends React.Component<

	LoginProps,

	LoginState

> {

	registerCaptcha: string


	constructor(props: any) {

		super(props)

		this.state = this.resetObject()



		this.save = this.save.bind(this)

		this.gotoLoginTab = this.gotoLoginTab.bind(this)

	}



	resetObject() {

		return {

			name: '',

			surname: '',

			email: '',

			phone: '',

			phone2: '',

			password: '',

			password2: '',

			AvaibleForChat: false,

			AvaibleForCall: false,

			AvaibleForSMS: false,

			AvaibleForEmail: false,

			sapCheck: false,

			amazonCheck: false,

			error: ''

		}

	}



	reset() {

		this.setState(this.resetObject())

	}



	newUserValidation() {

		const hasPasswordError = passwordController(this.state.password)

		

		if (!this.state.name || !this.state.name.trim()) {

			return this.setErrorMessage('Ad boş geçilemez')

		} else if (!this.state.surname || !this.state.surname.trim()) {

			return this.setErrorMessage('Soyad boş geçilemez')

		} else if (!this.state.email || !this.state.email.trim()) {

			return this.setErrorMessage('E-mail boş geçilemez')

		} else if (!Validation.isEmail(this.state.email)) {

			return this.setErrorMessage('Geçerli bir eposta adresi giriniz')

		} else if (!this.state.phone2 || !this.state.phone2.trim()) {

			return this.setErrorMessage('Telefon boş geçilemez')

		} else if (this.state.phone2.length !== 15) {

			return this.setErrorMessage('Geçerli bir telefon numarası giriniz')

		} else if (!this.state.password || !this.state.password.trim()) {

			return this.setErrorMessage('Şifre boş geçilemez')

		} else if(hasPasswordError) {

			return this.setErrorMessage(hasPasswordError)

		} else if (!this.state.password2 || !this.state.password2.trim()) {

			return this.setErrorMessage('Şifre tekrar boş geçilemez')

		} else if (

			this.state.password2 &&

			this.state.password2.trim() &&

			this.state.password &&

			this.state.password.trim() &&

			this.state.password != this.state.password2

		) {

			return this.setErrorMessage(

				'Aynı parolayı girmeniz gerekmektedir. Tekrar deneyiniz.'

			)

		} else if (!this.state.AvaibleForCall && !this.state.AvaibleForEmail && !this.state.AvaibleForSMS){

			return this.setErrorMessage(

				'Üyelik işleminizin tamamlanabilmesi için iletişim tercihlerinizden en az birini işaretlemeniz gerekmektedir.'

			)

		} else if (!this.state.sapCheck){

			return this.setErrorMessage(

				'Üyelik işleminizin tamamlanabilmesi için belirtilen bilgilerinizin paylaşımını kabul etmeniz gerekmektedir.'

			)

		} else if (this.state.AvaibleForEmail && !this.state.amazonCheck) {

			return this.setErrorMessage(

				'Üyelik işleminizin tamamlanabilmesi için yurt dışı servis sağlayıcıları ile e-mail iletişim iznini kabul etmeniz gerekmektedir.'

			)

		} 

		return true

	}



	setErrorMessage(text: string) {

		var message = document.getElementById('mesaj')

		message && (message.style.display = 'block')

		$('.login-box').animate(

			{

				scrollTop: 0

			},

			600

		)

		$('#mesaj').text(text)

	}



	showMapModal() {

		$('.sedvice-circle').removeClass('active')

		$('.login-overlay').removeClass('active')

		$('.new-map-overlay').removeClass('active')

		$('.new-map-area').removeClass('active')

		$('body').removeClass('pfix-sb')

		$('first-overlay').removeClass('pfix-sb')

		$('.new-map-overlay').addClass('active')

		$('#new-map-modal').addClass('active')

		$('.sedvice-circle').addClass('active')

	}



	showSuccessModal() {

		$('.sedvice-circle').removeClass('active')

		$('.login-overlay').removeClass('active')

		$('.new-map-overlay').removeClass('active')

		$('.new-map-area').removeClass('active')

		$('body').removeClass('pfix-sb')

		$('first-overlay').removeClass('pfix-sb')

		$('#success-modal').addClass('active')

	}



	async save(event: any) {

		var loginModal = $('#login-modal')

		if (!this.newUserValidation()) return

		const {state} = this

		ServiceUser.save(

			state.email,

			state.name,

			state.surname,

			state.password,

			state.phone,

			state.AvaibleForChat,

			state.AvaibleForCall,

			state.AvaibleForSMS,

			state.AvaibleForEmail,

			state.sapCheck,

			state.amazonCheck,

			this.registerCaptcha

		)

			.then((res) => {

				if (res.data == 'Email already taken') {

					this.setErrorMessage('Bu email adresi ile daha önce kayıt olunmuş!')

				} else if (res.status === 200) {

					this.props.loginStore.closeLoginModal()

					this.showSuccessModal()

					if (localStorage.getItem('facebooklogin') == '1') {

						$('#uyeolmesaj').html(

							'Üyelik işlemi başarıyla gerçekleşti.<br /> Giriş yapmak için email adresinize gelen aktivasyon mailini onaylayınız.'

						)

						localStorage.removeItem('facebooklogin')

					}

					$('#uyeolmesaj').html(

						'Üyelik işlemi başarıyla gerçekleşti.<br /> Giriş yapmak için email adresinize gelen aktivasyon mailini onaylayınız.'

					)

				} else {

					this.setErrorMessage(res.data)

				}

				event.preventDefault()

			})

			.catch((res) => {

				this.setErrorMessage(res.response.data.Message)

			})

	}



	componentDidMount() {

		$('#phone').mask('(000) 000 00 00')



		$('.campain-bottom').slideUp('fast')



		var checkboxes = document.querySelectorAll('input.subFirsat') as any,

			checkall = document.getElementById('firsatSoz') as any



		for (var i = 0; i < checkboxes.length; i++) {

			checkboxes[i].onclick = function () {

				var checkedCount = document.querySelectorAll('input.subFirsat:checked')

					.length

				checkall.checked = checkedCount > 0

				if (checkedCount <= 0) {

					$('.campain-bottom').slideUp('fast')

				}

			}

		}



		checkall.onclick = function () {

			$('.campain-bottom').slideToggle('fast')

			for (var i = 0; i < checkboxes.length; i++) {

				checkboxes[i].checked = this.checked

			}

			$('#txtfirsatSoz_phone').val('1')

			$('#txtfirsatSoz_sms').val('1')

			$('#txtfirsatSoz_email').val('1')

			$('#txtfirsatSoz').val('1')

		}



		$('input[name=chkKisiselSozc]').change(function () {

			if ($(this).prop('checked')) {

				$('#chkKisiselSoz').val('1')

			} else {

				$('#chkKisiselSoz').val('0')

			}

		})



		$('input[name=kisiselSozc]').change(function () {

			if ($(this).prop('checked')) {

				$('#kisiselSoz').val('1')

			} else {

				$('#kisiselSoz').val('0')

			}

		})



		$('input[name=txtfirsatSozc]').change(function () {

			if ($(this).prop('checked')) {

				$('#txtfirsatSoz').val('1')

			} else {

				$('#txtfirsatSoz').val('0')

			}

		})



		$('input[name=txtfirsatSoz_smsc]').change(function () {

			if ($(this).prop('checked')) {

				$('#txtfirsatSoz_sms').val('1')

			} else {

				$('#txtfirsatSoz_sms').val('0')

			}

		})



		$('input[name=txtfirsatSoz_emailc]').change(function () {

			if ($(this).prop('checked')) {

				$('#txtfirsatSoz_email').val('1')

			} else {

				$('#txtfirsatSoz_email').val('0')

			}

		})



		$('input[name=txtfirsatSoz_phonec]').change(function () {

			if ($(this).prop('checked')) {

				$('#txtfirsatSoz_phone').val('1')

			} else {

				$('#txtfirsatSoz_phone').val('0')

			}

		})



		$('input[name=messageConfirmc]').change(function () {

			if ($(this).prop('checked')) {

				$('#messageConfirm').val('1')

			} else {

				$('#messageConfirm').val('0')

			}

		})

	}



	mapFooterButton() {

		return (

			<div className="next-button">

				<div className="map-next-tooltip">

					<p>

						Hesabım sayfasından daha sonra gittiğiniz yerlere ekleme

						yapabilirsiniz! Keyifli keşifler!

					</p>

				</div>

				<a href="#" onClick={(e) => e.preventDefault()} className="map-next">

					İleri

				</a>

			</div>

		)

	}



	gotoLoginTab(e: React.MouseEvent<HTMLAnchorElement>) {

		e.preventDefault()

		this.props.changeTab('Login')

	}



	render() {

		return (

			<>

				{/* Yeni Kullanıcı */}
				<GoogleReCaptchaProvider reCaptchaKey='6LeEcZkgAAAAAHgE3gei4EqZ9_BvLotd0uuxIKSp'>
					<form

						onSubmit={(e) => e.preventDefault()}

						id="form0"

						onKeyDown={(event) => event.keyCode != 13}>

						<input

							type="hidden"

							value="0"

							name="chkKisiselSoz"

							id="chkKisiselSoz"

						/>

						<input type="hidden" value="0" name="kisiselSoz" id="kisiselSoz" />

						<input

							type="hidden"

							value="0"

							name="txtfirsatSoz"

							id="txtfirsatSoz"

						/>

						<input

							type="hidden"

							value="0"

							name="txtfirsatSoz_sms"

							id="txtfirsatSoz_sms"

						/>

						<input

							type="hidden"

							value="0"

							name="txtfirsatSoz_email"

							id="txtfirsatSoz_email"

						/>

						<input

							type="hidden"

							value="0"

							name="txtfirsatSoz_phone"

							id="txtfirsatSoz_phone"

						/>

						<input

							type="hidden"

							value="0"

							name="messageConfirm"

							id="messageConfirm"

						/>

						<div

							className="login-bottom-in"

							id="new-user"

							style={{display: 'block'}}>

							<span>Bilgilerinizi Giriniz</span>

							<p id="mesaj" className="error">

								{this.state.error}

							</p>



							<div className="user-info-area clearfix">

								<input

									type="text"

									id="nameuyeol"

									name="name"

									onChange={(e) =>

										this.setState({

											name: e.target.value

										})

									}

									value={this.state.name}

									placeholder="Adınız"

								/>

							</div>



							<div className="user-info-area clearfix">

								<input

									type="text"

									id="surnameuyeol"

									name="surname"

									onChange={(e) =>

										this.setState({

											surname: e.target.value

										})

									}

									value={this.state.surname}

									placeholder="Soyadınız"

								/>

							</div>



							<div className="user-info-area clearfix">

								<input

									type="text"

									id="emailuyeol"

									name="email"

									onChange={(e) =>

										this.setState({

											email: e.target.value

										})

									}

									value={this.state.email}

									placeholder="E-mail"

								/>

							</div>

							<div className="user-info-area clearfix">

								<input

									type="text"

									id="phone"

									name="phoneNumber"

									onKeyPress={(event) => {

										const keyCode = event.keyCode || event.which

										const keyValue = String.fromCharCode(keyCode)

										if (!/^[0-9]+$/g.test(keyValue)) event.preventDefault()

									}}

									onChange={(e) =>

										this.setState({

											phone: $('#phone').cleanVal(),

											phone2: e.target.value

										})

									}

									value={this.state.phone2}

									placeholder="Telefon"

									maxLength={11}

								/>

							</div>

							<div className="user-info-area clearfix">

								<input

									type="password"

									name="password"

									onChange={(e) =>

										this.setState({

											password: e.target.value

										})

									}

									value={this.state.password}

									placeholder="Şifre"

								/>

							</div>



							<div className="user-info-area clearfix">

								<input

									type="password"

									name="password2"

									onChange={(e) =>

										this.setState({

											password2: e.target.value

										})

									}

									value={this.state.password2}

									placeholder="Şifrenizi Tekrar Giriniz"

								/>

							</div>

							<div className="form-b-left">

								<label className="checkbox-container">

									<a

										href="https://seturkvkkdocuments.blob.core.windows.net/kvkk-documents/2.0.0/6/16/1/Uyelik_Sozlesmesi.pdf"

										target="_blank">

									Üyelik Sözleşmesi

									</a>{' '}

								

								görüntülemek için tıklayınız.

								</label>

							</div>

						

							<div className="form-b-left">

								<label>

									<a

										href="https://kvkk.setur.com.tr/public/document/6/1/pdf/false"

										title="Müşteri Kişisel Verilerin İşlenmesi Aydınlatma Metni">

									Müşteri Aydınlatma Metni

									</a>{' '}

								

								uyarınca Setur Servis Turistik Anonim Şirketi tarafından;

								</label>

								<label className="checkbox-container" id="firsatSoz-mark">

									<span>

								Kimlik, iletişim, müşteri işlem ve pazarlama bilgilerimin ürün ve hizmetlerin doğrudan pazarlama süreçlerinin gerçekleştirilmesi ile beğenilerime, kullanım alışkanlıklarıma ve ihtiyaçlarıma göre özelleştirerek önerilmesi ve tanıtılması, profilleme ve analiz faaliyetlerinin yürütülmesi, ve paylaşmış olduğum iletişim bilgilerime Setur tarafından reklam, promosyon, vb. ticari elektronik ileti gönderilmesi ve gönderim sağlanması için Setur tarafından hizmet alınan üçüncü kişilerle paylaşılması amaçlarıyla işlenmesini kabul ediyorum.

									</span>

									<input

										type="checkbox"

										name="txtfirsatSozc"

										onChange={(e) =>

											this.setState({

												AvaibleForEmail: e.target.checked,

												AvaibleForSMS: e.target.checked,

												AvaibleForCall: e.target.checked

											})

										}

										checked={

											this.state.AvaibleForCall ||

										this.state.AvaibleForEmail ||

										this.state.AvaibleForSMS

										}

										id="firsatSoz"

									/>

									<span className="checkmark"></span>

								</label>

							</div>

							<div className="campain-bottom">

								<div className="form-b-left form-campaign">

									<label className="checkbox-container">

									SMS

										<input

											type="checkbox"

											className="subFirsat"

											name="txtfirsatSoz_smsc"

											onChange={(e) =>

												this.setState({

													AvaibleForSMS: e.target.checked,

												})

											}

											checked={this.state.AvaibleForSMS}

											id="firsatSoz-sms"

										/>

										<span className="checkmark"></span>

									</label>

									<label className="checkbox-container">

									Telefon

										<input

											type="checkbox"

											className="subFirsat"

											name="txtfirsatSoz_phonec"

											onChange={(e) =>

												this.setState({

													AvaibleForCall: e.target.checked

												})

											}

											checked={this.state.AvaibleForCall}

											id="firsatSoz-tel"

										/>

										<span className="checkmark"></span>

									</label>

									<label className="checkbox-container">

									E-Mail

										<input

											type="checkbox"

											className="subFirsat"

											name="txtfirsatSoz_emailc"

											onChange={(e) =>

												this.setState({

													AvaibleForEmail: e.target.checked

												})

											}

											checked={this.state.AvaibleForEmail}

											id="firsatSoz-email"

										/>

										<span className="checkmark"></span>

									</label>

								</div>

							</div>



							<div className="form-b-left">

								<label className="checkbox-container" id="firsatSoz-mark">

									<span>

								Yukarıda belirtilen bilgilerimin pazarlama ve üyelik kapsamında barındırma hizmetini aldığınız ve sunucuları yurtdışında bulunan tedarikçiniz SAP Türkiye Yazılım Üretim ve Tic. A.Ş. ve yurtdışında bulunan tedarikçiniz Microsoft Ireland Operations LTD. ile paylaşılmasını kabul ediyorum.

									</span>

									<input

										type="checkbox"

										name="txtfirsatSozc"

										onChange={(e) =>

											this.setState({

												sapCheck: e.target.checked

											})

										}

										checked={this.state.sapCheck}

										id="firsatSoz"

									/>

									<span className="checkmark"></span>

								</label>

							</div>



							<div className="form-b-left" style={{display: this.state.AvaibleForEmail ? 'block' : 'none'}}>

								<label className="checkbox-container" id="firsatSoz-mark">

									<span>

								Kimlik ve iletişim bilgilerimin, kabulüm kapsamında paylaşmış isem e-posta adresime ticari elektronik ileti gönderilmesi amacıyla altyapı hizmeti aldığınız ve yurt dışında bulunan tedarikçiniz Amazon AWS EMEA SARL ile paylaşılmasını kabul ediyorum. 

									</span>

									<input

										type="checkbox"

										name="txtfirsatSozc"

										onChange={(e) =>

											this.setState({

												amazonCheck: e.target.checked

											})

										}

										checked={this.state.amazonCheck}

										id="firsatSoz"

									/>

									<span className="checkmark"></span>

								</label>

							</div>



							<div className="form-b-left">

								<label className="checkbox-container">

								Mesaj Gelsin

									<p className="message-label-p">

									Kişilerin tarafıma seturday blog mesajlaşma özelliğini

									kullanarak mesaj atmasına izin veriyorum.

									</p>

									<input

										type="checkbox"

										name="messageConfirmc"

										onChange={(e) =>

											this.setState({

												AvaibleForChat: e.target.checked

											})

										}

										checked={this.state.AvaibleForChat}

										className="mesConfirm"

									/>

									<span className="checkmark"></span>

								</label>

							</div>


							<GoogleReCaptcha
								onVerify={token => {
									this.registerCaptcha = token
								}}
							/>



							<input

								type="submit"

								className="login-nextnew1"

								data-sitekey="6LeEcZkgAAAAAHgE3gei4EqZ9_BvLotd0uuxIKSp" 
								
								onClick={this.save}

								value="Kaydet"

							/>

							<div className="creat-new-account">

								<p>

								Zaten bir hesabım var{' '}

									<a href="#" onClick={this.gotoLoginTab} className="giris-back">

									GİRİŞ YAP

									</a>

								</p>

							</div>

						</div>

					</form>
				</GoogleReCaptchaProvider>
				{/* Yeni Kullanıcı Bitiş */}

			</>

		)

	}

}



interface LoginState {

	name: string

	surname: string

	email: string

	phone: string

	phone2: string

	password: string

	password2: string

	AvaibleForChat: boolean

	AvaibleForCall: boolean

	AvaibleForSMS: boolean

	AvaibleForEmail: boolean

	sapCheck: boolean

	amazonCheck: boolean

	error: string

}



interface LoginProps {

	loginStore?: ILoginStore

	visitedPlaceStore?: IVisitedPlaceStore

	changeTab: Action1<LoginModalType>

}

