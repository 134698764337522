import React from 'react'
import ServiceUser from '../services/ServiceUser'
import ServiceArticle from '../services/ServiceArticle'
import { RouteComponentProps } from 'react-router'
import ServiceCategory from '../services/ServiceCategory'
import Helmet from 'react-helmet'
import Seo from '../helper/seo'
import { Link } from 'react-router-dom'

export default class AuthorComponent extends React.Component<IAuthorProp, IAuthorState> {
    constructor(props: any) {
        super(props)
        this.state = {
            user: {},
            categories: [],
            articles: []
        }

        ServiceCategory.categories().then(res => {
            this.setState({
                categories: res.data
            })
        })

        const authorId = Number(this.getQueryVariable('guid'));
        /*ServiceUser.byId(authorId).then(res => {
            this.setState({
                user: res.data
            })
        })*/

        ServiceArticle.getAuthorsArticles(authorId, 100, 0).then(res => {
            this.setState({
                articles: res.data.articleList
            })
        })
    }

    getQueryVariable(variable:string) {
		var query = window.location.search.substring(1);
		var vars = query.split('&');
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split('=');
			if (decodeURIComponent(pair[0]) == variable) {
				return decodeURIComponent(pair[1]);
			}
		}
    }
    
    getCoverStyle() {
        var obj: any = {}
        this.state.user.WriterPagePictureUrl ? (obj.background = `url('https://cdn.seturday.com/content/uploads/makale_resimleri/kapak/${this.state.user.WriterPagePictureUrl}') center center / cover`) : (obj.background = `url('https://cdn.seturday.com/content/uploads/makale_resimleri/kapak/${this.state.articles.length && this.state.articles[0].MakaleBuyukResim}') center center / cover`)
        return obj
    }

    getCategory(categoryId: number): ICategory {
        if (this.state.categories && this.state.categories.length > 0) {
            return this.state.categories.filter(c => c.Id == categoryId)[0]
        }
        return {} as ICategory
    }

    render() {
        const bgStyle = this.getCoverStyle()
        return <div className="first-right-content">
            {
                this.state.user && this.state.user.Name && <Helmet>
                    <title>{Seo.getTitle(`${this.state.user.Name}${this.state.user.Surname}`)}</title>
                </Helmet>
            }
            <div className="main-slide category" style={bgStyle}>
                <div className="main-title-area-index">
                    <div className="main-title-index">
                        <h1>{this.state.user.Name} {this.state.user.Surname}</h1>
                        <p className="cat-subtitle">{this.state.user.Yazarhakkinda}</p>
                    </div>
                </div>
            </div>
            <div className="category-wide-grid">
                <div className="grid-area wide-grid">
                    {
                        this.state.articles.map((item) => {
                            return <Link key={`article_${item.Id}`} to={`/${this.getCategory(item.MakaleKategoriId).Kategoriurl}/${item.Url}`}>
                                <div className="grid grid-medium-ctg">
                                    <div className="grid-top grid-h-100">
                                        <div className="grid-top-info">
                                            <h4>{item.MakaleAdi.trim()}</h4>
                                            <p>{item.MakaleOzeti.trim().substring(0, 83) + '...'}</p>
                                        </div>
                                        <div className="grid-grd"></div>
                                        <div className="grid-date">
                                            <span>
                                                <img className="heart" src="..\Content\img\liked.png" width="11" alt={item.MakaleAdi} title={item.MakaleAdi} />
                                                <span className="heart-count">
                                                    {item.MakaleBeğeni} Beğenme
                                                </span>
                                            </span>
                                        </div>
                                        <div className="grid-image" style={{ background: 'url(https://cdn.seturday.com/content/uploads/makale_resimleri/kapak/' + item.MakaleBuyukResim + ') center center' }}></div>
                                    </div>
                                   {/* <div className="grid-bottom">
                                        <div className="grid-profile-img">
                                            <img src={'https://cdn.seturday.com/content/uploads/yazar_resimleri/' + this.state.user.Resim} alt={item.MakaleAdi} title={item.MakaleAdi} />
                                        </div>
                                        <div className="grid-bottom-info">
                                            <h6>{this.state.user.Name} {this.state.user.Surname}</h6>
                                            <p>{this.state.user.Yazarhakkinda && this.state.user.Yazarhakkinda.trim().substring(0, 83) + '...'}</p>
                                        </div>
                        </div>*/}
                                </div>
                            </Link>
                        })
                    }
                </div>
            </div>
        </div>
    }
}

interface IAuthorRouteProp {
    id: any
}

interface IAuthorProp extends RouteComponentProps<IAuthorRouteProp> {

}

interface IAuthorState {
    user: IUserInfo
    categories: ICategory[]
    articles: Array<IArticle>
}