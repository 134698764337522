import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import Pagination from '../../components/Pagination'
import ServiceArticle from '../../services/ServiceArticle'
import HelperDom from '../../helper/helper-dom'
import Helmet from 'react-helmet'
import Seo from '../../helper/seo'
import moment from 'moment'
import ServiceCategory from '../../services/ServiceCategory'
import {RouteComponentProps} from 'react-router'
import Notification from '../../helper/notification';
@inject('loginStore')
@observer
export default class MyArticlesComponent extends Component<
	IMyArticlesProp,
	IMyArticlesState
> {
	constructor(props: IMyArticlesProp) {
		super(props)

		this.state = {
			pageId: 1,
			articles: [],
			articleCount: 0,
			categories: []
		}

		this.edit = this.edit.bind(this)
		this.delete = this.delete.bind(this)

		this.getData()

		this.props.loginStore.getScore()

		HelperDom.loadStyle('/Content/yazigonder/yazarol.css')

		ServiceCategory.categories().then(resCat => {
			this.setState({
				categories: resCat.data
			})
		})
	}

	async getData() {
		if (!this.state.pageId) return
		const res = await ServiceArticle.getUsersArticle(5, this.state.pageId - 1)
		this.setState({
			articles: res.data
			// articleCount: res.data.TotalCount
		})
	}

	componentWillReceiveProps(nextProps: IMyArticlesProp) {
		if (nextProps.pageId != this.state.pageId) {
			this.setState({
				pageId: nextProps.pageId
			})
		}
	}

	async edit(e: React.MouseEvent<HTMLElement>, item: IUserArticle) {
		e.preventDefault()
		this.props.history.push(`/yazi-gonder/${item.Guid}`)
	}

	async preview(e: React.MouseEvent<HTMLElement>, item: IUserArticle) {
		e.preventDefault()
		this.props.history.push(`/yazi/${item.Guid}`)
	}
	async delete(e: React.MouseEvent<HTMLElement>, item: IUserArticle) {
		e.preventDefault()
		const res = await ServiceArticle.removeUserArticle(item.Id);
		if (res.status === 200) {
			Notification.showToast('Yazınız başarıyla silinmiştir.');
			this.getData();
		}
	}

	getCategory(categoryId: number): ICategory {
		if (this.state.categories && this.state.categories.length > 0) {
			return this.state.categories.filter(c => c.Id == categoryId)[0]
		}
		return {} as ICategory
	}

	getStatusName(status: number) {
		switch (status) {
			case 0:
				return 'TASLAK'
			case 1:
				return 'ONAYDA BEKLİYOR'
			case 2:
				return 'ONAYDA BEKLİYOR'
			case 3:
				return 'REDDEDİLDİ'
			case 4:
				return 'ONAYLANDI'
			case 5:
				return 'YAYINLANDI'
			case 6:
				return 'KALDIRMA TALEP EDİLDİ'
		}
	}

	getStatusBadgeClass(status: number) {
		switch (status) {
			case 0:
				return 'draft'
			case 1:
				return 'waiting'
			case 2:
				return 'waiting'
			case 3:
				return 'rejected'
			case 4:
				return 'approved'
			case 5:
				return 'published'
			case 6:
				return 'retraction'
		}
	}

	render() {
		return (
			<div className="profile-content-in">
				<Helmet>
					<title>{Seo.getTitle('Yazılarım')}</title>
				</Helmet>
				<div className="table-wrap-in">
					<div className="table-area">
						<table>
							<thead>
								<tr className="table-head">
									<th scope="col">Başlık</th>
									<th scope="col" className="wide-th">
										Kategori
									</th>
									<th scope="col">Etiketler</th>
									<th scope="col" className="middle-wide-th">
										Tarih
									</th>
									<th scope="col" className="middle-wide-th">
										Beğenme
									</th>
									<th scope="col" className="middle-wide-th">
										Tıklanma
									</th>
									<th scope="col" className="wide-th">
										Durum
									</th>
									<th scope="col" className="middle-wide-th">
										{' '}
									</th>
								</tr>
							</thead>
							<tbody>
								{this.state.articles &&
									this.state.articles.map(item => {
										let category = !item.CategoryId
											? 'Tanımlanmadı'
											: this.getCategory(item.CategoryId).KategoriAdi
										return (
											<tr key={`my-article-${item.Id}`}>
												<td data-label="Başlık">{item.Title}</td>
												<td data-label="Kategori">
													<span
														className={`badge ${
															category ? `badge-${category.toLowerCase()}` : ''
														}`}>
														{category}
													</span>
												</td>
													<td data-label="Etiketler">{item.Tag && item.Tag.split(',').map(item => <span key={item}>{item} </span>)}</td>
												<td data-label="Tarih">
													{moment(item.CreatedDate).format('DD.MM.YYYY')}
												</td>
												<td data-label="Beğenme">-</td>
												<td data-label="Tıklanma">-</td>
												<td data-label="Durum">
													<span
														className={`badge badge-${this.getStatusBadgeClass(
															item.Status
														)}`}>
														{this.getStatusName(item.Status)}
													</span>
												</td>
												<td data-label="" className="text-center">
													{ item.Status === 0 ? <>
													<a
														href="#"
														onClick={e => this.delete(e, item)}
														className="article-delete">
														Sil
													</a>
													<a
														href="#"
														onClick={e => this.edit(e, item)}
														className="article-edit">
														Düzenle
													</a>
													</>
													:<a
													href="#"
													onClick={e => this.preview(e, item)}
													className="article-edit">
													Önizleme
												</a>}
												</td>
											</tr>
										)
									})}
							</tbody>
						</table>
						{/* {
                        this.state.pageId > 5 && <Pagination urlWithoutPageId={'/hesabim/yazilarim'} activePageId={this.props.pageId} perPageCount={5} totalCount={this.state.articleCount} />
                    } */}
					</div>
				</div>
				<div className="message-wrap-area"></div>
			</div>
		)
	}
}

interface IMyArticlesProp {
	pageId?: any
	history?: any
	loginStore?: ILoginStore
}

interface IMyArticlesState {
	pageId: any
	articles: IUserArticle[]
	articleCount: number
	categories: ICategory[]
}
