import React from 'react'
import ServiceUser from '../services/ServiceUser'
import NewUserComponent from './NewUserComponent'
import {inject, observer} from 'mobx-react'
import ResetPassword from './ResetPassword'
import Interests from './Interest'
import LoginComponent from './LoginComponent'
import BucketListMapComponent from '../pages/BucketListMapComponent'

@inject('loginStore')
@observer
export default class Login extends React.Component<LoginProps, LoginState> {
	registerCaptcha: any

	constructor(props: LoginProps) {
		super(props)
		this.state = {
			showModal: 'Login'
		}

		this.changeTab = this.changeTab.bind(this)
	}

	changeTab(showModal: LoginModalType) {
		this.setState({
			showModal
		})
	}

	componentDidMount() {
		let loginOverlay = $('.login-overlay')
		let loginModal = $('#login-modal')
		let circle = $('.circle')

		$('#login-modal .login-close').on('click', function() {
			loginModal.removeClass('active')
			circle.removeClass('active')
			$('body').removeClass('pfix')
			$('first-overlay').removeClass('pfix')
			loginModal.one(
				'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
				function() {
					loginOverlay.removeClass('active')
					$('.giris-button').removeClass('active')
				}
			)
		})

		$('#login-modal .login-close').on('click', function() {
			loginModal.removeClass('active')
			circle.removeClass('active')
			$('body').removeClass('pfix')
			$('first-overlay').removeClass('pfix')
			loginModal.one(
				'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
				function() {
					loginOverlay.removeClass('active')
					$('.giris-button').removeClass('active')
				}
			)
		})
	}

	render() {
		return (
			<>
				<BucketListMapComponent isVisitedPlaceList />
				<Interests />

				<div className="overlay login-overlay">
					<div className="login-wrap">
						<div className="login-box" id="login-modal">
							<div className="login-image">
								<div
									className="login-close"
									onClick={() =>
										this.setState({
											showModal: 'Login'
										})
									}></div>
							</div>
							{this.state.showModal == 'Login' && (
								<LoginComponent chageTab={this.changeTab} />
							)}
							{this.state.showModal == 'Register' && (
								<NewUserComponent changeTab={this.changeTab} />
							)}
							{this.state.showModal == 'ForgotPassword' && (
								<ResetPassword changeTab={this.changeTab} />
							)}
						</div>
					</div>
				</div>
			</>
		)
	}
}

interface LoginState {
	showModal: LoginModalType
}

interface LoginProps {}
