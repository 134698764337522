import React from 'react';
import { COOKIES, COOKIE_INFORMATIONS, COOKIE_WARNING_TEXT_1, COOKIE_WARNING_TEXT_2, SETUR_EMAIL, SETUR_PHONE } from '../cookies-static';

const CookiePolicy = () => {
    return (
        <div className="first-right-content">
				<div
					className="main-slide category"
					style={{
						background:
							'url(Content/img/cookie-policy-background.png) center center',
					}}>
					<div className="main-title-area-index">
						<div className="main-title-index">
							<h1>Çerez Politikası</h1>
						</div>
					</div>
				</div>
				<div className="writers-wide-grid col">
					<div className="sedvice-wrap light-white">		
						<div className="sedvice-wrap-in writer-block-area clearfix">
							<div className='separator-sm' />
							<div className="frame-4">
                                <div className='row'>
                                  <span className='static-title'>E-posta Adresi: {SETUR_EMAIL}</span>
							      <span className='static-title space-left'>Telefon Numarası: {SETUR_PHONE}</span>
                                </div>
							</div>

							<div className='separator-bg' />

							<p className='static-description'>{COOKIE_WARNING_TEXT_1}</p>
							<p className='static-description'>{COOKIE_WARNING_TEXT_2}</p>
				
				
						<div>
						{COOKIES.map((cookie, index) => 	
                    	<div key={index} style={externalStyles.tableWrapper} className="sedvice-profile-block">
								
                                  <p className='cookies-table-title'>{cookie.name}</p>
                                  <div className='cookies-table-col'>
                                    <span className='cookies-table-title'>Kullanım Amacı</span>
                                    <span className='cookies-table-description'>{cookie.forWhat}</span>
                                  </div>

                                  <div className='separator-sm' />

                                  <div className='cookies-table-col'>
                                    <span className='cookies-table-title'>Türü</span>
                                    <span className='cookies-table-description'>{cookie.category}</span>
                                  </div>

                                  <div className='separator-sm' />

                                  <div className='cookies-table-col'>
                                    <span className='cookies-table-title'>Çıkış Bağlantısı</span>
                                    {cookie.url.map((item) => <a href={item} target='_blank' className='cookies-table-description'>{item}/</a>)}
                                  </div>
							
							
						</div>
						)
					  }
						</div>
						</div>
					
					  	{COOKIE_INFORMATIONS.map((cookie, i) =>
						 <div key={i}>
							<div className='separator-bg' />
							<div className='sedvice-wrap-in writer-block-area clearfix col'>						 
									<span className='static-title'>{cookie.title}</span>
									<span className='static-description'>{cookie.description}</span>
							</div> 
						</div>
						)}
					</div>
				</div>
			</div>
    )
}

export default CookiePolicy;

const externalStyles = {
	tableWrapper: {
		height: 260, 
		paddingBottom: 20,
		marginTop: 40,	
	}
}