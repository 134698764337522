// eslint-disable-next-line no-unused-vars
import React, {Component} from 'react'

export default class Layout2SliderComponent extends Component<
	ILayout3Prop,
	ILayout3State
> {
	constructor(props: ILayout3Prop) {
		super(props)
	}

	render() {
		return (
			<React.Fragment>
				{this.props.articleLayout.layout2 &&
					this.props.articleLayout.slider2 &&
					this.props.articleLayout.slider2.length > 0 && (
					<div className="full-w">
						<div className="two-div-slider">
							<div className="swiper-container two-slide">
								<div className="swiper-wrapper">
									{this.props.articleLayout.slider2.map((item, i) => {
										if (this.props.articleLayout.slider2.length == i + 1) {
											setTimeout(() => {
												this.initializeSlider()
											}, 500)
										}

										return (
											<div
												key={`Layout2_Slider_${i}`}
												className="swiper-slide">
												{item.Resim && (
													<div
														className="two-slide-left"
														style={{
															background: `url(https://cdn.seturday.com/content/uploads/makale_resimleri/layiki/slideriki/${item.Resim}) center center no-repeat`
														}}></div>
												)}
												{item.Embeed && (
													<div
														className="two-slide-left"
														dangerouslySetInnerHTML={{
															__html: item.Embeed
														}}></div>
												)}
												<div className="two-slide-right">
													<div className="two-slide-right-in">
														<h3>{item.Baslik}</h3>
														<p dangerouslySetInnerHTML={{
															__html: item.Ozet
														}}></p>
														<p dangerouslySetInnerHTML={{
															__html: item.Aciklama
														}}></p>
													</div>
												</div>
											</div>
										)
									})}
								</div>
								<div className="two-slide-controls">
									<div className="two-slide-prev"></div>
									<div className="two-slide-next"></div>
								</div>
							</div>
						</div>
					</div>
				)}
			</React.Fragment>
		)
	}

	initializeSlider() {
		var twoSlide = new Swiper('.two-slide', {
			spaceBetween: 30,
			effect: 'fade',
			pagination: {
				el: '.swiper-pagination',
				clickable: !0
			},
			navigation: {
				nextEl: '.two-slide-next',
				prevEl: '.two-slide-prev'
			}
		})
	}
}

interface ILayout3Prop {
	articleLayout: IArticleLayout
}

interface ILayout3State {}
