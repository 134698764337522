import React, { Component } from "react"
import { withRouter } from "react-router"

class SearchComponent extends Component<any, ISearchState> {
	constructor(props: ISearchProp) {
		super(props)
		this.state = {
			search: ''
		}

		this.search = this.search.bind(this)
		this.searchEnter = this.searchEnter.bind(this)
	}

	searchEnter(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.which === 13) {
			this.search()
		}
	}

	search() {
		this.props.history.push(`/arama?q=${encodeURI(this.state.search)}`);
		this.setState({ search: ''});
	}

	render() {
		return <div id="the-basics">
			<input
				className="typeahead"
				id="Id"
				name="Id"
				onChange={(e) => this.setState({ search: e.target.value })}
				placeholder="Arama yapın..."
				type="text"
				onKeyPress={this.searchEnter}
				value={this.state.search} />
			<a className="typesubmit"
				onClick={this.search}></a>
		</div>
	}
}

export default withRouter(SearchComponent as any)

interface ISearchProp {
}

interface ISearchState {
	search: string
}