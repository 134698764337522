import React, { Component } from 'react'
import ServiceComment from '../../services/ServiceComment'

export default class CommentsComponent extends Component<ICommentsProp, ICommentsState> {
	lastArticleUrl: string = '';
	constructor(props: ICommentsProp) {
		super(props)

		this.state = {
			comments: {}
		}
	}

	async getComments(articleUrl: string) {
		this.lastArticleUrl = articleUrl
		const res = await ServiceComment.getByArticleUrl(articleUrl, 0, 100, this.props.isSetTalks)
		this.setState({
			comments: res.data
		})
		this.props.onChangeModel && this.props.onChangeModel(res.data)
	}

	componentWillReceiveProps(nexProps: ICommentsProp) {
		if (this.lastArticleUrl != nexProps.articleUrl) {
			this.getComments(nexProps.articleUrl)
		}
	}

	render() {
		return <div id="yorumlardiv" className="comment-list-area">
			{
				this.state.comments && this.state.comments.CommentList && this.state.comments.CommentList.map((item) => {
					return <div key={`comment_${item.Id}`} className="one-commnet-wrap">
						<div className="commnent-profile">
							<img src={`https://cdn.seturday.com/content/uploads/yazar_resimleri/${item.Picture}`} />
						</div>
						<div className="commnent-text">
							<p className="comment-profile-name" dangerouslySetInnerHTML={{
								__html: item.KullaniciAd }}></p>
							<p className="comment-text" dangerouslySetInnerHTML={{
								__html: item.Yorum }}></p>
						</div>
					</div>
				})
			}
		</div>
	}
}

interface ICommentsProp {
	articleUrl: string
	articleId: number
	onChangeModel: Action1<ICommentArticleResponseModel>
	isSetTalks?: boolean
}

interface ICommentsState {
	comments: ICommentArticleResponseModel
}
