import React, {Component} from 'react'
import {Link} from 'react-router-dom'

export default class Pagination extends Component<
	IPaginationProp,
	IPaginationState
> {
	render() {
		return (
			<>
				{this.props.totalCount > 9 && (
					<div className="pagination">
						<div className="pagination-container">
							<ul className="pagination">
								{this.props.activePageId > 9 &&
									this.getLastPage() >= this.props.activePageId &&
									this.getItem(1, 'PagedList-skipToFirst', '««')}
								{this.props.activePageId > 1 &&
									this.getLastPage() >= this.props.activePageId &&
									this.getItem(
										this.props.activePageId - 1,
										'PagedList-skipToPrevious',
										'«'
									)}
								{this.props.activePageId > 9 && this.getPrevEllipsesItem()}
								{this.getPreviousLinks().map(item => item)}
								{this.getItem(this.props.activePageId, 'active')}
								{this.getNextLinks().map(item => item)}
								{this.props.activePageId > 9 && this.getNextEllipsesItem()}
								{this.getNextItem()}
								{this.getLastItem()}
							</ul>
						</div>
					</div>
				)}
			</>
		)
	}

	get activePageId() {
		if (typeof this.props.activePageId === 'string') {
			return parseInt(this.props.activePageId)
		}
		this.props.activePageId
	}

	getLastItem() {
		if (this.props.activePageId + 9 < this.getLastPage())
			return this.getItem(this.getLastPage(), 'PagedList-skipToLast', '»»')
	}

	getNextItem() {
		if (this.props.activePageId < this.getLastPage())
			return this.getItem(
				this.props.activePageId + 1,
				'PagedList-skipToNext',
				'»'
			)
	}

	getNextEllipsesItem() {
		if (
			this.props.activePageId + 5 < this.getLastPage() &&
			9 < this.getLastPage()
		)
			return this.getItem(
				this.props.activePageId,
				'disabled PagedList-ellipses',
				'...',
				'-next'
			)
	}

	getPrevEllipsesItem() {
		if (
			this.props.activePageId > 9 &&
			10 <
				this.getLastPage() /*Querystring'den yapılacak bir fake doğrultusunda gizlemek için yapıldı*/
		)
			return this.getItem(
				this.props.activePageId,
				'disabled PagedList-ellipses',
				'...',
				'-prev'
			)
	}

	getItem(activePageId: number, className: string = '', text: string = '', key:string = '') {
		return (
			<li key={`page_${activePageId}_${text}${key}`} className={className}>
				<Link to={`${this.props.urlWithoutPageId}?sayfa=${activePageId}`}>
					{text || activePageId}
				</Link>
			</li>
		)
	}

	getLastPage() {
		return Math.ceil(this.props.totalCount / this.props.perPageCount)
	}

	getNextLinks() {
		var array: JSX.Element[] = []
		var activePageId: number = this.props.activePageId
		var lastPage = this.getLastPage()
		const max =
			this.props.activePageId < 10
				? 10 - this.props.activePageId
				: this.props.activePageId < 5
				? 9
				: 4
		if (lastPage <= activePageId) return array
		for (let i = 1; i <= max; i++) {
			activePageId++
			array.push(this.getItem(activePageId))
			if (activePageId == lastPage || array.length >= max) {
				return array
			}
		}
		return array
	}

	getPreviousLinks() {
		var array: JSX.Element[] = []
		var activePageId =
			this.props.activePageId > 9 ? this.props.activePageId - 5 : 1
		var lastPage = this.getLastPage()
		if (lastPage <= activePageId) return array
		while (activePageId < this.props.activePageId) {
			array.push(this.getItem(activePageId))
			activePageId++
		}
		return array
	}
}

interface IPaginationProp {
	activePageId: number
	perPageCount: number
	totalCount: number
	urlWithoutPageId: string
}

interface IPaginationState {}
