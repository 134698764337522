import { observable, action } from 'mobx'
import ServiceBucket from '../services/ServiceBucket'

class _BucketStore implements IBucketStore {
	@observable bucketList: IBucket[] = []

	@action
	async add(bucket: IBucket) {
		if (this.bucketList.filter(item => item.CityCountryModel.GooglePlaceId == bucket.CityCountryModel.GooglePlaceId).length == 0) {
			let res = await ServiceBucket.addToBucketAsList(bucket)
			this.bucketList = res.data
			const { CityCountryModel } = res.data[res.data.length - 1]
			window.dataLayer.push({
				'event': 'addToCart',
				'add': {
					'location': [{
						GooglePlaceId: CityCountryModel.GooglePlaceId,
						Id: CityCountryModel.Id,
						City: CityCountryModel.Sehir.trim(),
						Country: CityCountryModel.Ulke.trim(),
					}]
				}
			})
			return res.status === 200
		}
		return false
	}

	@action
	async remove(bucket: IBucket) {
		const res = await ServiceBucket.remove(bucket)
		if (res.status === 200) {
			this.bucketList.splice(this.bucketList.indexOf(bucket), 1)
			return true
		}
		return false
	}

	@action
	async changeReadyToGo(bucket: IBucket) {
		if (bucket.Gitmekistiyorum) {
			let res = await ServiceBucket.setNotReadyToGo(bucket.Id)
			this.bucketList = res.data
		} else {
			let res = await ServiceBucket.setReadyToGo(bucket.Id)
			this.bucketList = res.data
		}
	}

	@action
	async getBucketList() {
		let res = await ServiceBucket.bucketList()
		this.bucketList = res.data
	}
}

let BucketStore = new _BucketStore()
export default BucketStore