export default class Notification {
	private static checkElement() {
		let element = document.getElementsByClassName('toaster-notification')
		if (element.length == 0) {
			var toast = document.createElement('div')
			toast.classList.add('toaster-notification')
			toast.append(document.createElement('p'))
			document.body.append(toast)
		}
	}

	static showToast(html: string, timeout:number = 2000) {
		this.checkElement()
		$('.toaster-notification p').html(html)
		$('.toaster-notification').addClass('active')
		setTimeout(() => {
			$('.toaster-notification').removeClass('active')
		}, timeout)
	}

	static showLoading(htmlText: string) {
		var loading = document.getElementById('loading-overlay')
		if (!loading) {
			$('body').append(`<div class="error-overlay" id='loading-overlay'>
				<div class="error-modal">
					<div class="bar-loader">
						<div class="bar bar1"></div>
						<div class="bar bar2"></div>
						<div class="bar bar3"></div>
					</div>
					<p>${htmlText}</p>
				</div>
			</div>`)
			loading = document.getElementById('loading-overlay')
		}

		loading.classList.add('active')
	}

	static hideLoading() {
		var loading = document.getElementById('loading-overlay')
		if (loading) {
			loading.classList.remove('active')
		}
	}
}