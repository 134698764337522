import React, { Component } from 'react'

export default class Layout3Field1Component extends Component<ILayout3Prop, ILayout3State> {
    constructor(props: ILayout3Prop) {
        super(props)
    }
    
    render() {
        return <React.Fragment>
            {
                this.props.articleLayout.layout3 &&
                <div className="full-w">
                    <div className="layout-third-wrap-in clearfix">
                        <p className="layout-third-title-parag">
                            {this.props.articleLayout.layout3 && this.props.articleLayout.layout3.AlanbirBaslik}
                        </p>
                        <p className="layout-third-text-parag" dangerouslySetInnerHTML={{
                            __html: this.props.articleLayout.layout3.AlanbirAciklama
                        }}>
                        </p>
                    </div>
                </div>
            }
        </React.Fragment>
    }
}

interface ILayout3Prop {
    articleLayout: IArticleLayout
}

interface ILayout3State {

}