import React, {Component} from 'react'
import Const from '../../const'

export default class Layout1Field2Component extends Component<
	ILayout1Prop,
	ILayout1State
> {
	constructor(props: ILayout1Prop) {
		super(props)
	}

	render() {
		return (
			<React.Fragment>
				{this.props.articleLayout.layout1 && (
					<React.Fragment>
						<div className="full-w bg-grey grid-top-text">
							<div className="layout-wrap-in clearfix">
								<div className="full-w-text-area">
									<h3
										className="full-h2-top"
										dangerouslySetInnerHTML={{
											__html: this.props.articleLayout.layout1.Alan5baslik
										}}></h3>
									<p
										className="full-p-middle"
										dangerouslySetInnerHTML={{
											__html: this.props.articleLayout.layout1.Alan5ozet
										}}></p>
									<p
										className="full-p-bottom"
										dangerouslySetInnerHTML={{
											__html: this.props.articleLayout.layout1.Alan5aciklama
										}}></p>
								</div>
							</div>
						</div>
						<div className="grid-image-area">
							<div className="layout-wrap-in clearfix">
								<div className="grid-left">
									{[
										this.props.articleLayout.layout1.Alan5resim1,
										this.props.articleLayout.layout1.Alan5resim2,
										this.props.articleLayout.layout1.Alan5resim3,
										this.props.articleLayout.layout1.Alan5resim4
									].map((item, i) => {
										return (
											<div key={`layout1_field5_${i}`} className="grid-img-4-1">
												<img
													src={`${Const.seturdayCdnArticleLayout1Url}${item}`}
												/>
											</div>
										)
									})}
								</div>
								<div className="grid-right">
									<div className="grid-img-1-1">
										<img
											src={`${Const.seturdayCdnArticleLayout1Url}${this.props.articleLayout.layout1.Alan5resim5}`}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="full-w pt-50 pb-50 align-center">
							<p
								className="middle-p"
								dangerouslySetInnerHTML={{
									__html: this.props.articleLayout.layout1.Alan5aciklamauzun
								}}></p>
						</div>
					</React.Fragment>
				)}
			</React.Fragment>
		)
	}
}

interface ILayout1Prop {
	articleLayout: IArticleLayout
}

interface ILayout1State {}
