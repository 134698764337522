import * as React from 'react'
import Const from '../const';

interface IProps {
	message: IUserConversationHistory,
	user: IUserInfo
	userPhoto: string
}


const ChatMessages: React.FC<IProps> = ({ message, user, userPhoto }) => {
	const type = user.Id === message.GonderenId ? 'self' : 'user';
	const avatar = user.Id === message.GonderenId ? Const.seturdayCdnAuthorUrl + user.Resim : userPhoto; //'https://cdn.seturday.com/content/uploads/yazar_resimleri/anymous.png';
	
	React.useEffect(() => {

	}, [message.Id]);

	return (
		<div id={`chat-msg-${message.Id}`} className={`chat-msg ${type}`}>
			<span className="msg-avatar">
				<img className="msg-avatar-left" src={avatar} />
			</span>
			<div className="cm-msg-text">
				{message.Mesaj}
			</div>
		</div>

	);
}

export default ChatMessages;
