import HttpClient from '../helper/http'
import { AxiosResponse } from 'axios'

export default class ServiceUser {
	/*static byId(id: number) {
		let userInfo = sessionStorage.getItem(`user_${id}`)
		if (!userInfo) {
			const request = HttpClient.get<IUserInfo>(`/api/User/byId?id=${id}`)
			request.then(res => {
				sessionStorage.setItem(`user_${id}`, JSON.stringify(res.data))
			})
			return request
		} else {
			return new Promise<AxiosResponse<IUserInfo>>((resolve) => {
				resolve({
					data: JSON.parse(userInfo)
				} as any)
			})
		}
	}*/

	static authors(isAuthor:boolean) {
		if (isAuthor)
			return HttpClient.get<IUserInfo[]>('/api/User/authors')
		else 
			return HttpClient.get<IUserInfo[]>('/api/User/getExternalAuthors')
	}
    
	static getConversations(pageSize: number, pageId: number){
		return HttpClient.get<IResultUserPagination>(`/api/User/getConversations?pageSize=${pageSize}&pageId=${pageId}`)
	}

	static getConversationDetails(receiverId:Number, pageId:Number, pageSize:Number) {
		return HttpClient.get<IResultUserConversation>(`/api/User/getConversationDetails?contactId=${receiverId}&pageId=${pageId}&pageSize=${pageSize}`)
	}

	static removeconversation(contactId:number){
		return HttpClient.delete<IResultUserPagination>(`/api/User/removeConversation?contactId=${contactId}`)
	}

	static login(email: string, password: string, token: string) {
		return HttpClient.post<IAccessToken>(`/api/User/login?token=${token}`, { email, password })
	}
	
	static loginFb(data: any) {
		return HttpClient.post<IAccessToken>('/api/User/loginFb', data)
	}

    
	static save(email: string, name: string, surname: string, password: string, mobile: string, AvaibleForChat: boolean, AvaibleForCall: boolean, AvaibleForSMS: boolean, AvaibleForEmail: boolean, AbroadDataCenterConsentConfirmation: boolean, AbroadEmailServiceConsentConfirmation: boolean, token: string) {
		return HttpClient.post<any>(`/api/User/register?captchaToken=${token}`, { email, name, surname, password, mobile, AvaibleForChat, AvaibleForCall, AvaibleForSMS, AvaibleForEmail, AbroadDataCenterConsentConfirmation, AbroadEmailServiceConsentConfirmation }, {headers: {'Channel': 'Web'}})
	}

	static me() {
		return HttpClient.get<IUserInfo>('/api/User/me')
	}
	
	static removeAccount() {
		return HttpClient.delete<boolean>('/api/User/removeAccount')
	}

	static deactivateAccount() {
		return HttpClient.put<boolean>('/api/user/deactivateAccount')
	}


	static blockuser(blockId:number){
		return HttpClient.get<any>(`/api/User/blockUser?blockId=${blockId}`)
	}

	static unblockuser(blockId:number){
		return HttpClient.get<any>(`/api/User/unblockUser?blockId=${blockId}`)
	}
    
	static requestNewPassword(email: string, token: string) {
		return HttpClient.get<any>(`/api/User/requestNewPassword?email=${email}&token=${token}`)
	}
    
	static reNewPassword(data: IResetPassword, token: string) {
		return HttpClient.post<any>(`/api/User/createNewPassword?token=${token}`, data)
	}
    
	static resendActivationMail(email: string) {
		return HttpClient.get<any>(`/api/User/resendActivationMail?email=${email}`)
	}

	static setInterests(intrests: string) {
		return HttpClient.get<any>(`/api/User/setInterests?intrests=${intrests}`)
	}

	static changePassword(OldPassword:string, NewPassword: string) {
		return HttpClient.put<IUserInfo>('/api/user/changePassword', { OldPassword, NewPassword })
	}

	static useReferenceCode(referenceCode: string) {
		return HttpClient.get<string>(`/api/user/UserReferanceCode?refCode=${referenceCode}`)
	}
	
	static updateUser(data: IUpdateUser) {
		return HttpClient.put<IUserInfo>('/api/user/updateuser', data)
	}

	static getVisitedPlaces() {
		return HttpClient.get<IVisitedPlace[]>('/api/user/visited')
	}

	static addVisited(data: IVisitedPlace[]) {
		return HttpClient.post<IVisitedPlace[]>('/api/user/addvisited', data)
	}

	static removeVisited(visitedId: number) {
		return HttpClient.delete<IVisitedPlace>(`/api/user/removeVisited?visitedId=${visitedId}`)
	}

	static uploadUserImage(pic: File) {
		const formData = new FormData()
		formData.append('pic', pic)
		return HttpClient.post<string>('/api/User/uploadUserImage', formData)
	}
}