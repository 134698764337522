import React, {Component} from 'react'
import Const from '../../const'

export default class Layout1Field1Component extends Component<
	ILayout1Prop,
	ILayout1State
> {
	constructor(props: ILayout1Prop) {
		super(props)
	}

	render() {
		return (
			this.props.articleLayout.layout1 && (
				<div className="layout-wrap-in clearfix">
					<div className="layout-text-image-area">
						<div className="layout-text-left">
							<div className="layout-text-left-in">
								<h2 className="layout-h2-top">
									{this.props.articleLayout.layout1.Alanbirbaslik}
								</h2>
								<p
									className="layout-p-bottom"
									dangerouslySetInnerHTML={{
										__html: this.props.articleLayout.layout1.Alanbiraciklama
									}}></p>
							</div>
						</div>
						<div className="layout-image-right clearfix">
							<div className="two-image-area">
								<img
									src={`${Const.seturdayCdnArticleLayout1Url}${this.props.articleLayout.layout1.Alan1resim1}`}
									className="two-image-1 wow fadeInUp"
								/>
								<img
									src={`${Const.seturdayCdnArticleLayout1Url}${this.props.articleLayout.layout1.Alan1resim2}`}
									className="two-image-2 wow fadeInUp"
								/>
							</div>
						</div>
					</div>
				</div>
			)
		)
	}
}

interface ILayout1Prop {
	articleLayout: IArticleLayout
}

interface ILayout1State {}
