import React from 'react'
import ServiceUser from '../services/ServiceUser'

export default class Interests extends React.Component {
	constructor(props: any) {
		super(props)
	}

	btnInterestNext(e: React.MouseEvent<HTMLAnchorElement>) {
		e.preventDefault()

		$('#interest-modal').removeClass('active');
		$('#success-modal').addClass('active');
		
		let interesteds: any[] = []
		$('div[data-interested].tick.active').each(function() {
			interesteds.push($(this).data('interested'));
		});
		ServiceUser.setInterests(interesteds.join(',')).then()
	}

	componentDidMount() {
		const width = $(window).width();

		const interestSliderItem = $('.intest-slider .item')
		interestSliderItem.on('click', function() {
			$(this)
				.find('.tick')
				.toggleClass('active')
			$(this).toggleClass('shadow')
			$('.interest-tool-hover').removeClass('tooltip')
			$('.interest-next').addClass('active')
		})
		$('.interest-next').mouseover()

		width <= 768
			? new Swiper('.interest-slide-1', {
					slidesPerView: 'auto',
					spaceBetween: 10,
					loop: !1,
					cssWidthAndHeight: !0,
					pagination: {
						el: '.swiper-pagination',
						clickable: !0
					},
					navigation: {
						nextEl: '.third-slider-right-arrow',
						prevEl: '.third-slider-left-arrow'
					}
			  })
			: new Swiper('.interest-slide-1', {
					slidesPerView: 'auto',
					spaceBetween: 10,
					loop: !1,
					cssWidthAndHeight: !0,
					pagination: {
						el: '.swiper-pagination',
						clickable: !0
					},
					navigation: {
						nextEl: '.third-slider-right-arrow',
						prevEl: '.third-slider-left-arrow'
					}
			  })
	}

	complete() {
		let successModal = $('#success-modal')
		successModal.removeClass('active')
		$('.circle').removeClass('active')
		$('body').removeClass('pfix')
		$('first-overlay').removeClass('pfix')
		successModal.one(
			'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
			function() {
				$('.login-overlay').removeClass('active')
				$('.new-map-overlay').removeClass('active')
				$('.sedvice-circle').removeClass('active')
			}
		)
	}

	render() {
		return (
			<form
				data-ajax="true"
				data-ajax-success="basarili"
				id="form3"
				method="post"
				onKeyDown={event => event.keyCode != 13}>
				{/* İlgi Alanları  */}
				<div className="interest-wrap">
					<input id="intListId" name="intListId" type="hidden" />

					<div
						className="interest-area"
						id="interest-modal"
						style={{zIndex: 9999}}>
						<div className="boxes-top">
							<div className="line"></div>
							<div className="line-bottom-icon">
								<img src="content\img\eye-icon.png" />
							</div>
						</div>
						<div className="interest-area-in">
							<div className="boxes-title">
								<h3> İlgi Alanlarım </h3>
							</div>
							<div className="intest-slider">
								<div className="swiper-container interest-slide-1">
									<div className="swiper-wrapper">
										<div className="swiper-slide">
											<div className="item color-1">
												<div className="tick-area">
													<div className="tick-area-in">
														<div className="tick" data-interested="yemek"></div>
													</div>
												</div>
												<div className="item-in">
													<img src="content\img\int-1.png" />
													<h2>YEMEK</h2>
												</div>
											</div>
										</div>

										<div className="swiper-slide">
											<div className="item color-2">
												<div className="tick-area">
													<div className="tick-area-in">
														<div className="tick" data-interested="sanat"></div>
													</div>
												</div>
												<div className="item-in">
													<img src="content\img\int-2.png" />
													<h2>SANAT</h2>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="item color-3">
												<div className="tick-area">
													<div className="tick-area-in">
														<div className="tick" data-interested="tarih"></div>
													</div>
												</div>
												<div className="item-in">
													<img src="content\img\int-3.png" />
													<h2>TARİH</h2>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="item color-4 ">
												<div className="tick-area">
													<div className="tick-area-in">
														<div
															className="tick"
															data-interested="alisveris"></div>
													</div>
												</div>
												<div className="item-in">
													<img src="content\img\int-4.png" />
													<h2>ALIŞVERİŞ</h2>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="item color-5">
												<div className="tick-area">
													<div className="tick-area-in">
														<div className="tick" data-interested="doga"></div>
													</div>
												</div>
												<div className="item-in">
													<img src="content\img\int-5.png" />
													<h2>DOĞA</h2>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="item color-6">
												<div className="tick-area">
													<div className="tick-area-in">
														<div className="tick" data-interested="is"></div>
													</div>
												</div>
												<div className="item-in">
													<img src="content\img\int-6.png" />
													<h2>İŞ</h2>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="item color-7">
												<div className="tick-area">
													<div className="tick-area-in">
														<div className="tick" data-interested="kesif"></div>
													</div>
												</div>
												<div className="item-in">
													<img src="content\img\int-7.png" />
													<h2>KEŞİF</h2>
												</div>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="item color-8">
												<div className="tick-area">
													<div className="tick-area-in">
														<div className="tick" data-interested="kultur"></div>
													</div>
												</div>
												<div className="item-in">
													<img src="content\img\int-8.png" />
													<h2>KÜLTÜR</h2>
												</div>
											</div>
										</div>
									</div>
									{/* Add Pagination */}
									<div className="swiper-pagination"></div>
								</div>
							</div>
							<div className="next-button intest-next">
								<div className="interest-tool-hover tooltip">
									<a
										href="#"
										onClick={this.btnInterestNext}
										className="interest-next ">
										İleri
									</a>
								</div>
								<div className="interest-next-tooltip">
									<p>
										Seyahat amacınızı işaretleyin, diğer kullanıcılarla
										deneyimlerinizi paylaşın!
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* İlgi Alanları Bitiş */}
				{/* Üyelik Başarılı Popup */}
				<div className="success-wrap">
					<div
						className="sign-up-success-area"
						id="success-modal"
						style={{zIndex: 99999}}>
						<div className="sign-up-success-img"></div>
						<div className="sign-up-success-text">
							<p id="uyeolmesaj">
								Üyelik işleminiz tamamlandı!
							</p>
						</div>
						<div className="sign-up-success-text">
							<a
								onClick={this.complete}
								className="success-next"
								style={{display: 'inline-block'}}>
								Tamam
							</a>
						</div>
					</div>
				</div>
				{/* Üyelik Başarılı Popup Bitiş */}
			</form>
		)
	}
}
