export default class Const {
	static readonly seturdayCdnDomain = 'https://cdn.seturday.com/'
	static readonly seturdayCdnAuthorUrl = `${Const.seturdayCdnDomain}content/uploads/yazar_resimleri/`
	static readonly seturdayCdnArticle = `${Const.seturdayCdnDomain}content/uploads/yazigonder/images/`
	static readonly seturdayCdnArticleCover = `${Const.seturdayCdnDomain}content/uploads/makale_resimleri/kapak/`
	static readonly seturdayCdnArticleLayout1Url = `${Const.seturdayCdnDomain}content/uploads/makale_resimleri/laybir/`
	static readonly seturdayCdnArticleLayout2Url = `${Const.seturdayCdnDomain}content/uploads/makale_resimleri/layiki/`
	static readonly seturdayCdnArticleLayout3Url = `${Const.seturdayCdnDomain}content/uploads/makale_resimleri/layuc/`
	static readonly seturdayCdnArticleSlider3Url = `${Const.seturdayCdnArticleLayout3Url}slideruc/`
	static readonly seturdayCdnBuckerImageUrl = (imageName: string) =>
		`${Const.seturdayCdnDomain}content/uploads/soylesi_resimleri/big/${imageName}`
	static readonly baseApiUrl = 'https://seturdayapp.azurewebsites.net'
	// process.env.NODE_ENV === 'development'
	// 	// ?  'http://localhost:5000'
	// 	? 'https://seturdayapp-staging.azurewebsites.net'
	// 	: 'https://seturdayapp.azurewebsites.net'
}
