import React, {Component, ChangeEvent, Fragment} from 'react'
import HelperDom from '../../helper/helper-dom'
import ServiceCategory from '../../services/ServiceCategory'
import Helmet from 'react-helmet'
import Seo from '../../helper/seo'
import ServiceArticle from '../../services/ServiceArticle'
import moment from 'moment'
import {withRouter, RouteComponentProps} from 'react-router'
import Const from '../../const'
import Notification from '../../helper/notification'
import Guid from '../../helper/guid'
import {observer, inject} from 'mobx-react'
import sortBy from 'lodash/sortBy'

@inject('loginStore')
@observer
class SendArticleComponent extends Component<
	ISendArticleProp,
	ISendArticleState
> {
	readonly query: any = document.querySelector.bind(document)
	readonly savehtmlText =
		'<strong>Yazınız kaydediliyor...</strong><br />Lütfen sayfayı kapatmayınız.'

	constructor(props: ISendArticleProp) {
		super(props)

		this.state = {
			categoryId: 0,
			content: '',
			subTitle: '',
			title: '',
			guid: '',
			pictures: [],
			description: '',
			categories: [],
			isAgreed: false,
			tags: [],
			pictureIds: [],
			docName: '',
			status: 0
		}

		ServiceCategory.categories().then(res => {
			const categories = sortBy(
				res.data.filter(c => ![9, 10, 11, 13].includes(c.Id as number)),
				i => i.MenuOrder
			)
			this.setState(
				{
					categories
				},
				() => {
					$('select').niceSelect('update')
				}
			)
		})

		this.changeCategory = this.changeCategory.bind(this)
		this.changeTitle = this.changeTitle.bind(this)
		this.changeSubTitle = this.changeSubTitle.bind(this)
		this.changeContent = this.changeContent.bind(this)
		this.btnAddTag = this.btnAddTag.bind(this)
		this.modifyTags = this.modifyTags.bind(this)
		this.deleteTag = this.deleteTag.bind(this)
		this.changeIsAgreed = this.changeIsAgreed.bind(this)
		this.showViewArticleText = this.showViewArticleText.bind(this)

		if (this.query('.input') !== null)
			this.query('.input').addEventListener('click', this.focus)

		if (this.query('#Tag') !== null) this.initTags()

		if (this.props.match.params.guid) {
			ServiceArticle.getUserDetailedArticle(this.props.match.params.guid).then(
				res => {
					this.setState(
						{
							categoryId: res.data.CategoryID,
							docName: res.data.DocUri,
							content: res.data.Content,
							description: res.data.Description,
							title: res.data.Title,
							guid: res.data.GUID,
							pictures: res.data.Pictures,
							tags: res.data.Tag ? res.data.Tag.split(',') : [],
							pictureIds: res.data.PictureIds,
							status: res.data.Status
						},
						() => {
							this.initTags()
							if (!this.props.isPreview && res.data.Status > 0) {
								window.showLinebarMessage(
									'Yazınız onaya gönderdikten sonra düzeltme yapamazsınız.',
									3000
								)
								this.props.history.push('/hesabim/yazilarim')
							}
							if (!this.props.isPreview) {
								setTimeout(() => {
									while ($('#ImageInputs .image-input-block').length < 5) {
										$('.add-more-image').click()
									}
								},300);
							}
						}
					)
				}
			).catch( () => {
				this.props.history.push('/')
			})
		}
	}

	removeComma(str: string) {
		return str.replace(',', '').trim()
	}

	isInvalid(stringInput: string) {
		const tags: HTMLElement = this.query('.tags')
		if (!tags) return
		const inputs = Array.from(tags.children).map(
			(input: any) => input.firstElementChild.textContent
		)
		return (
			!/^[A-Za-z0-9_ğüşıöçĞÜŞİÖÇ]{2,}/.test(stringInput) ||
			inputs.some(name => name === this.removeComma(stringInput)) ||
			tags.children.length >= 5
		)
	}

	initTags() {
		this.state.tags.forEach(element => {
			this.addTag(this.removeComma(element))
		})
		this.query('.tags-count').textContent = `${
			this.query('.tags').children.length
		}`
	}

	modifyTags(e: any) {
		if (e.key === 'Enter' || e.key === ',') {
			if (this.isInvalid(e.target.value)) {
				e.target.value = ''
				return
			}
			if (!this.state.tags.includes(e.target.value.toLowerCase()))
				this.addTagWithHidden(e.target.value.toLowerCase())
			e.target.value = ''
		}
		this.query('.tags-count').textContent = `${
			this.query('.tags').children.length
		}`
	}

	addTag(textValue: string) {
		textValue = this.removeComma(textValue)
		const tag = document.createElement('div'),
			tagName = document.createElement('label'),
			remove = document.createElement('span')

		tagName.setAttribute('class', 'tag-name')
		tagName.textContent = textValue.toLowerCase()

		remove.setAttribute('class', 'remove')
		remove.textContent = 'X'
		remove.addEventListener('click', this.deleteTag)
		tag.setAttribute('class', 'tag')
		tag.appendChild(tagName)
		if (!this.props.isPreview) {
			tag.appendChild(remove)
		}
		this.query('.tags').appendChild(tag)
	}

	btnAddTag() {
		var inputVal = $('#tagInput').val() as string
		if (inputVal === '' || this.isInvalid(inputVal)) {
			return false
		} else {
			this.addTagWithHidden(inputVal)
			$('#tagInput').val('')
			this.query('.tags-count').textContent = `${
				this.query('.tags').children.length
			}`
		}
	}

	addTagWithHidden(textValue: string) {
		textValue = this.removeComma(textValue)
		this.addTag(textValue.toLowerCase())
		this.setState(
			{
				tags: [...this.state.tags, textValue.toLowerCase()]
			},
			() => {
				this.updateHiddenValue(this.getStrTags())
			}
		)
	}

	deleteTag(
		e: any,
		i = Array.from(this.query('.tags').children).indexOf(e.target.parentElement)
	) {
		const index = this.query('.tags').getElementsByClassName('tag')[i]
		this.query('.tags').removeChild(index)
		this.query('.tags-count').textContent = `${
			this.query('.tags').children.length
		}`
		this.state.tags.splice(i, 1)
		this.setState({
			tags: this.state.tags
		})
		this.updateHiddenValue(this.getStrTags())
	}

	focus() {
		this.query('#tagInput').focus()
	}

	getStrTags() {
		let strTags = ''
		this.state.tags.forEach((element: string) => {
			if (strTags.length > 0) strTags = strTags + ', ' + element
			else strTags = element
		})
		return strTags
	}

	updateHiddenValue(inputValue: string) {
		this.query('#Tag').value = inputValue
	}

	componentDidMount() {
		if (!this.props.isPreview && !this.props.match.params.guid) {
			while ($('#ImageInputs .image-input-block').length < 5) {
				$('.add-more-image').click()
			}
		}
		$('select.writer-select-category').niceSelect()
		HelperDom.loadStyle('/Content/yazigonder/yazarol.css')
		HelperDom.loadStyle('/Content/css/nice-select.css')

		$('.terms-accordion .accordion-header').click(function(j) {
			const dropDown = $(this)
				.closest('.accordion-item')
				.find('.collapse')

			$(this)
				.closest('.terms-accordion')
				.find('.collapse')
				.not(dropDown)
				.slideUp()

			if ($(this).hasClass('active')) {
				$(this).removeClass('active')
			} else {
				$(this)
					.closest('.terms-accordion')
					.find('.accordion-header.active')
					.removeClass('active')
				$(this).addClass('active')
			}

			dropDown.stop(false, true).slideToggle()

			j.preventDefault()
		})

		$('.close-article').on('click', function() {
			$('.view-article-area').fadeOut()
			$('body')
				.removeClass('overflow-hidden')
				.removeClass('blur')
			setTimeout(function() {
				$('.main-wrap').removeClass('ov-pe')
			}, 500)
			$('#YaziGonderForm').removeClass('blur')
		})

		function initWordCountLimit(selector: string) {
			const input = $(selector)
			input.focusin(function() {
				const counterId = input.attr('id') + 'Counter'
				if ($('#' + counterId).length === 0) {
					const counterTemplate =
						'<span class=\'input-word-counter\' id=\'' + counterId + '\'></span>'
					input.after(counterTemplate)
				}
				const counter = $('#' + counterId)
				const setCounterText = function() {
					let wordCount = input
						.val()
						.toString()
						.trim()
						.split(' ').length
					let length = input
						.val()
						.toString()
						.trim().length
					counter.text(
						'Toplam ' +
							length +
							' karakterden oluşan ' +
							wordCount.toString() +
							' kelime yazıldı.'
					)
				}
				input.keyup(function() {
					setCounterText()
				})
				input.focusout(function() {
					if (
						input
							.val()
							.toString()
							.trim().length === 0
					)
						counter.remove()
				})
				setCounterText()
			})
		}

		initWordCountLimit('#Title')
		initWordCountLimit('#Description')
		initWordCountLimit('#Content')

		$('input, textbox').keypress(function(event) {
			if (event.keyCode === 10 || event.keyCode === 13) {
				event.preventDefault()
			}
		})
	}
	addMorePicture = () => {
		this.cloneImageInput()
		this.initImageInputBlocks()
	}
	componentWillReceiveProps(nextProps:any) {
		if (!nextProps.isPreview) {
			while ($('#ImageInputs .image-input-block').length < 5) {
				$('.add-more-image').click()
			}
		}
	}
	componentDidUpdate() {
		$('#YaziGonderForm').validate()

		jQuery.validator.addMethod(
			'wordCountRange',
			function(value: any, element: any, params: any) {
				return (
					value.trim().split(' ').length >= params[0] &&
					value.trim().split(' ').length <= params[1]
				)
			},
			'En az {0} en çok {1} kelimeden oluşmalıdır.'
		)

		$('#Title').rules('add', {wordCountRange: [3, 8]})
		$('#Description').rules('add', {wordCountRange: [12, 25]})
		$('#Content').rules('add', {wordCountRange: [450, 800]})

		this.initImageInputBlocks()

		var documentInputBlock = $('#DocumentInput')
		var documentFile = documentInputBlock.find('input[type=\'file\']')
		documentFile.on('change', function() {
			let label = documentInputBlock.find('label'),
				labelText = label.find('span'),
				labelDefault = 'EKLE',
				fileName = ((documentFile.val() as string) || '').split('\\').pop()
			if (fileName) {
				label.addClass('file-ok')
				if (fileName.length > 20) fileName = fileName.substring(0, 20) + '...'
				labelText.text(fileName)
				$('#documentFileUrlTip').hide()
			} else {
				label.removeClass('file-ok')
				labelText.text(labelDefault)
				$('#documentFileUrlTip').show()
			}
		})
		$('select').niceSelect('update')
	}

	changeIsAgreed(e: ChangeEvent<HTMLInputElement>) {
		this.setState({
			isAgreed: e.target.value as any
		})
	}

	changeCategory(e: ChangeEvent<HTMLSelectElement>) {
		this.setState({
			categoryId: e.target.value as any
		})
	}

	changeTitle(e: ChangeEvent<HTMLInputElement>) {
		this.setState({
			title: e.target.value
		})
	}

	changeSubTitle(e: ChangeEvent<HTMLInputElement>) {
		this.setState({
			description: e.target.value
		})
	}

	changeContent(e: ChangeEvent<HTMLTextAreaElement>) {
		this.setState({
			content: e.target.value
		})
	}

	cloneImageInput() {
		let index = $('#ImageInputs .image-input-block').length
		let inputFileID = `Images_${index}__File`
		let inputFileName = `Images[${index}].File`
		let inputDescriptionID = `Images_${index}__ImageDescription`
		let inputDescriptionName = `Images[${index}].ImageDescription`

		let instance = $('#ImageInputs .image-input-block:first-child').clone()

		instance
			.find('label')
			.attr('for', inputFileID)
			.removeAttr('style')
			.removeClass('file-ok')
		instance.find('label span').text('Fotoğraf Ekle')
		instance
			.find('.block-top input.image-file-input')
			.attr('id', inputFileID)
			.attr('name', inputFileName)
			.val('')
			.attr('onChange', 'this.initImageInputBlocks')
		instance
			.find('.block-bottom input')
			.attr('id', inputDescriptionID)
			.attr('name', inputDescriptionName)
			.val('')
		instance.insertBefore('#ImageInputs .add-more-image')

		document.getElementById(inputFileID).dataset.id = '0'

		if (index == 7) {
			$('#ImageInputs .add-more-image').remove()
		}
	}

	initImageInputBlocks() {
		$('.image-input-block').each(function() {
			var block = $(this)
			var file = $(this).find('input[type=\'file\']') as JQuery<HTMLInputElement>
			file.on('change', function(event) {
				let label = block.find('label'),
					labelText = label.find('span'),
					labelDefault = 'Fotoğraf Ekle'
				const maxfilesize = 1024 * 1024 * 2 // 2 Mb
				if (file[0] && file[0].files && file[0].files.length > 0) {
					if (file[0].files[0].size > maxfilesize) {
						label.removeClass('file-ok').removeAttr('style')
						labelText.text(labelDefault)
						window.showLinebarMessage(
							'Seçtiğiniz dosya boyutu 2 MB boyutunu aşmamalıdır.',
							3000
						)
					} else {
						const fileName = file[0].files[0].name
						if (event.target.files) {
							const tmppath = URL.createObjectURL(event.target.files[0])
							const descriptionErrorText =
								'Lütfen yüklediğiniz fotoğraf için açıklama giriniz.'
							const description = block.find('.image-file-description')
							label
								.addClass('file-ok')
								.removeAttr('style')
								.css('background-image', 'url(' + tmppath + ')')
							labelText.text(fileName)
							description.focus()
							description.keyup(function() {
								if ($('#imagesError').text() == descriptionErrorText)
									$('#imagesError').text('')
							})
							description.focusout(function() {
								if (description.val() == null || description.val() == '') {
									$('#imagesError').text(descriptionErrorText)
								}
							})
						}
					}
				} else {
					label.removeClass('file-ok').removeAttr('style')
					labelText.text(labelDefault)
				}
			})
		})
	}

	validateAsFormInput() {
		let title = $('#Title')
		let category = $('#Category')
		let description = $('#Description')
		let content = $('#Content')
		let tag = $('#Tag')
		let tagInput = $('#tagInput')

		if (!title.valid()) this.scrollToElem(title)
		else if (!description.valid()) this.scrollToElem(description)
		else if (!content.valid()) this.scrollToElem(content)
		else if (!category.valid())
			this.scrollToElem(category.closest('.writer-input-side'))
		else if (!tag.valid()) this.scrollToElem(tagInput)
		else if (this.validateImages()) return true
		return false
	}

	validateAsDocument() {
		const title = $('#Title')
		const category = $('#Category')
		let tag = $('#Tag')
		let tagInput = $('#tagInput')
		const documentInput = $('#Document')
		const documentFileUrl = $('#DocumentFileUrl')

		if (!title.valid()) this.scrollToElem(title)
		else if (!category.valid())
			this.scrollToElem(category.closest('.writer-input-side'))
		else if (!tag.valid()) this.scrollToElem(tagInput)
		else if (documentFileUrl.length == 0 && documentInput.val() == '')
			this.scrollToElem(documentInput)
		else if (this.validateImages()) return true
		return false
	}

	validateImages() {
		let errors = []
		let imagesCount = 0
		let invalid = false
		let descriptionError = false
		let maxFileSizeError = false
		const maxfilesize = 1024 * 1024 * 2 // 2 Mb

		$('#ImageInputs .image-input-block').each(function() {
			let block = $(this)
			let fileInput = block.find('input[type=\'file\']')[0] as HTMLInputElement
			let fileUrl = block.find('input[type=\'hidden\']')
			let fileLabel = block.find('> .block-top > label')
			let description = block.find('.image-description input')

			if (
				fileLabel.hasClass('file-ok') ||
				(fileInput.files && fileInput.files.length > 0)
			) {
				if (description.val() == null || description.val() == '') {
					block.addClass('invalid')
					description.focus()
					descriptionError = true
				} else if (
					fileInput.files.length > 0 &&
					fileInput.files[0].size > maxfilesize
				) {
					maxFileSizeError = true
				}
				imagesCount++
			} else if (fileUrl.val() != null && fileUrl.val() != null) {
				imagesCount++
			}
		})
		if (imagesCount < 5) {
			errors.push('En az 5 fotoğraf yüklemelisiniz.')
			invalid = true
		}
		if (descriptionError) {
			errors.push('Lütfen yüklediğiniz fotoğraflar için açıklama giriniz.')
			invalid = true
		}
		if (maxFileSizeError) {
			errors.push('Yüklediğiniz fotoğraflar en fazla 2 MB boyutunda olabilir.')
			invalid = true
		}
		if (invalid) {
			let imagesError = $('#imagesError')
			imagesError.text('')
			errors.forEach(function(element) {
				imagesError.append(element + ' ')
			})
			this.scrollToElem($('#ImageInputs'))
			return false
		}

		$('#imagesError').text('')
		return true
	}

	scrollToElem(elem: JQuery) {
		$('html, body').animate(
			{
				scrollTop: (elem.offset() as any).top - 150
			},
			800
		)
	}

	getModel(status: number) {
		let model: any = {
			status,
			pictures: [],
			picturesDescription: '',
			articleMedia: null,
			title: this.state.title,
			categoryId: this.state.categoryId || 0,
			content: this.state.content || '',
			guid: this.state.guid,
			description: this.state.description || '',
			tag: this.state.tags.join(','),
			pictureIds: this.state.pictureIds || [],
			userId: this.props.loginStore.user.Id
		}
		const articlePictures:any = this.state.pictures

		const documentFile: HTMLInputElement = document.getElementById(
			'Document'
		) as HTMLInputElement
		model.articleMedia = documentFile.files[0]

		if (this.state.guid) {
			model = {
				...model
			} as IUpdateUserArticle
		} else {
			model = {
				...model,
				status: 0
			} as IUserArticleByMediaRequest
		}
		let description: string[] = []
		for (let i = 0; i <= 7; i++) {
			var file: HTMLInputElement = document.getElementById(
				`Images_${i}__File`
			) as HTMLInputElement
			if (file) {
				if (file.files.length == 1) {
					if (!model.pictures) model.pictures = []
					model.pictures.push(file.files[0])
					const desc = (document.getElementById(
						`Images_${i}__ImageDescription`
					) as HTMLInputElement).value
					desc && description.push(desc)
					model.pictureIds.push(Number(file.dataset.id || 0))
				}
				else {
					if (articlePictures[i]) {
						let uriParts:any = articlePictures[i].PictureUri.split(".");
						let fileType:any = uriParts[uriParts.length - 1];
						const file = new File([], `${Guid()}.${fileType}`, {type: `image/${fileType}`})
						model.pictures.push(file)
						description.push(articlePictures[i].Description)
					}
				}
			} 
		}
		model.picturesDescription = description.join(',')
		return model
	}

	save(status: number) {
		let model = this.getModel(status)
		Notification.showLoading(this.savehtmlText)
		try {
			if (this.state.guid) {
				ServiceArticle.updateUserArticle(model).then(() => {
					Notification.hideLoading()
					if (status === 0) {
						window.showLinebarMessage(
							'Makale güncelleme işlemi başarıyla gerçekleşti.',
							3000
						)
						this.props.history.push('/yazi-gonder/SaveDraft')
					} else {
						this.props.history.push('/yazi-gonderildi')
					}
				}).catch((err:any) => {
					Notification.hideLoading()
					window.showLinebarMessage('Beklenmedik bir hata oluştu.', 3000)
				})
			} else {
				ServiceArticle.addUserArticle(model).then((res) => {
					Notification.hideLoading()
					if (status === 0) {
						window.showLinebarMessage('Makale başarıyla eklendi.', 3000)
						this.props.history.push('/yazi-gonder/SaveDraft')
					} else {
						this.props.history.push('/yazi-gonderildi')
					}
				}).catch((err:any) => {
					Notification.hideLoading()
					window.showLinebarMessage('Beklenmedik bir hata oluştu.', 3000)
				})
			}
		} catch (error) {
			Notification.hideLoading()
			window.showLinebarMessage('Bir hata oluştu.', 3000)
		}
	}

	btnSave(e: React.MouseEvent<HTMLAnchorElement>, status: number) {
		e.preventDefault()
		this.initImageInputBlocks()
		let documentInput = $('#Document')
		let documentFileUrl = $('#DocumentFileUrl')
		if (status === 0) {
			this.save(status)
		} else {
			if ($('#IsAgreed').is(':checked')) {
				if (documentInput.val() != '' || documentFileUrl.length > 0) {
					if (this.validateAsDocument()) {
						this.save(status)
					}
				} else {
					if (this.validateAsFormInput()) {
						this.save(status)
					}
				}
			} else {
				window.showLinebarMessage(
					'Sözleşmeyi okuyup onaylamanız gerekmektedir.',
					3000
				)
			}
		}
	}

	showViewArticleText() {
		$('.view-article-area').fadeIn()
		$('body').addClass('overflow-hidden')
		$('.main-wrap').addClass('ov-pe')
		$('#YaziGonderForm').addClass('blur')
	}

	ImageDescription(e:ChangeEvent<HTMLInputElement>, item:any) {
		if (item) {
			const { value } = e.currentTarget
			const { pictures } = this.state
			if (item.Description !== value) {
				item.Description = value
				this.setState({
					...pictures,
					...item
				})
			}
		}
	}

	render() {
		return (
			<Fragment>
				<div className="first-right-content">
					<Helmet>
						<title>{Seo.getTitle('Yazı Gönder')}</title>
					</Helmet>
					<div
						className="main-slide category"
						style={{
							background:
								'url(/Content/img/categoryimage/yazar-ol-bg.png) center center / cover'
						}}>
						<div className="main-title-area-index">
							<div className="main-title-index">
								<h1>Yazar Ol</h1>
							</div>
						</div>
					</div>
					<form
						className="needs-validation"
						action="/yazi-gonder"
						autoComplete="off"
						id="YaziGonderForm"
						method="post"
						style={{marginTop: '520px'}}
						noValidate={true}>
						<div className="full-w writer-bg-grey">
							<div className="writer-card-wrapper clearfix">
								<div className="writer-first-title">
									<h2 className="input-title">Yazar Ol!</h2>
									<p className="input-info">
										Siz de kendi seyahat deneyimlerinizi kaleme alın, Seturday
										yazarları arasında yerinizi alın!
									</p>
								</div>
								<div className="writer-input-col">
									<div className="writer-input-info">
										<h3 className="input-title">Başlık*</h3>
										<p className="input-info">
											Başlığınızla yazınızı ilgi çekici hale getirin! Başlığınız
											25-70 karakter sınırına uygun, en az 3 en çok 8 kelimeden
											oluşmalıdır.
										</p>
									</div>
									<div className="writer-input-side">
										<input
											data-val="true"
											data-val-length="Başlık en az 25 en çok 70 karakterden oluşabilir."
											data-val-length-max="70"
											data-val-length-min="25"
											data-val-required="Bu alan boş bırakılamaz."
											id="Title"
											name="Title"
											type="text"
											onChange={this.changeTitle}
											value={this.state.title}
											disabled={this.props.isPreview}
										/>
										<span
											className="field-validation-valid"
											data-valmsg-for="Title"
											data-valmsg-replace="true"></span>
									</div>
								</div>
								<div className="writer-input-col">
									<div className="writer-input-info">
										<h3 className="input-title">Alt Başlık*</h3>
										<p className="input-info">
											Yazınız ile ilgili özet açıklamanızı giriniz. Başlığın
											hemen altında yer alacak açıklama yazınız 90- 200 karakter
											sınırına uygun en az 12 en çok 25 kelimeden oluşmalıdır.{' '}
										</p>
									</div>
									<div className="writer-input-side">
										<input
											data-val="true"
											data-val-length="Spot içerik alanı en az 90 en çok 200 karakterden oluşabilir."
											data-val-length-max="200"
											data-val-length-min="90"
											data-val-required="Bu alan boş bırakılamaz."
											id="Description"
											name="Description"
											type="text"
											onChange={this.changeSubTitle}
											value={this.state.description}
											disabled={this.props.isPreview}
										/>
										<span
											className="field-validation-valid"
											data-valmsg-for="Description"
											data-valmsg-replace="true"></span>
									</div>
								</div>
								<div className="writer-input-col">
									<div className="writer-input-info">
										<h3 className="input-title">Yazı İçeriği*</h3>
										<p className="input-info">
											Yazınızda kullanacağınız karakter sayısı 3500- 5500
											sınırına uygun, en az 450 en çok 800 kelime aralığında
											yazılmalıdır.{' '}
										</p>
										<img
											src="/Content/yazigonder/img/article-sablon.png"
											onClick={this.showViewArticleText}
											className="view-article"
										/>
										<span
											className="view-article-text"
											onClick={this.showViewArticleText}>
											<img
												src="/Content/yazigonder/img/view-article.png"
												alt="Yazı Gönder"
											/>
											Örnek yazı şablonunu görmek için tıklayın.
										</span>
									</div>
									<div className="writer-input-side">
										<textarea
											disabled={this.props.isPreview}
											cols={20}
											data-val="true"
											data-val-length="Yazı içeriği en az 3500 en çok 5500 karakterden oluşabilir."
											data-val-length-max="5500"
											data-val-length-min="3500"
											data-val-required="Bu alan boş bırakılamaz."
											id="Content"
											name="Content"
											onChange={this.changeContent}
											rows={10}
											value={this.state.content}></textarea>
										<span
											className="field-validation-valid"
											data-valmsg-for="Content"
											data-valmsg-replace="true"></span>
									</div>
								</div>
								<div className="writer-input-col">
									<div className="writer-input-info">
										<h3 className="input-title">Kategori*</h3>
										<p className="input-info">
											Yazınıza en uygun kategoriyi seçin.{' '}
										</p>
									</div>
									<div className="writer-input-side">
										<div className="">
											<select
												disabled={this.props.isPreview}
												className="writer-select-category"
												data-val="true"
												data-val-number="The field Kategori must be a number."
												data-val-required="Bu alan boş bırakılamaz."
												id="Category"
												name="Category"
												onChange={this.changeCategory}
												value={this.state.categoryId}>
												<option value={0}>-- Kategori Seç --</option>
												{this.state.categories.map(item => {
													return (
														<option
															key={`category_option_${item.Id}`}
															value={item.Id}>
															{item.KategoriAdi}
														</option>
													)
												})}
											</select>
										</div>
										<span
											className="field-validation-valid"
											data-valmsg-for="Category"
											data-valmsg-replace="true"></span>
									</div>
								</div>
								<div className="writer-input-col">
									<div className="writer-input-info">
										<h3 className="input-title">Etiketler*</h3>
										<p className="input-info">
											İçeriğinize uygun etiketler kullanarak yazınızı dikkat
											çekici hale getirin. İçeriklerinizin başına '#' işareti
											eklemeden giriş yapınız.
										</p>
									</div>
									<div className="writer-input-side">
										<section id="wrap">
											<div className="input-container">
												{!this.props.isPreview &&
												<div className="input">
													<button
														id="addtag-button"
														onClick={this.btnAddTag}
														type="button">
														Ekle
													</button>
													<input
														id="tagInput"
														onKeyUp={this.modifyTags}
														type="text"
														placeholder="seyahat, tatil, deniz"
														autoComplete="off"
													/>
												</div>}
												<div className="tags"></div>
												<span className="tip">
													Enter ya da Ekle butonu ile ekleyiniz.
													&nbsp;&nbsp;|&nbsp;&nbsp;En fazla 5 etiket
													ekleyebilirsiniz. &nbsp;&nbsp;|&nbsp;&nbsp;
													<b className="tags-count">0</b> etiket eklendi.
												</span>
											</div>
										</section>
										<input
											disabled={this.props.isPreview}
											data-val="true"
											data-val-required="Bu alan boş bırakılamaz."
											id="Tag"
											name="Tag"
											type="hidden"
											value=""
										/>
										<span
											className="field-validation-valid"
											data-valmsg-for="Tag"
											data-valmsg-replace="true"></span>
									</div>
								</div>
							</div>
							<div className="writer-card-wrapper clearfix">
								<div className="writer-first-title">
									<h2 className="input-title">Fotoğraf Ekleme</h2>
									<p className="input-info">
										Yazınızı fotoğraflarla zenginleştirin! İçeriğinizde mutlaka
										kendi fotoğrafınızın yer aldığı 1 paylaşıma yer vererek en
										az 5 en çok 8 fotoğraf kullanmalısınız. En fazla 2 MB
										olmalıdır
									</p>
								</div>
								<div className="writer-input-col">
									<div className="writer-input-info">
										<h3 className="input-title">Fotoğraf*</h3>
										<p className="input-info">
											Yükleyeceğiniz fotoğraf en fazla 2 MB büyüklüğünde ve JPEG
											formatında olmalıdır. Fotoğraf açıklaması girilmelidir.
										</p>
										<span
											className="field-validation-error"
											id="imagesError"></span>
									</div>
									<div className="writer-input-side">
										<div className="image-inputs-wrapper" id="ImageInputs">
											{this.state.pictures &&
												this.state.pictures.map((item: any, i) => {
													if (this.state.pictures.length === i + 1) {
														setTimeout(() => {
															this.initImageInputBlocks()
														}, 1000)
													}
													return (
														<div
															key={`image_upload_${i}`}
															className="image-input-block">
															<div className="block-top">
																<label
																	htmlFor={`Images_${i}__File`}
																	className="file-ok"
																	style={{
																		backgroundImage: `url(${Const.seturdayCdnArticle}${item.PictureUri})`
																	}}>
																</label>
																<input
																	id={`Images_${i}__File`}
																	name={`Images[${i}].File`}
																	onChange={this.initImageInputBlocks}
																	data-id={item.Id}
																	type="file"
																	className="image-file-input"
																	disabled={this.props.isPreview}
                                                                    accept=".jpeg"
																/>
															</div>
															<div className="block-bottom image-description">
																<input
																	id={`Images_${i}__ImageDescription`}
																	onChange={(e) => this.ImageDescription(e, item)}
																	name={`Images[${i}].ImageDescription`}
																	type="text"
																	className="valid image-file-description"
																	placeholder="Açıklama girin...*"
																	value={item.Description}
																	disabled={this.props.isPreview}
                                                                    accept=".jpeg"																/>
															</div>
														</div>
													)
												})}
											{this.state.pictures.length === 0 && (
												<div className="image-input-block">
													<div className="block-top">
														<label htmlFor="Images_0__File">
															<span>Fotoğraf Ekle</span>
														</label>
														<input
															id="Images_0__File"
															name="Images[0].File"
															type="file"
															onChange={this.initImageInputBlocks}
															className="image-file-input"
															disabled={this.props.isPreview}
                                                            accept=".jpeg"														/>
													</div>
													<div className="block-bottom image-description">
														<input
															id="Images_0__ImageDescription"
															name="Images[0].ImageDescription"
															type="text"
															className="valid image-file-description"
															placeholder="Açıklama girin...*"
															disabled={this.props.isPreview}
                                                            accept=".jpeg"														/>
													</div>
												</div>
											)}
											{!this.props.isPreview && (
												<div
													className="add-more-image"
													onClick={this.addMorePicture}>
													<span>Daha Fazla Ekle</span>
												</div>
											)}
										</div>
									</div>
								</div>
								{(!this.props.isPreview || this.state.docName) && (
									<div className="writer-input-col">
										<div className="writer-input-info">
											<h3 className="input-title">Word Yükleme</h3>
											<p className="input-info">
												Dilerseniz yazınızı Word Dökümanı olarak
												yükleyebilirsiniz. (Başlık, Kategori, Etiketler ve
												Fotoğraf Ekleme alanlarını doldurmanız gerekmektedir.)
											</p>
										</div>
										<div className="writer-input-side">
											<div className="document-input" id="DocumentInput">
												<label
													htmlFor="Document"
													className={this.state.docName ? 'file-ok' : ''}>
													<span>
														{this.state.docName ? this.state.docName : 'EKLE'}
													</span>
												</label>
												<input
													type="file"
													id="Document"
													name="Document"
													accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
												/>
											</div>
										</div>
									</div>
								)}
								<div className="writer-full-w">
									<h3 className="input-title">Bize Ulaşın!</h3>
									<p className="input-info">
										Yardımcı olabileceğimiz herhangi bir konu, paylaşmak
										istediğiniz görüş ya da önerileriz için bizimle
										<br />
										iletişime geçin. En kısa sürede tüm sorularınız için size
										ulaşalım.
										<br />
										<a href="mailto:info@seturday.com">info@seturday.com</a>
									</p>
								</div>
							</div>
							{!this.props.isPreview && (
								<div className="writer-outline">
									<div className="terms-accordion-area">
										<div className="terms-accordion">
											<div className="accordion-item">
												<a
													href="#"
													onClick={e => e.preventDefault()}
													className="accordion-header writer-outline-row-title">
													Sözleşme
												</a>
												<div className="collapse writer-agreement">
													<p>
														<strong>1.) TARAFLAR </strong>
													</p>
													<p>
														İşbu Sözleşme, bir tarafta Saray Mahallesi, Site
														Yolu Caddesi No. 4/1 Ümraniye 34768 İstanbul
														adresinde mukim Setur Servis Turistik A.Ş. (bundan
														sonra kısaca “Setur” olarak anılacaktır) ile diğer
														tarafta Seturday’e üye olmuş “Yazar” arasında,
														aşağıdaki hüküm ve koşullarda akdedilmiştir.
													</p>
													<p>
														<strong>2.) KONU </strong>
													</p>
													<p>
														Setur’ un markası olan “Seturday” de aşağıda
														belirtilen teknik şartlarda olmak üzere “blog yazarı
														olma” usul ve esaslarını içermektedir.
													</p>
													<p>
														<strong>- TEKNİK ŞARTLAR </strong>
													</p>
													<p>
														<strong>GÖRSEL EKLEME KURALLARI</strong>
													</p>
													<p>
														Yüklenilecek görseller; en fazla 2 MB büyüklüğünde,
														JPEG formatında ve en az 5 en çok 8 adet olmalıdır.
													</p>
													<p>
														Yazar’a ait olmayan görseller için telif haklarının
														ihlali çerçevesinde yasal işlem başlatılacaktır.
													</p>
													<p>
														İçerikte yer alan görsellerin açıklaması yapılarak
														yazı içerisinde görselin ait olduğu lokasyon
														belirtilmelidir.
													</p>
													<p>
														<strong>YAZI KARAKTER SINIRLAMALARI</strong>
													</p>
													<p>
														Yazıda kullanılacak karakter sayısı 3500- 5500
														sınırını aşmamalı ve yazı en az 450 en çok 800
														kelime içermelidir.
													</p>
													<p>
														Yazının başlığı 25-70 karakter sınırına uygun
														olmalıdır.
													</p>
													<p>
														Başlıkta kullanılacak kelime sayısı en az 3 en çok 8
														kelime sınırına uygun olmalıdır.
													</p>
													<p>
														<strong>ÖZGÜN İÇERİK OLUŞTURMA</strong>
													</p>
													<p>
														Yazı içeriğinin daha önce yayınlanmış yazıların
														içeriğiyle benzerlik taşıması durumunda Setur yazıyı
														yayınlamama hakkına sahiptir.
													</p>
													<p>
														Yazı, içeriğinde alkole özendirici, hakaret içeren
														herhangi bir görsel veya metnin yer alması halinde
														yayınlanmayacaktır.
													</p>
													<p>
														Daha önce bir yerde yayınlanmış olması halinde yazı
														yayınlanmayacaktır.
													</p>
													<p>
														Tüm görsel ve yazı içeriğinden yazar sorumludur.
													</p>
													<p>
														<strong>YAŞ SINIRI</strong>
													</p>
													<p>
														Seturday için içerik oluşturacak yazar adaylarının
														18 yaşını doldurmuş olmaları işbu sözleşmenin
														geçerlilik şartıdır.
													</p>
													<p>
														<strong>3) SORUMLULUK</strong>
													</p>
													<p>
														Yazar, işbu Sözleşme hükümleri çerçevesinde Seturday
														için hazırlayacağı ve/veya hazırlatacağı her türlü
														içerik, geliştirme, eser, çalışma, rapor ve
														dökümanlar ile hizmetlerin FSEK’nun 21.-25. madde
														hükümlerinde tanımlanan her türlü fikri ve mali ve
														manevi haklarının yurt içinde ve yurt dışında
														kullanılmak üzere 10 sene süre ile olmaksızın üçüncü
														kişilere devir haklarını da kapsar şekilde tek
														sahibinin Setur olacağını kabul ve taahhüt eder.
														Yazar, üçüncü kişi ve kuruluşların bu hususta
														herhangi bir hak iddia etmeleri halinde her türlü
														hukuki ve cezai sorumluluğun bizzat kendisine ait
														olduğunu kabul ve taahhüt ile Setur’un bu nedenle
														uğrayacağı tüm zararları Setur’un ilk yazılı
														talebine binaen tazmin etmekle yükümlüdür.
													</p>
													<p>
														Yazar, SETUR için hazırlayacağı ve/veya
														hazırlatacağı her tür içerik, eser, çalışma, rapor
														ve dökümanlara ilişkin olarak üçüncü kişiler
														tarafından iddia edilebilecek mali hak ve/veya telif
														hakkı talepleri dahil ve fakat bunlarla sınırlı
														olmamak üzere her türlü talepten ve söz konusu
														şahıslarla çıkması olası uzlaşmazlıklardan ve
														bunların müstakbel sonuçlarından tamamen ve bizzat
														sorumlu olduğunu kabul ile SETUR’u bu yöndeki
														taleplerden beri tutulması için gereken her nev’i
														tedbiri almayı taahhüt eder. Buna rağmen SETUR’un
														her ne nam altında olursa olsun ödemek zorunda
														kalabileceği her türlü tazminat ve/veya idari/adli
														ceza için Yazar’a rücu ve bu durumdan kaynaklanan
														her tür maddi-manevi zararlarının tazminini talep
														haklarını haizdir.
													</p>
													<p>
														İşbu sözleşme kapsamında Setur’a teslim edilen metin
														ve görsellerin tüm kullanım haklarını 10 sene süre
														ile Setur’a vermiş olduğunu kabul eder.
													</p>
													<p>
														Yazardan gelen yazılı ve görsel içerikler öncelikle
														Setur'un yayın politikasına göre değerlendirmeye
														tabi tutulacaktır. Yazar’ın Setur'a iletmiş olduğu
														içeriklerin ve görsellerin yayına alınması hususunda
														nihai karar Setur'a aittir. Setur, gönderilen
														içerikler ve görseller üzerinde değişiklik
														(eklemeler, çıkarmalar, cümle yapısı değişiklikleri
														vb.) yapma hakkına sahiptir. Yazarın Setur’ a
														iletmiş olduğu yazı ve görsel içeriklerin Setur’ un
														belirlemiş olduğu jüri üyesi tarafından yayınına
														onay verilmesi, işbu sözleşmenin geçerlilik
														şartıdır. Yayına onay verilmemiş yazı sahipleri için
														işbu sözleşme hükümleri uygulanmaz.
													</p>
													<p>
														<strong>
															4) SÖZLEŞME BEDELİ VE ÖDEME KOŞULLARI{' '}
														</strong>
													</p>
													<p>
														Yazının Seturday’ in oluşturduğu jüri üyeleri
														tarafından onaylanması halinde Setur işbu sözleşme
														hükümleri kapsamında yazara 150 TL ödeme yapacaktır.
														Ödeme, yazının yayınını takip eden 15 iş günü
														içerisinde, yazar tarafından bildirilen IBAN
														numarasına gerçekleştirilecektir.
													</p>
													<p>
														<strong>5) MÜCBİR SEBEPLER </strong>
													</p>
													<p>
														İşbu sözleşmenin imzalandığı tarihte var olmayan,
														öngörülmeyen ve tarafların iradesi dışında gelişen,
														ortaya çıkmasıyla taraflardan birinin ya da her
														ikisinin de sözleşme ile üstlendikleri edimleri
														yerine getirmelerini imkânsızlaştıran Türk Borçlar
														Kanunu’nda sayılan haller, taraflarca mücbir
														sebep(ler) olarak kabul edilir. Mücbir sebepler
														dolayısıyla işbu sözleşmede bahsi geçen
														yükümlülüklerini ifa edemeyecek ya da ifada
														gecikecek olan taraf, durumu aynı gün içerisinde
														karşı tarafa yazılı olarak bildirmekle yükümlüdür.
														Bu durumda taraflardan her biri, işbu sözleşmeyi
														feshetme hakkına sahip olduğunu ancak sözleşmenin
														ifa edilememesi veya ifada gecikilmesi nedeniyle
														uğradığı zararları talep etme hakkına sahip
														olmayacağını kabul eder. Ancak taraflar, karşılıklı
														mutabık kalmak suretiyle yeni bir sözleşme imzalayıp
														bu sözleşmeyi yeni şartlara uyarlayabileceklerdir.
													</p>
													<p>
														<strong>6) DEVİR </strong>
													</p>
													<p>
														Yazar işbu sözleşmeden doğan hak ve yükümlülüklerini
														Setur’un yazılı onayı olmaksızın 3.kişilere devir
														veya temlik edemez.
													</p>
													<p>
														<strong>7) DAMGA VERGİSİ </strong>
													</p>
													<p>
														İşbu sözleşmeden doğan vergi, resim, harç, damga
														vergisi Setur tarafından ödenecektir.
													</p>
												</div>
											</div>
										</div>
									</div>

									<div className="writer-outline-row align-items-center">
										<label className="checkbox-container writer-agreement-checkbox">
											Sözleşmeyi okudum, onayladım
											<input
												data-val="true"
												data-val-required="The IsAgreed field is required."
												id="IsAgreed"
												name="IsAgreed"
												type="checkbox"
												onChange={() =>
													this.setState({
														isAgreed:
															this.state.isAgreed == false ? true : false
													})
												}
												checked={this.state.isAgreed}
											/>
											<a className="checkmark"></a>
										</label>
										{this.state.status === 0 && (
											<div className="submit-buttons">
												<a
													href="#"
													onClick={e => this.btnSave(e, 0)}
													id="draftButton"
													className="btn btn-white">
													Taslak Olarak Kaydet
												</a>
												<a
													href="#"
													onClick={e => this.btnSave(e, 1)}
													id="submitButton"
													className="btn btn-primary">
													Onaya Gönder
												</a>
											</div>
										)}
									</div>
								</div>
							)}
						</div>
					</form>
				</div>
				<div className="view-article-area" style={{display: 'none'}}>
					<div className="view-article-area-close">
						<img
							src="/Content/yazigonder/img/close-article.png"
							alt="Yazı Gönder"
							className="close-article"
						/>
					</div>
					<div className="view-article-area-img">
						<img
							src="/Content/yazigonder/img/ornek-yazi.jpg"
							alt="Yazı Gönder"
							className="article-example"
						/>
					</div>
				</div>
			</Fragment>
		)
	}
}

interface ISendArticleRouteProp {
	guid?: string
}

interface ISendArticleProp extends RouteComponentProps<ISendArticleRouteProp> {
	isPreview: boolean
	loginStore?: ILoginStore
}

interface ISendArticleState {
	title: string
	subTitle: string
	content: string
	categoryId: number
	categories: ICategory[]
	isAgreed: boolean
	description: string
	pictures: []
	guid: string
	tags: string[]
	pictureIds: []
	docName: string
	status: Number
}

export default SendArticleComponent
