/* eslint-disable no-unused-vars */
import React from 'react'
import ServiceUser from '../services/ServiceUser'
import ServiceArticle from '../services/ServiceArticle'
import {RouteComponentProps, Link} from 'react-router-dom'
import Helmet from 'react-helmet'
import Seo from '../helper/seo'
import Const from '../const'
import Pagination from '../components/Pagination'
import Loading from '../elements/loading'

export default class SetTalksComponent extends React.Component<
	ISetTalksProp,
	ISetTalksState
> {
	pageSize = 10
	pageId = Number(this.getQueryVariable('sayfa')) || 1
	categoryId = 0
	categoryUrl = ''

	constructor(props: any) {
		super(props)
		this.state = {
			articles: [],
			articlesCount: 0,
			loaded: false
		}
	}

	componentDidMount() {
		this.getData(this.pageId)
	}
	componentWillReceiveProps(nextProps: ISetTalksProp) {
		if (this.pageId !== Number(this.getQueryVariable('sayfa'))) {
			this.pageId = Number(this.getQueryVariable('sayfa'))
			this.getData(this.pageId)
		}
	}

	getData(pageId: number = 1) {
		this.setState({loaded: false})
		this.pageId = Number(pageId) || 1

		ServiceArticle.getSetTalks(this.pageSize, this.pageId - 1).then(
			async res => {
				this.setState({
					loaded: true,
					articles: res.data.SetTalks,
					articlesCount: res.data.TotalCount
				})
			}
		)
	}

	getCoverStyle() {
		var obj: any = {}
		obj.background =
			'url("/content/img/categoryimage/soylesi.jpg") center center / cover'
		return obj
	}

	getQueryVariable(variable: string) {
		var query = window.location.search.substring(1)
		var vars = query.split('&')
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split('=')
			if (decodeURIComponent(pair[0]) == variable) {
				return decodeURIComponent(pair[1])
			}
		}
	}

	render() {
		const bgStyle = this.getCoverStyle()
		return (
			<div className="first-right-content">
				<Helmet>
					<title>{Seo.getTitle('SetTalks')}</title>
					<meta
						name="description"
						content="Dünyanın farklı yerlerini deneyimlemiş kişilerin tecrübelerini paylaştığı ve seyahat öncesi sizlere rehberlik ettiği söyleşi sayfamızı ziyaret edin!"
					/>
				</Helmet>
				<div className="main-slide category" style={bgStyle}>
					<div className="main-title-area-index">
						<div className="main-title-index">
							<h1 style={{fontSize: '55px'}}>
								SetTalks
							</h1>
							<p className="cat-subtitle">Bir de onlardan dinleyelim</p>
						</div>
					</div>
				</div>
				<div className="category-wide-grid">
					<div className="grid-area wide-grid">
						{!this.state.loaded && <Loading loaded={this.state.loaded} />}
						{this.state.articles &&
							this.state.articles.map(item => {
								return (
									<Link
										key={`category_${item.Id}`}
										to={`/set-talks/${item.SoylesiUrl}`}>
										<div className="grid grid-medium-ctg">
											<div
												className="grid-top grid-h-100">
												<div className="grid-top-info">
													<h4>{item.Baslik}</h4>
												</div>
												<div className="grid-grd"></div>
												<div
													className="grid-image"
													style={{
														background:
															`url(${Const.seturdayCdnBuckerImageUrl(item.SoylesiResim)}) center center`
													}}></div>
											</div>
											<div className="grid-bottom grid-none">
												<div className="grid-date ">
													<span>
														<img
															className="heart"
															src="Content\img\liked.png"
															width="11"
															alt={item.Baslik}
														/>
														<span className="heart-count">
															{item.TotalLikeCount} Beğenme
														</span>
													</span>
												</div>
											</div>
										</div>
									</Link>
								)
							})}
					</div>
				</div>
				<Pagination
					activePageId={this.pageId}
					perPageCount={10}
					totalCount={this.state.articlesCount}
					urlWithoutPageId="/set-talks"
				/>
			</div>
		)
	}
}

interface ISetTalksState {
	articles?: Array<ISetTalks>
	articlesCount?: number
	loaded?: boolean
}


interface ISetTalksRouteProp {
	id: any
	pageId: any
}

interface ISetTalksProp extends RouteComponentProps<ISetTalksRouteProp> {}
