import * as React from 'react'
import Const from '../const'
import ServiceUser from '../services/ServiceUser'
import ChatMessages from './ChatMessages'
interface IProps {
	userInfo: IUserConversations
	socketMessages: IUserConversationHistory[]
	resetUserInfo?: Function
	loginStore: ILoginStore
}

const ChatBox: React.FC<IProps> = ({
	userInfo,
	resetUserInfo,
	loginStore,
	socketMessages
}) => {
	let [isActive, setActive] = React.useState<Boolean>(false)
	let [messages, setMessages] = React.useState<IUserConversationHistory[]>([])
	let [text, setText] = React.useState<string>('')
	React.useEffect(() => {
		$('.chat-logs').scrollTop($('.chat-logs')[0].scrollHeight)
		if (socketMessages.length === 0) {
			loginStore.setUnseenCount()
		}
		return () => {
			loginStore.setUnseenCount()
		}
	})

	React.useEffect(() => {
		setMessages([])
		if (userInfo.Name) {
			setActive(true)
			if (socketMessages.length === 0) {
				getChatHistory(userInfo.RecieverId, 0, 100)
			}
		} else {
			setActive(false)
		}
	}, [userInfo, socketMessages])

	const handleClose = () => {
		resetUserInfo()
	}

	const getChatHistory = async (
		receiverId: Number,
		pageId: Number,
		pageSize: Number
	) => {
		const res = await ServiceUser.getConversationDetails(
			receiverId,
			pageId,
			pageSize
		)
		setMessages(res.data.ChatDetails.reverse())
		$('.chat-logs').scrollTop($('.chat-logs')[0].scrollHeight)
	}

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
		e.preventDefault()
		if (text !== '') {
			try {
				loginStore.connection.invoke('SendMessage', userInfo.RecieverId, text)
				setText('')
			} catch (error) {
				console.log(error);
			}
		}
	}

	return (
		<div>
			<div className="chat-area">
				<div id="body">
					<div className={`chat-box ${isActive ? 'active' : ''}`}>
						<div className="chat-box-header">
							<div className="chat-profile-img">
								<img src={Const.seturdayCdnAuthorUrl + userInfo.PictureUrl} />
							</div>
							<div className="chat-header-name">
								<p>
									{userInfo.Name} {userInfo.Surname}
								</p>
							</div>
							<span className="chat-box-toggle" onClick={handleClose}>
								<i className="material-icons">close</i>
							</span>
						</div>
						<div className="chat-box-body">
							<div className="chat-box-overlay"></div>
							<div className="chat-logs">
								{messages.map((message: IUserConversationHistory) => {
									return (
										<ChatMessages
											key={message.Id}
											user={loginStore.user}
											userPhoto={
												Const.seturdayCdnAuthorUrl + userInfo.PictureUrl
											}
											message={message}
										/>
									)
								})}
								{socketMessages.map((message: IUserConversationHistory) => {
									return (
										(userInfo.RecieverId === message.GonderenId ||
											userInfo.RecieverId === message.AliciId) && (
											<ChatMessages
												key={message.Id}
												user={loginStore.user}
												userPhoto={
													Const.seturdayCdnAuthorUrl + userInfo.PictureUrl
												}
												message={message}
											/>
										)
									)
								})}
							</div>
						</div>
						<div className="chat-input">
							<form onSubmit={handleSubmit}>
								<input
									type="text"
									id="chat-input"
									value={text}
									autoComplete="off"
									onChange={event => setText(event.target.value)}
									placeholder="Mesaj yazın..."
								/>
								<button type="submit" className="chat-submit" id="chat-submit">
									<i className="material-icons">send</i>
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ChatBox
