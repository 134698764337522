import axios, {AxiosRequestConfig} from 'axios'
import Const from '../const'

export default class HttpClient {


	static get<TResponse>(url: string, config?: AxiosRequestConfig) {
		return axios.get<TResponse>(`${Const.baseApiUrl}${url}`, this.getHeaders())
	}

	static delete<TResponse>(url: string, data?: any) {
		return axios.delete<TResponse>(
			`${Const.baseApiUrl}${url}`,
			this.getHeaders(null, data)
		)
	}

	static post<TResponse>(
		url: string,
		data: Object,
		config?: AxiosRequestConfig
	) {
		return axios.post<TResponse>(
			`${Const.baseApiUrl}${url}`,
			data,
			this.getHeaders(config)
		)
	}

	static put<TResponse>(
		url: string,
		data?: Object,
		config?: AxiosRequestConfig
	) {
		return axios.put<TResponse>(
			`${Const.baseApiUrl}${url}`,
			data,
			this.getHeaders(config)
		)
	}

	private static getHeaders(config?: AxiosRequestConfig, data?: any) {
		let opt: AxiosRequestConfig = (config as AxiosRequestConfig) || {
			headers: {'Content-Type': 'application/json', 'Seturday-Build': 'web' },
			data
		}
		const userTokenStr = localStorage.getItem('userToken')
		if (userTokenStr) {
			const userToken = JSON.parse(
				localStorage.getItem('userToken') as string
			) as IAccessToken
			opt.headers['Authorization'] = `Bearer ${userToken.AccessToken}`
		}
		return opt
	}
}
