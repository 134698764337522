import * as React from 'react'
import {RouteComponentProps, withRouter} from 'react-router'
import {Helmet} from 'react-helmet'

class ScrollToTop extends React.Component<RouteComponentProps<any>> {
	componentDidUpdate(prevProps: Readonly<RouteComponentProps<any>>) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0)
			$('.menu--salal .menu__item').removeClass('active')
			$('.menu--salal .menu__item').each(function() {
				location.pathname.startsWith($(this).attr('href')) &&
					$(this).addClass('active')
			})
			$('.right-menu li').removeClass('active')
			$('.right-menu li').each(function() {
				location.pathname.startsWith(
					$(this)
						.find('a')
						.attr('href')
				) && $(this).addClass('active')
			})

			const a = $(window).width()
			const n = $('.main-slide.category .main-title-index h1')
			let fontSizeTitle = 55
			n.css('font-size', fontSizeTitle)
			a <= 1024 ? (fontSizeTitle = 32, n.css({
				'font-size': fontSizeTitle
			})) : (fontSizeTitle = 55, n.css({
				'font-size': fontSizeTitle
			}))
			$(window).on('scroll', function () {
				var t = $(window).scrollTop(),
					e = t / 1e3 + 1,
					f = -t + 450,
					i = $('.main-slide.category'),
					r = $('.main-slide.category .main-title-index p.cat-subtitle'),
					o = .5 + t / 50,
					u = fontSizeTitle - t / 13
				i.css({
					height: f
				})
				n.css({
					'font-size': u
				})
				t >= 250 ? r.fadeOut() : r.fadeIn()
				u <= 25 && n.css({
					'font-size': '25px'
				})
				t >= 400 ? i.addClass('scrolled') : i.removeClass('scrolled')
				i.height() <= 60 && i.css({
					height: '60'
				})
			}).scroll()
		}
	}

	render() {
		return (
			<Helmet>
				<link rel="canonical" href={window.location.href} />
				<meta
					property="og:url"
					content={window.location.href}
				/>	
			</Helmet>
		)
	}
}
export default withRouter(ScrollToTop)
