import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Loading from '../elements/loading'
import ServiceCategory from '../services/ServiceCategory'
import sortBy from 'lodash/sortBy'

class SidenavMenu extends React.Component<any, { menus?: ICategory[], loaded?: boolean }> {
	constructor(props: any) {
		super(props)

		this.state = {
			menus: [],
			loaded: false
		}

		ServiceCategory.categories().then(res => {
			this.setState({
				menus: sortBy(res.data.filter(c => ![9, 10, 11, 13].includes(c.Id as number)), i => i.MenuOrder),
				loaded: true
			})
		})
	}

	render() {
		return <div className="side-menu-area">
			<div className="side-menu-in">
				<ul>
					<section className="content">
						{
							this.state.loaded ? this.getMenu() : <Loading loaded={this.state.loaded}></Loading>
						}
					</section>
					{ /* EndDonut */}
				</ul>
			</div>
		</div>
	}

	handleClick() {
		$('#nav-icon1').trigger('click');
	}

	getMenu() {
		return <nav className="menu menu--salal">
			<div className="menu-bottom-wrap">
				<Link onClick={this.handleClick} to="/arama?q=dijitalrotalar" className="menu__item mobile-menu-bottom">
					<span className="menu__item-name">#dijitalrotalar</span>
				</Link>
			</div>
			{
				this.state.menus && this.state.menus.map((item: any) => {
					return <Link key={`MenuItem_${item.Id}`} onClick={this.handleClick} to={`/${encodeURI(item.Kategoriurl)}`} className="menu__item">
						<span className="menu__item-name">{item.KategoriAdi}</span>
						<span className="menu__item-label">{item.SubTitle}</span>
					</Link>
				})
			}
			<div className="menu-bottom-wrap">
				<Link onClick={this.handleClick} to="/yazarlar" className="menu__item mobile-menu-bottom">
					<span className="menu__item-name">Yazarlar</span>
				</Link>
				<Link onClick={this.handleClick} to="/cok-okunanlar" className="menu__item mobile-menu-bottom">
					<span className="menu__item-name">Çok Okunanlar</span>
				</Link>
				<a className="menu__item sedvice-button mobile-menu-bottom" href="javascript:;">
					<span className="menu__item-name">Bir Bilene Sor</span>
				</a>
				<a className="menu__item bucket-button mobile-menu-bottom" href="javascript:;">
					<span className="menu__item-name">Bucket List</span>
				</a>
			</div>
		</nav>
	}
}

export default withRouter(SidenavMenu as any)