import { observable, action } from 'mobx'
import ServiceUser from '../services/ServiceUser'

class _VisitedPlaceStore implements IVisitedPlaceStore {
	@observable visitedPlaces: IVisitedPlace[] = []

	@action
	async add(place: IVisitedPlace) {
		if (this.visitedPlaces.filter(item => item.GooglePlaceId === place.GooglePlaceId).length == 0) {
			let res = await ServiceUser.addVisited([place])
			this.visitedPlaces = res.data
			return res.status === 200
		}
		return false
	}

	@action
	async remove(place: IVisitedPlace) {
		const res = await ServiceUser.removeVisited(place.Id)
		if (res.status === 200) {
			this.visitedPlaces.splice(this.visitedPlaces.indexOf(place), 1)
			return true
		}
		return false
	}

	@action 
	async getVisitedPlace() {
		const res = await ServiceUser.getVisitedPlaces()
		this.visitedPlaces = res.data	
	}
}

let VisitedPlaceStore = new _VisitedPlaceStore()
export default VisitedPlaceStore