import React from 'react'
import {Link} from 'react-router-dom'

export default class Page404Component extends React.Component<
	IPage404Prop,
	IPage404State
> {
	render() {
		return (
			<div className="full-w contract-page page-404">
				<div className="wrap-in">
					<div className="empty-area clearfix">
						<div className="empty-title">
							<p className="text-404">404</p>
							<p>
								Ulaşmaya çalıştığınız sayfa bulunamadı. Lütfen adres çubuğuna
								girdiğiniz adresi kontrol edin.
							</p>
						</div>
						<div className="empty-button btn-404">
							<a href="/">İçerikleri Keşfet</a>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

interface IPage404Prop {}

interface IPage404State {}
