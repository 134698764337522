let counter = 0
let scriptMap = new Map()

export const ScriptCache = (function(global: any) {
    return function ScriptCache <T>(scripts: T): {
        [P in keyof T]: { onLoad: Function }
    } {
        const Cache: any = {
            _onLoad: null,
            _scriptTag: null
        }

        Cache._onLoad = function(key: any) {
            return (cb: any) => {
                let stored = scriptMap.get(key)
                if (stored) {
                    stored.promise.then(() => {
                        stored.error ? cb(stored.error) : cb(null, stored)
                    })
                } else {
                    console.warn('No script cached')
                }
            }
        }

        Cache._scriptTag = (key: any, src: any) => {
            if (!scriptMap.has(key)) {
                let tag = document.createElement('script') as any
                let promise = new Promise((resolve, reject) => {
                    let resolved = false,
                        errored = false,
                        body = document.getElementsByTagName('body')[0]

                    tag.type = 'text/javascript'
                    tag.async = true
                    tag.defer = true

                    const cbName = `loaderCB${counter++}${Date.now()}` as any
                    let cb

                    const handleResult = (state: any) => {
                        return (evt: any) => {
                            let stored = scriptMap.get(key)

                            if (state === 'loaded') {
                                stored.resolved = true
                                resolve(src)
                            } else if (state === 'error') {
                                stored.errored = true
                                reject(evt)
                            }

                            cleanup()
                        }
                    }

                    const cleanup = () => {
                        if (global[cbName] && typeof global[cbName] === 'function') {
                            global[cbName] = null
                        }
                    }
                    tag.onload = handleResult('loaded')
                    tag.onerror = handleResult('error') as any
                    (tag as any)['onreadystatechange'] = () => {
                        handleResult((tag as any).readyState)
                    }

                    if (src.match(/callback=CALLBACK_NAME/)) {
                        src = src.replace(/(callback=)[^\&]+/, `$1${cbName}`)
                        cb = window[cbName] = tag.onload
                    } else {
                        tag.addEventListener('load', tag.onload)
                    }

                    tag.addEventListener('error', tag.onerror)

                    tag.src = src
                    body.appendChild(tag)

                    return tag
                })

                let initialState = {
                    loaded: false,
                    error: false,
                    promise,
                    tag
                }

                scriptMap.set(key, initialState)
            }

            return scriptMap.get(key)
        }

        Object.keys((scripts as any)).forEach(function(key: any) {
            const script = (scripts as any)[key]
            Cache[key] = {
                tag: Cache._scriptTag(key, script),
                onLoad: Cache._onLoad(key)
            }
        })

        return Cache
    }
})(window)

export default ScriptCache