import React, {Component} from 'react'
import Seo from '../../helper/seo'
import Helmet from 'react-helmet'
import {Link} from 'react-router-dom'

export default class SaveDraftComponent extends Component<
	ISaveDraftProp,
	ISaveDraftState
> {
	render() {
		return (
			<div className="first-right-content">
				<Helmet>
					<title>{Seo.getTitle('Yazı taslak olarak kaydedildi')}</title>
				</Helmet>
				<div
					className="main-slide category"
					style={{
						background: 'url(\'/Content/img/categoryimage/yazar-ol-bg.png\') center center / cover'
					}}>
					<div className="main-title-area-index">
						<div className="main-title-index">
							<h1>Yazar Ol</h1>
						</div>
					</div>
				</div>

				<div className="category-wide-grid">
					<div className="grid-area wide-grid">
						<div className="full-w">
							<div className="wrap-in clearfix">
								<div className="empty-area clearfix">
									<div className="empty-img clearfix">
										<img src="/Content/img/article-tick-icon.png" />
									</div>
									<div className="empty-title">
										<p>
                                            Taslak olarak kaydedildi.
										</p>
									</div>
									<div className="empty-button">
										<Link to={'/hesabim/yazilarim'}>Yazılarıma Git</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

interface ISaveDraftState {}

interface ISaveDraftProp {
	location: any
}
