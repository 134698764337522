import React from 'react'

import {KVKK_TEXT} from '../kvkk-static'

const KvkkPage = () => {
	return (
		<div className="first-right-content">
			<div
				className="main-slide category"
				style={{
					background: 'url(Content/img/kvkk-background.png) center center',

					backgroundSize: 'cover'
				}}>
				<div className="main-title-area-index">
					<div className="main-title-index">
						<h1>Kişisel Verilerin Korunması</h1>
					</div>
				</div>
			</div>

			<div className="writers-wide-grid">
				<div className="sedvice-wrap light-white">
					<div className="sedvice-wrap-in writer-block-area clearfix">
						<div className="frame-4">
							<a
								className="static-description"
								target="_blank"
								href="https://kvkk.setur.com.tr/public/document/13/15/pdf/true">
								SETUR SERVİS TURİSTİK ANONİM ŞİRKETİ KİŞİSEL VERİLERİN KORUNMASI
								VE İŞLENMESİ POLİTİKASI
							</a>

							<a
								className="static-description"
								target="_blank"
								href="https://kvkk.setur.com.tr/public/document/13/17/pdf/true">
								SETUR SERVİS TURİSTİK A.Ş. İLGİLİ KİŞİ BAŞVURU FORMU
							</a>

							<a
								className="static-description"
								target="_blank"
								href="https://www.seturday.com/cerez-politikasi">
								KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİN ÇEREZ AYDINLATMA METNİ
							</a>
						</div>

						<div className="separator-sm" />

						<div
							className="static-description"
							dangerouslySetInnerHTML={{__html: KVKK_TEXT}}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default KvkkPage
