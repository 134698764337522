import React, {Component} from 'react'

export default class Layout2Field2Component extends Component<
	ILayout3Prop,
	ILayout3State
> {
	constructor(props: ILayout3Prop) {
		super(props)
	}

	render() {
		return (
			<React.Fragment>
				{this.props.articleLayout.layout2 && (
					<div className="full-w clearfix tilt-bg">
						<div className="layout-wrap-in">
							<div className="tilt-area clearfix">
								<div className="tilt-top clearfix">
									<h6>{this.props.articleLayout.layout2.AlanikiBaslik}</h6>
									<p
										dangerouslySetInnerHTML={{
											__html: this.props.articleLayout.layout2.AlanikiOzet
										}}></p>
								</div>
								<div className="tilt-block-area">
									<div className="tilt-block-wrap">
										<div
											className="tilt-block wow fadeInUp"
											style={{
												background: `url(https://cdn.seturday.com/content/uploads/makale_resimleri/layiki/${this.props.articleLayout.layout2.AlanikiResim1}) center center no-repeat`
											}}>
											<div className="tilt-block-in">
												<div className="tilt-block-text">
													<h4>
														{
															this.props.articleLayout.layout2
																.AlanikiResim1Baslik
														}
													</h4>
													<p
														dangerouslySetInnerHTML={{
															__html: this.props.articleLayout.layout2
																.AlanikiResim1Ozet
														}}></p>
												</div>
											</div>
											<div className="tilt-overlay"></div>
										</div>
									</div>
									<div className="tilt-block-wrap">
										<div
											className="tilt-block wow fadeInUp"
											style={{
												background: `url(https://cdn.seturday.com/content/uploads/makale_resimleri/layiki/${this.props.articleLayout.layout2.AlanikiResim2}) center center no-repeat`
											}}>
											<div className="tilt-block-in">
												<div className="tilt-block-text">
													<h4>
														{
															this.props.articleLayout.layout2
																.AlanikiResim2Baslik
														}
													</h4>
													<p
														dangerouslySetInnerHTML={{
															__html: this.props.articleLayout.layout2
																.AlanikiResim2Ozet
														}}></p>
												</div>
											</div>
											<div className="tilt-overlay"></div>
										</div>
									</div>
									<div className="tilt-block-wrap">
										<div
											className="tilt-block wow fadeInUp"
											style={{
												background: `url(https://cdn.seturday.com/content/uploads/makale_resimleri/layiki/${this.props.articleLayout.layout2.AlanikiResim3}) center center no-repeat`
											}}>
											<div className="tilt-block-in">
												<div className="tilt-block-text">
													<h4>
														{
															this.props.articleLayout.layout2
																.AlanikiResim3Baslik
														}
													</h4>
													<p
														dangerouslySetInnerHTML={{
															__html: this.props.articleLayout.layout2
																.AlanikiResim3Ozet
														}}></p>
												</div>
											</div>
											<div className="tilt-overlay"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</React.Fragment>
		)
	}

	initializeSlider() {
		var twoSlide = new Swiper('.two-slide', {
			spaceBetween: 30,
			effect: 'fade',
			pagination: {
				el: '.swiper-pagination',
				clickable: !0
			},
			navigation: {
				nextEl: '.two-slide-next',
				prevEl: '.two-slide-prev'
			}
		})
	}
}

interface ILayout3Prop {
	articleLayout: IArticleLayout
}

interface ILayout3State {}
