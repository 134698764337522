import HttpClient from '../helper/http'

export default class ServiceComment {
	static add(item: IAddComment, isSetTalks: boolean = false) {
		const url = `/api/${isSetTalks ? 'SetTalks/AddSetTalkComment' : 'Comment/add'}`
		return HttpClient.post<IAddComment>(url, item)
	}

	static getByArticleId(
		articleId: number,
		pageId: number,
		pageSize: number,
		isSetTalks: boolean = false
	) {
		const url = `/api/${
			isSetTalks ? 'SetTalks/getSetTalkComments?=id' : 'Comment/getByArticleId'
		}?url=${articleId}&pageId=${pageId}&pageSize=${pageSize}`
		return HttpClient.get<ICommentArticleResponseModel>(url)
	}
	static getByArticleUrl(
		articleUrl: string,
		pageId: number,
		pageSize: number,
		isSetTalks: boolean = false
	) {
		const url = `/api/${
			isSetTalks ? 'SetTalks/getSetTalkComments' : 'Comment/getByArticleUrl'
		}?url=${articleUrl}&pageId=${pageId}&pageSize=${pageSize}`
		return HttpClient.get<ICommentArticleResponseModel>(url)
	}
}
