import HttpClient from '../helper/http'

export default class ServiceArticle {
	static byId(articleId: number) {
		return HttpClient.get<IArticle>(`/api/article/byId?id=${articleId}`)
	}

	static byUrl(articleUrl: string) {
		return HttpClient.get<IArticle>(`/api/article/byUrl?url=${articleUrl}`)
	}

	static byInactiveUrl(articleUrl: string) {
		return HttpClient.get<IArticle>(`/api/article/byInactiveUrl?url=${articleUrl}`)
	}

	static getArticlesByCategoryId(
		categoryId: number,
		pageSize: number,
		pageId: number
	) {
		return HttpClient.get<IResultArticlePagination>(
			`/api/article/getArticlesByCategoryId?categoryId=${categoryId}&pageSize=${pageSize}&pageId=${pageId}`
		)
	}

	static getRelatedArticles(
		articleId: number,
	) {
		return HttpClient.get<IArticle>(
			`/api/article/getBottomArticles?articleId=${articleId}`
		)
	}

	static getRelatedInactiveArticles(
		articleId: number
	) {
		return HttpClient.get<IArticle>(
			`/api/article/getBottomInactiveArticles?articleId=${articleId}`
		)
	}

	static getArticlesByCategoryName(
		categoryName: string,
		pageSize: number,
		pageId: number
	) {
		return HttpClient.get<IResultArticlePagination>(
			`/api/article/getArticlesByCategoryName?categoryName=${categoryName}&pageSize=${pageSize}&pageId=${pageId}`
		)
	}
	
	static getAuthorsArticles(
		authorId: number,
		pageSize: number,
		pageId: number
	) {
		return HttpClient.get<IResultArticlePagination>(
			`/api/article/getAuthorsArticles?authorId=${authorId}&pageSize=${pageSize}&pageId=${pageId}`
		)
	}

	static readArticles() {
		return HttpClient.get<IArticle[]>('/api/article/readArticles?size=20')
	}

	static homeArticles(size: number) {
		return HttpClient.get<IArticle[]>(`/api/article/homeArticles?size=${size}`)
	}

	static getLayoutByArticleById(articleId: number) {
		return HttpClient.get<IArticleLayout>(
			`/api/article/getLayoutByArticleById?id=${articleId}`
		)
	}

	static getLayoutByArticleByName(articleUrl: string) {
		return HttpClient.get<IArticleLayout>(
			`/api/article/getLayoutByArticleByUrl?url=${articleUrl}`
		)
	}

	static getLayoutByInactiveArticleByName(articleUrl: string) {
		return HttpClient.get<IArticleLayout>(
			`/api/article/getLayoutByInactiveArticleByUrl?url=${articleUrl}`
		)
	}

	static search(keyword: string) {
		return HttpClient.get<IArticle[]>(
			`/api/article/search?keyword=${keyword}&pageSize=100&pageId=0`
		)
	}

	static removeUserArticle(articleId: number) {
		return HttpClient.delete<boolean>(
			`/api/Article/removeUserArticle?articleId=${articleId}`
		)
	}

	static likeAnArticle(articleId: number, isSetTalks:boolean = false) {
		return HttpClient.get<number>(
			`/api/article/likeAnArticle?articleId=${articleId}&articleType=${isSetTalks ? 1:0}`
		)
	}

	static favorites(pageSize: number, pageId: number) {
		return HttpClient.get<IResultArticlePagination>(
			`/api/article/favorites?pageSize=${pageSize}&pageId=${pageId}`
		)
	}

	static getUsersArticle(pageSize: number, pageId: number) {
		return HttpClient.get<IUserArticle[]>(
			`/api/article/getUsersArticle?pageSize=${pageSize}&pageId=${pageId}`
		)
	}

	static getUserDetailedArticle(guid: string) {
		return HttpClient.get<IGetUserDetailedArticle>(
			`/api/article/getUserDetailedArticle?guid=${guid}`
		)
	}

	static updateUserArticle(item: IUpdateUserArticle) {
		var formData = new FormData()

		const keys = Object.keys(item)

		for (const key of keys) {
			if (key === 'pictures') {
				const pictures = (item as any)[key] as any[]

				if (pictures) {
					for (const picture of pictures) {
						formData.append(key, picture)
					}
				}
			} else {
				formData.append(key, (item as any)[key])
			}
		}

		return HttpClient.post<IUserArticleByMediaRequest>(
			'/api/article/updateUserArticle',
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
					Accept: "application/json"
				}
			}
		)
	}

	static addUserArticle(item: IUserArticleByMediaRequest) {
		var formData = new FormData()

		const keys = Object.keys(item)

		for (const key of keys) {
			if (key === 'pictures') {
				const pictures = (item as any)[key] as any[]

				if (pictures) {
					for (const picture of pictures) {
						formData.append(key, picture)
					}
				}
			} else {
				formData.append(key, (item as any)[key])
			}
		}

		return HttpClient.post<IUserArticleByMediaRequest>(
			'/api/article/addUserArticle',
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		)
	}

	
	static getSetTalks(
		pageSize: number,
		pageId: number
	) {
		return HttpClient.get<IResultSetTalksPagination>(
			`/api/SetTalks/getSetTalks?pageSize=${pageSize}&pageId=${pageId}`
		)
	}

	static getSetTalksArticle(url: string) {
		return HttpClient.get<IArticleLayout>(
			`/api/SetTalks/getSetTalkDetailByUrl?url=${url}`
		)
	}
}
