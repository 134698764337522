import React, {Component} from 'react'

export default class LayoutField5Component extends Component<
	ILayout3Prop,
	ILayout3State
> {
	constructor(props: ILayout3Prop) {
		super(props)
	}

	render() {
		return (
			<React.Fragment>
				{this.props.articleLayout.layout3 &&
					(this.props.articleLayout.layout3.AlanbesBaslik ||
						this.props.articleLayout.layout3.AlanbesAciklama) && (
					<div className="comment-top-text clearfix align-center">
						<h3
							className="comment-tittle-top"
							dangerouslySetInnerHTML={{
								__html: this.props.articleLayout.layout3.AlanbesBaslik
							}}></h3>
						{this.props.articleLayout.layout3.AlanbesOzet && (
							<p
								className="comment-p-middle"
								dangerouslySetInnerHTML={{
									__html: this.props.articleLayout.layout3.AlanbesOzet
								}}></p>
						)}
						{this.props.articleLayout.layout3.AlanbesAciklama && (
							<p
								className="comment-p-bottom"
								dangerouslySetInnerHTML={{
									__html: this.props.articleLayout.layout3.AlanbesAciklama
								}}></p>
						)}
					</div>
				)}
				{this.props.articleLayout.layout2 &&
					(this.props.articleLayout.layout2.AlanDortBaslik ||
						this.props.articleLayout.layout2.AlanDortAciklama) && (
					<div className="comment-top-text clearfix align-center">
						<h3
							className="comment-tittle-top"
							dangerouslySetInnerHTML={{
								__html: this.props.articleLayout.layout2.AlanDortBaslik
							}}></h3>
						{this.props.articleLayout.layout2.AlanDortOzet && (
							<p
								className="comment-p-middle"
								dangerouslySetInnerHTML={{
									__html: this.props.articleLayout.layout2.AlanDortOzet
								}}></p>
						)}

						{this.props.articleLayout.layout2.AlanDortAciklama && (
							<p
								className="comment-p-bottom"
								dangerouslySetInnerHTML={{
									__html: this.props.articleLayout.layout2.AlanDortAciklama
								}}></p>
						)}
					</div>
				)}
				{this.props.articleLayout.layout1 &&
					(this.props.articleLayout.layout1.Alan6baslik ||
						this.props.articleLayout.layout1.Alan6aciklama) && (
					<div className="comment-top-text clearfix align-center">
						{this.props.articleLayout.layout1.Alan6baslik && (
							<h3
								className="comment-tittle-top"
								dangerouslySetInnerHTML={{
									__html: this.props.articleLayout.layout1.Alan6baslik
								}}></h3>
						)}
						{this.props.articleLayout.layout1.Alan6ozet && (
							<p
								className="comment-p-bottom"
								dangerouslySetInnerHTML={{
									__html: this.props.articleLayout.layout1.Alan6ozet
								}}></p>
						)}
						{this.props.articleLayout.layout1.Alan6aciklama && (
							<p
								className="comment-p-bottom"
								dangerouslySetInnerHTML={{
									__html: this.props.articleLayout.layout1.Alan6aciklama
								}}></p>
						)}
					</div>
				)}
			</React.Fragment>
		)
	}
}

interface ILayout3Prop {
	articleLayout: IArticleLayout
}

interface ILayout3State {}
