import React from 'react'
import ServiceArticle from '../services/ServiceArticle'
import {RouteComponentProps, Link} from 'react-router-dom'
import ServiceCategory from '../services/ServiceCategory'
import Seo from '../helper/seo'
import Helmet from 'react-helmet'

export default class SearchComponent extends React.Component<
	ISearchProp,
	ISearchState
> {
	lastKeyword: string = ''

	constructor(props: ISearchProp) {
		super(props)
		this.state = {
			articles: [],
			categories: [],
			searched: false
		}

		ServiceCategory.categories().then(res => {
			this.setState({
				categories: res.data
			})
		})
	}

	componentWillMount() {
		if (!this.getQueryVariable('q')) {
			this.props.history.push('/');
		}
	}
	getArticles(keyword: string) {
		ServiceArticle.search(encodeURIComponent(keyword)).then((res: any) => {
			this.setState({
				articles: res.data.articleList,
				searched: true
			})
			$('div.overlay.sedvice-overlay').click()
		})
	}

	componentDidUpdate() {
		const keyword = this.getQueryVariable('q')
		if (keyword != this.lastKeyword) {
			this.getArticles(keyword)
			this.lastKeyword = keyword
		}
	}

	componentDidMount() {
		const keyword = this.getQueryVariable('q')
		this.getArticles(keyword)
		this.lastKeyword = keyword
	}

	getCategory(categoryId: number): ICategory {
		if (this.state.categories && this.state.categories.length > 0) {
			return this.state.categories.filter(c => c.Id == categoryId)[0]
		}
		return {} as ICategory
	}

	getQueryVariable(variable: string) {
		var query = window.location.search.substring(1)
		var vars = query.split('&')
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split('=')
			if (decodeURIComponent(pair[0]) == variable) {
				return decodeURIComponent(pair[1])
			}
		}
	}

	render() {
		return (
			<div className="first-right-content">
				<Helmet>
					<title>
						{Seo.getTitle(
							`${this.lastKeyword} için arama sonuçları`
						)}
					</title>
				</Helmet>
				<div className="search-wrap clearfix">
					<div className="search-wrap-in">
						<div className="search-title-area">
							<h1>{this.lastKeyword}</h1>
						</div>
						<div className="seach-block-area">
							<div className="grid-area wide-grid">
								{(!this.lastKeyword ||
									(this.state.articles.length === 0 &&
										this.state.searched)) && (
									<div className="search-title-area">
										<div className="empty-area clearfix">
											<div className="empty-img clearfix">
												<img src="/Content/img/search-no-result.png" />
											</div>
											<div className="empty-title">
												<p>Aradığınız İçerik Bulunamadı</p>
											</div>
											<div className="empty-button">
												<a
													href="#"
													onClick={e => e.preventDefault()}
													className="search-a">
													Yeniden Ara
												</a>
											</div>
										</div>
									</div>
								)}
								{this.state.articles &&
									this.state.articles.map((item: IArticle) => {
										var showAuthorInfos =
											item.AuthorFullName && item.AuthorFullName.trim()
										return (
											<Link
												key={`article_${item.Id}`}
												to={`/${
													this.getCategory(item.MakaleKategoriId).Kategoriurl
												}/${item.Url}`}>
												<div className="grid grid-33">
													<div
														className={
															'grid-top ' +
															(!showAuthorInfos ? 'grid-h-100' : '')
														}>
														<div className="grid-top-info">
															<h4>{item.MakaleAdi}</h4>
															<p>{item.MakaleOzeti.substring(0, 83) + '...'}</p>
														</div>{' '}
														<div className="grid-grd"></div>
														<div className="grid-date">
															<span>
																<img
																	className="heart"
																	src="Content\img\liked.png"
																	width="11"
																	alt={item.MakaleAdi}
																	title={item.MakaleAdi}
																/>
																<span className="heart-count">
																	{item.MakaleBeğeni} Beğenme
																</span>
															</span>
														</div>
														<div
															className="grid-image"
															style={{
																background:
																	'url(https://cdn.seturday.com/content/uploads/makale_resimleri/kapak/' +
																	item.MakaleBuyukResim +
																	') center center'
															}}></div>
													</div>
													{showAuthorInfos && (
														<div className="grid-bottom">
															<div className="grid-profile-img">
																<img
																	src={item.Resim}
																	alt={item.MakaleAdi}
																	title={item.MakaleAdi}
																/>
															</div>
															<div className="grid-bottom-info">
																<h6>{item.AuthorFullName} </h6>
																<p>{item.AuthorDetail && item.AuthorDetail.trim().substring(0, 83) + '...'}</p>
															</div>
														</div>
													)}
												</div>
											</Link>
										)
									})}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

interface ISearchRouteProp {
	keyword: string
}

interface ISearchProp extends RouteComponentProps<ISearchRouteProp> {}

interface ISearchState {
	articles: IArticle[]
	categories: ICategory[]
	searched: boolean
}
