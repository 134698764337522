import React, { Component } from 'react'
import Const from '../../const'

export default class Layout3Field2Component extends Component<ILayout3Prop, ILayout3State> {
    constructor(props: ILayout3Prop) {
        super(props)
    }
    
    render() {
        return <React.Fragment>
            {
                this.props.articleLayout.layout3 &&
                this.props.articleLayout.layout3.AlanikiResimUrl &&
                <div className="layout-3-parallax papa" style={{ backgroundImage: `url(${Const.seturdayCdnArticleLayout3Url}${this.props.articleLayout.layout3.AlanikiResimUrl})`, backgroundSize: 'cover' }}>
                    <p dangerouslySetInnerHTML={{ __html: this.props.articleLayout.layout3.AlanikiResimAciklama}}>
                    </p>
                    <div className="gradient-bg">
                    </div>
                </div>
            }
        </React.Fragment>
    }
}

interface ILayout3Prop {
    articleLayout: IArticleLayout
}

interface ILayout3State {

}