import React from 'react'
import './ArticleComponent.css'
import {RouteComponentProps} from 'react-router'
import ServiceArticle from '../../services/ServiceArticle'
import ServiceCategory from '../../services/ServiceCategory'
import ServiceUser from '../../services/ServiceUser'
import Const from '../../const'
import {Link} from 'react-router-dom'
import LayoutField5Component from './LayoutField5Component'
import Layout3Field4Component from './Layout3Field4Component'
import Layout3Field1Component from './Layout3Field1Component'
import Layout3Field2Component from './Layout3Field2Component'
import Layout3Field3Component from './Layout3Field3Component'
import Layout3SliderComponent from './Layout3SliderComponent'
import Layout2Field1Component from './Layout2Field1Component'
import Layout2Field2Component from './Layout2Field2Component'
import Layout2Field3Component from './Layout2Field3Component'
import Layout2SliderComponent from './Layout2SliderComponent'
import Layout1Field1Component from './Layout1Field1Component'
import Layout1Field2Component from './Layout1Field2Component'
import Layout1Field3Component from './Layout1Field3Component'
import Layout1Field4Component from './Layout1Field4Component'
import Layout1Field5Component from './Layout1Field5Component'
import Layout1SliderComponent from './Layout1SliderComponent'
import Helmet from 'react-helmet'
import {times} from 'lodash'
import Seo from '../../helper/seo'
import {inject, observer} from 'mobx-react'
import ServiceComment from '../../services/ServiceComment'
import RequiredLoginComponent from '../../components/RequiredLogin'
import CommentsComponent from './CommentsComponent'

@inject('loginStore')
@observer
export default class ArticleComponent extends React.Component<
	IArticleProp,
	IArticleState
> {
	articleUrl: string = ''

	constructor(props: any) {
		super(props)

		this.reset()
		this.getData()
		this.btnComment = this.btnComment.bind(this)
	}

	getData(articleUrl?: string) {
		this.articleUrl = articleUrl || this.props.match.params.articleUrl

		if (!this.articleUrl) return

		if(process.env.NODE_ENV === 'production' && window.location.origin == 'https://www.seturday.com'){
			ServiceArticle.byUrl(this.articleUrl).then(resArt => {
				const tags:any = resArt.data.Metatag.split(',');
				this.setState({
					article: resArt.data,
					author: {
						Id: resArt.data.Yazarid,
						Name: resArt.data.Name,
						Surname: resArt.data.Surname,
						Resim: resArt.data.Resim,
						Profilgoster: resArt.data.Profilgoster
					},
					tags,
				})
				if (resArt.status === 200) {
					ServiceArticle.getRelatedArticles(resArt.data.Id).then(
						(resCat: any) => {
							this.setState({
								relatedArticles: resCat.data
							})
						}
					)

					ServiceCategory.categories().then(resCat => {
						this.setState({
							categories: resCat.data,
							category: resCat.data.filter(
								c => c.Id == resArt.data.MakaleKategoriId
							)[0]
						})
					})

					/*ServiceUser.byId(resArt.data.Yazarid).then(resAuth => {
						this.setState({
							author: resAuth.data
						})
					})*/
				}
			})
			ServiceArticle.getLayoutByArticleByName(this.articleUrl).then(resArt => {
				this.setState({
					articleLayout: resArt.data
				})
			})
		}else {
			ServiceArticle.byInactiveUrl(this.articleUrl).then(resArt => {
				const tags:any = resArt.data.Metatag.split(',');
				this.setState({
					article: resArt.data,
					author: {
						Id: resArt.data.Yazarid,
						Name: resArt.data.Name,
						Surname: resArt.data.Surname,
						Resim: resArt.data.Resim,
						Profilgoster: resArt.data.Profilgoster
					},
					tags,
				})
				if (resArt.status === 200) {
					ServiceArticle.getRelatedArticles(resArt.data.Id).then(
						(resCat: any) => {
							this.setState({
								relatedArticles: resCat.data
							})
						}
					)

					ServiceCategory.categories().then(resCat => {
						this.setState({
							categories: resCat.data,
							category: resCat.data.filter(
								c => c.Id == resArt.data.MakaleKategoriId
							)[0]
						})
					})
				}
			})
			ServiceArticle.getLayoutByInactiveArticleByName(this.articleUrl).then(resArt => {
				this.setState({
					articleLayout: resArt.data
				})
			})
		}
	}

	reset() {
		this.state = {
			categories: [] as any,
			category: {} as any,
			article: {} as any,
			articleLayout: {} as any,
			author: {} as any,
			comment: '',
			relatedArticles: [],
			commentCount: 0,
			tags: [],
		}
	}

	componentWillMount() {
		$('.top-menu').removeClass('active-profile')
	}

	componentWillUnmount() {
		$('.top-menu').addClass('active-profile')
	}

	getCoverStyle() {
		var obj: any = {}
		if (this.state.category && this.state.category.KategoriResim) {
			obj.backgroundImage = `url(${Const.seturdayCdnArticleCover}${this.state.article.MakaleBuyukResim})`
			obj.backgroundPositionY = 0
		}
		return obj
	}

	componentDidMount() {
		$('.transition-first')
		.delay(400)
		.queue(function(n) {
			$(this).addClass('active')
			n()
		})
		$('.no-log-notactive').on('click', () => {
			$('#error-overlay-active').addClass('active')
		})
		$('.no-log').on('click', () => {
			$('.error-overlay').addClass('active')
		})

		$(window).on('scroll', function() {
			try {
				if ($(this).scrollTop() > $('#layout').position().top) {
					$('.control-arrows').hide()
				} else {
					$('.control-arrows').show()
				}
			} catch (error) {}
		})
		$('.transition-first').on(
			'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
			function() {
				$('.first-right').on(
					'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
					function() {
						$('.first-right-in .loading-middle').addClass('active')
						$('.first-right-in .loading-middle').on(
							'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
							function() {
								$('.first-right-in .loading-middle').addClass('bubble')
							}
						)
						$('.first-right-in .loading-middle.bubble').on(
							'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
							function() {
								$('.loading-middle .bubble-a').addClass('active')
								$('.loading-middle .loading-ripple').addClass('active')
								$('.first-right-in').removeClass('transiton')
								$('.first-right-in').addClass('zoom')
								$('.middle-title-area-anim').addClass('active')
								$('body').removeClass('position-fixed')
								$('.first-overlay').removeClass('position-fixed')
							}
						)
					}
				)
			}
		)

		$('.control-arrows').css('display', 'block')
		$('.control-arrows .control-next').hover(
			function() {
				$('.first-right-right').addClass('hover')
			},
			function() {
				$('.first-right-right').removeClass('hover')
			}
		)
		$('.control-arrows .control-prev').hover(
			function() {
				$('.first-right-left').addClass('hover')
			},
			function() {
				$('.first-right-left').removeClass('hover')
			}
		)
		$('.control-arrows .control-prev a').click(function(n) {
			n.preventDefault()
		})
		$('.control-arrows .control-next a').click(function(n) {
			n.preventDefault()
		})
		$('.control-arrows .control-next a').on('click', function() {
			var n = $(this).attr('href') as string
			$('.first-right-right').addClass('active')
			$('.first-right-right .right-title-area-anim').addClass('oldanim')
			$('.first-right-right .loading-right').addClass('oldanim')
			$('.loading-right .bubble-a').addClass('oldanim')
			$('.loading-right .loading-ripple').addClass('oldanim')
			$('.first-right-right').on(
				'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
				function() {
					window.location.href = n
					$('.first-right-right .loading-right.oldanim').addClass('active')
					$('.first-right-right .loading-right').on(
						'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
						function() {
							$('.first-right-right .loading-right.oldanim').addClass('bubble')
						}
					)
					$('.first-right-right .loading-right.bubble.oldanim').on(
						'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
						function() {
							$('.loading-right .bubble-a.oldanim').addClass('active')
							$('.loading-right .loading-ripple.oldanim').addClass('active')
							$('.first-right-in').addClass('zoom')
							$('.first-right-right .right-title-area-anim.oldanim').addClass(
								'active'
							)
						}
					)
				}
			)
		})
		$('.control-arrows .control-prev a').on('click', function() {
			var n = $(this).attr('href') as string
			$('.first-right-left').addClass('active')
			$('.first-right-left .left-title-area-anim').addClass('oldanim')
			$('.first-right-left .loading-left').addClass('oldanim')
			$('.loading-left .bubble-a').addClass('oldanim')
			$('.loading-left .loading-ripple').addClass('oldanim')
			$('.first-right-left').on(
				'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
				function() {
					window.location.href = n
					$('.first-right-left .loading-left.oldanim').addClass('active')
					$('.first-right-left .loading-left').on(
						'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
						function() {
							$('.first-right-left .loading-left.oldanim').addClass('bubble')
						}
					)
					$('.first-right-left .loading-left.bubble.oldanim').on(
						'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
						function() {
							$('.loading-left .bubble-a.oldanim').addClass('active')
							$('.loading-left .loading-ripple.oldanim').addClass('active')
							$('.first-right-in').addClass('zoom')
							$('.first-right-left .left-title-area-anim.oldanim').addClass(
								'active'
							)
						}
					)
				}
			)
		})
		window.scrollTo(0, 0)
		$('.top-menu').removeClass('active-profile')

		var windowWidth = $(window).width() as number

		$('.social-share').jsSocials({
			showLabel: false,
			showCount: false,
			shares: ['email', 'twitter', 'facebook', 'whatsapp']
		})

		let timer: any
		if (windowWidth <= 1024) {
			$('.share-button-area').on('mouseenter', function() {
				timer = setTimeout(function() {
					$('.jssocials-share').animate(
						{
							marginLeft: '5px',
							marginRight: '5px',
							opacity: 1
						},
						100
					)
					$('.share-button').fadeOut(350)
				}, 200)
			})
			$('.share-button-area').on('mouseleave', function() {
				setTimeout(function() {
					$('.jssocials-share').animate(
						{
							marginLeft: '5px',
							marginRight: '5px',
							opacity: 1
						},
						100
					)
					$('.share-button').fadeIn(200)
				}, 200)
				clearTimeout(timer)
			})
		} else {
			$('.share-button-area').on('mouseenter', function() {
				timer = setTimeout(function() {
					$('.jssocials-share').animate(
						{
							marginLeft: '0px',
							marginRight: '5px',
							opacity: 1
						},
						100
					)
					$('.share-button').fadeOut(350)
				}, 200)
			})
			$('.share-button-area').on('mouseleave', function() {
				setTimeout(function() {
					$('.jssocials-share').animate(
						{
							marginLeft: '35px',
							marginRight: '-45px',
							opacity: 0
						},
						100
					)
					$('.share-button').fadeIn(200)
				}, 200)
				clearTimeout(timer)
			})
			$('.jssocials-share-link .jssocials-share-logo').addClass('fa-2x')
			$(
				'.jssocials-share-whatsapp .jssocials-share-link .jssocials-share-logo'
			).removeClass('fa-whatsapp')
			$(
				'.jssocials-share-whatsapp .jssocials-share-link .jssocials-share-logo'
			).removeClass('fab')
			$(
				'.jssocials-share-whatsapp .jssocials-share-link .jssocials-share-logo'
			).addClass('far')
			$(
				'.jssocials-share-whatsapp .jssocials-share-link .jssocials-share-logo'
			).addClass('fa-copy')
		}
		$(
			'.jssocials-share-whatsapp .jssocials-share-link .jssocials-share-logo.fa-copy'
		).on('click', () => {
			this.copyLink()
		})
		$(window).resize(function() {
			if (windowWidth <= 1024) {
				$('.share-button-area').on('mouseenter', function() {
					timer = setTimeout(function() {
						$('.jssocials-share').animate(
							{
								marginLeft: '5px',
								marginRight: '5px',
								opacity: 1
							},
							100
						)
						$('.share-button').fadeOut(350)
					}, 200)
				})
				$('.share-button-area').on('mouseleave', function() {
					setTimeout(function() {
						$('.jssocials-share').animate(
							{
								marginLeft: '5px',
								marginRight: '5px',
								opacity: 1
							},
							100
						)
						$('.share-button').fadeIn(200)
					}, 200)
					clearTimeout(timer)
				})
			} else {
				$('.share-button-area').on('mouseenter', function() {
					timer = setTimeout(function() {
						$('.jssocials-share').animate(
							{
								marginLeft: '0px',
								marginRight: '5px',
								opacity: 1
							},
							100
						)
						$('.share-button').fadeOut(350)
					}, 200)
				})
				$('.share-button-area').on('mouseleave', function() {
					setTimeout(function() {
						$('.jssocials-share').animate(
							{
								marginLeft: '35px',
								marginRight: '-45px',
								opacity: 0
							},
							100
						)
						$('.share-button').fadeIn(200)
					}, 200)
					clearTimeout(timer)
				})
			}
		})
	}

	copyLink() {
		var t = window.location.href,
			n = document.getElementById('get-url-input') as HTMLInputElement
		n.value = t
		n.select()
		document.execCommand('copy')
		$(
			'.jssocials-share-whatsapp .jssocials-share-link .jssocials-share-logo.fa-copy'
		).prepend('<p class=\'copied-text\'>Link Kopyalandı</p>')
		$('.copied-text').animate(
			{
				top: '-35px',
				opacity: 1,
				fontSize: '11px'
			},
			500,
			function() {
				$('.copied-text')
					.delay(250)
					.animate(
						{
							top: '-5px',
							opacity: 0,
							fontSize: '7px'
						},
						500,
						function() {
							$('.copied-text')
								.delay(300)
								.remove()
						}
					)
			}
		)
	}

	relatedSliderInit() {
		new Swiper('.benzer-container', {
			slidesPerView: 5,
			spaceBetween: 30,
			loop: false,
			pagination: {
				el: '.last-added',
				clickable: true
			},
			breakpoints: {
				1550: {
					slidesPerView: 4,
					spaceBetween: 40
				},
				1350: {
					slidesPerView: 3,
					spaceBetween: 40
				},
				1024: {
					slidesPerView: 2,
					spaceBetween: 20
				},
				660: {
					slidesPerView: 1,
					spaceBetween: 20
				}
			}
		})
	}

	scrollToArticleBody(e: React.MouseEvent<any>) {
		$('html, body').animate({
			scrollTop: $('#layout').offset().top - 50
		})
		e.preventDefault()
	}

	btnComment(e: React.MouseEvent<HTMLInputElement>) {
		e.preventDefault()
		if (
			this.props.loginStore.user.Id > 0 &&
			this.state.comment &&
			this.state.comment.trim()
		) {
			debugger
			ServiceComment.add({
				Yorum: this.state.comment,
				MakaleId: this.state.article.Id,
				Yayinla: true,
				Aktif: true
			}).then(() => {
				this.setState(
					{
						comment: ''
					},
					() => {
						$('.comment-moderation').addClass('active')
						setTimeout(
							() => $('.comment-moderation').removeClass('active'),
							3e3
						)
					}
				)
			})
		}
	}

	getCategory(categoryId: number): ICategory {
		if (this.state.categories && this.state.categories.length > 0) {
			return this.state.categories.filter(c => c.Id == categoryId)[0]
		}
		return {} as ICategory
	}

	componentWillReceiveProps(nextProps: IArticleProp) {
		if (this.articleUrl != nextProps.match.params.articleUrl) {
			this.getData(nextProps.match.params.articleUrl)
			$('html').animate(
				{
					scrollTop: 0
				},
				'slow'
			)
		}
	}

	like(articleId: number) {
		ServiceArticle.likeAnArticle(articleId).then(res => {
			this.setState({
				articleLayout: {
					...this.state.articleLayout,
					isLiked: !this.state.articleLayout.isLiked
				}
			})
			$('.like-p').text(res.data)
		})
	}

	render() {
		const bgStyle = this.getCoverStyle()
		const {
			Order1,
			Order2,
			Order3,
			Order4,
			Order5,
			Order6
		} = this.state.articleLayout

		return (
			<React.Fragment>
				{this.state.article && this.state.article.MakaleAdi && (
					<Helmet>
						<title>{Seo.getTitle(this.state.article.MetaTitle)}</title>
						<meta name="keywords" content={this.state.article.Metatag} />
						<meta
							name="description"
							content={this.state.article.Metadescription}
						/>
						<meta property="og:type" content="article" />
						<meta property="og:title" content={this.state.article.MetaTitle} />
						<meta
							property="og:description"
							content={this.state.article.Metadescription}
						/>
						<meta
							property="og:image"
							content={`${Const.seturdayCdnArticleCover}${this.state.article.MakaleBuyukResim}`}
						/>
						<meta property="article:tag" content={this.state.article.Metatag} />
						<meta
							property="article:published_time"
							content={this.state.article.MakaleTarihi}
						/>
						<meta
							property="article:section"
							content={this.state.article.RelatedDestination}
						/>
					</Helmet>
				)}
				<div className="control-arrows">
					<div className="control-prev">
						{this.state.article.ArticleNextandPrevious && (
							<Link
								to={`/${
									this.getCategory(
										this.state.article.ArticleNextandPrevious
											.prevArticleCategoryId
									).Kategoriurl
								}/${
									this.state.article.ArticleNextandPrevious.prev_article_url
								}`}
								title={
									this.state.article.ArticleNextandPrevious.prev_article
								}></Link>
						)}
					</div>
					<div className="control-next">
						{this.state.article.ArticleNextandPrevious && (
							<Link
								to={`/${
									this.getCategory(
										this.state.article.ArticleNextandPrevious
											.nextArticleCategoryId
									).Kategoriurl
								}/${
									this.state.article.ArticleNextandPrevious.next_article_url
								}`}
								title={
									this.state.article.ArticleNextandPrevious.next_article
								}></Link>
						)}
					</div>
				</div>
				{this.state.article.ArticleNextandPrevious && (
					<div
						className="first-right-left"
						style={{
							background: `url(${Const.seturdayCdnArticleCover}${this.state.article.ArticleNextandPrevious.prev_article_image}) center center no-repeat`
						}}>
						<div className="gradient-bg"></div>
					</div>
				)}
				{this.state.article.ArticleNextandPrevious && (
					<div
						className="first-right-right"
						style={{
							background: `url(${Const.seturdayCdnArticleCover}${this.state.article.ArticleNextandPrevious.next_article_image}) center center no-repeat`
						}}>
						<div className="gradient-bg"></div>
					</div>
				)}
				<div className="first-right-in bg-fixed transiton" style={bgStyle}>
					<div className="transition-first"></div>
					<div className="main-title-area middle-title-area-anim">
						<div className="main-tag">
							<Link to={`/${this.state.category.Kategoriurl}`}>
								{this.state.category.KategoriAdi && this.state.category.KategoriAdi.toUpperCase()}
							</Link>
						</div>
						<div className="main-title">
							<h1>{this.state.article && this.state.article.MakaleAdi}</h1>
						</div>
						<div className="main-text">
							<p>{this.state.article && this.state.article.MakaleOzeti}</p>
						</div>
					</div>
					<div className="loading loading-middle">
						<a href="#" onClick={this.scrollToArticleBody} className="bubble-a">
							<img
								src="..\Content\img\scroll-icon.png"
								alt={this.state.article && this.state.article.MakaleAdi}
								title={this.state.article && this.state.article.MakaleAdi}
							/>
						</a>
						<div className="loading-ripple"></div>
					</div>
					<div className="gradient-bg"></div>
				</div>
				<div className="first-right-content">
					<div className="layout-wrap" id="layout">
						{this.state.author && this.state.author.Profilgoster && (
							<div className="full-w author-bg">
								<div className="layout-wrap-in clearfix">
									<div className="author-info-area clearfix">
										<div className="author-info-in clearfix">
											<div className="author-info-top">
												<div className="author-info-top-left">
													<div className="author-image">
														{this.state.author && (
															<img
																src={`${Const.seturdayCdnAuthorUrl}${this.state
																	.author.Resim || 'anymous.png'}`}
																alt={this.state.article.MakaleAdi}
																title={this.state.article.MakaleAdi}
															/>
														)}
													</div>
													<div className="author-info-name">
														<h5 className="aut-name">
															{this.state.author.Name}{' '}
															{this.state.author.Surname}
														</h5>
													</div>
												</div>
												<div className="author-info-top-right">
													<h6 className="genre">
														{this.state.category.KategoriAdi}
													</h6>
													<p className="date">26.04.19</p>
												</div>
											</div>
											<div className="author-info-bottom">
												<p>{this.state.author.Yazarhakkinda}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
						{this.state.articleLayout &&
							this.state.articleLayout.LayoutId == 1 && (
							<React.Fragment>
								{times(6, num => {
									if (Order1 == num + 1) {
										return (
											<Layout1Field1Component
												key={num}
												articleLayout={this.state.articleLayout}
											/>
										)
									}
									if (Order2 == num + 1) {
										return (
											<Layout1Field2Component
												key={num}
												articleLayout={this.state.articleLayout}
											/>
										)
									}
									if (Order3 == num + 1) {
										return (
												<>
													<Layout1Field3Component
														key={num}
														articleLayout={this.state.articleLayout}
													/>
													<Layout1Field4Component
														key={num + 1}
														articleLayout={this.state.articleLayout}
													/>
												</>
										)
									}
									if (Order4 == num + 1) {
										return (
											<Layout1Field5Component
												key={num}
												articleLayout={this.state.articleLayout}
											/>
										)
									}
								})}
								<Layout1SliderComponent
									articleLayout={this.state.articleLayout}
								/>
							</React.Fragment>
						)}
						{this.state.articleLayout &&
							this.state.articleLayout.LayoutId == 2 && (
							<React.Fragment>
								<Layout2SliderComponent
									articleLayout={this.state.articleLayout}
								/>
								{times(6, num => {
									if (Order1 === num + 1) {
										return (
											<Layout2Field1Component
												key={num}
												articleLayout={this.state.articleLayout}
											/>
										)
									}
									if (Order2 === num + 1) {
										return (
											<Layout2Field2Component
												key={num}
												articleLayout={this.state.articleLayout}
											/>
										)
									}
									if (Order3 === num + 1) {
										return (
											<Layout2Field3Component
												key={num}
												articleLayout={this.state.articleLayout}
											/>
										)
									}
								})}
							</React.Fragment>
						)}
						{this.state.articleLayout &&
							this.state.articleLayout.LayoutId == 3 && (
							<React.Fragment>
								{times(6, num => {
									if (Order1 === num + 1) {
										return (
											<Layout3Field1Component
												key={num}
												articleLayout={this.state.articleLayout}
											/>
										)
									}
									if (Order2 === num + 1) {
										return (
											<Layout3Field2Component
												key={num}
												articleLayout={this.state.articleLayout}
											/>
										)
									}
									if (Order3 === num + 1) {
										return (
											<Layout3Field3Component
												key={num}
												articleLayout={this.state.articleLayout}
											/>
										)
									}
									if (Order4 === num + 1) {
										return (
											<Layout3Field4Component
												key={num}
												articleLayout={this.state.articleLayout}
											/>
										)
									}
								})}
								<Layout3SliderComponent
									articleLayout={this.state.articleLayout}
								/>
							</React.Fragment>
						)}
						<div className="full-w bg-grey comment-block-area">
							<div className="layout-comment-area clearfix">
								<LayoutField5Component
									articleLayout={this.state.articleLayout}
								/>
								{this.state.tags.length > 0 && 								
								<div className="comment-info-area clearfix">
									<div className="comment-tag-area clearfix">
										<p className="tags">
											<span>Etiketler: </span>
											{
												this.state.tags.map((tag:string, index:number) => 											<a
													href={`/arama?q=${encodeURIComponent(tag.trim())}`}
													target="_self">
													{tag.trim()} 
													{index + 1 !== this.state.tags.length && 
														<span>, </span>
													}
												</a>)
											}
										</p>
									</div>
								</div>}
								<div className="comment-info-area clearfix">
									<div className="share-button-area">
										<div className="share-button">
											<p>
												<img src="..\Content\img\share-icon.png" />
											</p>
										</div>
										<div className="social-share clearfix"></div>
										<input type="text" id="get-url-input" />
									</div>
									<div className="comment-like-area">
										<div
											className={`grid-like ${
												(this.props.loginStore.user.Id as number) > 0
													? this.state.articleLayout.isLiked
														? 'user-like liked'
														: ''
													: 'no-log'
											}`} // user-like liked
											onClick={e =>
												(this.props.loginStore.user.Id as number) > 0
													? this.like(this.state.article.Id)
													: e.preventDefault()
											}>
											<a className="like-button">
												<span className="like-icon">
													<div className="heart-animation-1"></div>
													<div className="heart-animation-2"></div>
												</span>
											</a>
										</div>
										<p className="like-p">
											{this.state.article && this.state.article.MakaleBeğeni}
										</p>
										<p className="comment-p">{this.state.commentCount}</p>
									</div>
								</div>
								<RequiredLoginComponent />
								<div className="comment-area">
									<CommentsComponent
										onChangeModel={model =>
											this.setState({commentCount: model.TotalCount})
										}
										articleUrl={this.articleUrl}
										articleId={0}
									/>
									<div id="yorumdiv" className="write-comment">
										<div className="write-comment-top">
											<p>Yorum Yap</p>
										</div>

										<form id="form0" method="post">
											<input
												data-val="true"
												data-val-number="The field id must be a number."
												data-val-required="The id field is required."
												id="id"
												name="id"
												type="hidden"
												value="595"
											/>
											<div id="alanform" className="write-comment-input-area">
												<div className="write-comment-textarea-area">
													<textarea
														cols={20}
														id="commentText"
														name="commentText"
														onChange={e =>
															this.setState({
																comment: e.target.value
															})
														}
														value={this.state.comment}></textarea>
												</div>
												<div className="write-comment-submmit-area">
													<input
														type="submit"
														name="commnnet-submit"
														onClick={this.btnComment}
														value="Yorum Yap"
														className={
															this.props.loginStore.user.Id > 0 ? '' : 'no-log'
														}
														id="comment-submit"
													/>
													<p className="comment-moderation">
														<img src="/Content/img/comment-tick.png" />
														Yorumunuz onaylandıktan sonra yayınlanacaktır.
													</p>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
						{this.state.article.RelatedDestination && (
							<div className="advice-bottom-area">
								<div className="advice-bottom-in">
									<p>
										<strong> {this.state.article.RelatedDestination} </strong>{' '}
										hakkında ziyaret eden gezginlerden tavsiye almak için
										tıklayın!
									</p>
									<p></p>
									<form id="sedForm" method="post">
										<div className="sedvice-input-area-left">
											<input
												type="hidden"
												value={this.state.article.RelatedDestination}
												name="sedviceautocomplete"
												id="sedviceautocomplete"
											/>
										</div>
										<input
											type="submit"
											value="Tavsiye Al"
											onClick={e => {
												e.preventDefault()
												this.props.history.push(
													`/sedvice/${encodeURI(
														this.state.article.RelatedDestination
													)}`
												)
											}}
											className="bottom-advice-button"
										/>
									</form>
									<p></p>
								</div>
							</div>
						)}
						{/*this.state.article.RelatedDestination && (
							<div className="full-w">
								<div className="layout-wrap-in">
									<div className="about-tour-form-area">
										<div className="agreement-area">
											<div className="agreement-in">
												<h4>Kullanıcı Sözleşmesi</h4>
												<p></p>
											</div>
											<div className="agreement-button">
												<a
													href="#"
													onClick={e => e.preventDefault()}
													className="agreeButton">
													Kabul Ediyorum
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						)*/}
						<div className="full-w clearfix">
							<div className="benzer-icerik-slider">
								<div className="benzer-top">
									<h4>Benzer İçerikler</h4>
								</div>
								<div className="swiper-container benzer-container">
									<div className="swiper-wrapper">
										{this.state.relatedArticles.map((item, i) => {
											if (this.state.relatedArticles.length - 1 == i) {
												setTimeout(() => {
													this.relatedSliderInit()
												}, 500)
											}
											return (
												<div key={`releated_${i}`} className="swiper-slide">
													<Link
														to={`/${
															this.getCategory(item.MakaleKategoriId)
																.Kategoriurl
														}/${item.Url}`}
														title={item.MakaleAdi}>
														<div className="item" style={{width: '100%'}}>
															<div className="benzer-image">
																<img
																	src={`${Const.seturdayCdnArticleCover}${item.MakaleBuyukResim}`}
																	alt={item.MakaleAdi}
																	title={item.MakaleAdi}
																/>
															</div>
															<div className="benzer-title">
																<p className="benzer-title-p">
																	{item.MakaleAdi}
																</p>
																<p className="benzer-title-sub">
																	{item.MakaleOzeti.length > 100
																		? item.MakaleOzeti.substring(0, 100) + '...'
																		: item.MakaleOzeti}
																</p>
															</div>
															<div className="benzer-info">
																<div className="benzer-info-in">
																	<p className="benzer-date">
																		{item.AuthorFullName}
																	</p>
																</div>
															</div>
														</div>
													</Link>
												</div>
											)
										})}
									</div>
									<div className="swiper-pagination last-added"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

interface IArticleRouteProp {
	articleUrl: string
	id: any
}

interface IArticleProp extends RouteComponentProps<IArticleRouteProp> {
	loginStore: ILoginStore
}

interface IArticleState {
	article?: IArticle
	category?: ICategory
	categories?: ICategory[]
	author?: IUserInfo
	articleLayout?: IArticleLayout
	relatedArticles?: IArticle[]
	comment?: string
	commentCount?: number
	tags?: []
}
