import React, {Component} from 'react'
import Const from '../../const'

export default class Layout1Field2Component extends Component<
	ILayout1Prop,
	ILayout1State
> {
	constructor(props: ILayout1Prop) {
		super(props)
	}

	render() {
		return (
			<React.Fragment>
				{this.props.articleLayout.layout1 && (
					<div
						className="layout-parallax-bg-area papa"
						style={{
							backgroundImage: `url(${Const.seturdayCdnArticleLayout1Url}${this.props.articleLayout.layout1.Alan2resim})`
						}}>
						<div className="layout-parallax-bg-text">
							<p
								dangerouslySetInnerHTML={{
									__html: this.props.articleLayout.layout1.Alan2resimaciklama
								}}></p>
						</div>
					</div>
				)}
			</React.Fragment>
		)
	}
}

interface ILayout1Prop {
	articleLayout: IArticleLayout
}

interface ILayout1State {}
