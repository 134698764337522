import React, {Component} from 'react'
import ConstructorComponent from '../components/Constructor'
import UnAuthorizedComponent from './UnAuthorized'

import {inject, observer} from 'mobx-react'
import Helmet from 'react-helmet'
import Seo from '../helper/seo'
import Const from '../const'

@inject('loginStore', 'visitedPlaceStore')
@observer
export default class MyPlacesWentComponent extends Component<
	IMyPlacesWentProp,
	IMyPlacesWentState
> {
	constructor(props: IMyPlacesWentProp) {
		super(props)
	}

	showMap() {
		$('.sedvice-circle').removeClass('active')
		$('.new-map-overlay').removeClass('active')
		$('.new-map-area').removeClass('active')
		$('body').removeClass('pfix-sb')
		$('first-overlay').removeClass('pfix-sb')
		$('.new-map-overlay').addClass('active')
		$('#new-map-modal').addClass('active')
		$('.sedvice-circle').addClass('active')
	}

	async remove(e: React.MouseEvent<HTMLAnchorElement>, place: IVisitedPlace) {
		e.preventDefault()
		await this.props.visitedPlaceStore.remove(place)
	}

	async getVisitedPlace() {
		await this.props.visitedPlaceStore.getVisitedPlace()
		this.setState({})
	}
	componentDidMount() {
		this.getVisitedPlace();
	}

	render() {
		let {user} = this.props.loginStore
		if (!this.props.loginStore.user.Id) {
			return <UnAuthorizedComponent />
		}
		return (
			<div className="first-right-content">
				<Helmet>
					<title>{Seo.getTitle('Gittiğim Yerler')}</title>
				</Helmet>
				<div className="profile-wrap clearfix">
					<div className="profile-wrap-in clearfix">
						<div className="username-area">
							<div className="user-name-big">
								<h1>
									{user.Name} {user.Surname}
								</h1>
							</div>
						</div>
						<div className="profile-content-in" id="profile-ayarlar">
							<div className="profile-hesabim pt-30 mb-30">
								<div className="profile-hesabim pt-30 mb-30">
									<p className="profile-title-p">Gittiğim Yerler</p>
									<div className="gittigim-list-area">
										{this.props.visitedPlaceStore.visitedPlaces.map(place => {
											return (
												<div
													key={place.GooglePlaceId}
													className="place-block place-visited"
													data-googleplaceid={place.GooglePlaceId}>
													<div className="place-image-top">
														<img
															src={Const.seturdayCdnBuckerImageUrl(place.Resim)}
														/>
													</div>
													<div className="place-name-bottom">
														<div className="place-detail">
															<h4>{place.Sehir}</h4>
															<h5>{place.Ulke}</h5>
														</div>
														<div className="place-delete">
															<a href="#" onClick={e => this.remove(e, place)}>
																<img src="/content/img/delete-icon.png" />
															</a>
														</div>
													</div>
												</div>
											)
										})}
										<div className="place-block">
											<div
												onClick={this.showMap}
												className="place-image-top add-new-block new-map-button"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

interface IMyPlacesWentProp {
	loginStore?: ILoginStore
	visitedPlaceStore?: IVisitedPlaceStore
}

interface IMyPlacesWentState {}
