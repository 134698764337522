import React, { Component } from 'react'
import ServiceUser from '../../services/ServiceUser'
import ServiceScore from '../../services/ServiceScore'
import HowToScorePopup from '../../components/HowToScorePopup'
import Const from '../../const'
import moment from 'moment'
import HelperDom from '../../helper/helper-dom'
import ScriptCache from '../../helper/script.cache'
import MyAccountSettingsComponent from './MyAccountSettings'
import ConstructorComponent from '../../components/Constructor'
import { RouteComponentProps } from 'react-router'
import { inject, observer } from 'mobx-react'
import UnAuthorizedComponent from '../UnAuthorized'
import MyAccountMessageComponent from './MyAccountMessage'
import { Link } from 'react-router-dom'
import MyArticlesComponent from './MyArticlesComponent'

@inject('loginStore')
@observer
export default class MyAccountComponent extends Component<IMyAccountProp, IMyAccountState> {
	constructor(props: IMyAccountProp) {
		super(props)

		this.state = {
			tab: this.props.match.params.tab || 'profilim',
		}
	}

	componentWillReceiveProps(nextProps: IMyAccountProp) {
		if (nextProps.match.params.tab !== this.state.tab) {
			this.setState({
				tab: nextProps.match.params.tab
			})
		}
	}

	render() {
		if (!this.props.loginStore.user.Id) {
			return <UnAuthorizedComponent />
		}
		return <div className="first-right-content">
			<HowToScorePopup />
			<div className="profile-wrap clearfix">
				<div className="profile-wrap-in clearfix">
					<div className="profile-top">
						<div className="username-area">
							<div className="user-name-big">
								<h1>{this.props.loginStore.user.Name} {this.props.loginStore.user.Surname}</h1>
							</div>
							{/*<div className="user-score-area"><span className="user-score">{this.props.loginStore.score}</span> Puan</div>
							<p><a className="how-to-score-btn" href="#" onClick={(e) => e.preventDefault()} id="HowToScoreLink">Nasıl Puan Kazanırım?</a></p>*/}
						</div>
						<div className="profile-tab-menu-area">
							<div className="profile-tab-menu">
								<ul>
									<li className={this.state.tab === 'profilim' ? 'active' : ''}>
										<Link to="/hesabim/profilim" className={this.state.tab !== 'profilim' ? 'active' : ''}>
											PROFİLİM
										</Link>
									</li>
									<li className={this.state.tab === 'mesajlarim' ? 'active' : ''}>
										<Link to="/hesabim/mesajlarim" className={this.state.tab !== 'mesajlarim' ? 'active' : ''}>
											MESAJLARIM
										</Link>
									</li>
									<li className={this.state.tab === 'yazilarim' ? 'active' : ''}>
										<Link to="/hesabim/yazilarim" className={this.state.tab !== 'yazilarim' ? 'active' : ''}>
											YAZILARIM
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
					{
						this.state.tab === 'profilim' && <MyAccountSettingsComponent history={this.props.history} loginStore={this.props.loginStore} />
					}
					{
						this.state.tab === 'mesajlarim' && <MyAccountMessageComponent />
					}
					{
						this.state.tab === 'yazilarim' && <MyArticlesComponent history={this.props.history} pageId={this.props.match.params.pageId} />
					}
				</div>
			</div>
		</div>
	}
}

interface IMyAccountRouteProp {
	tab: TabType
	pageId: string
}

interface IMyAccountProp extends RouteComponentProps<IMyAccountRouteProp> {
	loginStore: ILoginStore
}

interface IMyAccountState {
	tab: TabType
}

type TabType = 'profilim' | 'mesajlarim' | 'yazilarim'