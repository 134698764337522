export const KVKK_TEXT = `
<html>

<head>
<meta charset="utf-8">

<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Liberation Sans";
	panose-1:0 0 0 0 0 0 0 0 0 0;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:16.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
h1
	{mso-style-link:"Heading 1 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:41.9pt;
	text-indent:-18.05pt;
	text-autospace:none;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
}
h2
	{mso-style-link:"Heading 2 Char";
	margin-top:.05pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:5.9pt;
	text-autospace:none;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	font-style:italic;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{mso-style-link:"Body Text Char";
	margin-top:1.65pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:23.9pt;
	text-indent:-18.05pt;
	text-autospace:none;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}

p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:16.0pt;
	margin-left:36.0pt;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:16.0pt;
	margin-left:36.0pt;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.Heading1Char
	{mso-style-name:"Heading 1 Char";
	mso-style-link:"Heading 1";
	font-family:"Arial",sans-serif;
	font-weight:bold;
	}
span.Heading2Char
	{mso-style-name:"Heading 2 Char";
	mso-style-link:"Heading 2";
	font-family:"Arial",sans-serif;
	font-weight:bold;
	font-style:italic;}
span.BodyTextChar
	{mso-style-name:"Body Text Char";
	mso-style-link:"Body Text";
	font-family:"Arial",sans-serif;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:70.85pt 70.85pt 70.85pt 70.85pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=EN-US link="#0563C1" vlink="#954F72" style='word-wrap:break-word; width: calc(100% - 50px); display:block !important; padding-left: 20px; max-width: 1300px;'>
<div class=aydinlatma-metni style='overflow-y:auto;'>
<div class=WordSection1>
</span></div>
<h1 align=center style='margin-top:0cm;margin-right:4.2pt;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:center;text-indent:0cm;
line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif;text-decoration:none'>SETUR SERVİS TURİSTİK
ANONİM ŞİRKETİ </span></h1>

<h1 align=center style='margin-top:0cm;margin-right:4.2pt;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:center;text-indent:0cm;
line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif;text-decoration:none'>MÜŞTERİ KİŞİSEL
VERİLERİN İŞLENMESİ AYDINLATMA<span style='letter-spacing:-1.3pt'> </span>METNİ</span></h1>


<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:41.9pt;text-align:justify;text-indent:-18.05pt;text-autospace:
none'><b><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:
"Times New Roman",serif;letter-spacing:-.05pt'>a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span dir=LTR></span><b><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>Veri<span
style='letter-spacing:-.1pt'> </span>Sorumlusu</span></b></p>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:5.25pt;margin-bottom:
0cm;margin-left:5.9pt;margin-bottom:.0001pt;text-align:justify;text-indent:
0cm;line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>6698 sayılı Kişisel Verilerin Korunması
Kanunu (“<b>Kanun</b>”) uyarınca, kişisel verileriniz; veri sorumlusu olarak
Setur Servis Turistik Anonim Şirketi (“<b>Şirket</b>” veya “<b>Setur</b>”)
tarafından aşağıda açıklanan kapsamda işlenebilecektir.</span></p>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-align:justify;
text-indent:0cm;line-height:115%'><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:5.1pt;margin-bottom:0cm;
margin-left:5.9pt;text-align:justify'><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>Kişisel verilerinizin işlenme amaçları konusunda detaylı
bilgiye; </span><span lang=TR><a
href="https://kvkk.setur.com.tr/public/document/13/15/pdf/true"><b><span
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif;
color:#565656'>https://kvkk.setur.com.tr/public/document/13/15/pdf/true</span></b><b><span
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif;
color:#565656;text-decoration:none'> </span></b></a></span><span lang=TR
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'>internet
adresinden kamuoyu ile paylaşılmış olan Setur Kişisel Verilerin Korunması ve
İşlenmesi Politikasından<span style='letter-spacing:-.45pt'> </span>ulaşabilirsiniz.</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:5.1pt;margin-bottom:0cm;
margin-left:5.9pt;text-align:justify'><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<h1 style='margin-left:36.0pt;text-align:justify;line-height:115%'><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif;
letter-spacing:-.05pt;text-decoration:none'>b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;text-decoration:none'>Kişisel
Verilerin Hangi Amaçlarla ve Hukuki Sebeplerle İşleneceği</span></h1>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:5.15pt;margin-bottom:
0cm;margin-left:5.9pt;margin-bottom:.0001pt;text-align:justify;text-indent:
0cm;line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Toplanan kişisel verileriniz, sunulan ürün
ve hizmetlerden sizleri faydalandırmak için gerekli çalışmaların iş
birimlerimiz tarafından yapılması ve ilgili iş süreçlerinin yürütülmesi
kapsamında aşağıdaki amaçlarla (“<b>Amaçlar</b>”) Kanun’un 5. maddesinde
belirtilen kişisel veri işleme şartları dahilinde Şirket tarafından
işlenebilecektir:</span></p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:5.15pt;margin-bottom:
0cm;margin-left:5.9pt;margin-bottom:.0001pt;text-align:justify;text-indent:
0cm;line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>&nbsp;</span></p>

<h2 style='margin-top:0cm;margin-right:5.55pt;margin-bottom:0cm;margin-left:
5.9pt;margin-bottom:.0001pt;text-align:justify;line-height:115%'><span lang=TR
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'>Bir
sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla,
sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması
hukuki sebebine dayalı olarak;</span></h2>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:5.25pt;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>Turizm hizmetlerimizden
yararlanmanız halinde; tur satışı, seyahat veya otel organizasyonu, bilet
satışı gibi tur, seyahat ve tatil ürün ve hizmetlerinin (“<b>Turizm Ürünleri</b>”)
satış faaliyetlerinin yürütülmesi, Turizm Ürünleri’nden faydalanmanızın<span
style='letter-spacing:-.1pt'> </span>sağlanması,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:5.25pt;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>Gümrüksüz<span
style='letter-spacing:-.65pt'> </span>satış<span style='letter-spacing:-.6pt'> </span>(<i>duty<span
style='letter-spacing:-.6pt'> </span>free)<span style='letter-spacing:-.6pt'> </span></i>kapsamında<span
style='letter-spacing:-.55pt'> </span>alışveriş<span style='letter-spacing:
-.65pt'> </span>yapmanız<span style='letter-spacing:-.6pt'> </span>halinde;<span
style='letter-spacing:-.55pt'> </span>gümrüksüz<span style='letter-spacing:
-.55pt'> </span>satışların<span style='letter-spacing:-.55pt'> </span>(<i>duty<span
style='letter-spacing:-.5pt'> </span>free) </i>gerçekleştirilmesi ve gümrüksüz
satış hizmetlerinin sunulması,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:5.25pt;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;color:#333333'>Talep
etmeniz halinde; vize danışmanlığı hizmetlerinin<span style='letter-spacing:
-.1pt'> </span>sağlanması,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:5.25pt;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;color:#333333'>Turizm
Ürünleri kapsamında olması halinde; seyahat sağlık sigortası düzenlenmesi için
destek sağlanması,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:5.25pt;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;color:#333333'>Satış
kayıtlarının açılması ve faturalandırma faaliyetlerinin<span style='letter-spacing:
-.5pt'> </span>gerçekleştirilmesi,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:5.25pt;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;color:#333333'>Satış
sonrası destek hizmetlerinin<span style='letter-spacing:-.05pt'> </span>sunulması,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:5.25pt;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;color:#333333'>Şirketimizin
ürün ve hizmetlerini satın almanız dolayısıyla satış sözleşmesinin kurulması ve<span
style='letter-spacing:-1.65pt'> </span>ifası.</span></p>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-align:justify;
text-indent:0cm;line-height:115%'><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<h2 style='margin-top:0cm;text-align:justify;line-height:115%'><span lang=TR
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'>İlgili
kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun
meşru menfaatleri için veri işlenmesinin zorunlu olması hukuki sebebine dayalı
olarak;</span></h2>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Şirketimizin ürün ve hizmetlerinin
pazarlama süreçlerinin<span style='letter-spacing:-.15pt'> </span>gerçekleştirilmesi,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Müşteri talep ve şikayetlerinin takibi ve<span
style='letter-spacing:-.1pt'> </span>yönetimi,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif;color:#333333'>Anket ve benzeri müşteri
memnuniyeti ve kurumsal iletişim faaliyetlerinin planlanması ve<span
style='letter-spacing:-1.55pt'> </span>icrası,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif;color:#333333'>Müşteri iletişim
bilgilerinin güncellenmesi,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif;color:#333333'>Müşteri ilişkilerinin<span
style='letter-spacing:-.05pt'> </span>yürütülmesi,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif;color:#333333'>Etkinlik<span
style='letter-spacing:-.05pt'> </span>yönetimi,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Finansal işlemlerin<span style='letter-spacing:
-.1pt'> </span>takibi,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif;color:#333333'>Ürün ve hizmetlerimizin
satış ve pazarlama faaliyetleri dahil olmak üzere Şirketimiz tarafından sunulan
ürün ve hizmetlerden ilgili kişileri faydalandırmak için gerekli çalışmaların
iş birimlerimiz tarafından yapılması ve ilgili iş süreçlerinin<span
style='letter-spacing:-.35pt'> </span>yürütülmesi,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif;color:#333333'>Ticari faaliyetlerin
gerçekleştirilmesi için iş birimlerimizin gerekli çalışmaları ve bağlı iş
süreçlerini gerçekleştirmesi,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif;color:#333333'>Şirketimizin ticari ve iş
stratejilerinin belirlenmesi ve<span style='letter-spacing:-.5pt'> </span>uygulanması,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif;color:#333333'>Hukuk işlerinin takibi ve<span
style='letter-spacing:-.25pt'> </span>yürütülmesi,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif;color:#333333'>Bilgi güvenliği süreçlerinin<span
style='letter-spacing:-.1pt'> </span>yürütülmesi,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif;color:#333333'>Denetim ve etik
faaliyetlerinin<span style='letter-spacing:-.25pt'> </span>gerçekleştirilmesi,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif;color:#333333'>Çekiliş, kampanya, yarışma,
promosyon veya reklam aktivitelerinin<span style='letter-spacing:-.75pt'> </span>gerçekleştirilmesi,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif;color:#333333'>İleride<span
style='letter-spacing:-.35pt'> </span>meydana<span style='letter-spacing:-.25pt'>
</span>gelmesi<span style='letter-spacing:-.3pt'> </span>muhtemel<span
style='letter-spacing:-.2pt'> </span>olası<span style='letter-spacing:-.25pt'> </span>uyuşmazlıklarda<span
style='letter-spacing:-.3pt'> </span>delil<span style='letter-spacing:-.3pt'> </span>teşkil<span
style='letter-spacing:-.3pt'> </span>etmesi<span style='letter-spacing:-.3pt'> </span>amacı<span
style='letter-spacing:-.2pt'> </span>ile<span style='letter-spacing:-.25pt'> </span>kişisel<span
style='letter-spacing:-.3pt'> </span>verilerin genel zamanaşımı süresince<span
style='letter-spacing:-.25pt'> </span>saklanması.</span></p>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-align:justify;
text-indent:0cm;line-height:115%'><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<h2 style='margin-top:0cm;text-align:justify;line-height:115%'><span lang=TR
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'>Bir
hakkın tesisi kullanılması veya korunması için veri işlemenin zorunlu olması
hukuki sebebine dayalı olarak;</span></h2>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Müşteri talep ve şikayetlerinin takibi ve<span
style='letter-spacing:-.1pt'> </span>yönetimi,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif;color:#333333'>İleride<span
style='letter-spacing:-.35pt'> </span>meydana<span style='letter-spacing:-.25pt'>
</span>gelmesi<span style='letter-spacing:-.3pt'> </span>muhtemel<span
style='letter-spacing:-.2pt'> </span>olası<span style='letter-spacing:-.25pt'> </span>uyuşmazlıklarda<span
style='letter-spacing:-.3pt'> </span>delil<span style='letter-spacing:-.3pt'> </span>teşkil<span
style='letter-spacing:-.3pt'> </span>etmesi<span style='letter-spacing:-.3pt'> </span>amacı<span
style='letter-spacing:-.2pt'> </span>ile<span style='letter-spacing:-.25pt'> </span>kişisel<span
style='letter-spacing:-.3pt'> </span>verilerin genel zamanaşımı süresince<span
style='letter-spacing:-.25pt'> </span>saklanması.</span></p>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-align:justify;
text-indent:0cm;line-height:115%'><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<h2 style='margin-top:0cm;text-align:justify;line-height:115%'><span lang=TR
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif;
color:#333333'>Kanunlarda<span style='letter-spacing:-.9pt'> </span>açıkça<span
style='letter-spacing:-1.05pt'> </span>öngörülmesi<span style='letter-spacing:
-.9pt'> </span>ve<span style='letter-spacing:-.95pt'> </span>veri<span
style='letter-spacing:-.9pt'> </span>sorumlusunun<span style='letter-spacing:
-.95pt'> </span>hukuki<span style='letter-spacing:-.9pt'> </span>yükümlülüğünü<span
style='letter-spacing:-1.0pt'> </span>yerine<span style='letter-spacing:-.95pt'>
</span>getirebilmesi için zorunlu olması </span><span lang=TR style='font-size:
12.0pt;line-height:115%;font-family:"Times New Roman",serif'>hukuki sebebine
dayalı<span style='letter-spacing:-.25pt'> </span>olarak;</span></h2>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;color:#333333'>Resmi kurum
taleplerinin yerine<span style='letter-spacing:-.2pt'> </span>getirilmesi,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;color:#333333'>Yetkili kuruluşlara
mevzuattan kaynaklı bilgi<span style='letter-spacing:-.35pt'> </span>verilmesi,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;color:#333333'>Mevzuatta
öngörülen saklama yükümlülüklerine uygunluğun<span style='letter-spacing:-.45pt'>
</span>sağlanması,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;color:#333333'>Şirketimiz
tarafından işlenen kişisel verileriniz ile ilgili olarak Kanun’un 11.
maddesinde belirtilen haklarınızın kullanılması kapsamında Şirket’e iletmiş
olduğunuz başvurularınıza ilişkin değerlendirmelerin yapılması ve mevzuata
uygun olarak sonuçlandırılması ile bu çerçevede gerekli işlemlerin<span
style='letter-spacing:-.1pt'> </span>gerçekleştirilmesi.</span></p>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-align:justify;
text-indent:0cm;line-height:115%'><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<h2 style='margin-top:0cm;text-align:justify;line-height:115%'><span lang=TR
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'>Açık
rızanızın varlığı halinde;</span></h2>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:5.15pt;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Kimlik, iletişim ve müşteri işlem
bilgileriniz; Şirketimizin ürün ve hizmetlerinin doğrudan pazarlama
süreçlerinin gerçekleştirilmesi, izniniz kapsamında çevrimiçi davranışsal
hareketleriniz dahil olmak üzere* profilleme ve analiz faaliyetlerinin
yürütülmesi, sunulan ürün ve hizmetlerin beğeni, kullanım alışkanlıklarınız ve
ihtiyaçlarınıza göre özelleştirerek sizlere önerilmesi ve<span
style='letter-spacing:-.1pt'> </span>tanıtılması,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:5.15pt;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Kimlik, iletişim ve müşteri işlem
bilgileriniz; kabulünüz kapsamında paylaşmış olduğunuz iletişim bilgilerinize
reklam, promosyon, vb. ticari elektronik ileti<span style='letter-spacing:-.55pt'>
</span>gönderilmesi.</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:5.15pt;margin-bottom:
0cm;margin-left:7.1pt;text-align:justify;text-autospace:none'><span lang=TR
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'>*Setur’a
ait internet siteleri ve mobil uygulamalardaki çevrimiçi davranışsal
hareketleriniz ile ilgilendiğiniz ürün ve hizmetlerimiz çerez (cookie)
kullanımına açık rıza vermeniz durumunda izin verdiğiniz pazarlama/analiz amacı
ile sınırlı şekilde takip edilmektedir (Bkz. Setur Çerez Aydınlatma Metni ve
izinleri). Açık rızanızın yanı sıra, ilgili çerezlerin (cookie) kullanımına
izin vermeniz durumunda çevrimiçi davranışsal hareketleriniz ile kişisel verileriniz
ürün ve hizmetlerimizin size özel pazarlama faaliyetleri yapılması için
işlenecektir.</span></p>

<p class=MsoListParagraph style='margin:0cm;text-align:justify;background:white'><b><u><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'><span
 style='text-decoration:none'>&nbsp;</span></span></u></b></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:7.05pt;margin-bottom:
0cm;margin-left:41.9pt;text-align:justify;text-indent:-18.0pt;background:white'><b><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif;
letter-spacing:-.05pt'>c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span dir=LTR></span><b><u><span lang=TR style='font-size:
12.0pt;line-height:115%;font-family:"Times New Roman",serif;color:black'>Sadakat
Programı Kapsamında Veri İşleme Amaçları ile Hukuki Sebepleri</span></u></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:7.05pt;margin-bottom:
0cm;margin-left:0cm;text-align:justify;background:white'><span lang=TR
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:7.05pt;margin-bottom:
0cm;margin-left:7.1pt;text-align:justify;background:white'><span lang=TR
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif;
color:black'>Sadakat programımıza üye olmanız halinde kimlik, iletişim, müşteri
işlem ve pazarlama verileriniz: </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:7.05pt;margin-bottom:
0cm;margin-left:7.1pt;text-align:justify;background:white'><span lang=TR
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:7.05pt;margin-bottom:
0cm;margin-left:7.1pt;text-align:justify;background:white'><b><i><span lang=TR
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif;
color:black'>Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması hukuki sebebine dayalı olarak;</span></i></b></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:7.05pt;margin-bottom:
0cm;margin-left:7.1pt;text-align:justify;text-indent:0cm;background:white'><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;color:black'>Sadakat programına katılımınızın sağlanması, sadakat programı üyeliğinizin oluşturulması, doğrulamaların sağlanması ve üyelik hesabınızın güvenli bir şekilde işletilmesi,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:7.05pt;margin-bottom:
0cm;margin-left:7.1pt;text-align:justify;text-indent:0cm;background:white'><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;color:black'>Sadakat programı kapsamında kazanılan puanların/sair menfaatlerin hesaplanması/tanınması ve bu kapsamda tarafınıza bilgi verilmesi,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:7.05pt;margin-bottom:
0cm;margin-left:7.1pt;text-align:justify;text-indent:0cm;background:white'><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;color:black'>Sadakat programı kapsamında indirim, kampanya ve benzeri faydalardan yararlanmanızın sağlanması ve bu kapsamda gerekli işlemlerin gerçekleştirilmesi,</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:7.05pt;margin-bottom:
0cm;margin-left:7.1pt;text-align:justify;background:white'><b><i><span lang=TR
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif;
color:black'>İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu
olması hukuki sebebine dayalı olarak;</span></i></b></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:7.05pt;margin-bottom:
0cm;margin-left:7.1pt;text-align:justify;text-indent:0cm;background:white'><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;color:black'>Sadakat
programı bağlılık oluşturma/geliştirme çalışmalarının gerçekleştirilmesi,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:7.05pt;margin-bottom:
0cm;margin-left:7.1pt;text-align:justify;text-indent:0cm;background:white'><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;color:black'>Sadakat
programı süreç ve işlemlerinin güvenliğinin sağlanması ve bu kapsamda doğrulama
adımlarının icrası, </span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:7.05pt;margin-bottom:
0cm;margin-left:7.1pt;text-align:justify;text-indent:0cm;background:white'><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;color:black'>Sadakat
programı kapsamında faydalanabileceğiniz imkanlar hakkında bilgilendirilmeniz,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:7.05pt;margin-bottom:
0cm;margin-left:7.1pt;text-align:justify;text-indent:0cm;background:white'><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;color:black'>Sadakat
programı kapsamında size sağlanan ek menfaatlere ilişkin kayıtların tutulması, </span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:7.05pt;margin-bottom:
0cm;margin-left:7.1pt;text-align:justify;text-indent:0cm;background:white'><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;color:black'>Söz konusu
olması halinde ek menfaatleri kullanabildiğiniz üçüncü taraf anlaşmalı şirket
ile kayıtların ve işlemlerin uyumlu bir şekilde
tutulmasının/gerçekleştirilmesinin sağlanması,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:7.05pt;margin-bottom:
0cm;margin-left:7.1pt;text-align:justify;text-indent:0cm;background:white'><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;color:black'>Müşteri
memnuniyeti süreçlerinin yürütülmesi,</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:7.05pt;margin-bottom:
0cm;margin-left:7.1pt;text-align:justify;background:white'><b><i><span lang=TR
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'>&nbsp;</span></i></b></p>

<h2 style='margin-top:0cm;text-align:justify;line-height:115%'><span lang=TR
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'>Açık
rızanızın varlığı halinde;</span></h2>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:5.15pt;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Kimlik, iletişim, müşteri işlem ve
pazarlama bilgileriniz; Şirketimizin ürün ve hizmetlerinin doğrudan pazarlama
süreçlerinin gerçekleştirilmesi, izniniz kapsamında </span><span lang=TR
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'>çevrimiçi
davranışsal hareketleriniz dahil olmak üzere* </span><span lang=TR
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'>profilleme
ve analiz faaliyetlerinin yürütülmesi, sunulan ürün ve hizmetlerin beğeni,
kullanım alışkanlıklarınız ve ihtiyaçlarınıza göre özelleştirerek sizlere
önerilmesi ve<span style='letter-spacing:-.1pt'> </span>tanıtılması,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:5.15pt;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Kimlik, iletişim ve müşteri işlem
bilgileriniz; kabulünüz kapsamında paylaşmış olduğunuz iletişim bilgilerinize
reklam, promosyon, vb. ticari elektronik ileti<span style='letter-spacing:-.55pt'>
</span>gönderilmesi,</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:7.05pt;margin-bottom:
0cm;margin-left:7.1pt;text-align:justify;background:white'><span lang=TR
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:7.05pt;margin-bottom:
0cm;margin-left:7.1pt;text-align:justify;background:white'><span lang=TR
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif;
color:black'>amaçları ile işlenecektir.</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:7.05pt;margin-bottom:
0cm;margin-left:7.1pt;text-align:justify;background:white'><span lang=TR
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:7.05pt;margin-bottom:
0cm;margin-left:7.1pt;text-align:justify;background:white'><span lang=TR
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif;
color:black'>*Setur’a ait internet siteleri ve mobil uygulamalardaki çevrimiçi
davranışsal hareketleriniz ile ilgilendiğiniz ürün ve hizmetlerimiz çerez
(cookie) kullanımına açık rıza vermeniz durumunda izin verdiğiniz pazarlama/analiz
amacı ile sınırlı şekilde takip edilmektedir (Bkz. </span><span lang=TR
style='color:black'><a href="https://www.setur.com.tr/kurumsal/cerez-politikasi"><span
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'>Setur
Çerez Aydınlatma Metni</span></a></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;color:black'> ve izinleri).
Açık rızanızın yanı sıra, ilgili çerezlerin (cookie) kullanımına izin vermeniz
durumunda çevrimiçi davranışsal hareketleriniz ile kişisel verileriniz ürün ve
hizmetlerimizin size özel pazarlama faaliyetleri yapılması için işlenecektir.</span></p>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-align:justify;
text-indent:0cm;line-height:115%'><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<h1 style='margin-left:36.0pt;text-align:justify;line-height:115%'><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif;
letter-spacing:-.05pt;text-decoration:none'>d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;text-decoration:none'>İşlenen
Kişisel Verilerin Kimlere ve Hangi Amaçla<span style='letter-spacing:-.3pt'> </span>Aktarılabileceği</span></h1>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:5.25pt;margin-bottom:
0cm;margin-left:5.9pt;margin-bottom:.0001pt;text-align:justify;text-indent:
0cm;line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Toplanan kişisel verileriniz; denetim faaliyetlerinin
gerçekleştirilmesi amacıyla Koç Holding Anonim Şirketi’ne, avantajlı
üyeliklerin sağlanması ve seyahatlerinizin gerçekleştirilmesi kapsamında iş
ortaklarımıza, sadakat programımıza üye olmanız ve bu kapsamda faydalanmak
istediğiniz menfaati sağlayan üçüncü bir taraf hizmet sağlayıcı olması halinde işbu
anlaşmalı şirkete, <span style='color:#333333'>seyahat sağlık sigortası
düzenlenmesi için destek sağlanması kapsamında anlaşmalı </span>sigorta<span
style='letter-spacing:-.9pt'> </span>şirketi<span style='letter-spacing:-.9pt'>
</span>Ram<span style='letter-spacing:-.75pt'> </span>Sigorta<span
style='letter-spacing:-.8pt'> </span>Aracılık<span style='letter-spacing:-.8pt'>
</span>Hizmetleri<span style='letter-spacing:-.8pt'> </span>A.Ş.’ye,<span
style='letter-spacing:-.75pt'> </span>yurt<span style='letter-spacing:-.8pt'> </span>içi<span
style='letter-spacing:-.9pt'> </span>Turizm<span style='letter-spacing:-.75pt'>
</span>Ürünleri’nden<span style='letter-spacing:-.65pt'> </span>faydalanmanız<span
style='letter-spacing:-.65pt'> </span>halinde satın alımınıza bağlı olarak
ilgili otele, Tatilbudur anlaşmalı bir otel rezervasyonu söz konusu ise
Tatilbudur Seyahat Acenteliği ve Turizm A.Ş.’ye, ilgili havayolu şirketine, tur
seyahatleri kapsamında anlaşmalı olduğumuz iş ortağı Binrota Turizm A.Ş.’ye,
Zubizu üyeliğiniz bulunması ve alışverişiniz kapsamında<span style='letter-spacing:
-.95pt'> </span>bu<span style='letter-spacing:-.95pt'> </span>üyeliğinizi<span
style='letter-spacing:-.95pt'> </span>kullanmak<span style='letter-spacing:
-.85pt'> </span>istemeniz<span style='letter-spacing:-.65pt'> </span>durumunda<span
style='letter-spacing:-.85pt'> </span>Zubizu<span style='letter-spacing:-.9pt'>
</span>programının<span style='letter-spacing:-1.05pt'> </span>sağlayıcısı<span
style='letter-spacing:-.85pt'> </span>Doğuş<span style='letter-spacing:-.85pt'>
</span>Müşteri Sistemleri A.Ş.’ye, Chippin üyeliğinizin bulunması ve
alışverişiniz kapsamında bu üyeliğinizi kullanmak istemeniz<span
style='letter-spacing:-.75pt'> </span>durumunda<span style='letter-spacing:
-.8pt'> </span>Chippin<span style='letter-spacing:-.75pt'> </span>programının<span
style='letter-spacing:-.8pt'> </span>sağlayıcısı<span style='letter-spacing:
-.6pt'> </span>Tanı<span style='letter-spacing:-.8pt'> </span>Pazarlama<span
style='letter-spacing:-.85pt'> </span>ve<span style='letter-spacing:-.8pt'> </span>İletişim<span
style='letter-spacing:-.8pt'> </span>Hizmetleri<span style='letter-spacing:
-.7pt'> </span>A.Ş.’ye,<span style='letter-spacing:-.8pt'> </span>Türk Telekom
aboneliğiniz bulunması ve alışverişiniz kapsamında bu aboneliğinizi kullanmak
istemeniz durumunda Türk Telekomünikasyon A.Ş, TTNET A.Ş ve TT Mobil İletişim
Hizmetleri A.Ş.’ye, Hopi üyeliğinizin bulunması ve alışverişiniz kapsamında bu
üyeliğinizi kullanmak istemeniz durumunda Hopi programının<span
style='letter-spacing:-.45pt'> </span>sağlayıcısı<span style='letter-spacing:
-.4pt'> </span>BNR<span style='letter-spacing:-.5pt'> </span>Teknoloji<span
style='letter-spacing:-.45pt'> </span>A.Ş.’ye,<span style='letter-spacing:-.25pt'>
</span>anlaşmalı<span style='letter-spacing:-.4pt'> </span>olduğumuz<span
style='letter-spacing:-.35pt'> </span>tedarikçilerimize,<span style='letter-spacing:
-.45pt'> </span>hissedarlarımıza, kanunlarda açıkça öngörülmesi ve hukuki
yükümlülüklerimizin yerine getirilmesi kapsamında kanunen yetkili kamu
kurumlarına ve kanunen yetkili özel kişilere Kanun’un 8. Maddesinde belirtilen
kişisel veri işleme şartları ve amaçları çerçevesinde<span style='letter-spacing:
.05pt'> </span>aktarılabilecektir.</span></p>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-align:justify;
text-indent:0cm;line-height:115%'><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:5.9pt;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Açık rızanızı vermeniz halinde,</span></p>

<h1 style='margin-top:0cm;margin-right:5.1pt;margin-bottom:0cm;margin-left:
42.55pt;margin-bottom:.0001pt;text-align:justify;text-indent:-21.25pt;
line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:Symbol;font-weight:normal;text-decoration:none'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>Yurt dışındaki bir
tarafça sağlanan tur, seyahat ve tatil ürün ve hizmetlerinden (&quot;Yurt Dışı
Turizm Ürünleri”) faydalanmanız halinde:</span><span lang=TR style='font-size:
12.0pt;line-height:115%;font-family:"Times New Roman",serif;text-decoration:
none'> </span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif;font-weight:normal;text-decoration:none'>Kimlik,
iletişim ve müşteri işlem verileriniz, ilgili Yurt Dışı Turizm Ürünleri’nden
faydalanmanızın sağlanması amacıyla aşağıda detaylandırıldığı şekilde satın
almayı talep ettiğiniz ürün özelinde hizmet alacağınız yurt dışındaki ilgili 3.
kişi/ kişiler</span></h1>

<h1 style='margin-top:0cm;margin-right:5.1pt;margin-bottom:0cm;margin-left:
84.95pt;margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt;
line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Courier New";font-weight:normal;text-decoration:none'>o<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span dir=LTR></span><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif;
font-weight:normal;text-decoration:none'>Yurt Dışı Turizm Ürünleri’nden Expedia
anlaşmalı otel rezervasyonu yapılması halinde yurt dışında mukim Expedia Group,
Inc. ile;</span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'> </span></h1>

<h1 style='margin-top:0cm;margin-right:5.1pt;margin-bottom:0cm;margin-left:
84.95pt;margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt;
line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Courier New";font-weight:normal;text-decoration:none'>o<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span dir=LTR></span><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif;
font-weight:normal;text-decoration:none'>Yurt Dışı Turizm Ürünleri’nden yurt
dışı otel rezervasyonu yapılması halinde satın alımınıza konu yurt dışında
mukim otel ile;</span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'> </span></h1>

<h1 style='margin-top:0cm;margin-right:5.1pt;margin-bottom:0cm;margin-left:
84.95pt;margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt;
line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Courier New";font-weight:normal;text-decoration:none'>o<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span dir=LTR></span><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif;
font-weight:normal;text-decoration:none'>Yurt Dışı Turizm Ürünleri’nden uçak
bileti alımı yapılması halinde, yurt dışında mukim Amadeus IT Group SA ile;</span></h1>

<h1 style='margin-top:0cm;margin-right:5.1pt;margin-bottom:0cm;margin-left:
84.95pt;margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt;
line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Courier New";font-weight:normal;text-decoration:none'>o<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span dir=LTR></span><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif;
font-weight:normal;text-decoration:none'>Yurt Dışı Turizm Ürünleri’nden tur
alımı yapılması halinde, satın alımınıza konu yurt dışında mukim otel, havayolu
şirketi ve tur şirketi ile.</span></h1>

<h1 style='margin-right:5.1pt;text-align:justify;line-height:115%'><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol;font-weight:
normal;text-decoration:none'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>Şirketimizin ürün ve
hizmetlerinin doğrudan pazarlama süreçlerinin gerçekleştirilmesi, profilleme ve
analiz faaliyetlerinin yürütülmesi, sunulan ürün ve hizmetlerin<span
style='letter-spacing:.15pt'> </span>beğeni, kullanım alışkanlıklarınız ve
ihtiyaçlarınıza göre özelleştirerek sizlere önerilmesi ve<span
style='letter-spacing:-2.5pt'> </span>tanıtılması<span style='letter-spacing:
-.55pt'> </span>amaçları<span style='letter-spacing:-.5pt'> </span>için<span
style='letter-spacing:-.45pt'> </span>açık<span style='letter-spacing:-.5pt'> </span>rızanız<span
style='letter-spacing:-.45pt'> </span>bulunuyorsa:<span style='letter-spacing:
-.5pt'> </span></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif;font-weight:normal;text-decoration:none'>Kimlik,<span
style='letter-spacing:-.5pt'> </span>iletişim,<span style='letter-spacing:-.5pt'>
</span>müşteri<span style='letter-spacing:-.45pt'> </span>işlem<span
style='letter-spacing:-.3pt'> </span>ve<span style='letter-spacing:-.5pt'> </span>pazarlama
bilgileriniz,<span style='letter-spacing:-.45pt'> </span>Şirketimizin<span
style='letter-spacing:-.6pt'> </span>ürün<span style='letter-spacing:-.55pt'> </span>ve<span
style='letter-spacing:-.5pt'> </span>hizmetlerinin<span style='letter-spacing:
-.3pt'> </span>doğrudan<span style='letter-spacing:-.5pt'> </span>pazarlama<span
style='letter-spacing:-.45pt'> </span>süreçlerinin<span style='letter-spacing:
-.5pt'> </span>gerçekleştirilmesi, profilleme ve analiz faaliyetlerinin yürütülmesi,
sunulan ürün ve hizmetlerin beğeni, kullanım alışkanlıklarınız ve
ihtiyaçlarınıza göre özelleştirerek sizlere önerilmesi ve tanıtılması amacıyla
barındırma hizmetini aldığımız ve sunucuları yurt dışında bulunan tedarikçimiz
SAP Türkiye Yazılım Üretim ve Tic.<span style='letter-spacing:.05pt'> </span>A.Ş.
ile;</span></h1>

<h1 style='margin-right:5.1pt;text-align:justify;line-height:115%'><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol;font-weight:
normal;text-decoration:none'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;letter-spacing:-2.5pt;
font-weight:normal;text-decoration:none'> </span><span lang=TR
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'>Paylaşmış<span
style='letter-spacing:1.55pt'> </span>olduğunuz<span style='letter-spacing:
1.6pt'> </span>iletişim<span style='letter-spacing:1.65pt'> </span>bilgilerinize<span
style='letter-spacing:1.55pt'> </span>reklam,<span style='letter-spacing:1.7pt'>
</span>promosyon,<span style='letter-spacing:1.55pt'> </span>vb.<span
style='letter-spacing:1.65pt'> </span>Ticari<span style='letter-spacing:1.55pt'>
</span>elektronik<span style='letter-spacing:1.6pt'> </span>ileti gönderilmesi
amacı için açık rızanız bulunuyorsa: </span><span lang=TR style='font-size:
12.0pt;line-height:115%;font-family:"Times New Roman",serif;font-weight:normal;
text-decoration:none'>İletişim verilerinizin, onayınız kapsamında paylaşmış
olduğunuz iletişim bilgilerinize reklam, promosyon, vb. Ticari elektronik ileti
gönderilmesi amacıyla altyapı hizmeti aldığımız ve yurt dışında bulunan
tedarikçimiz Amazon AWS EMEA<span style='letter-spacing:.05pt'> </span>SARL
ile;</span></h1>

<h1 style='margin-right:5.1pt;text-align:justify;line-height:115%'><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol;font-weight:
normal;text-decoration:none'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;letter-spacing:-2.5pt'> </span><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'>Sadakat
Programı üyeliği oluşturmanız halinde: </span><span lang=TR style='font-size:
12.0pt;line-height:115%;font-family:"Times New Roman",serif;font-weight:normal;
text-decoration:none'>Kimlik, iletişim ve müşteri işlem bilgileriniz <span
style='color:#333333'>ticari faaliyetlerin gerçekleştirilmesi için iş
birimlerimizin gerekli çalışmaları ve bağlı iş süreçlerini gerçekleştirmesi</span>
amacıyla altyapı hizmeti aldığımız ve yurt dışında bulunan tedarikçimiz
Microsoft Ireland Operations LTD<span style='letter-spacing:.15pt'> </span>ile,</span></h1>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:5.9pt;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:5.9pt;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Kanun’un 9. Maddesinde belirtilen kişisel
veri işleme şartları çerçevesinde paylaşılabilecektir.</span></p>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-align:justify;
text-indent:0cm;line-height:115%'><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<h1 style='margin-left:0cm;text-align:justify;text-indent:0cm;line-height:115%'><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif;
letter-spacing:-.05pt;text-decoration:none'>e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;text-decoration:none'>Kişisel
Veri Toplamanın Yöntemi</span></h1>

<h1 style='margin-left:0cm;text-align:justify;text-indent:0cm;line-height:115%'><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'><span
 style='text-decoration:none'>&nbsp;</span></span></h1>

<p class=MsoBodyText style='margin-top:0cm;margin-right:5.15pt;margin-bottom:
0cm;margin-left:5.9pt;margin-bottom:.0001pt;text-align:justify;text-indent:
0cm;line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Kişisel verileriniz Şirket ile aranızda
var olan iş ilişkisinin yürütülmesi ve yukarıda yer verilen Amaçlar’ın yerine
getirilmesi kapsamında Şirketimiz ile temas edeceğiniz; fiziki ortamda
mağazalar, şubelerimiz, kargo/posta, matbu formlar ve yüz yüze gerçekleştirilen
görüşme kanalları ve acentelerimiz aracılığıyla, elektronik ortamda anlaşmalı
iş ortağımız olan rezervasyon şirketlerinin internet siteleri, internet
sitemiz, mobil uygulamamız, Şirket içi sistemlerimiz, telefon, çağrı merkezi ve
e-posta kanalıyla<span style='letter-spacing:-.7pt'> </span>toplanmaktadır.
Sadakat programımıza üye olmanız halinde sadakat programı kapsamında iş birliği
içerisinde olunan ortaklar kanalıyla da kişisel verileriniz elde
edilebilecektir. </span></p>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-align:justify;
text-indent:0cm;line-height:115%'><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<h1 style='margin-left:36.0pt;text-align:justify;line-height:115%'><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif;
letter-spacing:-.05pt;text-decoration:none'>f)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;text-decoration:none'>İlgili
Kişinin Kanun’un 11. maddesinde Sayılan<span style='letter-spacing:.15pt'> </span>Hakları</span></h1>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-align:justify;
text-indent:0cm;line-height:115%'><b><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:5.9pt;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Kişisel verileriniz ile ilgili olarak
Kanun’un 11. maddesi uyarınca aşağıdaki haklara sahip olduğunuzu bildiririz:</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Kişisel verilerinizin işlenip
işlenmediğini<span style='letter-spacing:-.2pt'> </span>öğrenme,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Kişisel verileriniz işlenmişse buna
ilişkin bilgi talep<span style='letter-spacing:-.2pt'> </span>etme,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Kişisel<span style='letter-spacing:-.3pt'>
</span>verilerinizin<span style='letter-spacing:-.25pt'> </span>işlenme<span
style='letter-spacing:-.15pt'> </span>amacını<span style='letter-spacing:-.2pt'>
</span>ve<span style='letter-spacing:-.25pt'> </span>bunların<span
style='letter-spacing:-.25pt'> </span>amacına<span style='letter-spacing:-.25pt'>
</span>uygun<span style='letter-spacing:-.3pt'> </span>kullanılıp<span
style='letter-spacing:-.25pt'> </span>kullanılmadığını<span style='letter-spacing:
-.15pt'> </span>öğrenme,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Yurt içinde veya yurt dışında kişisel
verilerinizin aktarıldığı üçüncü kişileri<span style='letter-spacing:-.6pt'> </span>bilme,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Kişisel verilerinizin eksik veya yanlış
işlenmiş olması halinde bunların düzeltilmesini isteme ve bu kapsamda yapılan
işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini<span
style='letter-spacing:-1.4pt'> </span>isteme,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Kanun ve ilgili diğer kanun hükümlerine
uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin
ortadan kalkması halinde kişisel verilerinizin silinmesini veya yok edilmesini
isteme ve bu kapsamda ve kişisel verilerinizin eksik veya yanlış işlenmiş
olması halinde yapılan işlemlerin kişisel verilerinizin aktarıldığı üçüncü
kişilere bildirilmesini<span style='letter-spacing:-.4pt'> </span>isteme,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>İşlenen<span style='letter-spacing:-.75pt'>
</span>verilerinizin<span style='letter-spacing:-.75pt'> </span>münhasıran<span
style='letter-spacing:-.7pt'> </span>otomatik<span style='letter-spacing:-.65pt'>
</span>sistemler<span style='letter-spacing:-.7pt'> </span>vasıtasıyla<span
style='letter-spacing:-.7pt'> </span>analiz<span style='letter-spacing:-.65pt'>
</span>edilmesi<span style='letter-spacing:-.75pt'> </span>suretiyle<span
style='letter-spacing:-.7pt'> </span>kişinin<span style='letter-spacing:-.75pt'>
</span>kendisi aleyhine bir sonucun ortaya çıkmasına itiraz<span
style='letter-spacing:.15pt'> </span>etme,</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;text-align:justify;text-autospace:
none'><span lang=TR style='font-size:12.0pt;line-height:115%;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
dir=LTR></span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Kişisel verilerinizin kanuna aykırı olarak
işlenmesi sebebiyle zarara uğramanız halinde zararın giderilmesini talep<span
style='letter-spacing:-.1pt'> </span>etme.</span></p>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-align:justify;
text-indent:0cm;line-height:115%'><span lang=TR style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:5.3pt;margin-bottom:
0cm;margin-left:5.9pt;margin-bottom:.0001pt;text-align:justify;text-indent:
0cm;line-height:115%'><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>Yukarıda sıralanan haklarınıza yönelik
başvurularınızı, </span><span lang=TR><a
href="https://kvkk.setur.com.tr/izin-iptal"><span style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;color:blue'>https://kvkk.setur.com.tr/izin-iptal</span></a></span><span
lang=TR style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif;
color:blue'> </span><span lang=TR style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'>adresinden ulaşabileceğiniz İlgili Kişi
Başvuru Formu’nu doldurarak Şirketimize iletebilirsiniz. Talebinizin niteliğine
göre en kısa sürede ve en geç otuz gün içinde başvurularınız ücretsiz olarak
sonuçlandırılacaktır; ancak işlemin ayrıca bir maliyet gerektirmesi halinde
Kişisel Verileri Koruma Kurulu tarafından belirlenecek tarifeye göre
tarafınızdan ücret talep edilebilecektir.</span></p>

</div>
</div>

</body>

</html>


`
