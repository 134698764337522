import React, {Component} from 'react'
import {withRouter} from 'react-router'

class SedviceSearchComponent extends Component<any, ISedviceSearchState> {
	constructor(props: ISedviceSearchProp) {
		super(props)
		this.state = {
			sedvicesearch: ''
		}

		this.sedvicesearch = this.sedvicesearch.bind(this)
		this.sedvicesearchEnter = this.sedvicesearchEnter.bind(this)
	}

	sedvicesearch(e: any) {
		e.stopPropagation()
		this.props.history.push(`/sedvice/${encodeURI(this.state.sedvicesearch)}`)
		this.setState({
			sedvicesearch: ''
		})
		e.preventDefault()
	}

	sedvicesearchEnter(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.which === 13) {
			this.sedvicesearch(e)
		}
	}

	componentDidMount() {}

	render() {
		return (
			<div className="overlay sedvice-overlay">
				<div className="sedvice-area" id="sedvice-modal">
					<div className="sedvice-close-area">
						<div className="sedvice-close-icon"></div>
					</div>
					<div className="sedvice-img"></div>
					<div className="sedvice-text">
						<p>Bir Bilene Sor </p>
						<h5 className="sed-sub">
							Gideceğiniz şehri yazın, o şehri daha önce gezen ve iyi bilen
							kullanıcılardan tavsiyeler alın.
						</h5>
					</div>
					<div className="sedvice-input-area">
						<div className="sedvice-input-in">
							<div id="sedForm">
								<div className="sedvice-input-area-left">
									<input
										className="sedviceautocomplete"
										onKeyPress={this.sedvicesearchEnter}
										id="sedviceautocomplete"
										name="sedviceautocomplete"
										placeholder="Tavsiye almak için şehir yazın"
										onChange={e =>
											this.setState({sedvicesearch: e.target.value})
										}
										value={this.state.sedvicesearch}
										type="text"
									/>
								</div>
								<div className="sedvice-input-area-right">
									<div className="sedvice-input">
										<a
											onClick={e => this.sedvicesearch(e)}
											className="sedvice-next">
											<img src="content\img\right-arrow.png" />
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(SedviceSearchComponent as any)

interface ISedviceSearchProp {}

interface ISedviceSearchState {
	sedvicesearch: string
}
