import React, { Component } from 'react'
import ServiceAdvice from '../services/ServiceAdvice'
import RequiredLoginComponent from '../components/RequiredLogin'
import Helmet from 'react-helmet'
import Seo from '../helper/seo'
import Const from '../const'
import { inject, observer } from 'mobx-react'
import { RouteComponentProps } from 'react-router'
import { observable, observe } from 'mobx'
import ChatBox from '../components/ChatBox';

@inject('loginStore')
@observer
export default class SedviceComponent extends Component<ISedviceProp, ISedviceState> {
	lastCity: string = ''
	constructor(props: ISedviceProp) {
		super(props)
		this.state = {
			sedvice: [],
			userInfo: {},
			socketMessages: []
		}
		const city = this.props.match.params.city
		this.getAdvice(city)
		observe(this.props.loginStore.user, (change: any) => {
			if (change.newValue.Id) {
				this.getAdvice(this.props.match.params.city)
				$('.error-overlay.for-like-error').removeClass('active')
			} else {
				this.setState({ sedvice: [] })
				$('.error-overlay.for-like-error').addClass('active')
			}
		})
	}

	getAdvice(city: string) {
		ServiceAdvice.sedvice(city).then((res: any) => {
			this.setState({
				sedvice: res.data
			})
			$('div.overlay.sedvice-overlay').click()
		}).catch(res => {
			if (res && res.response && res.response.status === 401) {
				$('.error-overlay.for-like-error').addClass('active')
			}
		})
	}

	componentDidMount() {
		$('.transition-first').delay(400).queue(function (n) {
			$(this).addClass('active')
			n()
		});
		$('div.sedvice-circle').removeClass('active')
		$('div.sedvice-overlay').removeClass('active')
		$('div.sedvice-circle').removeClass('active')
		$('#sedvice-modal').removeClass('active')
		$('body').removeClass('pfix-sb')

		$('.transition-first').on('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
			$('.first-right').on('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
				$('.first-right-in .loading-middle').addClass('active')
				$('.first-right-in .loading-middle').on('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
					$('.first-right-in .loading-middle').addClass('bubble')
				})
				$('.first-right-in .loading-middle.active.bubble').on('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
					$('.loading-middle .bubble-a').addClass('active')
					$('.loading-middle .loading-ripple').addClass('active')
					$('.middle-title-area-anim').addClass('active')
					$('.first-right-in').removeClass('transiton')
					$('.first-right-in').addClass('zoom')
					// $('body').removeClass('position-fixed')
					// $('.first-overlay').removeClass('position-fixed')
				})
			})
		})
	}


	componentDidUpdate() {
		const city = this.props.match.params.city
		if (city != this.lastCity) {
			this.getAdvice(city)
			this.lastCity = city
		}
	}

	handleClick = (e: React.MouseEvent, item: IAdvice) => {
		e.preventDefault();
		try {
			this.props.loginStore.connection.off("ReceiveMessage");
		} catch (error) {
			console.log(error);
		}
		this.setState({
			userInfo: {
				...item,
				RecieverId: item.Id,
				PictureUrl: item.Resim
			},
			socketMessages: [],
		});
		this.syncMessage();
	}

	syncMessage = () => {
		try {
			this.props.loginStore.connection.on("ReceiveMessage", (senderId: number, message: string, id: number, receiverId: number) => {
				this.updateList(senderId, message, id, receiverId);
			})
		} catch (error) {
			console.log(error);
		}
	}
	resetUserInfo = () => {
		this.setState({
			userInfo: {},
			socketMessages: [],
		});
		try {
			this.props.loginStore.connection.off("ReceiveMessage");
		} catch (error) {
			console.log(error);
		}
	}

	updateList = (senderId: number, message: string, id: number, receiverId: number) => {
		let messages = this.state.socketMessages
		messages.push({
			Id: id,
			GonderenId: senderId,
			AliciId: receiverId,
			Mesaj: message,
		})
		this.setState({
			socketMessages: messages,
		});
	}

	render() {
		const { userInfo, socketMessages } = this.state;
		const { loginStore } = this.props;

		return <>
			<div className="first-right-active">
				<Helmet>
					<title>{Seo.getTitle('')}</title>
				</Helmet>
				<RequiredLoginComponent title="Bir Bilene Sormak için Giriş Yap, üye değil misin?" onClose={() => this.props.history.push('/')} />
				<div className="first-right-in bg-fixed sedvice-hero zoom" style={{ backgroundImage: 'url(/content/img/sedvice-bg-2.png)' }}>
					<div className="transition-first"></div>
					<div className="main-title-area middle-title-area-anim">
						<div className="main-title">
							<h1>{this.props.match.params.city}</h1>
						</div>
					</div>
					<div className="loading loading-middle">
						<a href="#layout" className="bubble-a">
							<img src="/Content/img/scroll-icon.png" />
						</a>
						<div className="loading-ripple"></div>
					</div>
					<div className="gradient-bg"></div>
				</div>
				<div className="first-right-content">

					<div className="sedvice-wrap">

						<div className="sedvice-wrap-in clearfix">
							{
								this.state.sedvice && this.state.sedvice.map((item: IAdvice, i: number) => {
									return <div id="1195" key={`author_${i}`} className="sedvice-profile-block">
										<div className="sedvice-profile-left">
											<div className="sedvice-profile-image">
												<img src={`${Const.seturdayCdnAuthorUrl}${item.Resim}`} />
											</div>
											<div className="sedvice-profile-name">
												<p>{item.Name} {item.Surname}</p>
												<p className="sedvice-int">{item.Ilgialanlari}</p>
											</div>
										</div>
										<div className="sedvice-profile-right">
											<a href="#" onClick={(e) => this.handleClick(e, item)} className="sed-link">Tavsiye Al</a>
										</div>
									</div>
								})
							}

						</div>
					</div>
				</div>
			</div>
			{this.props.loginStore.user.Id && <ChatBox userInfo={userInfo} socketMessages={socketMessages} resetUserInfo={this.resetUserInfo} loginStore={loginStore} />}
		</>
	}
}

interface ISedviceRouteProp {
	city: string
}

interface ISedviceProp extends RouteComponentProps<ISedviceRouteProp> {
	loginStore: ILoginStore
}

interface ISedviceState {
	sedvice: IAdvice[]
	userInfo: any
	socketMessages: any
}