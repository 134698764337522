import HttpClient from '../helper/http'
import {AxiosResponse} from 'axios'

export default class ServiceCategory {
	static getById(id: number) {
		return HttpClient.get<ICategory>(`/api/Category/getById?id=${id}`)
	}
	static getByName(url: string) {
		return HttpClient.get<ICategory>(`/api/Category/getByName?name=${url}`)
	}
	static categories() {
		const categories = sessionStorage.getItem('categories')
		if (categories) {
			return new Promise<AxiosResponse<ICategory[]>>((res, rej) => {
				res({
					data: JSON.parse(categories)
				} as AxiosResponse<ICategory[]>)
			})
		}
		const res = HttpClient.get<ICategory[]>(`/api/Category/categories`)
		res.then(res => {
			sessionStorage.setItem('categories', JSON.stringify(res.data))
		})
		return res
	}
}
