import React from 'react'
import './ArticleComponent.css'
import {RouteComponentProps} from 'react-router'
import ServiceArticle from '../../services/ServiceArticle'
import ServiceCategory from '../../services/ServiceCategory'
import ServiceUser from '../../services/ServiceUser'
import Const from '../../const'
import {Link} from 'react-router-dom'
import LayoutField5Component from './LayoutField5Component'
import Layout3Field4Component from './Layout3Field4Component'
import Layout3Field1Component from './Layout3Field1Component'
import Layout3Field2Component from './Layout3Field2Component'
import Layout3Field3Component from './Layout3Field3Component'
import Layout3SliderComponent from './Layout3SliderComponent'
import Layout2Field1Component from './Layout2Field1Component'
import Layout2Field2Component from './Layout2Field2Component'
import Layout2Field3Component from './Layout2Field3Component'
import Layout2SliderComponent from './Layout2SliderComponent'
import Layout1Field1Component from './Layout1Field1Component'
import Layout1Field2Component from './Layout1Field2Component'
import Layout1Field3Component from './Layout1Field3Component'
import Layout1Field4Component from './Layout1Field4Component'
import Layout1Field5Component from './Layout1Field5Component'
import Helmet from 'react-helmet'
import {times, groupBy} from 'lodash'
import Seo from '../../helper/seo'
import {inject, observer} from 'mobx-react'
import ServiceComment from '../../services/ServiceComment'
import RequiredLoginComponent from '../../components/RequiredLogin'
import CommentsComponent from './CommentsComponent'
import indexOf from 'lodash/indexOf'

@inject('loginStore')
@observer
export default class ArticleComponent extends React.Component<
	IArticleProp,
	IArticleState
> {
	articleUrl: string = ''

	constructor(props: any) {
		super(props)

		this.reset()
		this.getData()
		this.btnComment = this.btnComment.bind(this)
	}

	getData(articleUrl?: string) {
		this.articleUrl = articleUrl || this.props.match.params.articleUrl

		if (!this.articleUrl) return

		ServiceArticle.getSetTalksArticle(this.articleUrl).then((res: any) => {
			this.setState({
				article: res.data
			})
			setTimeout (() => this.sliderInit(), 500)
		})
	}

	reset() {
		this.state = {
			article: {} as ISetTalksLayout,
			comment: '',
			commentCount: 0
		}
	}

	componentWillMount() {
		$('.top-menu').removeClass('active-profile')
	}

	componentWillUnmount() {
		$('.top-menu').addClass('active-profile')
	}

	getCoverStyle() {
		var obj: any = {}
		obj.backgroundImage = this.state.article.Info ? `url(${Const.seturdayCdnBuckerImageUrl(this.state.article.Info.SoylesiResim)})` : 'url()'
		obj.backgroundPositionY = 0
		return obj
	}

	componentDidMount() {
		$('.transition-first')
		.delay(400)
		.queue(function(n) {
			$(this).addClass('active')
			n()
		})

	window.scrollTo(0, 0)
	
		$('.no-log-notactive').on('click', () => {
			$('#error-overlay-active').addClass('active')
		})
		$('.no-log').on('click', () => {
			$('.error-overlay').addClass('active')
		})

		$('.transition-first').on(
			'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
			function() {
				$('.first-right').on(
					'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
					function() {
						$('.first-right-in .loading-middle').addClass('active')
						$('.first-right-in .loading-middle').on(
							'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
							function() {
								$('.first-right-in .loading-middle').addClass('bubble')
							}
						)
						$('.first-right-in .loading-middle.bubble').on(
							'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
							function() {
								$('.loading-middle .bubble-a').addClass('active')
								$('.loading-middle .loading-ripple').addClass('active')
								$('.middle-title-area-anim').addClass('active')
								$('.first-right-in').removeClass('transiton')
								$('.first-right-in').addClass('zoom')
								// $('body').removeClass('position-fixed')
								// $('.first-overlay').removeClass('position-fixed')
							}
						)
					}
				)
			}
		)

		$('.top-menu').removeClass('active-profile')

		var windowWidth = $(window).width() as number

		$('.social-share').jsSocials({
			showLabel: false,
			showCount: false,
			shares: ['email', 'twitter', 'facebook', 'whatsapp']
		})

		let timer: any
		if (windowWidth <= 1024) {
			$('.share-button-area').on('mouseenter', function() {
				timer = setTimeout(function() {
					$('.jssocials-share').animate(
						{
							marginLeft: '5px',
							marginRight: '5px',
							opacity: 1
						},
						100
					)
					$('.share-button').fadeOut(350)
				}, 200)
			})
			$('.share-button-area').on('mouseleave', function() {
				setTimeout(function() {
					$('.jssocials-share').animate(
						{
							marginLeft: '5px',
							marginRight: '5px',
							opacity: 1
						},
						100
					)
					$('.share-button').fadeIn(200)
				}, 200)
				clearTimeout(timer)
			})
		} else {
			$('.share-button-area').on('mouseenter', function() {
				timer = setTimeout(function() {
					$('.jssocials-share').animate(
						{
							marginLeft: '0px',
							marginRight: '5px',
							opacity: 1
						},
						100
					)
					$('.share-button').fadeOut(350)
				}, 200)
			})
			$('.share-button-area').on('mouseleave', function() {
				setTimeout(function() {
					$('.jssocials-share').animate(
						{
							marginLeft: '35px',
							marginRight: '-45px',
							opacity: 0
						},
						100
					)
					$('.share-button').fadeIn(200)
				}, 200)
				clearTimeout(timer)
			})
			$('.jssocials-share-link .jssocials-share-logo').addClass('fa-2x')
			$(
				'.jssocials-share-whatsapp .jssocials-share-link .jssocials-share-logo'
			).removeClass('fa-whatsapp')
			$(
				'.jssocials-share-whatsapp .jssocials-share-link .jssocials-share-logo'
			).removeClass('fab')
			$(
				'.jssocials-share-whatsapp .jssocials-share-link .jssocials-share-logo'
			).addClass('far')
			$(
				'.jssocials-share-whatsapp .jssocials-share-link .jssocials-share-logo'
			).addClass('fa-copy')
		}
		$(
			'.jssocials-share-whatsapp .jssocials-share-link .jssocials-share-logo.fa-copy'
		).on('click', () => {
			this.copyLink()
		})
		$(window).resize(function() {
			if (windowWidth <= 1024) {
				$('.share-button-area').on('mouseenter', function() {
					timer = setTimeout(function() {
						$('.jssocials-share').animate(
							{
								marginLeft: '5px',
								marginRight: '5px',
								opacity: 1
							},
							100
						)
						$('.share-button').fadeOut(350)
					}, 200)
				})
				$('.share-button-area').on('mouseleave', function() {
					setTimeout(function() {
						$('.jssocials-share').animate(
							{
								marginLeft: '5px',
								marginRight: '5px',
								opacity: 1
							},
							100
						)
						$('.share-button').fadeIn(200)
					}, 200)
					clearTimeout(timer)
				})
			} else {
				$('.share-button-area').on('mouseenter', function() {
					timer = setTimeout(function() {
						$('.jssocials-share').animate(
							{
								marginLeft: '0px',
								marginRight: '5px',
								opacity: 1
							},
							100
						)
						$('.share-button').fadeOut(350)
					}, 200)
				})
				$('.share-button-area').on('mouseleave', function() {
					setTimeout(function() {
						$('.jssocials-share').animate(
							{
								marginLeft: '35px',
								marginRight: '-45px',
								opacity: 0
							},
							100
						)
						$('.share-button').fadeIn(200)
					}, 200)
					clearTimeout(timer)
				})
			}
		})
	}

	copyLink() {
		var t = window.location.href,
			n = document.getElementById('get-url-input') as HTMLInputElement
		n.value = t
		n.select()
		document.execCommand('copy')
		$(
			'.jssocials-share-whatsapp .jssocials-share-link .jssocials-share-logo.fa-copy'
		).prepend('<p class="copied-text">Link Kopyalandı</p>')
		$('.copied-text').animate(
			{
				top: '-35px',
				opacity: 1,
				fontSize: '11px'
			},
			500,
			function() {
				$('.copied-text')
					.delay(250)
					.animate(
						{
							top: '-5px',
							opacity: 0,
							fontSize: '7px'
						},
						500,
						function() {
							$('.copied-text')
								.delay(300)
								.remove()
						}
					)
			}
		)
	}

	sliderInit() {
		new Swiper('.soylesi', {
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			pagination: {
				el: '.swiper-pagination'
			}
		})
	}

	scrollToArticleBody(e: React.MouseEvent<any>) {
		$('html, body').animate({
			scrollTop: $('#layout').offset().top - 50
		})
		e.preventDefault()
	}

	btnComment(e: React.MouseEvent<HTMLInputElement>) {
		e.preventDefault()
		if (
			this.props.loginStore.user.Id > 0 &&
			this.state.comment &&
			this.state.comment.trim()
		) {
			ServiceComment.add(
				{
					Yorum: this.state.comment,
					MakaleId: this.props.match.params.id,
					Yayinla: true,
					Aktif: true
				},
				true
			).then(() => {
				this.setState(
					{
						comment: ''
					},
					() => {
						$('.comment-moderation').addClass('active')
						setTimeout(
							() => $('.comment-moderation').removeClass('active'),
							3e3
						)
					}
				)
			})
		}
	}

	componentWillReceiveProps(nextProps: IArticleProp) {
		if (this.articleUrl != nextProps.match.params.articleUrl) {
			this.getData(nextProps.match.params.articleUrl)
			$('html').animate(
				{
					scrollTop: 0
				},
				'slow'
			)
		}
	}

	like(articleId: number) {
		ServiceArticle.likeAnArticle(articleId, true).then(res => {
			this.setState({
				article: {
					...this.state.article,
					isLiked: !this.state.article.isLiked,
					Info: {
						...this.state.article.Info,
						TotalLikeCount: res.data,
					}
					
				}
			})
			$('.like-p').text(res.data)
		})
	}

	render() {
		const {Info, Details, Sliders, isLiked} = this.state.article

		const bgStyle = this.getCoverStyle()
		const groupedSlider = groupBy(Sliders, 'OrderNo')
		return (
			<React.Fragment>
				{Info && Info.Baslik && (
					<Helmet>
						<title>{Seo.getTitle(Info.Baslik)}</title>
						<meta name="keywords" content={Info.Baslik} />
						<meta property="og:type" content="article" />
						<meta property="og:title" content={Info.Baslik} />
						<meta
							property="og:image"
							content={`${Const.seturdayCdnArticleCover}${Info.SoylesiResim}`}
						/>
						<meta
							property="article:published_time"
							content={Info.SoylesiTarih}
						/>					
					</Helmet>
				)}
				<div className="first-right-in bg-fixed transiton" style={bgStyle}>
					<div className="transition-first"></div>
					<div className="main-title-area middle-title-area-anim">
						<div className="main-tag">
							<Link to="/set-talks">SÖYLEŞİ</Link>
						</div>
						<div className="main-title">
							<h1>{Info && Info.Baslik}</h1>
						</div>
					</div>
					<div className="loading loading-middle">
						<a href="#" onClick={this.scrollToArticleBody} className="bubble-a">
							<img
								src="..\Content\img\scroll-icon.png"
								alt={Info && Info.Baslik}
								title={Info && Info.Baslik}
							/>
						</a>
						<div className="loading-ripple"></div>
					</div>
					<div className="gradient-bg"></div>
				</div>
				<div className="first-right-content">
					<div className="wrap-in">
						{Info && Info.EmbeedVar && Info.EmbeedAlan && (
							<div className="iframe-block" dangerouslySetInnerHTML={{
								__html: Info.EmbeedAlan
							}} />
						)}

						{Details &&
							Details.map((detail: any, index: number) => (
								<React.Fragment key={detail.Id}>
									{groupedSlider[index] && (
										<div className="soylesi-slider">
											<div className="swiper-container soylesi">
												<div className="swiper-wrapper">
													{groupedSlider[index].map(
														(slider: any) =>
															slider.Aktif && (
																<div
																	key={slider.Id}
																	className="swiper-slide"
																	style={{
																		background: `url(https://cdn.seturday.com/content/uploads/soylesi_resimleri/slider/${slider.Resim})`
																	}}></div>
															)
													)}
												</div>
												<div className="swiper-pagination swiper-pagination-white"></div>
												<div className="swiper-button-next swiper-button-white"></div>
												<div className="swiper-button-prev swiper-button-white"></div>
											</div>
										</div>
									)}
									{detail.Header === '' && (
										<div className="left-line">
											<p>{detail.Text}</p>
										</div>
									)}
									{detail.Header !== '' && (
										<>
											<h5 className="soylesi-title">{detail.Header}</h5>
											<p className="soylesi-text">{detail.Text}</p>
										</>
									)}
								</React.Fragment>
							))}

						<div className="comment-info-area soylesi-comment clearfix">
							<div className="share-button-area">
								<div className="share-button">
									<p>
										<img src="..\Content\img\share-icon.png" />
									</p>
								</div>
								<div className="social-share clearfix"></div>
								<input type="text" id="get-url-input" />
							</div>
							<div className="comment-like-area">
								<div
									className={`grid-like ${
										(this.props.loginStore.user.Id as number) > 0
											? isLiked
												? 'user-like liked'
												: ''
											: 'no-log'
									}`} // user-like liked
									onClick={e =>
										(this.props.loginStore.user.Id as number) > 0
											? this.like(Info.Id)
											: e.preventDefault()
									}>
									<a className="like-button">
										<span className="like-icon">
											<div className="heart-animation-1"></div>
											<div className="heart-animation-2"></div>
										</span>
									</a>
								</div>
								<p className="like-p">{Info && Info.TotalLikeCount}</p>
								<p className="comment-p">{this.state.commentCount}</p>
							</div>
						</div>
						<RequiredLoginComponent />
						<div className="comment-area">
							<CommentsComponent
								onChangeModel={model =>
									this.setState({commentCount: model.TotalCount})
								}
								articleUrl={this.articleUrl}
								articleId={0}
								isSetTalks
							/>
							<div id="yorumdiv" className="write-comment">
								<div className="write-comment-top">
									<p>Yorum Yap</p>
								</div>

								<form id="form0" method="post">
									<input
										data-val="true"
										data-val-number="The field id must be a number."
										data-val-required="The id field is required."
										id="id"
										name="id"
										type="hidden"
										value="595"
									/>
									<div id="alanform" className="write-comment-input-area">
										<div className="write-comment-textarea-area">
											<textarea
												cols={20}
												id="commentText"
												name="commentText"
												
												onChange={e =>
													this.setState({
														comment: e.target.value
													})
												}
												value={this.state.comment}></textarea>
										</div>
										<div className="write-comment-submmit-area">
											<input
												type="submit"
												name="commnnet-submit"
												onClick={this.btnComment}
												value="Yorum Yap"
												className={
													this.props.loginStore.user.Id > 0 ? '' : 'no-log'
												}
												id="comment-submit"
											/>
											<p className="comment-moderation">
												<img src="/Content/img/comment-tick.png" />
												Yorumunuz onaylandıktan sonra yayınlanacaktır.
											</p>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

interface IArticleRouteProp {
	articleUrl: string
	id: any
}

interface IArticleProp extends RouteComponentProps<IArticleRouteProp> {
	loginStore: ILoginStore
}

interface IArticleState {
	article?: ISetTalksLayout
	comment?: string
	commentCount?: number
}
