import React from 'react'
import {Link, RouteComponentProps} from 'react-router-dom'
import Loading from '../elements/loading'
import ServiceUser from '../services/ServiceUser'
import Seo from '../helper/seo'
import Helmet from 'react-helmet'

export default class AuthorsComponent extends React.Component<
	IAuthorProp,
	IAuthorState
> {
	constructor(props: any) {
		super(props)
		this.state = {
			authors: [],
			loaded: false
		}

		this.getData(this.props.isAuthor)
	}

	componentWillReceiveProps(nextProps:any) {
		this.getData(nextProps.isAuthor)
	}

	getData(isAuthor:boolean=false) {
		this.setState({
			loaded: false
		})
		ServiceUser.authors(isAuthor).then(res => {
			this.setState({
				authors: res.data,
				loaded: true
			})
		})
	}

	render() {
		return (
			<div className="first-right-content">
				{
					<Helmet>
						<title>
							{Seo.getTitle(
								this.props.isAuthor ? 'Yazarlar' : 'Sizden Gelenler'
							)}
						</title>
					</Helmet>
				}
				<div
					className="main-slide category"
					style={{
						background:
							'url(Content/img/categoryimage/en-son-eklenenler-hero.jpg) center center',
						backgroundSize: 'cover'
					}}>
					<div className="main-title-area-index">
						<div className="main-title-index">
							<h1>{this.props.isAuthor ? 'Yazarlar' : 'Sizden Gelenler'}</h1>
						</div>
					</div>
				</div>
				<div className="writers-wide-grid">
					<div className="sedvice-wrap">
						<div className="writers-tab-wrapper profile-tab-menu-area">
							<div className="profile-tab-menu">
								<ul>
									<li>
										<Link
											to="/yazarlar"
											className={this.props.isAuthor ? 'active' : ''}>
											Seturday Yazarları
										</Link>
									</li>
									<li>
										<Link
											to="/sizden-gelenler"
											className={!this.props.isAuthor ? 'active' : ''}>
											Sizden Gelenler
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="sedvice-wrap-in writer-block-area clearfix">
							{!this.state.loaded ? (
								<Loading loaded={this.state.loaded}></Loading>
							) : (
								this.state.authors &&
								this.state.authors.map((item: any) => {
									return (
										<Link
											key={`Authors_${item.Id}`}
											to={`/yazarlar/${window.URLify(
												item.Name.toLowerCase()
											)}-${window.URLify(item.Surname.toLowerCase())}?guid=${
												item.Id
											}`}>
											<div id="82" className="sedvice-profile-block">
												<div className="sedvice-profile-left">
													<div className="sedvice-profile-image">
														<img
															src='https://cdn.seturday.com/content/uploads/yazar_resimleri/anymous.png'
															data-src={
																'https://cdn.seturday.com/content/uploads/yazar_resimleri/' +
																(item.Resim ? item.Resim : 'anymous.png')
															}
															className="lazyload"
														/>
													</div>
													<div className="sedvice-profile-name">
														<p>
															{item.Name} {item.Surname}
															<span className="username-divider"> </span>
															<span className="username-text">
																{item.UserName ? ` | @${item.UserName}` : ''}
															</span>
														</p>
														<p className="sedvice-int">{item.Yazarhakkinda}</p>
													</div>
												</div>
												<div className="sedvice-profile-right">
													<span className="writers-link">Yazılarına Git</span>
												</div>
											</div>
										</Link>
									)
								})
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

interface IAuthorRouteProp {}

interface IAuthorProp extends RouteComponentProps<IAuthorRouteProp> {
	isAuthor: boolean
}

interface IAuthorState {
	authors?: IUserInfo[]
	loaded?: boolean
}
