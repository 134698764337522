import React from 'react'

import ServiceUser from '../services/ServiceUser'

import { inject, observer } from 'mobx-react'

import HelperDom from '../helper/helper-dom'

import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3'

@inject('loginStore')

@observer

export default class LoginComponent extends React.Component<ILoginProp, ILoginState> {

	loginCaptcha: any

	constructor(props: ILoginProp) {

		super(props)

		

		this.state = {

			login: {

				email: '',

				password: ''

			},

			error: '',

		}



		this.getUser()

		this.sendActivationCodeAgain = this.sendActivationCodeAgain.bind(this)

		this.gotoForgotPasswordTab = this.gotoForgotPasswordTab.bind(this)

		this.gotoRegisterTab = this.gotoRegisterTab.bind(this)

	}



	async getUser() {

		if (sessionStorage.getItem('userInfo')) {

			if (this.props.loginStore)

				this.props.loginStore.setUser(

					JSON.parse(sessionStorage.getItem('userInfo') as string)

				)

		} else {

			if (localStorage.getItem('userToken')) {

				const user = await ServiceUser.me()

				this.props.loginStore.setUser(user.data)

			}

		}

	}	



	async setLogin(data: IAccessToken) {


		localStorage.setItem('userToken', JSON.stringify(data))

		const userInfo = await ServiceUser.me()

		if (!userInfo.data.Ilgialanlari) {

			$('.sedvice-circle').removeClass('active')

			$('.login-overlay').removeClass('active')

			$('.new-map-overlay').removeClass('active')

			$('.new-map-area').removeClass('active')

			$('body').removeClass('pfix-sb')

			$('first-overlay').removeClass('pfix-sb')

			$('.new-map-overlay').addClass('active')

			$('#new-map-modal').addClass('active')

			$('.sedvice-circle').addClass('active')

		}

		sessionStorage.setItem('userInfo', JSON.stringify(userInfo.data))

		$('#login-modal div.login-close').click()

		if (this.props.loginStore) this.props.loginStore.setUser(userInfo.data)

	}



	async login(event: any) {

		

		ServiceUser.login(this.state.login.email, this.state.login.password, this.loginCaptcha)

			.then(async res => {

				if (res.data.Success) {

					await this.setLogin(res.data)

						

					this.setState({

						login: {

							email: '',

							password: ''

						}

					})

					window.location.reload()

				} else {

					$('#errorlogin span').text(res.data.Message)

				}

			})

			.catch(res => {

				$('#errorlogin span').text(res.response.data.Message)


				//if (this.props.loginStore) this.props.loginStore.signOut();

			})

		

		event.preventDefault()

	}



	async statusChangeCallback(response: any) {

		if (response.status === 'connected') {

			FB.api(

				'/me?fields=name_format,first_name,short_name,middle_name,last_name,name,id,email,picture',

				async (response: any) => {

					if (response && !response.error) {

						var picture = response.picture.data.url

						localStorage.setItem('facebooklogin', '1')

						var email = response.email

						var name = response.first_name

						var surname = response.last_name

						var shortName = response.short_name

						var ID = response.id



						var data = {

							Picture: picture,

							Email: email,

							Name: name,

							LastName: surname,

							ShortName: shortName,

							ID: ID

						}

						const res = await ServiceUser.loginFb(data)

						if (res && res.status === 200) {

							await this.setLogin(res.data)

						}

					}

				}

			)

		}

	}



	sendActivationCodeAgain() {

		if (!this.state.login.email || !this.state.login.email.trim()) {

			$('#errorlogin span').text('E-mail alanı boş olamaz.')

		} else {

			ServiceUser.resendActivationMail(this.state.login.email)

				.then(res => {

					$('#errorlogin span').text(res.data.Message)

					this.setState({

						login: {

							email: '',

							password: ''

						}

					})

				})

				.catch(res => {

					$('#errorlogin span').text(res.response.data.Message)

				})

		}

	}



	componentDidMount() {	

		HelperDom.isDefinedAndRun('FB').then(() => {

			// Re-initialize facebook login button.

			FB.XFBML.parse()

		})



		

		$('.login-nextnew1').on('click', function() {

			$('#uyeolmesaj').text(

				'Üyelik işlemi başarıyla gerçekleşti. Giriş yapmak için email adresinize gelen aktivasyon mailini onaylayınız.'

			)

			setTimeout(function() {

				$('#uyeolmesaj').text(

					'Üyelik işlemi başarıyla gerçekleşti. Giriş yapmak için email adresinize gelen aktivasyon mailini onaylayınız.'

				)

			}, 4e3)

		})		



		window.checkLoginState = () => {

			FB.getLoginStatus((response: any) => {

				this.statusChangeCallback(response)

			})

		}

	}



	gotoForgotPasswordTab(e: React.MouseEvent<HTMLAnchorElement>) {

		e.preventDefault()

		this.props.chageTab('ForgotPassword')

	}



	gotoRegisterTab(e: React.MouseEvent<HTMLAnchorElement>) {

		e.preventDefault()

		this.props.chageTab('Register')

	}



	render() {

		return <>

			<div className="facebook-login-area">

				<div

					className="facebook-login-hidden"

					style={{opacity: 1}}

					dangerouslySetInnerHTML={{

						__html: `<fb:login-button length='long' size='large' scope='public_profile,email' onlogin='checkLoginState'

						id='facebookButtonPlugin'>

						<span> Facebook ile Bağlan </span>

					</fb:login-button>`

					}}></div>

			</div>

			<div className="login-bottom">

				{/* Kullanıcı Girişi */}

				<div className="login-bottom-in" id="login-user">

					<span>Giriş Yap</span>

					<GoogleReCaptchaProvider reCaptchaKey='6LeEcZkgAAAAAHgE3gei4EqZ9_BvLotd0uuxIKSp'>
						<form

							onSubmit={e => e.preventDefault()}

							id="form2"

							onKeyDown={event => event.keyCode != 13}>

							<div className="form-wrapper clearfix">

								<p

									id="mesajlogin"

									style={{display: 'none'}}

									className="error">

									{' '}

								</p>



								<div className="user-info-wrap clearfix">

									<div className="user-info-area clearfix">

										<input

											id="username"

											name="username"

											onChange={e =>

												this.setState({

													login: {

														...this.state.login,

														email: e.target.value

													}

												})

											}

											placeholder="E-mail"

											type="text"

											value={this.state.login.email}

										/>

									</div>



									<div className="user-info-area clearfix">

										<input

											id="password"

											name="password"

											onChange={e =>

												this.setState({

													login: {

														...this.state.login,

														password: e.target.value

													}

												})

											}

											placeholder="Şifre"

											type="password"

											value={this.state.login.password}

										/>

									</div>

								</div>

								<GoogleReCaptcha
									onVerify={token => {
										this.loginCaptcha = token
									}}
								/>

								<div className="user-info-area-sub user-info-area clearfix">

									<input

										className="submit-button"

										type="submit"

										data-sitekey="6LeEcZkgAAAAAHgE3gei4EqZ9_BvLotd0uuxIKSp" 

										onClick={e => this.login(e)}

										name="submit"

										value="Giriş Yap"

									/>

								</div>

								<div id="errorlogin">

									<span>{this.state.error}</span>

								</div>

							</div>

						</form>
					</GoogleReCaptchaProvider>
					<div className="form-bottom">

						<div className="form-b-right">

							<a

								href="#"

								onClick={this.gotoForgotPasswordTab}

								className="reset-password">

								Şifrenizi mi unuttunuz?

							</a>

						</div>

					</div>



					<div className="creat-new-account">

						<p>

							Hesabınız yok mu?{' '}

							<a

								href="#"

								onClick={this.gotoRegisterTab}

								className="uye-next">

								<strong>ÜYE OL</strong>

							</a>

						</p>

					</div>

					<div className="form-b-right" style={{marginBottom: 20}}>

						<a

							href="#"

							onClick={this.sendActivationCodeAgain}

							className="send-activationcode-again">

								Aktivasyon kodunu tekrar gönder

						</a>

					</div>

				</div>

				{/* Kullanıcı Girişi Bitiş */}

			</div>

		</>

	}

}



interface ILogin {

	email?: string

	password?: string

}



interface ILoginProp {

	chageTab: Action1<LoginModalType>

	loginStore?: ILoginStore

}



interface ILoginState {

	login: ILogin

	error: string

}



declare var FB: any