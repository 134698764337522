import React, {Component} from 'react'
import Const from '../../const'

export default class Layout2Field1Component extends Component<
	ILayout3Prop,
	ILayout3State
> {
	constructor(props: ILayout3Prop) {
		super(props)
	}

	render() {
		return (
			<React.Fragment>
				{this.props.articleLayout.layout2 && (
					<div className="full-w">
						<div
							className="slide-down-parallax-bg"
							style={{
								background: `url('${Const.seturdayCdnArticleLayout2Url}${this.props.articleLayout.layout2.AlanBirResim1}') center center no-repeat`
							}}>
							<div className="parallax-in-white">
								<div className="parallax-in-red-border">
									<div className="parallax-in-text-auto">
										<h2
											dangerouslySetInnerHTML={{
												__html: this.props.articleLayout.layout2.AlanBirBaslik
											}}></h2>
										<p
											dangerouslySetInnerHTML={{
												__html: this.props.articleLayout.layout2.AlanBirOzet
											}}></p>
									</div>
								</div>
							</div>
							<div className="parallax-bg-overlay"></div>
						</div>
					</div>
				)}
			</React.Fragment>
		)
	}

	initializeSlider() {
		var twoSlide = new Swiper('.two-slide', {
			spaceBetween: 30,
			effect: 'fade',
			pagination: {
				el: '.swiper-pagination',
				clickable: !0
			},
			navigation: {
				nextEl: '.two-slide-next',
				prevEl: '.two-slide-prev'
			}
		})
	}
}

interface ILayout3Prop {
	articleLayout: IArticleLayout
}

interface ILayout3State {}
