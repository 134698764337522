import React from 'react'

import ServiceUser from '../services/ServiceUser'

import PasswordChange from './ResetPasswordChange'

import HelperDom from '../helper/helper-dom'

import {
	GoogleReCaptchaProvider,
	GoogleReCaptcha
} from 'react-google-recaptcha-v3'


export default class ResetPassword extends React.Component<

	IResetPasswordProp,

	IResetPasswordState

> {

	loginCaptcha: any

	captchaToken: string

	constructor(props: IResetPasswordProp) {

		super(props)



		this.state = {

			email: '',

			step: 1,

			message: ''

		}

	}



	btnReset = (

		e: React.MouseEvent<HTMLInputElement>,

	) => {

		e.preventDefault()

		

		if (!this.state.email) {

			this.setState({message: 'Geçersiz email adresi!'})

		} else {

			this.setState({message: ''})

			ServiceUser.requestNewPassword(this.state.email, this.captchaToken)

				.then(res => {

					if (res.status === 200) {

						this.setState({

							message: 'E-postanıza doğrulama kodu gönderildi.',

							step: 2

						})

					}

					//grecaptcha.reset(this.loginCaptcha)

				})

				.catch(err => {

					this.setState({

						message: err.response.data.Message || 'Bilinmeyen hata!',

						step: 1

					})

					//grecaptcha.reset(this.loginCaptcha)

				})

		}

		

	}



	gotoLoginTab = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {

		e.preventDefault()

		this.props.changeTab('Login')

	}



	componentWillUnmount() {

		this.setState({

			step: 1

		})

	}



	render() {

		const {step} = this.state

		return (

			<>

				{step === 1 && (
					<GoogleReCaptchaProvider reCaptchaKey='6LeEcZkgAAAAAHgE3gei4EqZ9_BvLotd0uuxIKSp'>
						<form

							id="form1"

							method="post"

							onKeyDown={event => event.keyCode != 13}>

							<div

								className="login-bottom-in"

								id="reset-password"

								style={{display: 'block'}}>

								<span>Şifre Sıfırlama</span>

								{this.state.message && (

									<p id="reset-password-mesaj" className="error">

										{this.state.message}

									</p>

								)}

								<div id="divResetPassword">

									<input

										type="email"

										id="emailRes"

										name="emailRes"

										onChange={e =>

											this.setState({

												email: e.target.value

											})

										}

										value={this.state.email}

										placeholder="E-mail"

									/>

									{/* <div className="recaptha">

										<div

											className="g-recaptcha-wrapper"

											id="resetPasswordCaptcha"></div>

									

									</div> */}

									<GoogleReCaptcha
										onVerify={token => {
											this.captchaToken = token
										}}
									/>

								

									<input

										className="submit-button"

										type="submit"

										name="submit"
									
										data-sitekey="6LeEcZkgAAAAAHgE3gei4EqZ9_BvLotd0uuxIKSp" 

										id="btnResetPassword"

										onClick={this.btnReset}

										value="Şifre Gönder"

									/>

								</div>

								<div className="creat-new-account">

									<p>

										<a

											href="#"

											className="giris-back"

											onClick={this.gotoLoginTab}>

											<strong>Geri</strong>

										</a>

									</p>

								</div>

							</div>

						</form>
					</GoogleReCaptchaProvider>

				)}

				{step === 2 && (

					<PasswordChange

						changeTab={this.props.changeTab}

						email={this.state.email}

						btnReset={this.btnReset}

						parentMessage={this.state.message}

					/>

				)}

			</>

		)

	}

}



interface IResetPasswordProp {

	changeTab: Action1<LoginModalType>

}



interface IResetPasswordState {

	email: string

	message: string

	step: number

}

