import React from 'react'
import {Provider} from 'mobx-react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import {BrowserRouter} from 'react-router-dom'
import ScrollToTop from './helper/scroll-top'
import Header from './layout/header'
import Footer from './layout/footer'
import SidenavMenu from './layout/sidenavmenu'
import SearchComponent from './components/SearchComponent'
import SedviceSearchComponent from './components/Sedvicesearch'
import Routes from './routes'
import Login from './components/LoginModalComponent'
import LoginStore from './stores/login.store'
import BucketStore from './stores/bucket.store'
import BucketListMapComponent from './pages/BucketListMapComponent'
import VisitedPlaceStore from './stores/visited.place.store'

ReactDOM.render(
	<BrowserRouter>
		<ScrollToTop />
		<Provider
			loginStore={LoginStore}
			bucketStore={BucketStore}
			visitedPlaceStore={VisitedPlaceStore}>
			<div id="fb-root"></div>
			<style
				dangerouslySetInnerHTML={{
					__html: `
				.checkbox-container span {
				font-size: 12px;
				color: #333;
				font-weight: 300;
				display: inline-block;
				margin: 0 0 0 0;
				}`
				}}></style>
			<Login />

			<BucketListMapComponent isBucketList={true} />

			{/* EndDonut */}
			{/* popup background */}
			{/* sedvice popup */}
			<SedviceSearchComponent />
			<div className="sedvice-circle"></div>
			{/* EndDonut */}
			<SidenavMenu />
			<div className="side-menu-closer"></div>

			<Header />
			<SearchComponent />
			<div className="first-overlay">
				<div className="first-right active">
					<Routes />
					<Footer />
				</div>
			</div>
			<div
				className="toaster-notification"
				style={{display: 'none'}}
				id="bucket-toaster">
				<p></p>
			</div>
		</Provider>
	</BrowserRouter>,
	document.getElementById('main-wrap')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
