import React, { Component } from 'react'
import ServiceUser from '../../services/ServiceUser'
import Const from '../../const'
import ScriptCache from '../../helper/script.cache'
import moment from 'moment'
import UnAuthorizedComponent from '../UnAuthorized'
import Helmet from 'react-helmet'
import Seo from '../../helper/seo'
import Notification from '../../helper/notification'
window.moment = moment

export default class MyAccountSettingsComponent extends Component<
	IMyAccountSettingsProp,
	IMyAccountSettingsState
	> {
	constructor(props: IMyAccountSettingsProp) {
		super(props)

		this.state = {
			availablePassword: '',
			newPassword: '',
			newPasswordAgain: '',
			referenceCode: '',
			fakeMobile: '',
			user: {}
		}

		this.props.loginStore.getScore()

		this.btnChangePassword = this.btnChangePassword.bind(this)
		this.btnUseReferenceCode = this.btnUseReferenceCode.bind(this)
		this.save = this.save.bind(this)
		this.removeAccount = this.removeAccount.bind(this)
		this.deactiveAccount = this.deactiveAccount.bind(this)

		ServiceUser.me().then(res => {
			this.setState(
				{
					user: res.data as any
				},
				() => {
					this.initializeBirthday()
				}
			)
		})
	}

	initializeBirthday() {
		let that = this
		let birthdaySettings: any = {
			parentEl: '.birthday-picker-wrapper',
			singleDatePicker: true,
			showDropdowns: true,
			minYear: 1901,
			maxYear: new Date().getFullYear() - 18,
			locale: {
				format: 'DD-MM-YYYY',
				separator: ' - ',
				weekLabel: 'H',
				daysOfWeek: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cts'],
				monthNames: [
					'Ocak',
					'Şubat',
					'Mart',
					'Nisan',
					'Mayıs',
					'Haziran',
					'Temmuz',
					'Ağustos',
					'Eylül',
					'Ekim',
					'Kasım',
					'Aralık'
				],
				firstDay: 1,
				drops: 'up'
			}
		}

		if ($.trim($('input[name="date"]').val() as string).length == 0) {
			birthdaySettings.startDate = moment()
				.subtract(18, 'years')
				.format('DD-MM-YYYY')
			birthdaySettings.maxYear = moment()
				.subtract(18, 'years')
				.format('DD-MM-YYYY')
			birthdaySettings.maxDate = moment(
				new Date(new Date().getFullYear(), 0, 1)
			)
				.subtract(17, 'years')
				.format('DD-MM-YYYY')
		}

		$("input[name='date']").daterangepicker(birthdaySettings)

		$("input[name='date']").on('apply.daterangepicker', function (
			ev: any,
			picker: any
		) {
			that.setState({
				user: {
					...that.state.user,
					Birthdate: picker.startDate.format()
				}
			})
		})
	}

	previewUserImage(input: HTMLInputElement) {
		if (input.files && input.files[0]) {
			var reader = new FileReader()
			reader.onload = function (e: any) {
				$('#userImage').attr('src', e.target.result)
			}
			reader.readAsDataURL(input.files[0])
		}
	}

	dateMask() {
		var ui = document.getElementById('date') as HTMLInputElement
		ui.addEventListener('keydown', function (event: any) {
			for (var i = 0; i <= 9; i++) {
				if (event.key == i && ui.value.length === 2) {
					ui.value += '.'
				} else if (event.key == i && ui.value.length === 5) {
					ui.value += '.'
				} else if (
					event.keyCode > 58 &&
					event.keyCode !== 9 &&
					event.key !== 'Enter'
				) {
					event.preventDefault()
				}
			}
		})
	}

	async save(e: React.MouseEvent<HTMLButtonElement>) {
		e.preventDefault()
		var file = document.getElementById('resim') as HTMLInputElement
		if (file.files.length > 0) {
			const res = await ServiceUser.uploadUserImage(file.files[0])
			if (res.data) {
				this.props.loginStore.replacePhoto(res.data)
				$('#resim').val(null)
			}
		}
		const { user } = this.state
		user.Birthdate =
			moment(user.Birthdate).format() ||
			moment(new Date().getFullYear() - 18).format()
		user.SmsFlag = user.AvaibleForSms
		user.EmailFlag = user.Emailgelsin === 1 ? true : false
		try {
			const res = await ServiceUser.updateUser(user)
			if (res.status === 200) {
				Notification.showToast('Değişiklikler başarıyla kaydedildi.')
				this.props.loginStore.setUser(res.data)
			}
		} catch (error) {
			Notification.showToast(
				'Değiştirmek istediğiniz alanı değiştirdikten sonra kaydedebilirsiniz.'
			)
		}
	}

	componentDidMount() {
		this.dateMask()
		let that = this

		$(document).ready(function () {
			$('.change-password-button').on('click', function () {
				$(this).fadeOut('slow', function () {
					$('.profile-input-wrap .password-area input').addClass('active')
					$('.change-password-area').slideDown()
				})
			})

			$('#resim').change(function () {
				that.previewUserImage(this as HTMLInputElement)
			})

			$('input[type="checkbox"]').change(function () {
				$(this)
					.siblings('ul')
					.find('input[type="checkbox"]')
					.prop('checked', (this as HTMLInputElement).checked)
			})

			$('.profile-tab-menu ul li').each(function () {
				if (
					location.pathname.toLowerCase().startsWith(
						$(this)
							.find('a')
							.attr('href')
							.toLowerCase()
					)
				) {
					$(this).addClass('active')
				}
			})

			$('#HowToScoreLink').on('click', function () {
				$('#HowToScorePopup').fadeIn()
				$('body').addClass('overflow-hidden')
			})

			$('#HowToScorePopup .popup-toggle-close').on('click', function () {
				$('#HowToScorePopup').fadeOut()
				$('body').removeClass('overflow-hidden')
			})

			$('#AccountActivityDecisions .user-delete-button').on('click', function (
				e
			) {
				e.preventDefault()
				$('.account-activity-decision-buttons').css('display', 'none')
				$('.user-delete-sure').css('display', 'block')
			})

			$('#AccountActivityDecisions .user-freeze-button').on('click', function (
				e
			) {
				e.preventDefault()
				$('.account-activity-decision-buttons').css('display', 'none')
				$('.user-account-freeze-sure').css('display', 'block')
			})

			$(
				'#AccountActivityDecisions .user-delete-no, #AccountActivityDecisions .user-freeze-no'
			).on('click', function (e) {
				e.preventDefault()
				$('.account-activity-decision-buttons').css('display', 'block')
				$('.user-delete-sure').css('display', 'none')
				$('.user-account-freeze-sure').css('display', 'none')
			})

			$('#AccountActivityDecisions .user-freeze-no').on('click', function (e) {
				e.preventDefault()
				$('.account-activity-decision-buttons').css('display', 'block')
				$('.user-delete-sure').css('display', 'none')
			})

			$('#UyelikNoAccordionTop').on('click', function () {
				$('#UyelikNoAccordion').slideToggle()
				$('#UyelikNoAccordionTop .arrow').toggleClass('active')
			})

			$('#RefCodeAccordionTop').on('click', function () {
				$('#RefCodeAccordion').slideToggle()
				$('#RefCodeAccordionTop .arrow').toggleClass('active')
			})

			$('#CampaignPermAccordionTop').on('click', function () {
				$('#CampaignPermAccordion').slideToggle()
				$('#CampaignPermAccordionTop .arrow').toggleClass('active')
			})

			$('#MessagePermAccordionTop').on('click', function () {
				$('#MessagePermAccordion').slideToggle()
				$('#MessagePermAccordionTop .arrow').toggleClass('active')
			})

			var checkboxesAcc = document.querySelectorAll<HTMLInputElement>(
				'input.subAccount'
			),
				checkallAcc = document.getElementById(
					'mesajgelsin-kampanya'
				) as HTMLInputElement

			for (var i = 0; i < checkboxesAcc.length; i++) {
				checkboxesAcc[i].onclick = function () {
					var checkedCount = document.querySelectorAll(
						'input.subAccount:checked'
					).length
					checkallAcc.checked = checkedCount > 0
					// checkallAcc.indeterminate = checkedCount > 0 && checkedCount < checkboxesAcc.length
					// if(checkedCount <= 0){
					//     $('.campain-bottom').slideUp('fast')
					// }
				}
			}

			checkallAcc.onclick = function () {
				for (var i = 0; i < checkboxesAcc.length; i++) {
					checkboxesAcc[i].checked = (this as HTMLInputElement).checked
				}
			}

			$('#refCodeCopy').on('click', () => {
				that.copyRefCode()
				$('#refCodeCopy').html('KOPYALANDI')
			})
		})
		$('#telefon').mask('(000) 000 00 00')
	}

	copyRefCode() {
		var refcodeNumber = document.getElementById('refCodeNumber') as any
		// refCode = document.getElementById('refCodeCopy')
		refcodeNumber.select()
		document.execCommand('copy')
	}

	setChangePasswordError(message: string, className: string = 'error') {
		var messagecc = document.getElementById('mesajcc')
		messagecc.style.display = 'block'
		if (className === 'success') {
			messagecc.style.color = 'green'
		}
		messagecc.innerText = message
		setTimeout(() => {
			$(messagecc).hide(1000)
		}, 3000)
	}


	btnChangePassword(e: React.MouseEvent<HTMLInputElement>) {
		e.preventDefault()

		const { availablePassword, newPassword, newPasswordAgain } = this.state

		if (!availablePassword || !availablePassword.trim()) {
			return this.setChangePasswordError('Mevcut şifrenizi girmelisiniz.')
		}

		if (
			!newPassword ||
			!newPasswordAgain.trim() ||
			!newPasswordAgain ||
			!newPasswordAgain.trim() ||
			newPassword != newPasswordAgain
		) {
			return this.setChangePasswordError(
				'"Yeni Şifre" ve "Yeni Şifre Tekrar" girişleri boş olmamalı ve aynı olmalı.'
			)
		}

		ServiceUser.changePassword(availablePassword, newPassword)
			.then(res => {
				if (res.data) {
					this.setChangePasswordError(
						'Şifreniz başarılı bir şekilde değiştirilmiştir.',
						'success'
					)
					$('.profile-input-wrap .password-area input').removeClass('active')
					$('.change-password-area').slideUp()
					$('.change-password-button').fadeIn()

					this.setState({
						availablePassword: '',
						newPassword: '',
						newPasswordAgain: ''
					})
				}
			})
			.catch((res: { response: { data: { Message: string } } }) => {
				this.setChangePasswordError(res.response.data.Message)
			})
	}

	async btnUseReferenceCode(e: React.MouseEvent<HTMLInputElement>) {
		e.preventDefault()

		let showMessage = (text: string) => {
			$('.ac-error-message').html(text)
			$('.ac-error-message').show(1000)
			setTimeout(() => {
				$('.ac-error-message').hide(1000)
			}, 4000)
		}

		if (!this.state.referenceCode || !this.state.referenceCode.trim()) {
			return showMessage('Girmiş olduğunuz Referans Kodu kullanılmamaktadır.')
		}

		const res = await ServiceUser.useReferenceCode(this.state.referenceCode)

		if (res.data) {
			showMessage(res.data)
			this.props.loginStore.refreshUser()
		}
	}

	async removeAccount(e: React.MouseEvent<HTMLElement>) {
		e.preventDefault()
		const res = await ServiceUser.removeAccount()
		if (res.status === 200) {
			this.props.loginStore.signOut()
			this.props.history.push('/')
		}
	}

	async deactiveAccount(e: React.MouseEvent<HTMLElement>) {
		e.preventDefault()
		const res = await ServiceUser.deactivateAccount()
		if (res.status === 200) {
			this.props.loginStore.signOut()
			this.props.history.push('/')
		}
	}

	render() {
		let { user } = this.state
		let { loginStore } = this.props
		return (
			<div className="profile-content-in" id="profile-ayarlar">
				<Helmet>
					<title>{Seo.getTitle('Profilim')}</title>
				</Helmet>
				<p className="ac-error-message" style={{ display: 'none' }}></p>
				<div className="profile-hesabim pt-30 mb-30">
					<p className="profile-title-p">Hesabım</p>
					<div className="profile-input-wrap">
						<p id="mesajcc" style={{ display: 'none' }} className="error">
							{' '}
						</p>
						<form
							data-ajax="true"
							data-ajax-success="onay"
							id="form0"
							method="post">
							<label>E-Mail Adresiniz</label>
							<div className="password-area">
								<input
									id="email"
									name="email"
									disabled={true}
									type="text"
									value={loginStore.user.Email}
								/>
								<a
									href="#"
									onClick={e => e.preventDefault()}
									className="change-password-button">
									ŞİFRE DEĞİŞTİR
								</a>
							</div>
							<div className="change-password-area">
								<label>Mevcut Şifre</label>
								<input
									type="password"
									id="mevcutsifre"
									name="mevcutsifre"
									onChange={e =>
										this.setState({
											availablePassword: e.target.value
										})
									}
									value={this.state.availablePassword}
								/>
								<label>Yeni Şifre</label>
								<input
									type="password"
									id="yenisifre"
									name="yenisifre"
									onChange={e =>
										this.setState({
											newPassword: e.target.value
										})
									}
									value={this.state.newPassword}
								/>
								<label>Yeni Şifre Tekrar</label>
								<input
									type="password"
									id="yenisifretekrar"
									name="yenisifretekrar"
									onChange={e =>
										this.setState({
											newPasswordAgain: e.target.value
										})
									}
									value={this.state.newPasswordAgain}
								/>
								<input
									type="submit"
									name="submit-profile"
									className="profile-submit"
									onClick={this.btnChangePassword}
									value="KAYDET"
								/>
							</div>
						</form>
						<div className="ref-code-area profile-cols ">
							<div className="ref-code-in uyelik-no profile-col">
								<div className="profile-accordion-wrapper">
									<div
										className="profile-accordion-top"
										id="UyelikNoAccordionTop">
										<label>Üyelik Numarası </label>
										<div className="arrow-wrap">
											<span className="arrow">
												<span></span>
												<span></span>
											</span>
										</div>
									</div>
									<div
										id="UyelikNoAccordion"
										className="profile-accordion-bottom">
										<p className="profile-mb-tel">
											Seturday Üyelik Numaranızı arkadaşlarınızla paylaşın, {/* puan ve*/} kampanyalardan yararlanma fırsatı yakalayın.
										</p>
										<div className="ref-input-area">
											<input
												type="text"
												value={loginStore.user.ReferenceCode}
												id="refCodeNumber"
												readOnly={true}
											/>
											<button className="act-button" id="refCodeCopy">
												KOPYALA
											</button>
										</div>
									</div>
								</div>
							</div>

							<div className="ref-code-in ref-no profile-col">
								<div className="profile-accordion-wrapper">
									<div
										className="profile-accordion-top"
										id="RefCodeAccordionTop">
										<label>Referans Kodu</label>
										<div className="arrow-wrap">
											<span className="arrow">
												<span></span>
												<span></span>
											</span>
										</div>
									</div>
									<div
										className="profile-accordion-bottom"
										id="RefCodeAccordion">
										<p className="profile-mb-tel">
											Arkadaşınızdan ya da Sosyal Medya kampanyalarından gelen
											kodu girin, {/* puan ve */} kampanyalardan yararlanma fırsatı
											yakalayın.
										</p>
										<form encType="multipart/form-data" method="post">
											<div className="ref-input-area">
												<input
													type="text"
													value={this.state.referenceCode}
													onChange={e =>
														this.setState({ referenceCode: e.target.value })
													}
													placeholder="Referans Kodu"
													name="refCode"
													id="refCode"
												/>
												<button
													className="act-button"
													onClick={this.btnUseReferenceCode}
													type="submit">
													EKLE
												</button>
											</div>
										</form>
										{loginStore.user.UsedReferenceCode && (
											<p className="profile-mb-tel">
												Kayıt olduğunuz referans kodu:{' '}
												<strong>{loginStore.user.UsedReferenceCode}</strong>
											</p>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<form encType="multipart/form-data" method="post">
					<div className="profile-hesabim pt-30 mb-30">
						<p className="profile-title-p">Profil</p>
						<div className="profile-input-wrap">
							<label>Profil Fotoğrafı</label>
							<div className="profile-photo">
								<img
									src={`${Const.seturdayCdnAuthorUrl}${loginStore.user.Resim ||
										'anymous.png'}`}
									id="userImage"
								/>
							</div>

							<p className="profile-mb">5 MB'dan yüksek olmamalıdır.</p>

							<div className="upload-btn-wrapper">
								<button className="profile-upload">YENİ EKLE</button>
								<input type="file" id="resim" name="resim" />
							</div>

							<div className="profile-cols">
								<div className="profile-col">
									<label>Ad</label>
									<input
										type="text"
										value={user.Name}
										id="ad"
										name="ad"
										onChange={e =>
											this.setState({
												user: {
													...user,
													Name: e.target.value
												}
											})
										}
									/>
								</div>
								<div className="profile-col">
									<label>Soyad</label>
									<input
										type="text"
										value={user.Surname}
										id="soyad"
										name="soyad"
										onChange={e =>
											this.setState({
												user: {
													...user,
													Surname: e.target.value
												}
											})
										}
									/>
								</div>
								<div className="profile-col">
									<label>Kullanıcı Adı</label>
									<input
										type="text"
										value={user.UserName}
										id="nickname"
										name="nickname"
										onChange={e =>
											this.setState({
												user: {
													...user,
													UserName: e.target.value
												}
											})
										}
									/>
								</div>
								<div className="profile-col">
									<label>Cinsiyet</label>
									<div className="gender-area">
										<div
											className={`gender-block ${
												user.Gender === 'Erkek' ? 'active' : ''
												}`}
											onClick={() =>
												this.setState({
													user: {
														...user,
														Gender: 'Erkek'
													}
												})
											}>
											<p>Erkek</p>
										</div>
										<div
											className={`gender-block ${
												user.Gender === 'Kadın' ? 'active' : ''
												}`}
											onClick={() =>
												this.setState({
													user: {
														...user,
														Gender: 'Kadın'
													}
												})
											}>
											<p>Kadın</p>
										</div>
									</div>
								</div>
								<div className="profile-col">
									<label htmlFor="date">Doğum Tarihi </label>
									<input
										id="date"
										maxLength={10}
										name="date"
										placeholder="Gün-Ay-Yıl"
										type="text"
										onChange={() => null}
										value={
											user.Birthdate &&
											moment(user.Birthdate).format('DD-MM-YYYY')
										}
									/>
								</div>
								<div className="profile-col">
									<label>Telefon</label>
									<input
										type="text"
										value={this.state.fakeMobile || user.Mobile}
										id="telefon"
										name="telefon"
										maxLength={11}
										onKeyPress={event => {
											const keyCode = event.keyCode || event.which
											const keyValue = String.fromCharCode(keyCode)
											if (!/^[0-9]+$/g.test(keyValue)) event.preventDefault()
										}}
										onChange={e =>
											this.setState({
												user: {
													...user,
													Mobile: $('#telefon').cleanVal()
												},
												fakeMobile: e.target.value
											})
										}
									/>
									<p className="profile-mb-tel tel-info">
										Setur Bucketlist'inizde gitmeye hazır olduğunuz turlara
										yönelik bilgi vermek için telefonunuzu saklı tutar.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="profile-hesabim">
						<div className="profile-input-wrap">
							<div className="profile-cols">
								<div className="profile-col">
									<div className="profile-accordion-wrapper">
										<div
											className="profile-accordion-top"
											id="CampaignPermAccordionTop">
											<label>Kampanya İletişimi / Bilgilendirmesi </label>
											<div className="arrow-wrap">
												<span className="arrow">
													<span></span>
													<span></span>
												</span>
											</div>
										</div>
										<div
											className="profile-accordion-bottom"
											id="CampaignPermAccordion">
											<div className="acc-check acc-check-kampanya">
												<label className="container">
													Kampanya
													<br />
													<p
														className="message-label-p"
														id="message-campain-label">
														Setur Servis Turistik A.Ş.'nin işletmekte olduğu
														markalar, tedarikçileri ve işbirliği içerisinde
														olduğu kuruluşlara ilişkin hizmet, reklam, ilan,
														kampanya ve faaliyetleri hakkında; tarafıma
														bilgilendirme yapılmasına ve tarafımla 6563 Sayılı
														Kanun uyarınca ticari elektronik iletiler
														paylaşılmasına onay veriyor ve verdiğim onayı iptal
														etmek istersem, bu durumu Setur Servis Turistik
														A.Ş'nin işbu mecrada yer alan iletişim kanallarına
														bildirmeyi kabul ediyorum.
													</p>
													<input
														id="mesajgelsin-kampanya"
														name="mesajgelsin-kampanya"
														type="checkbox"
														onChange={e => {
															this.setState({
																user: {
																	...user,
																	Emailgelsin: e.target.checked ? 1 : 0,
																	AvaibleForSms: e.target.checked,
																	AvaibleForCall: e.target.checked
																}
															})
														}}
														checked={
															user.AvaibleForCall ||
															(user.Emailgelsin === 1 ? true : false) ||
															user.AvaibleForSms
														}
													/>
													<span
														className="checkmark"
														id="message-campain-mark"></span>
												</label>
											</div>

											<div className="acc-check-other">
												<ul>
													<li>
														<div className="acc-check acc-check-sms">
															<label className="container">
																<p className="message-label-p">SMS</p>
																<input
																	id="mesajgelsin-sms"
																	name="mesajgelsin-sms"
																	className="subAccount"
																	type="checkbox"
																	checked={user.AvaibleForSms}
																	onChange={e =>
																		this.setState({
																			user: {
																				...user,
																				AvaibleForSms: e.target.checked,
																			}
																		})
																	}
																/>
																<span className="checkmark"></span>
															</label>
														</div>
													</li>
													<li>
														<div className="acc-check acc-check-email">
															<label className="container">
																<p className="message-label-p">Telefon</p>
																<input
																	id="mesajgelsin-phone"
																	className="subAccount"
																	checked={user.AvaibleForCall}
																	onChange={e =>
																		this.setState({
																			user: {
																				...user,
																				AvaibleForCall: e.target.checked
																			}
																		})
																	}
																	name="mesajgelsin-phone"
																	type="checkbox"
																/>
																<span className="checkmark"></span>
															</label>
														</div>
													</li>
													<li>
														<div className="acc-check acc-check-email">
															<label className="container">
																<p className="message-label-p">E-Mail</p>
																<input
																	id="mesajgelsin-email"
																	name="mesajgelsin-email"
																	className="subAccount"
																	type="checkbox"
																	checked={user.Emailgelsin === 1 ? true : false}
																	onChange={e =>
																		this.setState({
																			user: {
																				...user,
																				Emailgelsin: e.target.checked ? 1 : 0,
																			}
																		})
																	}
																/>
																<span className="checkmark"></span>
															</label>
														</div>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								<div className="profile-col">
									<div className="profile-accordion-wrapper">
										<div
											className="profile-accordion-top"
											id="MessagePermAccordionTop">
											<label>Mesaj İzni </label>
											<div className="arrow-wrap">
												<span className="arrow">
													<span></span>
													<span></span>
												</span>
											</div>
										</div>
										<div
											className="profile-accordion-bottom"
											id="MessagePermAccordion">
											<div className="acc-check acc-check-message">
												<label className="container">
													Mesaj Gelsin
													<br />
													<p className="message-label-p">
														Kişilerin tarafıma seturday blog mesajlaşma
														özelliğini kullanarak mesaj atmasına izin veriyorum.
													</p>
													<input
														id="mesajgelsin"
														name="mesajgelsin"
														type="checkbox"
														checked={user.Mesajgelsin || user.AvaibleForChat}
														onChange={e =>
															this.setState({
																user: {
																	...user,
																	AvaibleForChat: e.target.checked,
																	Mesajgelsin: e.target.checked
																}
															})
														}
													/>
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>

							<button
								type="submit"
								name="submit-profile"
								className="profile-submit"
								onClick={this.save}>
								KAYDET
							</button>
							<br />
							<br />
							<br />
						</div>
					</div>
				</form>
				<div
					id="AccountActivityDecisions"
					className="account-activity-decisions">
					<div className="account-activity-decision-buttons">
						<a
							href="#"
							onClick={e => e.preventDefault()}
							className="user-freeze-button">
							Hesabımı Dondur
						</a>
						&nbsp;|&nbsp;
						<a
							href="#"
							onClick={e => e.preventDefault()}
							className="user-delete-button">
							Hesabımı Sil
						</a>
					</div>
					<div className="user-delete-sure">
						<p>
							Üyeliğinizi Kaldırmak İstediğinize Emin Misiniz?
							<br />
							<strong>UYARI: Bu işlemi geri alamayacaksınız!</strong>
						</p>
						<button className="user-delete-yes" onClick={this.removeAccount}>
							Evet
						</button>
						<button className="user-delete-no">Hayır</button>
					</div>
					<div className="user-account-freeze-sure">
						<p>
							Hesabınızı Dondurmak İstediğinize Emin Misiniz?
							<br />
							Bir sonraki girişinize kadar hesabınız dondurulacaktır.
							<br />
							Giriş yaptığınızda hesabınızı tekrar aktifleştirmeniz
							gerekecektir.
						</p>
						<button className="user-freeze-yes" onClick={this.deactiveAccount}>
							Evet
						</button>
						<button className="user-freeze-no">Hayır</button>
					</div>
				</div>
			</div>
		)
	}
}

interface IMyAccountSettingsState {
	availablePassword: string
	newPassword: string
	newPasswordAgain: string
	referenceCode: string
	fakeMobile: string
	user: IUpdateUser
}

interface IMyAccountSettingsProp {
	loginStore?: ILoginStore
	history?: any
}
