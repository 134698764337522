import React, {Component} from 'react'
import {RouteComponentProps} from 'react-router'
import {inject, observer} from 'mobx-react'

import ServiceUser from '../../services/ServiceUser'
import Const from '../../const'
import moment from 'moment'
import Helmet from 'react-helmet'
import Seo from '../../helper/seo'
import ChatBox from '../../components/ChatBox'

@inject('loginStore')
@observer
export default class MyAccountMessageComponent extends Component<
	IMyMessageProp,
	IMyMessageState
> {
	constructor(props: IMyMessageProp) {
		super(props)
		this.state = {
			chats: [],
			block: {},
			remove: [],
			loaded: false,
			userInfo: {},
			socketMessages: []
		}
		this.props.loginStore.getScore()

		ServiceUser.getConversations(100, 0).then(res => {
			this.setState({
				chats: res.data.Chats,
				loaded: true
			})
		})

		this.remove = this.remove.bind(this)
		this.block = this.block.bind(this)
	}

	remove(
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		contactId: number
	) {
		e.stopPropagation()

		ServiceUser.removeconversation(contactId).then((res: any) => {
			const chat = this.state.chats.filter(c => c.RecieverId == contactId)[0]
			const index = this.state.chats.indexOf(chat)
			this.state.chats.splice(index, 1)
			this.setState({
				chats: this.state.chats
			})
		})
	}

	block(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, blockId: number) {
		e.stopPropagation()
		ServiceUser.blockuser(blockId).then((res: any) => {
			const chat = this.state.chats.filter(c => c.RecieverId == blockId)[0]
			const index = this.state.chats.indexOf(chat)
			chat.Blocked = true
			this.state.chats.splice(index, 1)
			this.state.chats.splice(index, 0, chat)
			this.setState({
				chats: this.state.chats
			})
		})
	}

	unblock(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, blockId: number) {
		e.stopPropagation()
		ServiceUser.unblockuser(blockId).then((res: any) => {
			const chat = this.state.chats.filter(c => c.RecieverId == blockId)[0]
			const index = this.state.chats.indexOf(chat)
			chat.Blocked = false
			this.state.chats.splice(index, 1)
			this.state.chats.splice(index, 0, chat)
			this.setState({
				chats: this.state.chats
			})
		})
	}

	handleClick(userInfo: IUserConversations) {
		const chats:IUserConversations[] = this.state.chats.map((item:IUserConversations) => {
			if (item.Id === userInfo.Id) {
				item.UnseenCount = 0;
			}
			return item;
		});
		this.setState({
			userInfo,
			chats,
			socketMessages: []
		})
	}
	resetUserInfo = () => {
		this.setState({
			userInfo: {},
			socketMessages: []
		})
	}
	componentDidMount() {
		try {
			this.props.loginStore.connection.on(
				'ReceiveMessage',
				(senderId: number, message: string, id: number, receiverId: number) => {
					this.updateList(senderId, message, id, receiverId)
				}
			)
		} catch (error) {}
	}

	updateList = (
		senderId: number,
		message: string,
		id: number,
		receiverId: number
	) => {
		let messages = this.state.socketMessages
		messages.push({
			Id: id,
			GonderenId: senderId,
			AliciId: receiverId,
			Mesaj: message
		})
		this.setState({
			socketMessages: messages
		})
		ServiceUser.getConversations(100, 0).then(res => {
			this.setState({
				chats: res.data.Chats,
				loaded: true
			})
		})
	}

	render() {
		const {userInfo, socketMessages} = this.state
		const {loginStore} = this.props
		return (
			<div className="profile-content-in" id="profile-ayarlar">
				<Helmet>
					<title>{Seo.getTitle('Mesajlarım')}</title>
				</Helmet>
				<div className="profile-hesabim pt-30 mb-30">
					<p className="profile-title-p">Mesajlarım</p>
					<div className="message-wrap-area">
						<ul className="message-ul">
							{this.state.chats &&
								this.state.chats.length === 0 &&
								this.state.loaded && (
									<div className="empty-area clearfix">
										<div className="empty-img clearfix">
											<img src="/Content/img/empty-message.png" />
										</div>
										<div className="empty-title">
											<p>Henüz mesajınız yok.</p>
										</div>
									</div>
								)}
							{!this.state.loaded && (
								<div className="empty-area clearfix">
									<div className="empty-img clearfix">
										<img src="/Content/img/empty-liked.png" />
									</div>
									<div className="empty-title">
										<p>Mesajlar yükleniyor...</p>
									</div>
								</div>
							)}
							{this.state.chats &&
								this.state.chats.map(item => {
									return (
										<li
											className="message-li"
											key={`message_${item.RecieverId}`}
											onClick={() => this.handleClick(item)}>
											<div className="message-li-left">
												<img
													style={{
														backgroundImage: `url(${Const.seturdayCdnAuthorUrl +
															item.PictureUrl})`,
														backgroundSize: 'cover'
													}}
												/>
											</div>
											<div className="message-li-right">
												<span
													className={`message-notification-in ${
														item.UnseenCount > 0 ? 'active' : ''
													}`}></span>
												<div className="message-li-rt">
													<h6>
														{item.Name} {item.Surname}
													</h6>
												</div>
												<div className="message-li-rb">
													<p className="message-p newmessage">
														{item.LastMessage}
													</p>
												</div>
												<div className="message-date">
													<span>
														{moment(item.LastMessageTime).format('DD.MM.YY')}
													</span>
												</div>
												<div className="message-delete">
													{!item.Blocked && (
														<a onClick={e => this.block(e, item.RecieverId)}>
															Engelle
														</a>
													)}
													{item.Blocked && (
														<a onClick={e => this.unblock(e, item.RecieverId)}>
															Engeli Kaldır
														</a>
													)}
													&nbsp;
													<a onClick={e => this.remove(e, item.RecieverId)}>
														Konuşmayı Sil
													</a>
												</div>
											</div>
										</li>
									)
								})}
						</ul>
					</div>
				</div>
				<ChatBox
					userInfo={userInfo}
					socketMessages={socketMessages}
					resetUserInfo={this.resetUserInfo}
					loginStore={loginStore}
				/>
			</div>
		)
	}
}

interface IMyMessageProp {
	loginStore?: ILoginStore
}

interface IMyMessageState {
	chats: Array<IUserConversations>
	remove: Array<IUserConversations>
	socketMessages: any
	block: {}
	loaded: boolean
	userInfo: any
}
