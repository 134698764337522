import React, { Component } from 'react'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import UnAuthorizedComponent from './UnAuthorized'
import ServiceCategory from '../services/ServiceCategory'
import Const from '../const'
import ServiceArticle from '../services/ServiceArticle'
import Helmet from 'react-helmet'
import Seo from '../helper/seo'
import { inject, observer } from 'mobx-react'

@inject('loginStore')
@observer
class MyLikesComponent extends Component<IMyLikesProp, IMyLikesState> {
	constructor(props: IMyLikesProp) {
		super(props)
		this.state = {
			articles: [],
			categories: []
		}

		ServiceCategory.categories().then(res => {
			this.setState({
				categories: res.data
			})
		})

		ServiceArticle.favorites(100, 0).then(res => {
			this.setState({
				articles: res.data.articleList
			})
		})
	}

	getCategory(categoryId: number): ICategory {
		if (this.state.categories && this.state.categories.length > 0) {
			return this.state.categories.filter(c => c.Id == categoryId)[0]
		}
		return {} as ICategory
	}

	render() {
		if (!this.props.loginStore.user.Id) {
			return <UnAuthorizedComponent />
		}
		return <div className="first-right-content">
			<Helmet>
				<title>{Seo.getTitle('Beğendiklerim')}</title>
			</Helmet>
			<div className="main-slide category" style={{ background: 'url(/Content/img/categoryimage/favorites.jpg) center center / cover' }}>
				<div className="main-title-area-index">
					<div className="main-title-index">
						<h1>Beğendiklerim</h1>
						<p className="cat-subtitle" style={{ display: 'none' }}></p>
					</div>
				</div>
			</div>

			<div className="category-wide-grid">
				<div className="grid-area wide-grid">
					{
						this.state.articles && this.state.articles.map(item => {
							return <Link key={`article_${item.Id}`} to={`/${this.getCategory(item.MakaleKategoriId).Kategoriurl}/${item.Url}`}>
								<div className="grid grid-medium-ctg">

									<div className="grid-top grid-h-100">
										<div className="grid-top-info">
											<h4>
												{item.MakaleAdi}
											</h4>
											<p>{item.MakaleOzeti.substring(0, 83) + '...'}</p>
										</div>
										<div className="grid-grd">
										</div>
										<div className="grid-image" style={{ background: `url(${Const.seturdayCdnArticleCover}${item.MakaleBuyukResim}) center center` }}></div>
									</div>
									<div className="grid-bottom grid-none">
										<div className="grid-date ">
											<span>
												<img className="heart" src="Content\img\liked.png" width="11" alt={item.MakaleAdi} title={item.MakaleAdi} />
												<span className="heart-count">{item.MakaleBeğeni} Beğenme</span>
											</span>
										</div>
									</div>
								</div>
							</Link>
						})
					}
					{this.state.articles.length === 0 &&
						<div className="full-w">
							<div className="wrap-in clearfix">
								<div className="empty-area clearfix">
									<div className="empty-img clearfix">
										<img src="/Content/img/empty-liked.png" />
									</div>
									<div className="empty-title">
										<p>
											Beğendiğiniz içerik bulunmuyor. İçeriği beğenmek için <span>
												<img src="/Content/img/like-icon-info.png" />
											</span> ikonuna basınız.
									</p>
									</div>
									<div className="empty-button">
										<a href="/">İçerikleri Keşfet</a>
									</div>
								</div>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	}
}

interface IMyLikesProp extends RouteComponentProps<any> {
	loginStore: ILoginStore
}

interface IMyLikesState {
	articles: IArticle[]
	categories: ICategory[]
}

export default withRouter(MyLikesComponent as any)