import React from 'react'
import Helmet from 'react-helmet'
import Seo from '../helper/seo'

export default class UnAuthorizedComponent extends React.Component<
	IUnAuthorizedProp,
	IUnAuthorizedState
> {
	render() {
		return (
			<>
				<Helmet>{Seo.getTitle('Sayfayı görüntüleme yetkiniz yok!')}</Helmet>
				<div className="full-w contract-page">
					<div className="wrap-in">
						<div className="empty-area clearfix">
							<div className="empty-img clearfix">
								<img src="/Content/img/empty-login.png" alt="Giriş Yap" />
							</div>
							<div className="empty-title">
								<p>
									Bu sayfayı görüntülemek için giriş yapmanız gerekmektedir.
								</p>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

interface IUnAuthorizedProp {}

interface IUnAuthorizedState {}
