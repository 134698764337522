import React from 'react'
import './MainPageComponent.css'
import {Link} from 'react-router-dom'
import {find} from 'lodash'
import ServiceArticle from '../../services/ServiceArticle'
import ServiceCategory from '../../services/ServiceCategory'
import Helmet from 'react-helmet'
import Seo from '../../helper/seo'
import Loading from '../../elements/loading'
import ServiceUser from '../../services/ServiceUser'

export default class MainPageComponent extends React.Component<
	{},
	{
		authors: any
		loaded: boolean
		articles?: Array<IArticle>
		categories: ICategory[]
	}
> {
	constructor(props: any) {
		super(props)

		this.state = {
			articles: [],
			categories: [],
			loaded: false,
			authors: []
		}

		ServiceCategory.categories().then(res => {
			this.setState({
				categories: res.data
			})
		})
		this.getArticles()
	}

	getArticleAuthors = (articles: any) => {
		ServiceUser.authors(true).then(res => {
			this.setState({
				authors: res.data,
				articles,
				loaded: true
			})
		})
	}

	getArticles = () => {
		ServiceArticle.homeArticles(12).then(res =>
			this.getArticleAuthors(res.data)
		)
	}

	getCategory(categoryId: number) {
		if (this.state.categories && this.state.categories.length > 0) {
			return this.state.categories.filter(c => c.Id == categoryId)[0]
		}
		return {} as ICategory
	}

	render() {
		return (
			<div className="first-right-content first-top">
				{
					<Helmet>
						<title>{Seo.getTitle('')}</title>
						<meta name="keywords" content="" />
						<meta
							name="description"
							content="Seyahat dünyasının trendleri, kültür & sanat, gastronomi ve macera rotaları, iş gezilerinin ‘en’leri… Hepsi, dün bilmedikleriniz için bugün Seturday’de!"
						/>
					</Helmet>
				}
				<div style={{backgroundColor: 'rgba(0,0,0,.1)'}}>
					{!this.state.loaded && <Loading loaded={this.state.loaded}></Loading>}
					{this.state.articles && this.state.articles.length > 0 && (
						<Link
							to={
								`/${
									this.getCategory(this.state.articles[0].MakaleKategoriId)
										.Kategoriurl
								}/` + this.state.articles[0].Url
							}>
							<div
								className="main-slide"
								style={{
									backgroundImage:
									'url(https://cdn.seturday.com/content/uploads/makale_resimleri/kapak/' +
										this.state.articles[0].MakaleBuyukResim +
										')'
								}}>
								<div 
									className="main-title-area-index"  
								>
									<div className="main-tag">
										<p className="banner-tag-st" data-link="kategori-adi">
											{
												this.getCategory(
													this.state.articles[0].MakaleKategoriId
												).KategoriAdi.toUpperCase()
											}
										</p>
									</div>
									<div className="main-title-index">
										<h1>{this.state.articles[0].MakaleAdi}</h1>
									</div>
									<div className="main-text-index">
										<p>{this.state.articles[0].MakaleOzeti}</p>
									</div>
									<div className="cta-area">
										<span className="animated-arrow">
											<span className="the-arrow -left">
												<span className="shaft"></span>
											</span>
											<span className="main">
												<span className="text">Devamını oku</span>
												<span className="the-arrow -right">
													<span className="shaft"></span>
												</span>
											</span>
										</span>
									</div>
								</div>
							</div>
						</Link>
					)}
				</div>
				{this.state.articles.length > 0 && this.state.authors.length > 0 && (
					<div className="grid-area-wrapper">
						<div className="grid-area">
							{this.state.articles &&
								this.state.articles.slice(1, 6).map((item, i) => {
									return this.getArticle(item, i + 1)
								})}
						</div>
						<div className="grid-area">
							{this.state.articles &&
								this.state.articles.slice(6).map((item, i) => {
									return this.getArticle(item, i + 6)
								})}
						</div>
					</div>
				)}
			</div>
		)
	}

	getArticle(item: IArticle, i: number) {
		const author = find(this.state.authors, a => a.Id == item.Yazarid)
		return (
			<Link
				key={`homeArticle_${i}`}
				to={
					`/${this.getCategory(item.MakaleKategoriId).Kategoriurl}/` + item.Url
				}>
				<div
					className={
						'grid ' +
						([1, 8].includes(i)
							? 'grid-big'
							: [2, 3, 6, 7].includes(i)
								? 'grid-small'
								: [4].includes(i)
									? 'grid-medium'
									: [9, 5].includes(i)
										? 'grid-wide'
										: [10, 11].includes(i)
											? 'grid-high'
											: '')
					}>
					<div className="block-tag-area">
						<p className="block-tag-link" data-link="meraklisina">
							{this.getCategory(item.MakaleKategoriId).KategoriAdi.toUpperCase()}
						</p>
					</div>

					<div className={author ? 'grid-top' : 'grid-top grid-h-100'}>
						<div className="grid-top-info">
							<h4>{item.MakaleAdi.trim()}</h4>
							<p>{item.MakaleOzeti.trim().substring(0, 83) + '...'}</p>
						</div>
						<div className="grid-grd"></div>
						{author && (
							<div className="grid-date">
								<span>
									<img
										className="heart"
										src="content\img\liked.png"
										width="11"
									/>
									<span className="heart-count">
										{item.MakaleBeğeni} Beğenme
									</span>
								</span>
							</div>
						)}

						<div
							className="grid-image lazyload"
							data-bgset={
								'https://cdn.seturday.com/content/uploads/makale_resimleri/kapak/' +
								item.MakaleBuyukResim
							}></div>
					</div>
					{!author && (
						<div className="grid-bottom grid-none">
							<div className="grid-date">
								<span>
									<img
										className="heart"
										src="content\img\liked.png"
										width="11"
									/>
									<span className="heart-count">
										{item.MakaleBeğeni} Beğenme
									</span>
								</span>
							</div>
						</div>
					)}
					{author && (
						<div className="grid-bottom">
							{
								<>
									<div className="grid-profile-img">
										<img
											src={
												'https://cdn.seturday.com/content/uploads/yazar_resimleri/' +
												(author.Resim || 'anymous.png')
											}
										/>
									</div>
									<div className="grid-bottom-info">
										<h6>
											{author.Name} {author.Surname}
										</h6>
										<p>{author.Yazarhakkinda && author.Yazarhakkinda.trim().substring(0, 83) + '...'}</p>
									</div>
								</>
							}
						</div>
					)}
				</div>
			</Link>
		)
	}
}
