import React, {Component} from 'react'
import Const from '../../const'

export default class Layout1Field2Component extends Component<
	ILayout1Prop,
	ILayout1State
> {
	constructor(props: ILayout1Prop) {
		super(props)
	}

	render() {
		return (
			<React.Fragment>
				{this.props.articleLayout.layout1 && (
					<div className="layout-wrap-in clearfix wow fadeInUp">
						<div className="layout-text-image-area-3">
							<div className="layout-image-left-3">
								<img
									src={`${Const.seturdayCdnArticleLayout1Url}${this.props.articleLayout.layout1.Alan4resim}`}
									className="two-image-5  wow fadeInUp"
								/>
							</div>
							<div className="layout-text-right-3">
								<div className="layout-text-right-in-3">
									<p
										className="layout-p-middle"
										dangerouslySetInnerHTML={{
											__html: this.props.articleLayout.layout1.Alan4ozet
										}}></p>
									<p
										className="layout-p-bottom"
										dangerouslySetInnerHTML={{
											__html: this.props.articleLayout.layout1.Alan4aciklama
										}}></p>
								</div>
							</div>
						</div>
					</div>
				)}
			</React.Fragment>
		)
	}
}

interface ILayout1Prop {
	articleLayout: IArticleLayout
}

interface ILayout1State {}
