export const SETUR_EMAIL = "kurumsal@setur.com.tr"
export const SETUR_PHONE = "0216 554 38 32"

export const COOKIES = 
[
    {
        name: "Google Analytics",
        forWhat: "Ölçümleme Site içi iyileştirme",
        category: "İşlevsel ve analitik çerezler Ticari çerezler",
        url: ["https://tools.google.com/dlpage/gaoptout?hl=tr"]
    },
    {
        name: "Google Dynamic Remarketing",
        forWhat: "Reklam",
        category: "Ticari çerezler",
        url: ["https://support.google.com/ds/answer/2839090?hl=tr", "https://adssettings.google.com/anonymous?hl=tr"]
    },
    {
        name: "Google Dynamic Remarketing",
        forWhat: "Reklam",
        category: "Ticari çerezler",
        url: ["https://support.google.com/ads/answer/2662922?hl=tr", "https://adssettings.google.com/anonymous?hl=tr"]
    },
    {
        name: "Google Adwords User Lists",
        forWhat: "Reklam",
        category: "Ticari çerezler",
        url: ["https://support.google.com/ads/answer/2662922?hl=tr", "https://adssettings.google.com/anonymous?hl=tr"]
    },
    {
        name: "Site içi iyileştirme",
        forWhat: "Site içi iyileştirme",
        category: "İşlevsel ve analitik çerezler",
        url: ["https://adssettings.google.com/anonymous?hl=tr"]
    },
    {
        name: "Facebook Connect",
        forWhat: "Reklam",
        category: "Ticari çerezler",
        url: ["https://en-gb.facebook.com/help/568137493302217"]
    },
    {
        name: "Yandex Metrica",
        forWhat: "Ölçümleme, Site içi iyileştirme",
        category: "İşlevsel ve analitik çerezler, Ticari çerezler",
        url: ["https://yandex.com/support/metrica/general/opt-out.xml"]
    },
    {
        name: "Hotjar",
        forWhat: "Ölçümleme, Site içi iyileştirme",
        category: "İşlevsel ve analitik çerezler, Ticari çerezler",
        url: ["https://www.hotjar.com/legal/compliance/opt-out/"]
    },
]

export const COOKIE_INFORMATIONS = [
    {
        title: "İşlevsel ve Analitik",
        description: "Çerezler tercihlerinizi hatırlamak, internet sitesinin etkin şekilde kullanılması, sitenin kullanıcı isteklerine cevap verecek şekilde optimize edilmesi ve ziyaretçilerin siteyi nasıl kullandığı hakkında verileri içerir. Niteliği gereği bu türdeki çerezler kullanıcı adı vb. kişisel bilgilerinizi içerebilir."
    },
    {
        title: "Üçüncü Taraf Çerezler",
        description: "Setur internet siteleri/mobil uygulamaları/mobil internet siteleri üçüncü parti güvenilir, tanınmış reklam sağlayıcılarıyla çalışmaktadır. Üçüncü parti hizmet sağlayıcılar size özel reklamları sunabilmek için kendi çerezlerini yerleştirmektedirler. Üçüncü parti tarafından yerleştirilen çerezler internet sitelerinde ziyaretçilerin gezinme bilgilerini toplar, işler ve nasıl kullandıklarını analiz eder.",
    },
    {
        title: "Ticari çerezler",
        description: "İlgi alanlarınız ve seçimleriniz doğrultusunda hedeflediğiniz ürün/içeriğe benzer nitelikli olanların sunulması ve daha gelişmiş, kişiselleştirilmiş bir reklam portföyü sunarak kullanım deneyiminizi artırmaya yarar. Yukarıda ifade edilen oturum, kalıcı, işlevsel ve analitik ve ticari çerezlerin arka planda tutulma süresi yaklaşık 2 (iki) ay olup şahıs internet tarayıcısı ayarlarından bunda gerekli ayarlamaları yapılabilir. İşbu ayarlardan kaldırılma işlemi internet tarayıcısı bazlı değişebilmektedir."
    },
    {
        title: "Çerezleri nasıl silebilirim?",
        description: "Pek çok internet tarayıcısı, bilgisayarınıza ilk kurulumundan itibaren çerezleri otomatik olarak kabul etmeye ve kullanmaya ayarlıdır. İnternet tarayıcınızın yardım ya da ayarlar menülerini kullanarak, çerezlerin engellenmesini veya cihazınıza çerez gönderildiğinde uyarı verilmesini sağlayabilirsiniz. Çerezleri yönetmenin farklı yollarını öğrenmek, kullandığınız tarayıcının ayarlarını nasıl düzenleyeceğiniz hakkında ayrıntılı bilgi almak için tarayıcınızın talimat ve yardım seçenekleri ekranından veya Chrome, Mozilla Firefox, Internet Explorer 'ın yardım sayfalarına ulaşabilirsiniz.",
    }
]


export const COOKIE_WARNING_TEXT_1 = "Setur’ a ait web siteleri ve diğer sistemlerde veya uygulamalarda kişisel verilerin toplanması, işlenmesi ve kullanılması durumunda ilgili kişiler gizlilik bildirimi ile ve gerekirse çerezler hakkında bilgilendirilir. Kişisel verileriniz, hukuka uygun olarak işlenecektir."
export const COOKIE_WARNING_TEXT_2 = "Web sayfamızı ziyaret ettiğinizde sayfamızda kullandığımız/kullanacağımız çerezlerle ile ilgili olarak aşağıdaki bilgileri dikkatinize sunmaktayız."
