import React, {PureComponent} from 'react'
import {observer, inject} from 'mobx-react'

@inject('loginStore')
@observer
export default class RequiredLoginComponent extends PureComponent<
	IRequiredLoginProp,
	IRequiredLoginState
> {
	constructor(props: IRequiredLoginProp) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.register = this.register.bind(this)
		this.login = this.login.bind(this)
	}

	componentDidMount() {}

	onClose(e: React.MouseEvent<HTMLElement>) {
		e.preventDefault()
		$('.error-overlay').removeClass('active')
		$('body').removeClass('pfix-sb')
		$('first-overlay').removeClass('pfix-sb')

		this.props.onClose && this.props.onClose()
	}

	closeModal() {
		$('.sedvice-circle').removeClass('active')
		$('.bucket-overlay, .new-map-overlay, .bucket-area, .new-map new-map-area').removeClass('active')
		$('body').removeClass('pfix-sb')
		$('first-overlay').removeClass('pfix-sb')
		setTimeout(function() {
			$('.main-wrap').removeClass('ov-pe')
		}, 2500)
	}

	register(e: React.MouseEvent<HTMLElement>) {
		e.preventDefault()
		this.closeModal()
		this.props.loginStore.openRegisterModal()
	}

	login(e: React.MouseEvent<HTMLElement>) {
		e.preventDefault()
		this.closeModal()
		this.props.loginStore.openLoginModal()
	}

	render() {
		if (!this.props.loginStore.user.Id)  {
			$('.error-overlay').addClass('active')
		}
		return (
			<>
				{!this.props.loginStore.user.Id && (
					<div
						className="error-overlay for-like-error"
						id={this.props.id}>
						<div className="error-modal">
							<div className="error-close"></div>
							<p>
								{this.props.title ||
									'Bu özelliği kullanabilmeniz için üye olmanız veya giriş yapmanız gerekmektedir.'}
							</p>
							<p>
								<a href="#" onClick={this.register} className="bucket-uye-ol">
									Üye Ol
								</a>
								<a href="#" onClick={this.login} className="giris-button">
									Giriş Yap
								</a>
								<a href="#" onClick={this.onClose} className="bucket-uye-close">
									Kapat
								</a>
							</p>
						</div>
					</div>
				)}
			</>
		)
	}
}

interface IRequiredLoginProp {
	loginStore?: ILoginStore
	title?: string
	onClose?: Function
	id?: string
}

interface IRequiredLoginState {}
