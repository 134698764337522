import { observable, action } from 'mobx'
import {
	HubConnectionBuilder,
	HubConnection,
	LogLevel,
	HttpTransportType
} from '@aspnet/signalr'
import ServiceUser from '../services/ServiceUser'
import { sumBy } from 'lodash'
import ServiceScore from '../services/ServiceScore'
import Const from '../const'

declare global {
	interface Window { asd: any; }
}

window.asd = window.asd || {}

class LoginStore implements ILoginStore {
	@observable user: IUserInfo = {}
	@observable unseenCount: number = 0
	@observable score: number = 0
	@observable isLogin: boolean = false

	connection: HubConnection

	constructor() {
	}

	private async getUnseenCount() {
		const res = await ServiceUser.getConversations(100, 0)
		this.unseenCount = sumBy(res.data.Chats, i => i.UnseenCount)
	}

	private disConnectSignalR() {
		if (this.connection) {
			this.connection.stop()
			this.connection = null
		}
	}
	@action setUnseenCount() {
		this.getUnseenCount()
	}

	@action openLoginModal() {
		$('.right-menu .giris-button')[0].click()
	}

	@action closeLoginModal() {
		$('.login-close')[0].click()
	}

	@action openRegisterModal() {
		this.openLoginModal()
		$('.uye-next')[0].click()
	}

	@action getScore() {
		ServiceScore.getScore().then(res => {
			this.score = res.data
		})
	}
	@action socket() {
	//	return this.connection;
	}
	private connectSignalR() {
		let token = JSON.parse(localStorage.getItem('userToken')) as IAccessToken
		if (token) {
			this.connection = new HubConnectionBuilder()
				.withUrl(`${Const.baseApiUrl}/chatter`, {
					accessTokenFactory: () => token.AccessToken,
					transport: HttpTransportType.LongPolling
				})
				.configureLogging(process.env.NODE_ENV !== 'development'? LogLevel.Critical : LogLevel.Debug)
				.build()
			this.connection.start().then(() => {
				this.connection.invoke('ConnectionIncome').catch(err => console.error(err.toString()))
				this.connection.on('ReceiveMessage', () => {
					this.getUnseenCount()
				})
			})
		}
	}

	@action setUser(user: IUserInfo) {
		$('.no-log').off('click')
		if (user.Activation === false) {
			$('.no-log-notactive').on('click', () => {
				$('#error-overlay-active').addClass('active')
			})
		}
		this.user = user
		this.isLogin = true
		this.connectSignalR()
		this.getUnseenCount()
	}

	@action refreshUser() {
		ServiceUser.me()
			.then(async res => {
				if (res.data.Success) {
					await this.setUser(res.data)
				}
			})
	}

	replacePhoto(photo: string) {
		const userInfo = JSON.parse(sessionStorage.getItem('userInfo')) as IUserInfo
		userInfo.Resim = photo
		this.setUser(userInfo)
		sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
	}

	signOut() {
		sessionStorage.removeItem('userInfo')
		localStorage.removeItem('userToken')
		this.disConnectSignalR()
		this.user = {}
		this.unseenCount = 0
		this.score = 0
		window.location.href = '/'
	}
}

const store = new LoginStore()
export default store
