const passwordController = (password: string) => {
    const uppercaseRegExp   = /(?=.*?[A-Z])/;
    const lowercaseRegExp   = /(?=.*?[a-z])/;
    const digitsRegExp      = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp   = /.{8,}/;

    const passwordLength = password.length;
    const uppercasePassword = uppercaseRegExp.test(password);
    const lowercasePassword = lowercaseRegExp.test(password);
    const digitsPassword =  digitsRegExp.test(password);
    const specialCharPassword = specialCharRegExp.test(password);
    const minLengthPassword = minLengthRegExp.test(password);

    let errMsg;
    // Messages can be customized in the future if if necessary.
    if (passwordLength===0) {
            errMsg = "Password is empty";
    } else if(!uppercasePassword){
            errMsg =" At least one Uppercase";
    } else if(!lowercasePassword){
            errMsg = "At least one Lowercase";
    } else if(!digitsPassword){
            errMsg = "At least one digit";
    } else if(!specialCharPassword){
            errMsg = "At least one Special Characters";
    } else if(!minLengthPassword){
            errMsg = "At least minumum 8 characters";
    } else{
        errMsg = "";
    }
        
    return errMsg ? "Şifreniz en az 8 karakter olup; bir büyük harf, bir küçük harf, bir rakam ve bir özel karakter içermelidir." : "";
}

export default passwordController