import React from 'react'
import { Switch, Route, Redirect } from 'react-router'
import ArticleComponent from './pages/Article/ArticleComponent'
import Page404Component from './pages/Page404Component'
import AuthorsComponent from './pages/AuthorsComponent'
import ToBeAuthorComponent from './pages/ToBeAuthorComponent'
import MainPageComponent from './pages/MainPage/MainPageComponent'
import AuthorComponent from './pages/AuthorComponent'
import MyAccountComponent from './pages/MyAccount/MyAccountComponent'
import MyLikesComponent from './pages/MyLikesComponent'
import CategoryComponent from './pages/CategoryComponent'
import SetTalksComponent from './pages/SetTalksComponent'
import SetTalksArticleComponent from './pages/Article/SetTalksComponent'
import MyBucketListComponent from './pages/MyBucketListComponent'
import MyPlacesWentComponent from './pages/MyPlacesWentComponent'
import MostClickedComponent from './pages/MostClickedComponent'
import SedviceComponent from './pages/SedviceComponent'
import SearchComponent from './pages/SearchComponent'
import SendArticleComponent from './pages/SendArticle/SendArticle'
import MyArticlesComponent from './pages/MyAccount/MyArticlesComponent'
import SaveDraftComponent from './pages/SendArticle/SaveDraft'
import SavePublishComponent from './pages/SendArticle/SavePublish'
import UnAuthorizedComponent from './pages/UnAuthorized'
import Kvkk from './pages/Kvkk'
import CookiePolicy from './pages/CookiePolicy'

const categories:string = '(dijital-gezgin|trend-seyahat-rotalari|gurme|is-seyahatim|kultur-sanat|kesifler|naturally|meraklisina|setur-liste)'
export default class Routes extends React.Component {
	render() {
		return (
			<Switch>
				<Route exact path="/" component={MainPageComponent} />
				<Route exact path="/yazarol" component={ToBeAuthorComponent} />
				<Redirect exact from="/hesabim" to="/hesabim/profilim" />
				<Route exact path="/hesabim/bucket" component={MyBucketListComponent} />
				<Route exact path="/hesabim/gittigim-yerler" component={MyPlacesWentComponent} />
				<Route exact path="/hesabim/:tab/:pageId?" component={MyAccountComponent} />
				<Route exact path="/yazarlar" render={(props) => (<AuthorsComponent isAuthor={true} {...props}/>)}/>
				<Route exact path="/sizden-gelenler" render={(props) => (<AuthorsComponent isAuthor={false} {...props}/>)} />
				<Route exact path="/yazarlar/:author" component={AuthorComponent} />
				<Route exact path='/cok-okunanlar' component={MostClickedComponent} />
				<Route exact path='/yazi-gonder' component={SendArticleComponent} />
				<Route path="/arama" component={SearchComponent} />
				<Route path="/sedvice/:city" component={SedviceComponent} />
				<Route exact path='/yazi-gonder/SaveDraft' component={SaveDraftComponent} />
				<Route exact path='/yazi-gonderildi' component={SavePublishComponent} />
				<Route exact path='/yazi-gonder/:guid?' render={(props) => (<SendArticleComponent isPreview={false} {...props}/>)} />
				<Route exact path='/yazi/:guid' render={(props) => (<SendArticleComponent isPreview={true} {...props}/>)} />
				<Route exact path='/yetkisizislem' component={UnAuthorizedComponent} />
				<Route exact path="/begendiklerim" component={MyLikesComponent} />
				<Route exact path="/set-talks/:articleUrl" component={SetTalksArticleComponent} />
				<Route exact path="/set-talks" component={SetTalksComponent} />
				<Route exact path={`/:categoryUrl${categories}`} component={CategoryComponent} />
				<Route exact path="/:categoryUrl/:articleUrl" component={ArticleComponent} />
				<Route exact path="/sedvice/arama/:keyword" component={SedviceComponent} />
				<Route exact path="/kisisel-verilerin-korunmasi" component={Kvkk} />
				<Route exact path="/cerez-politikasi" component={CookiePolicy} />

				<Route path="*" component={Page404Component} />
			</Switch>
		)
	}
}
