import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import UnAuthorizedComponent from './UnAuthorized'
import ServiceBucket from '../services/ServiceBucket'
import Const from '../const'
import Helmet from 'react-helmet'
import Seo from '../helper/seo'
import Notification from '../helper/notification'

@inject('loginStore', 'bucketStore')
@observer
export default class MyBucketListComponent extends Component<
IMyBucketListProp,
IMyBucketListState
> {
	constructor(props: IMyBucketListProp) {
		super(props)
	}

	async changeReadyToGo(bucket: IBucket) {
		const { user } = this.props.loginStore
		if (
			!bucket.Gitmekistiyorum &&
			!user.AvaibleForCall &&
			user.Emailgelsin === 0 &&
			!user.AvaibleForSms
		) {
			var mesaj =
				'Sizinle iletişime geçebilmemiz için hesabım sayfasından SMS, E-mail veya telefon seçeneklerinden en az bir tanesine izin vermeniz gerekmektedir.'
			var link =
				' İzin vermek için <a href="/hesabim/profilim" class="hesabim-link-white">tıklayın</a>'
			Notification.showToast(mesaj + link, 50000)
			bucket.Gitmekistiyorum = false
		} else {
			await this.props.bucketStore.changeReadyToGo(bucket)
		}
	}

	async remove(e: React.MouseEvent<HTMLAnchorElement>, bucket: IBucket) {
		e.preventDefault()
		const { CityCountryModel } = bucket
		await this.props.bucketStore.remove(bucket)
		window.dataLayer.push({
			'event': 'removeFromCart',
			'remove': {
				'location': [{
					GooglePlaceId: CityCountryModel.GooglePlaceId,
					Id: CityCountryModel.Id,
					City: CityCountryModel.Sehir.trim(),
					Country: CityCountryModel.Ulke.trim(),
				}]
			}
		})
	}

	showMapModal = () => {
		$('.bucket-overlay').addClass('active')
		$('#bucket-modal').addClass('active')
		$('.sedvice-circle').addClass('active')
		$('body').addClass('pfix-sb')
		$('first-overlay').addClass('pfix-sb')
		$('.main-wrap').addClass('ov-pe')
		if (!this.props.loginStore.user.Id) {
			$('.for-like-error').addClass('active')
		} else {
			$('.for-like-error').removeClass('active')
		}
	}

	async getBucketList() {
		await this.props.bucketStore.getBucketList()
		this.setState({})
	}

	componentDidMount() {
		this.getBucketList()
	}

	render() {
		let { user } = this.props.loginStore
		if (!this.props.loginStore.user.Id) {
			return <UnAuthorizedComponent />
		}
		return (
			<div className="first-right-content">
				<Helmet>
					<title>{Seo.getTitle('My Bucket List')}</title>
				</Helmet>
				<div className="profile-wrap clearfix">
					<div className="profile-wrap-in clearfix">
						<div className="username-area">
							<div className="user-name-big">
								<h1>
									{user.Name} {user.Surname}
								</h1>
							</div>
						</div>

						<div className="profile-content-in" id="profile-ayarlar">
							<div className="profile-hesabim pt-30 mb-30">
								<div className="profile-hesabim pt-30 mb-30">
									<p className="profile-title-p">Bucket List</p>
									<div className="gittigim-list-area">
										{this.props.bucketStore.bucketList.map(bucket => {
											let {
												GooglePlaceId,
												Resim,
												Sehir,
												Ulke
											} = bucket.CityCountryModel
											return (
												<div
													key={GooglePlaceId}
													className="place-block places-bucket"
													data-googleplaceid={GooglePlaceId}
													data-ready="False">
													<div className="place-image-top">
														<img src={Const.seturdayCdnBuckerImageUrl(Resim)} />
													</div>
													<div className="place-name-bottom">
														<div className="place-detail">
															<h4>{Sehir}</h4>
															<h5>{Ulke}</h5>
														</div>
														<div className="place-delete">
															<a href="#" onClick={e => this.remove(e, bucket)}>
																<img src="/content/img/delete-icon.png" />
															</a>
														</div>
													</div>
													<div className="place-control-wrapper">
														<div className="ready-to-go-controls toggle-ready-to-go">
															<label className="container">
																Gitmeye Hazırım
																<input
																	type="checkbox"
																	onChange={e => this.changeReadyToGo(bucket)}
																	checked={bucket.Gitmekistiyorum}
																	className="toggle-ready-to-go-input"
																/>
																<span className="checkmark"></span>
															</label>
														</div>
													</div>
												</div>
											)
										})}
										<div className="place-block">
											<div
												className="bucket-button add-new-block"
												onClick={this.showMapModal}></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

interface IMyBucketListProp {
	loginStore?: ILoginStore
	bucketStore?: IBucketStore
}

interface IMyBucketListState { }
