import React from 'react'
import ServiceArticle from '../services/ServiceArticle'
import ServiceCategory from '../services/ServiceCategory'
import {RouteComponentProps} from 'react-router'
import Seo from '../helper/seo'
import Helmet from 'react-helmet'
import {Link} from 'react-router-dom'
import Const from '../const'
import Pagination from '../components/Pagination'
import Loading from '../elements/loading'

export default class MostClickedComponent extends React.Component<
	IMostClickedProp,
	IMostClickedState
> {
	constructor(props: any) {
		super(props)
		this.state = {
			articles: [],
			categories: [],
			loaded: false
		}

		ServiceCategory.categories().then(res => {
			this.setState({
				categories: res.data
			})
		})

		ServiceArticle.readArticles().then(res => {
			this.setState({
				articles: res.data,
				loaded: true
			})
		})
	}

	getCategory(categoryId: number): ICategory {
		if (this.state.categories && this.state.categories.length > 0) {
			return this.state.categories.filter(c => c.Id == categoryId)[0]
		}
		return {} as ICategory
	}

	render() {
		return (
			<div className="first-right-content">
				{
					<Helmet>
						<title>{Seo.getTitle('Çok Okunanlar')}</title>
					</Helmet>
				}
				<div
					className="main-slide category"
					style={{
						background:
							'url(Content/img/categoryimage/en-cok-okunanlar-hero.jpg) center center',
						backgroundSize: 'cover'
					}}>
					<div className="main-title-area-index">
						<div className="main-title-index">
							<h1>Çok Okunanlar</h1>
						</div>
					</div>
				</div>
				<div className="category-wide-grid">
					<div className="grid-area wide-grid">
						{!this.state.loaded && (
							<Loading loaded={this.state.loaded}></Loading>
						)}
						{this.state.articles &&
							this.state.articles.map(item => {
								var showAuthorInfos =
									item.AuthorFullName && item.AuthorFullName.trim()
								return (
									<Link
										key={`article_${item.Id}`}
										to={`/${
											this.getCategory(item.MakaleKategoriId).Kategoriurl
										}/${item.Url}`}>
										<div className="grid grid-medium-ctg">
											<div
												className={
													'grid-top ' + (!showAuthorInfos ? 'grid-h-100' : '')
												}>
												<div className="grid-top-info">
													<h4>{item.MakaleAdi}</h4>
													<p>{item.MakaleOzeti.substring(0, 83) + '...'}</p>
												</div>{' '}
												<div className="grid-grd"></div>
												<div
													className="grid-image"
													style={{
														background:
															'url(https://cdn.seturday.com/content/uploads/makale_resimleri/kapak/' +
															item.MakaleBuyukResim +
															') center center'
													}}></div>
											</div>
											<div className="grid-bottom grid-none">
												<div className="grid-date ">
													<span>
														<img
															className="heart"
															src="Content\img\liked.png"
															width="11"
															alt={item.MakaleAdi}
														/>
														<span className="heart-count">
															{item.MakaleBeğeni} Beğenme
														</span>
													</span>
												</div>
											</div>
											{showAuthorInfos && (
												<div className="grid-bottom">
													<div className="grid-profile-img">
														<img
															src={`${Const.seturdayCdnAuthorUrl}${item.Resim}`}
															alt={item.MakaleAdi}
														/>
													</div>
													<div className="grid-bottom-info">
														<h6>{item.AuthorFullName} </h6>
														<p>{item.AuthorDetail && item.AuthorDetail.trim().substring(0, 83) + '...'}</p>
													</div>
												</div>
											)}
										</div>
									</Link>
								)
							})}
					</div>
				</div>
			</div>
		)
	}
}

interface IMostClickedRouteProp {}

interface IMostClickedProp extends RouteComponentProps<IMostClickedRouteProp> {}

interface IMostClickedState {
	articles: IArticle[]
	categories: ICategory[]
	loaded: boolean
}
