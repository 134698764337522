export default class HelperDom {
	static tryCount = 100

    static loadScript(src: string, async: boolean = false, defer: boolean = false) {
        if(document.querySelector(`script[src='${src}']`)) return

        const tag = document.createElement('script')
        tag.async = async
        tag.defer = defer
        tag.src = src
        const body = document.getElementsByTagName('body')[0]
        body.appendChild(tag)
    }

    static loadStyle(href: string) {
        if(document.querySelector(`link[href='${href}']`)) return
        const tag = document.createElement('link')
        tag.href = href
        tag.rel = 'stylesheet'
        const body = document.getElementsByTagName('head')[0]
        body.appendChild(tag)
	}
	
	static isDefinedAndRun(checkObject: any) {
		var _tryCount = 0
		var interval: NodeJS.Timeout
		return new Promise((res, rej) => {
			interval = setInterval(() => {
				if (_tryCount >= 100) {
					clearInterval(interval)
					rej()
				} else if (window[checkObject]) {
					clearInterval(interval)
					res()
				}
				_tryCount++
			}, 500)
		})
	}
}