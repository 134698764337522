import React, {Component} from 'react'
import Const from '../../const'

export default class Layout3Field4Component extends Component<
	ILayout3Prop,
	ILayout3State
> {
	constructor(props: ILayout3Prop) {
		super(props)
	}

	render() {
		return (
			<React.Fragment>
				{this.props.articleLayout.layout3 && (
					<div className="two-side-image-text">
						<div className="side-image-left">
							{this.props.articleLayout.layout3 && (
								<img
									src={`${Const.seturdayCdnArticleLayout3Url}${this.props.articleLayout.layout3.AlandortResimUrl}`}
								/>
							)}
						</div>
						<div className="side-image-right">
							<h2 className="side-image-right-title">
								{this.props.articleLayout.layout3 &&
									this.props.articleLayout.layout3.AlandortBaslik}
							</h2>
							<p className="side-image-right-subtext">
								{this.props.articleLayout.layout3 &&
									this.props.articleLayout.layout3.AlandortOzet}
							</p>
							<p
								className="side-image-right-text"
								dangerouslySetInnerHTML={{
									__html: this.props.articleLayout.layout3.AlandortAciklama
								}}
							/>
						</div>
					</div>
				)}
			</React.Fragment>
		)
	}
}

interface ILayout3Prop {
	articleLayout: IArticleLayout
}

interface ILayout3State {}
