import React, { Component } from 'react'

export default class HowToScorePopup extends Component<IHowToScorePopupProp, IHowToScorePopupState> {
	render() {
		return <div className='how-to-score-popup' id='HowToScorePopup'>
			<div className='popup-out-toggler popup-toggle-close'></div>
			<div className='popup-inner-wrapper'>
				<div className='popup-close popup-toggle-close'>x</div>
				<div className='popup-inner'>
					<div className="popup-content">
						<h4>Nasıl Puan Kazanırım?</h4>
						<div className="how-to-score-item">
							<div className="left"><div className="circle-icon-box"><img src="/Content/img/user-icon.png" /></div></div>
							<div className="right">
								<h5>Üye ol, kazan!</h5>
								<p><strong>520 Puan (Tek Sefer)</strong></p>
								<p>Seturday üyeleri her zaman kazançlı!<br />
									Seturday’e üye olanlar anında 520 puan kazanıyor, daha birçok fırsat Seturday’de sizi bekliyor.</p>
							</div>
						</div>
						<div className="how-to-score-item">
							<div className="left"><div className="circle-icon-box"><img src="/Content/img/group-icon.png" /></div></div>
							<div className="right">
								<h5>Referans ol, kazan!</h5>
								<p><strong>400 Puan (Aylık)</strong></p>
								<p>
									Sana verilen üye numaran ile ayda en fazla 5 kişinin Seturday’e üye olmasını sağla, puanları topla!
                        	</p>
							</div>
						</div>
						<div className="how-to-score-item">
							<div className="left"><div className="circle-icon-box"><img src="/Content/img/like-icon.png" /></div></div>
							<div className="right">
								<h5>Beğen, kazan!</h5>
								<p><strong>30 Puan (Aylık)</strong></p>
								<p>Seturday içeriklerini beğen, günde 1, ayda 30 puan topla!</p>
							</div>
						</div>
						<div className="how-to-score-item">
							<div className="left"><div className="circle-icon-box"><img src="/Content/img/send-icon.png" /></div></div>
							<div className="right">
								<h5>Yazı gönder, kazan!</h5>
								<p><strong>60 Puan (Aylık)</strong></p>
								<p>Seturday’e 1 ay içinde göndereceğin en fazla 3 yazı ile puanları topla!</p>
							</div>
						</div>
						<div className="how-to-score-item">
							<div className="left"><div className="circle-icon-box"><img src="/Content/img/badge-icon.png" /></div></div>
							<div className="right">
								<h5>Yazın onaylansın, kazan!</h5>
								<p><strong>500 Puan (Aylık)</strong></p>
								<p>Ayda 1 kez onaylanan içeriğinle puanları topla!</p>
							</div>
						</div>
						<div className="how-to-score-item">
							<div className="left"><div className="circle-icon-box"><img src="/Content/img/interaction-icon.png" /></div></div>
							<div className="right">
								<h5>Etkileşim sağla, kazan!</h5>
								<p><strong>300 Puan (Aylık)</strong></p>
								<p>Yayınlanan içeriğini, sosyal medya hesaplarında paylaş, beğenildikçe puan kazan!</p>
							</div>
						</div>
						<div className="how-to-score-item">
							<div className="left"><div className="circle-icon-box"><img src="/Content/img/mission-icon.png" /></div></div>
							<div className="right">
								<h5>Görevi tamamla, kazan!</h5>
								<p><strong>2000 Puan (Yıllık)</strong></p>
								<p>Sosyal medya hesaplarından ve Seturday.com üzerinden yapacağımız paylaşımları takip et, yarışmalara katıl, verilen görevleri tamamla, tamamladıkça puanları topla!</p>
							</div>
						</div>
						<div className="how-to-score-bottom">
							1 ay içinde en fazla <strong>1500</strong>, 1 yıl içinde ise en fazla <strong>18000</strong> puan kazanabilirsiniz.
                	</div>
					</div>
				</div>
			</div>
		</div>
	}
}

interface IHowToScorePopupProp {
}

interface IHowToScorePopupState {
}