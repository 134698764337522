import React from 'react'
import ServiceUser from '../services/ServiceUser'
import Notification from '../helper/notification'
import HelperDom from '../helper/helper-dom'
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3'

export default class ResetPasswordChange extends React.Component<
	IResetPasswordChangeProp,
	IResetPasswordChangeState
> {
	loginCaptcha: any

	captchaToken: string

	constructor(props: IResetPasswordChangeProp) {
		super(props)

		this.state = {
			hash: '',
			password: '',
			rePassword: '',
			message: '',
			isSuccess: false
		}
	}


	handleSubmit = (e: React.MouseEvent<HTMLInputElement>) => {
		e.preventDefault()
		const {hash, password, rePassword} = this.state
		const {email} = this.props
		
		if (hash === '') {
			e
			this.setState({message: 'Geçersiz doğrulama kodu!'})
		} else if (password === '') {
			this.setState({message: 'Şifre alanı boş olamaz!'})
		} else if (password !== rePassword) {
			this.setState({
				message: '"Şifre Tekrar" alanı "Şifre" alanı ile eşleşmelidir.'
			})
		} else {
			this.setState({message: ''})
			ServiceUser.reNewPassword({
				hash,
				password,
				email,
			}, this.captchaToken)
				.then(res => {
					if (res.status === 200) {
						this.setState({
							message:
									'Parolanız başarılı bir şekilde değiştirildi. Giriş yapabilirsiniz.',
							isSuccess: true,
						})
					}
				})
				.catch(err => {
					this.setState({
						message: err.response.data.Message || 'Bilinmeyen hata!'
					})
					
				})
		}
		
	}

	componentWillReceiveProps(nextProps: IResetPasswordChangeProp) {
		if (nextProps.parentMessage !== '') {
			Notification.showToast(nextProps.parentMessage)
		}
	}

	gotoLoginTab = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault()
		this.props.changeTab('Login')
	}

	render() {
		const { isSuccess, message } = this.state
		return (
			<GoogleReCaptchaProvider reCaptchaKey='6LeEcZkgAAAAAHgE3gei4EqZ9_BvLotd0uuxIKSp'>
				<form
					id="resetPassword"
					method="post"
					onKeyDown={event => event.keyCode != 13}>
					<div
						className="login-bottom-in"
						id="reset-password-code"
						style={{display: 'block'}}>
						<span>Şifre Sıfırlama</span>
						<span className="subText">
						E-postanıza gelen kod ile şifrenizi değiştirebilirsiniz.
						</span>
						{message && (
							<p id="reset-password-mesaj" className={isSuccess ? 'success': 'error'}>
								{message}
							</p>
						)}
						{!isSuccess && (
						<>
							<div id="divResetPasswordChange">
								<input
									type="text"
									name="hash"
									onChange={e =>
										this.setState({
											hash: e.target.value
										})
									}
									value={this.state.hash}
									placeholder="Doğrulama Kodu"
									autoComplete="off"
								/>
								<input
									type="password"
									name="password"
									onChange={e =>
										this.setState({
											password: e.target.value
										})
									}
									value={this.state.password}
									placeholder="Yeni Şifre"
								/>
								<input
									type="password"
									name="rePassword"
									onChange={e =>
										this.setState({
											rePassword: e.target.value
										})
									}
									value={this.state.rePassword}
									placeholder="Yeni Şifre Tekrar"
								/>
								<GoogleReCaptcha
									onVerify={token => {
										this.captchaToken = token
									}}
								/>
								<input
									className="submit-button"
									type="submit"
									name="submit"
									data-sitekey="6LeEcZkgAAAAAHgE3gei4EqZ9_BvLotd0uuxIKSp" 
									id="btnResetPasswordChange"
									onClick={this.handleSubmit}
									value="Kaydet"
								/>
							</div>
							<div className="form-b-right" style={{marginTop: 20}}>
								<a
									href="#"
									className="send-activationcode-again"
									onClick={(e) => this.props.btnReset(e, true)}>
									<strong>Tekrar kod gönder</strong>
								</a>
							</div>
						</>
						)}

						<div className="creat-new-account">
							<p>
								<a href="#" className="giris-back" onClick={this.gotoLoginTab}>
									<strong>Geri</strong>
								</a>
							</p>
						</div>
					</div>
				</form>
			</GoogleReCaptchaProvider>
		)
	}
}

interface IResetPasswordChangeProp {
	changeTab: Action1<LoginModalType>
	email?: string
	btnReset: any
	parentMessage: string
}

interface IResetPasswordChangeState {
	hash: string
	password: string
	rePassword: string
	message: string
	isSuccess: boolean
}
