import React, {Component} from 'react'
import Const from '../../const'

export default class Layout1Field2Component extends Component<
	ILayout1Prop,
	ILayout1State
> {
	constructor(props: ILayout1Prop) {
		super(props)
	}

	render() {
		return (
			<React.Fragment>
				{this.props.articleLayout.layout1 && (
					<div className="layout-wrap-in clearfix">
						<div className="layout-text-image-area-2">
							<div className="layout-text-left-2">
								<div className="layout-text-left-in-2">
									<p
										className="layout-p-middle"
										dangerouslySetInnerHTML={{
											__html: this.props.articleLayout.layout1.Alan3ozet
										}}></p>
									<p
										className="layout-p-bottom"
										dangerouslySetInnerHTML={{
											__html: this.props.articleLayout.layout1.Alan3aciklama
										}}></p>
								</div>
							</div>

							<div className="layout-image-right-2">
								<div className="two-image-area">
									<img
										src="/Content/img/parallax-image-4.png"
										className="two-image-3  wow fadeInUp"
									/>
									<img
										src={`${Const.seturdayCdnArticleLayout1Url}${this.props.articleLayout.layout1.Alan3resim}`}
										className="two-image-4  wow fadeInUp"
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			</React.Fragment>
		)
	}
}

interface ILayout1Prop {
	articleLayout: IArticleLayout
}

interface ILayout1State {}
