import React from 'react'

import HelperDom from '../helper/helper-dom'

import ServiceNewsletter from '../services/ServiceNewsletter'

import {observer, inject} from 'mobx-react'
import { Link } from 'react-router-dom';



declare global {

    interface Window { gtag: any; }

}

@inject('loginStore')

@observer

export default class Footer extends React.Component<IFooterProp, IFooterState> {

	constructor(props: any) {

		super(props)



		this.state = {

			email: '',

			name: '',

			surname: '',

			showForm: false,

		}



		this.addNewsletter = this.addNewsletter.bind(this)

	}

	addNewsletter(e: React.MouseEvent<HTMLElement>) {

		e.preventDefault()



		if (

			!this.state.name ||

			!this.state.name.trim() ||

			!this.state.surname ||

			!this.state.surname.trim() ||

			!this.state.email ||

			!this.state.email.trim()

		) {

			return this.showNewsletterMessage('Lütfen Boş Alan Bırakmayınız')

		}

		ServiceNewsletter.add({

			email: this.state.email,

			name: this.state.name,

			surname: this.state.surname

		})

			.then(res => {

				$('.newsletter-area .newsletter-top p').fadeOut(300, function() {

					$('.newsletter-area .newsletter-top h5').text(

						'E-Bülten Kaydınız Başarıyla Gerçekleştirilmiştir.'

					)

					$('.newsletter-area .newsletter-top h5').animate(

						{

							fontSize: '20px',

							marginTop: '40px',

							marginBottom: '0px'

						},

						300

					)

				})

				$('.newsletter-input-area').fadeOut(500)

			})

			.catch(res => {

				this.showNewsletterMessage(res)

			})

	}



	showNewsletterMessage(text: string) {

		$('#newsletter-error').text(text)

		var messageLogin = document.getElementById('mesajlogin')

		messageLogin && (messageLogin.style.display = 'block')

		messageLogin && (messageLogin.innerText = text)

	}



	showTooltip() {

		$('.open-firsat-tooltip').toggleClass('active')

		$('.firsat-tooltip').slideToggle('slow')

	}



	render() {

		if (this.props.loginStore.isLogin ) {

			window.gtag('set', {'user_id': this.props.loginStore.user.Id}) 

		}

		window.gtag('js', new Date())

		window.gtag('config', 'AW-1026052817')

		return (

			<div>

				{/*!this.props.loginStore.isLogin && && <form

					data-ajax='true'

					data-ajax-success='newsletterfunc'

					id='form4'

					method='post'

					onKeyDown={event => event.keyCode != 13}>

					<div className='full-w bg-grey2 pb-50'>

						<div className='newsletter-area clearfix'>

							<div className='newsletter-top'>

								<h5>E-Bülten Kayıt</h5>

								<div className='firsat-tooltip'>

									<p>

										Setur Servis Turistik A.Ş.'nin işletmekte olduğu markalar,

										tedarikçileri ve işbirliği içerisinde olduğu kuruluşlara

										ilişkin hizmet, reklam, ilan, kampanya ve faaliyetleri

										hakkında tarafıma bilgilendirme yapılmasına ve tarafımla

										6563 Sayılı Kanun uyarınca ticari elektronik iletiler

										paylaşılmasına onay veriyor ve verdiğim onayı iptal etmek

										istersem, bu durumu Setur Servis Turistik A.Ş'nin işbu

										mecrada yer alan iletişim kanallarına bildirmeyi kabul

										ediyorum.

									</p>

								</div>

								<p className='open-newsletter-tooltip'>

									<span

										className='open-firsat-tooltip'

										onClick={this.showTooltip}>

										Fırsat ve kampanyalarımızdan 

									</span> 

									haberdar olmak için lütfen e-posta adresinizi bırakın.

								</p>

								<p className='error-message' id='newsletter-error'></p>

							</div>

							<div className='newsletter-input-area'>

								<div className='newsletter-top-new'>

									<input

										type='text'

										name='name'

										id='name'

										placeholder='Adınız'

										onChange={e =>

											this.setState({

												name: e.target.value

											})

										}

										value={this.state.name}

									/>

									<input

										type='text'

										name='surname'

										id='surname'

										placeholder='Soyadınız'

										onChange={e =>

											this.setState({

												surname: e.target.value

											})

										}

										value={this.state.surname}

									/>

								</div>

								<div className='newsletter-input'>

									<input

										type='text'

										name='email'

										id='email'

										placeholder='Email Adresiniz'

										onChange={e =>

											this.setState({

												email: e.target.value

											})

										}

										value={this.state.email}

									/>

								</div>

								<div className='newsletter-submit'>

									<input

										className='submit-button'

										onClick={this.addNewsletter}

										type='submit'

										name='submit'

										value='KAYIT OL'

									/>

								</div>

							</div>

							<div className='newsletter-top news-bottom'>

								<p className='newsletter-bottom-text'>

									Kişisel Verileri Koruma Aydınlatma Metni'ne ulaşmak için{' '}

									<a

										href='https://seturkvkkdocuments.blob.core.windows.net/kvkk-documents/2.0.0/6/1/1/Setur_Musteri_Kisisel_Verilerin_Islenmesi_Aydinlatma_Metni.pdf'

										target='_blank'>

										tıklayın

									</a>

									.

								</p>

							</div>

						</div>

					</div>

				</form>*/}

				



				<div className='footer'>

					<div className='footer-top'>

						<div className='footer-in'>

							<div className='setur-tablet'>

								<img

									src='content\img\setur-extra-logo.jpg'

									alt=''

									className='setur-app-img'

								/>

								<p>

									Setur Extra iPad versiyonunu AppStore ikonundan

									indirebilirsiniz.

								</p>

								<div className='store-icon'>

									<a

										href='https://itunes.apple.com/tr/app/setur-extra/id1244072133?mt=8'

										target='_blank'>

										<img src='content\img\app-store.png' alt='' />

									</a>

								</div>

							</div>



							<div className='setur-mobile'>

								<div className='setur-app-area'>

									<img

										src='content\img\setur-app-logo.png'

										alt=''

										className='setur-app-img'

									/>



									<p>

										Seturday mobil versiyonunu AppStore veya Google Play ikonundan

										indirebilirsiniz.

									</p>

									<div className='store-icon'>

										<a

											href='https://itunes.apple.com/tr/app/seturday/id1475776507'

											target='_blank'>

											<img

												src='content\img\app-store-logo-white-1.png'

												alt=''

											/>

										</a>

										<a

											href='https://play.google.com/store/apps/details?id=com.seturday'

											target='_blank'>

											<img

												src='content\img\google-play-logo-white-1.png'

												alt=''

											/>

										</a>

									</div>

								</div>

							</div>

						</div>

					</div>



					<div className='footer-bottom'>

						<div className='footer-logo'>

							<img src='content\img\koc-logo.png' />

						</div>

						<div className='footer-menu'>

							<ul>

								<li>

									<a

										href='https://www.facebook.com/seturdaycom'

										target='_blank'>

										{' '}

										<img src='content\img\facebook-icon-footer.png' />{' '}

									</a>

								</li>

								<li>

									<a

										href='https://www.instagram.com/seturdaycom/'

										target='_blank'>

										{' '}

										<img src='content\img\instagram-icon-footer.png' />{' '}

									</a>

								</li>

								<li>

									<a href='https://twitter.com/Seturdaycom' target='_blank'>

										{' '}

										<img src='content\img\twitter-icon-footer.png' />{' '}

									</a>

								</li>

							</ul>

						</div>

						<div className='footer-social'>

							<ul>

								<li>

									<a

										href='mailto:info@seturday.com'

										className='seturday-info-mail'>

										info@seturday.com

									</a>

									<p className='seturday-setur'>

										Seturday bir Setur markasıdır.

									</p>

								</li>

							</ul>

						</div>

					</div>

					<div className='footer-bottom-contract'>

						<p >

						<Link to='/kisisel-verilerin-korunmasi' className='footer-kvkk-text'>Kisisel Verilerin Korunması</Link>
			
							<span className='footer-bottom-separator'>|</span>

						<Link to='/cerez-politikasi' className='footer-cookie-text'>Çerez Politikası</Link>
						</p>

					</div>

				</div>

			</div>

		)

	}



	componentDidMount() {

		if (localStorage.getItem('cookiePopState') !== 'shown') {

			$('.toaster-notification')

				.html(

					'<p><span className=\'notification-amount\'>Bu Sitede, kullanıcı deneyimini geliştirmek ve internet sitesinin verimli çalışmasını sağlamak amacıyla çerezler kullanılmaktadır. <a href="https://www.seturday.com/cerez-politikasi" target="blank" style="color: #ffffff!important;font-size: 14px;font-weight: 700;letter-spacing: -0.63px;text-decoration: underline;">Çerez Aydınlatma Metnini İncele</a> <a href=\'#\' onclick=\'function(e) { e.preventDefault()}\' class=\'coockie-ok\'>Tamam</a></span></p>'

				)

				.delay(2e3)

				.fadeIn()

			localStorage.setItem('cookiePopState', 'shown')

		}

	}

}



interface IFooterProp {

	loginStore?: ILoginStore

}



interface IFooterState {

	email: string

	name: string

	surname: string

	showForm: boolean

}

