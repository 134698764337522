import React, {Component} from 'react'
import Const from '../../const'

export default class Layout2Field3Component extends Component<
	ILayout3Prop,
	ILayout3State
> {
	constructor(props: ILayout3Prop) {
		super(props)
	}

	render() {
		return (
			<React.Fragment>
				{this.props.articleLayout.layout2 && (
					<div className="full-w clearfix">
						<div className="layout-wrap-in clearfix">
							<div className="bottom-two-side">
								{this.props.articleLayout.layout2.AlanucResimembeed && (
									<div
										className="bottom-two-side-left"
										dangerouslySetInnerHTML={{
											__html: this.props.articleLayout.layout2.AlanucResimembeed
										}}></div>
								)}
								{this.props.articleLayout.layout2.AlanucResim && (
									<div className="bottom-two-side-left">
										<img
											src={`${Const.seturdayCdnArticleLayout2Url}${this.props.articleLayout.layout2.AlanucResim}`}
											className="wow fadeInUp"
										/>
									</div>
								)}
								<div className="bottom-two-side-right">
									<div className="bottom-two-side-text-area">
										<p
											dangerouslySetInnerHTML={{
												__html: this.props.articleLayout.layout2.Alanucicerik
											}}></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</React.Fragment>
		)
	}

	initializeSlider() {
		var twoSlide = new Swiper('.two-slide', {
			spaceBetween: 30,
			effect: 'fade',
			pagination: {
				el: '.swiper-pagination',
				clickable: !0
			},
			navigation: {
				nextEl: '.two-slide-next',
				prevEl: '.two-slide-prev'
			}
		})
	}
}

interface ILayout3Prop {
	articleLayout: IArticleLayout
}

interface ILayout3State {}
