/* eslint-disable no-unused-vars */
import React from 'react'
import ServiceCategory from '../services/ServiceCategory'
import ServiceUser from '../services/ServiceUser'
import ServiceArticle from '../services/ServiceArticle'
import {RouteComponentProps, Link} from 'react-router-dom'
import Helmet from 'react-helmet'
import Seo from '../helper/seo'
import Const from '../const'
import Pagination from '../components/Pagination'
import Loading from '../elements/loading'

export default class CategoryComponent extends React.Component<
	ICategoryProp,
	ICategoryState
> {
	pageSize = 10
	pageId = Number(this.getQueryVariable('sayfa')) || 1
	categoryId = 0
	categoryUrl = ''

	constructor(props: any) {
		super(props)
		this.state = {
			category: {},
			categories: [],
			articles: [],
			articelsCount: 0,
			loaded: false
		}
		ServiceCategory.categories().then(res => {
			this.setState({
				categories: res.data
			})
		})

		this.categoryUrl = this.props.match.params.categoryUrl

		ServiceCategory.getByName(this.categoryUrl).then(res => {
			this.setState({
				category: res.data
			})
		})
	}

	componentDidMount() {
		this.getData(this.pageId)
	}
	componentWillReceiveProps(nextProps: ICategoryProp) {
		if (this.categoryUrl != nextProps.match.params.categoryUrl) {
			this.categoryUrl = nextProps.match.params.categoryUrl
			this.setState({
				category: this.getCategoryUrl(this.categoryUrl)
			})
			this.getData(1)
		}
		if (this.pageId !== Number(this.getQueryVariable('sayfa'))) {
			this.pageId = Number(this.getQueryVariable('sayfa'))
			this.getData(this.pageId)
		}
	}

	getData(pageId: number = 1) {
		this.setState({loaded: false})
		this.pageId = Number(pageId) || 1

		ServiceArticle.getArticlesByCategoryName(
			this.categoryUrl,
			this.pageSize,
			this.pageId - 1
		).then(async res => {
			for (const article of res.data.articleList) {
				let articleCategory: IArticleForCategoryPage = article
				articleCategory.Name = article.Name
				articleCategory.Surname = article.Surname
				articleCategory.Resim =  article.Resim
				articleCategory.Profilgoster= article.Profilgoster
			}

			this.setState({
				loaded: true,
				articles: res.data.articleList,
				articelsCount: res.data.TotalCount
			})
		})
	}

	getCoverStyle() {
		var obj: any = {}
		this.state.category.KategoriResim &&
			(obj.background = `url('/content/img/categoryimage/${this.state.category.KategoriResim}') center center / cover`)
		return obj
	}

	getQueryVariable(variable: string) {
		var query = window.location.search.substring(1)
		var vars = query.split('&')
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split('=')
			if (decodeURIComponent(pair[0]) == variable) {
				return decodeURIComponent(pair[1])
			}
		}
	}

	getCategory(categoryId: number): ICategory {
		if (this.state.categories && this.state.categories.length > 0) {
			return this.state.categories.filter(c => c.Id == categoryId)[0]
		}
		return {} as ICategory
	}

	getCategoryUrl(categoryUrl: string): ICategory {
		if (this.state.categories && this.state.categories.length > 0) {
			return this.state.categories.filter(c => c.Kategoriurl == categoryUrl)[0]
		}
		return {} as ICategory
	}

	render() {
		const bgStyle = this.getCoverStyle()
		return (
			<div className="first-right-content">
				{this.state.category && this.state.category.KategoriAdi && (
					<Helmet>
						<title>{Seo.getTitle(this.state.category.KategoriAdi)}</title>
						<meta
							name="description"
							content={this.state.category.Description}
						/>
					</Helmet>
				)}
				<div className="main-slide category" style={bgStyle}>
					<div className="main-title-area-index">
						<div className="main-title-index">
							<h1 style={{fontSize: '55px'}}>
								{this.state.category.KategoriAdi}
							</h1>
							<p className="cat-subtitle">{this.state.category.SubTitle}</p>
						</div>
					</div>
				</div>
				<div className="category-wide-grid">
					<div className="grid-area wide-grid">
						{!this.state.loaded && <Loading loaded={this.state.loaded} />}
						{this.state.articles &&
							this.state.articles.map(item => {
								return (
									<Link
										key={`category_${item.Id}`}
										to={`/${
											this.getCategory(item.MakaleKategoriId).Kategoriurl
										}/${item.Url}`}>
										<div className="grid grid-medium-ctg">
											<div
												className={
													'grid-top ' +  // 'grid-h-100'  +
													(!item.Profilgoster ? 'grid-h-100' : '')
												}>
												<div className="grid-top-info">
													<h4>{item.MakaleAdi}</h4>
													<p>{item.MakaleOzeti.substring(0, 83) + '...'}</p>
												</div>
												<div className="grid-grd"></div>
												<div
													className="grid-image"
													style={{
														background:
															'url(https://cdn.seturday.com/content/uploads/makale_resimleri/kapak/' +
															item.MakaleBuyukResim +
															') center center'
													}}></div>
											</div>
											<div className="grid-bottom grid-none">
												<div className="grid-date ">
													<span>
														<img
															className="heart"
															src="Content\img\liked.png"
															width="11"
															alt={item.MakaleAdi}
														/>
														<span className="heart-count">
															{item.MakaleBeğeni} Beğenme
														</span>
													</span>
												</div>
											</div>
											{item.Profilgoster && (
												<div className="grid-bottom ">
													<div className="grid-profile-img">
														<img
															src={`${Const.seturdayCdnAuthorUrl}${item.Resim ||
																'anymous.png'}`}
															alt={item.MakaleAdi}
														/>
													</div>
													<div className="grid-bottom-info">
														<h6>{item.AuthorFullName} </h6>
														{/*<p>{item.Yazarhakkinda && item.Yazarhakkinda.trim().substring(0, 83) + '...'}</p>*/}
													</div>
												</div>
												)}
										</div>
									</Link>
								)
							})}
					</div>
				</div>
				<Pagination
					activePageId={this.pageId}
					perPageCount={10}
					totalCount={this.state.articelsCount}
					urlWithoutPageId={`/${this.state.category.Kategoriurl}`}
				/>
			</div>
		)
	}
}

interface ICategoryState {
	category?: ICategory
	categories?: ICategory[]
	articles?: Array<IArticleForCategoryPage>
	articelsCount?: number
	loaded?: boolean
}

interface IArticleForCategoryPage extends IArticle {
	//author?: IUserInfo,
	Name?: string,
    Surname?: string,
	Resim?: string,
	Profilgoster?:boolean
}

interface ICategoryRouteProp {
	categoryUrl: string
	id: any
	pageId: any
}

interface ICategoryProp extends RouteComponentProps<ICategoryRouteProp> {}
